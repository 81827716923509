import React from 'react'
import { useStateContext } from '../context/DashboardStateContext'

export default function AddRouteModalForm() {
  const {
    showAddRouteMod, 
    setShowAddRouteMod,
    manualRoute, 
    setManualRoute,
    assignDriverRoute, 
    setAssignDriverRoute,
    routeName, 
    setRouteName
} = useStateContext();
  return (
    <div className='absolute w-full flex justify-center flex-col items-end bottom-0 left-0 right-0 top-0' style={{background:"#00000066"}}>
        <div className='w-full cursor-pointer' style={{height:"55%"}} onClick={() => setShowAddRouteMod(false)}></div>
        <div className='w-full px-8 bg-white' style={{}}>
            <p className='font-bold' style={{fontSize:".9rem", marginTop:"3rem"}}>Create a route</p>
            <input 
              type="text" 
              className='mt-3 w-full h-9 rounded-lg px-3' 
              placeholder='Route name' 
              style={{fontSize:".8rem", background:"#f2f2f2"}} 
              onChange={(e) => {
                setRouteName(e.target.value);
              }}
            />
            <p className='mt-2'>How would you like to create this route?</p>
            <button className='w-full h-9 cursor-pointer mb-2' onClick={() => setManualRoute(true)} style={{borderBottom:"1px solid", fontSize:"1rem"}}>Manual Input</button>
            <button className='w-full h-9 cursor-pointer mb-6' style={{borderBottom:"1px solid", fontSize:"1rem"}} onClick={() => setAssignDriverRoute(true)}>Assign Driver</button>
        </div>
    </div>
  )
}
