import React, {useRef, useState, useEffect} from 'react'
import { ImUser } from 'react-icons/im'
import { AiFillCamera } from "react-icons/ai"
import { HiIdentification } from 'react-icons/hi'
import { AiOutlinePlus } from 'react-icons/ai'
import {useFleetStateContext} from '../context/FleetDealsContext'
import {useStateContext} from '../context/DashboardStateContext'
import { height } from '@mui/system'
import { useForm, Controller } from "react-hook-form";
import PhoneInput, { isValidPhoneNumber, formatPhoneNumber } from "react-phone-number-input";
import 'react-phone-number-input/style.css'
import Select from "react-select";
import firebase from '../firebase-config'
import DatePicker from "react-datepicker";
import {AiOutlineCheck} from "react-icons/ai";

// verify pin
const AddDriverSucess = ({
setDriverSuccess,
setAddDriverForm
}) => {
    return (
        <div className='absolute h-full w-full flex items-center justify-center'>
            <div className='p-5 rounded-xl flex items-center justify-center flex-col' style={{width:"fit-content", height:"fit-content", background:"#fff"}}>
                <p style={{fontSize:"1rem"}}>Driver Successfully Added</p>
            </div>
        </div>
    )
}

export default function AddDriverFormMod() {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [IdUpload, setIdUpload] = useState("");
    const [driverUpload, setDriverUpload] = useState("");
    const [otherUpload, setOtherUpload] = useState("")
    const [IdUrl,  setIdUrl] = useState(null);
    const [otherUrl,  setOtherUrl] = useState(null);
    const [licenseUrl,  setLicenseUrl] = useState(null);
    const [AllDocs, setAllDocs] = useState([])
    const [showStartDate, setShowStartDate] = useState(true);
    const [showEndDate, setShowEndDate] = useState(true);
    const [verificationCode, setVerificationCode] = useState("");
    const [driverSuccess, setDriverSuccess] = useState(false)
    const [profileImage, setProfileImage] = useState("")
    const [profileUrl, setProfileUrl] = useState("")
    const [userUidData, setUserUidData] = useState("");
    const [fleetData, setFleetData] = useState([]);
    const [checked, setChecked] = useState(false);
    const [alertPrimary, setAlertPrimary] = useState();
    const [allPendingDrivers, setAllPendingDrivers] = useState([]);
    const [isLicenseSame, setIsLicenseSame] = useState(false);
    const [isIDSame, setIsIDSame] = useState(false);
    const [isPhoneSame, setIsPhoneSame] = useState(false);
    const [allTheDrivers, setAllTheDrivers] = useState([]);

    firebase.auth().useDeviceLanguage();

    const { register, handleSubmit, formState: { errors }, control } = useForm();
    const {
        selectedBookingDeal,
        setSelectedBookingDeal,
        allDeals,
        setAllDeals,
        addDriversData,
        setAddDriversData,
        addDriverForm,
        setAddDriverForm
    } = useFleetStateContext();

    const {
        userUid
    } = useStateContext();


    const  proviceOptions = [
        { value:"Eastern Cape", label:"Eastern Cape" },
        { value:"Free State", label:"Free State" },
        { value:"Gauteng", label:"Gauteng" },
        { value:"KwaZulu-Natal", label:"KwaZulu-Natal"},
        { value:"Limpopo", label:"Limpopo"},
        { value:"Mpumalanga", label:"Mpumalanga"},
        { value:"Northern Cape", label:"Northern Cape"},
        { value:"North West", label:"North West"},
        { value:"Western Cape", label:"Western Cape"}
    ]

    const  licenseCodeOptions = [
        { value:"A", label:"A" },
        { value:"A1", label:"A1" },
        { value: "B", label: "B" },
        { value:"C1", label:"C1"},
        { value:"C", label:"C"},
        { value:"EB", label:"EB" },
        { value:"EC1", label:"EC1"},
        { value:"EC", label:"EC"},
    ]

    const  countryOptions = [
        {value:"Afghanistan", label:"Afghanistan"},
        {value:"Aland Islands", label:"Åland Islands"},
        {value:"Albania", label:"Albania"},
        {value:"Algeria", label:"Algeria"},
        {value:"American Samoa", label:"American Samoa"},
        {value:"Andorra", label:"Andorra"},
        {value:"Angola", label:"Angola"},
        {value:"Anguilla",label:"Anguilla"},
        {value:"Antarctica", label:"Antarctica"},
        {value:"Antigua and Barbuda", label:"Antigua & Barbuda"},
        {value:"Argentina", label:"Argentina"},
        {value:"Armenia", label: "Armenia"},
        {value:"Aruba", label:"Aruba"},
        {value:"Australia",label:"Australia"},
        {value:"Austria", label:"Austria"},
        {value:"Azerbaijan", label:"Azerbaijan"},
        {value:"Bahamas", label:"Bahamas"},
        {value:"Bahrain", label:"Bahrain"},
        {value:"Bangladesh", label:"Bangladesh"},
        {value:"Barbados",label:"Barbados"},
        {value:"Belarus", label:"Belarus"},
        {value:"Belgium", label:"Belgium"},
        {value:"Belize", label:"Belize"},
        {value:"Benin", label:"Benin"},
        {value:"Bermuda", label:"Bermuda"},
        {value:"Bhutan",label:"Bhutan"},
        {value:"Bolivia", label:"Bolivia"},
        {value:"Bonaire, Sint Eustatius and Saba", label:"Caribbean Netherlands"},
        {value:"Bosnia and Herzegovina", label:"Bosnia & Herzegovina"},
        {value:"Botswana", label:"Botswana"},
        {value:"Bouvet Island", label:"Bouvet Island"},
        {value:"Brazil", label:"Brazil"},
        {value:"British Indian Ocean Territory", label:"British Indian Ocean Territory"},
        {value:"Brunei Darussalam", label:"Brunei"},
        {value:"Bulgaria", label:"Bulgaria"},
        {value:"Burkina Faso", label:"Burkina Faso"},
        {value:"Burundi", label:"Burundi"},
        {value:"Cambodia", label:"Cambodia"},
        {value:"Cameroon", label:"Cameroon"},
        {value:"Canada", label:"Canada"},
        {value:"Cape Verde", label:"Cape Verde"},
        {value:"Cayman Islands", label:"Cayman Islands"},
        {value:"Central African Republic", label:"Central African Republic"},
        {value:"Chad", label:"Chad"},
        {value:"Chile", label:"Chile"},
        {value:"China", label:"China"},
        {value:"Christmas Island", label:"Christmas Island"},
        {value:"Cocos (Keeling) Islands", label:"Cocos (Keeling) Islands"},
        {value:"Colombia", label:"Colombia"},
        {value:"Comoros", label:"Comoros"},
        {value:"Congo", label:"Congo - Brazzaville"},
        {value:"Congo, Democratic Republic of the Congo", label:"Congo - Kinshasa"},
        {value:"Cook Islands", label:"Cook Islands"},
        {value:"Costa Rica", label:"Costa Rica"},
        {value:"Cote D'Ivoire", label:"Côte d’Ivoire"},
        {value:"Croatia", label:"Croatia"},
        {value:"Cuba", label:"Cuba"},
        {value:"Curacao", label:"Curaçao"},
        {value:"Cyprus", label:"Cyprus"},
        {value:"Czech Republic", label:"Czechia"},
        {value:"Denmark", label:"Denmark"},
        {value:"Djibouti", label:"Djibouti"},
        {value:"Dominica", label:"Dominica"},
        {value:"Dominican Republic", label:"Dominican Republic"},
        {value:"Ecuador", label:"Ecuador"},
        {value:"Egypt", label:"Egypt"},
        {value:"El Salvador", label:"El Salvador"},
        {value:"Equatorial Guinea", label: "Equatorial Guinea"},
        {value:"Eritrea", label:"Eritrea"},
        {value:"Estonia", label:"Estonia"},
        {value:"Ethiopia", label:"Ethiopia"},
        {value:"Falkland Islands (Malvinas)", label:"Falkland Islands (Islas Malvinas)"},
        {value:"Faroe Islands", label:"Faroe Islands"},
        {value:"Fiji", label:"Fiji"},
        {value:"Finland", label:"Finland"},
        {value:"France", label:"France"},
        {value:"French Guiana", label:"French Guiana"},
        {value:"French Polynesia", label:"French Polynesia"},
        {value:"French Southern Territories", label:"French Southern Territories"},
        {value:"Gabon", label:"Gabon"},
        {value:"Gambia", label:"Gambia"},
        {value:"Georgia", label:"Georgia"},
        {value:"Germany", label:"Germany"},
        {value:"Ghana", label:"Ghana"},
        {value:"Gibraltar", label:"Gibraltar"},
        {value:"Greece", label:"Greece"},
        {value:"Greenland", label:"Greenland"},
        {value:"Grenada", label:"Grenada"},
        {value:"Guadeloupe", label:"Guadeloupe"},
        {value:"Guam", label:"Guam"},
        {value:"Guatemala", label:"Guatemala"},
        {value:"Guernsey",label:"Guernsey"},
        {value:"Guinea", label:"Guinea"},
        {value:"Guinea-Bissau", label:"Guinea-Bissau"},
        {value:"Guyana", label:"Guyana"},
        {value:"Haiti", label:"Haiti"},
        {value:"Heard Island and Mcdonald Islands", label:"Heard & McDonald Islands"},
        {value:"Holy See (Vatican City State)", label:"Vatican City"},
        {value:"Honduras", label:"Honduras"},
        {value:"Hong Kong", label:"Hong Kong"},
        {value:"Hungary", label:"Hungary"},
        {value:"Iceland", label:"Iceland"},
        {value:"India",label:"India"},
        {value:"Indonesia", label:"Indonesia"},
        {value:"Iran, Islamic Republic of",label:"Iran"},
        {value:"Iraq", label:"Iraq"},
        {value:"Ireland", label:"Ireland"},
        {value:"Isle of Man", label:"Isle of Man"},
        {value:"Israel", label:"Israel"},
        {value:"Italy", label:"Italy"},
        {value:"Jamaica", label:"Jamaica"},
        {value:"Japan", label:"Japan"},
        {value:"Jersey", label:"Jersey"},
        {value:"Jordan", label:"Jordan"},
        {value:"Kazakhstan", label:"Kazakhstan"},
        {value:"Kenya", label:"Kenya"},
        {value:"Kiribati", label:"Kiribati"},
        {value:"Korea, Democratic People's Republic of", label:"North Korea"},
        {value:"Korea, Republic of", label:"South Korea"},
        {value:"Kosovo", label:"Kosovo"},
        {value:"Kuwait", label:"Kuwait"},
        {value:"Kyrgyzstan", label:"Kyrgyzstan"},
        {value:"Lao People's Democratic Republic", label:"Laos"},
        {value:"Latvia", label:"Latvia"},
        {value:"Lebanon", label:"Lebanon"},
        {value:"Lesotho", label:"Lesotho"},
        {value:"Liberia", label:"Liberia"},
        {value:"Libyan Arab Jamahiriya", label:"Libya"},
        {value:"Liechtenstein", label:"Liechtenstein"},
        {value:"Lithuania", label:"Lithuania"},
        {value:"Luxembourg", label:"Luxembourg"},
        {value:"Macao", label:"Macao"},
        {value:"Macedonia, the Former Yugoslav Republic of", label:"North Macedonia"},
        {value:"Madagascar", label:"Madagascar"},
        {value:"Malawi", label:"Malawi"},
        {value:"Malaysia", label:"Malaysia"},
        {value:"Maldives", label:"Maldives"},
        {value:"Mali", label:"Mali"},
        {value:"Malta", label:"Malta"},
        {value:"Marshall Islands", label:"Marshall Islands"},
        {value:"Martinique", label:"Martinique"},
        {value:"Mauritania", label:"Mauritania"},
        {value:"Mauritius", label:"Mauritius"},
        {value:"Mayotte", label:"Mayotte"},
        {value:"Mexico", label:"Mexico"},
        {value:"Micronesia, Federated States of", label:"Micronesia"},
        {value:"Moldova, Republic of", label:"Moldova"},
        {value:"Monaco", label:"Monaco"},
        {value:"Mongolia", label:"Mongolia"},
        {value:"Montenegro", label:"Montenegro"},
        {value:"Montserrat", label:"Montserrat"},
        {value:"Morocco", label:"Morocco"},
        {value:"Mozambique", label:"Mozambique"},
        {value:"Myanmar", label:"Myanmar (Burma)"},
        {value:"Namibia", label:"Namibia"},
        {value:"Nauru", label:"Nauru"},
        {value:"Nepal", label:"Nepal"},
        {value:"Netherlands", label:"Netherlands"},
        {value:"Netherlands Antilles", label:"Curaçao"},
        {value:"New Caledonia", label:"New Caledonia"},
        {value:"New Zealand", label:"New Zealand"},
        {value:"Nicaragua", label:"Nicaragua"},
        {value:"Niger", label:"Niger"},
        {value:"Nigeria", label:"Nigeria"},
        {value:"Niue", label:"Niue"},
        {value:"Norfolk Island", label:"Norfolk Island"},
        {value:"Northern Mariana Islands", label:"Northern Mariana Islands"},
        {value:"Norway", label:"Norway"},
        {value:"Oman", label:"Oman"},
        {value:"Pakistan", label:"Pakistan"},
        {value:"Palau", label:"Palau"},
        {value:"Palestinian Territory, Occupied", label:"Palestine"},
        {value:"Panama", label:"Panama"},
        {value:"Papua New Guinea", label:"Papua New Guinea"},
        {value:"Paraguay", label:"Paraguay"},
        {value:"Peru", label:"Peru"},
        {value:"Philippines", label:"Philippines"},
        {value:"Pitcairn", label:"Pitcairn Islands"},
        {value:"Poland", label:"Poland"},
        {value:"Portugal", label:"Portugal"},
        {value:"Puerto Rico", label:"Puerto Rico"},
        {value:"Qatar", label:"Qatar"},
        {value:"Reunion", label:"Réunion"},
        {value:"Romania", label:"Romania"},
        {value:"Russian Federation", label:"Russia"},
        {value:"Rwanda", label:"Rwanda"},
        {value:"Saint Barthelemy", label:"St. Barthélemy"},
        {value:"Saint Helena", label:"St. Helena"},
        {value:"Saint Kitts and Nevis", label:"St. Kitts & Nevis"},
        {value:"Saint Lucia", label:"St. Lucia"},
        {value:"Saint Martin", label:"St. Martin"},
        {value:"Saint Pierre and Miquelon", label:"St. Pierre & Miquelon"},
        {value:"Saint Vincent and the Grenadines", label:"St. Vincent & Grenadines"},
        {value:"Samoa", label:"Samoa"},
        {value:"San Marino", label:"San Marino"},
        {value:"Sao Tome and Principe", label:"São Tomé & Príncipe"},
        {value:"Saudi Arabia", label:"Saudi Arabia"},
        {value:"Senegal", label:"Senegal"},
        {value:"Serbia", label:"Serbia"},
        {value:"Serbia and Montenegro", label:"Serbia"},
        {value:"Seychelles", label:"Seychelles"},
        {value:"Sierra Leone", label:"Sierra Leone"},
        {value:"Singapore", label:"Singapore"},
        {value:"Sint Maarten", label:"Sint Maarten"},
        {value:"Slovakia", label:"Slovakia"},
        {value:"Slovenia", label:"Slovenia"},
        {value:"Solomon Islands", label:"Solomon Islands"},
        {value:"Somalia", label:"Somalia"},
        {value:"South Africa", label:"South Africa"},
        {value:"South Georgia and the South Sandwich Islands", label:"South Georgia & South Sandwich Islands"},
        {value:"South Sudan", label:"South Sudan"},
        {value:"Spain", label:"Spain</value"},
        {value:"Sri Lanka", label:"Sri Lanka"},
        {value:"Sudan", label:"Sudan"},
        {value:"Suriname", label:"Suriname"},
        {value:"Svalbard and Jan Mayen", label:"Svalbard & Jan Mayen"},
        {value:"Swaziland", label:"Eswatini"},
        {value:"Sweden", label:"Sweden"},
        {value:"Switzerland", label:"Switzerland"},
        {value:"Syrian Arab Republic", label:"Syria"},
        {value:"Taiwan, Province of China", label:"Taiwan"},
        {value:"Tajikistan", label:"Tajikistan"},
        {value:"Tanzania, United Republic of", label:"Tanzania"},
        {value:"Thailand", label:"Thailand"},
        {value:"Timor-Leste", label:"Timor-Leste"},
        {value:"Togo", label:"Togo"},
        {value:"Tokelau", label:"Tokelau"},
        {value:"Tonga", label:"Tonga"},
        {value:"Trinidad and Tobago", label:"Trinidad & Tobago"},
        {value:"Tunisia", label:"Tunisia"},
        {value:"Turkey", label:"Turkey"},
        {value:"Turkmenistan", label:"Turkmenistan"},
        {value:"Turks and Caicos Islands", label:"Turks & Caicos Islands"},
        {value:"Tuvalu", label:"Tuvalu"},
        {value:"Uganda", label:"Uganda"},
        {value:"Ukraine", label:"Ukraine"},
        {value:"United Arab Emirates", label:"United Arab Emirates"},
        {value:"United Kingdom", label:"United Kingdom"},
        {value:"United States", label:"United States"},
        {value:"United States Minor Outlying Islands", label:"U.S. Outlying Islands"},
        {value:"Uruguay", label:"Uruguay"},
        {value:"Uzbekistan", label:"Uzbekistan"},
        {value:"Vanuatu", label:"Vanuatu"},
        {value:"Venezuela", label:"Venezuela"},
        {value:"Viet Nam", label:"Vietnam"},
        {value:"Virgin Islands, British", label:"British Virgin Islands"},
        {value:"Virgin Islands, U.s.", label:"U.S. Virgin Islands"},
        {value:"Wallis and Futuna", label:"Wallis & Futuna"},
        {value:"Western Sahara", label:"Western Sahara"},
        {value:"Yemen", label:"Yemen"},
        {value:"Zambia", label:"Zambia"},
        {value:"Zimbabwe", label:"Zimbabwe"}
    ]

    // const getDriverIDFnc = async ()=>{
    //     return  await firebase.database().ref().push()
    // }

      const handleAddDriver = (data) => {
        const storage = firebase.storage()

        const driver_id = firebase.database().ref().push().key;

        const sameLicense = allPendingDrivers.filter((item, i) => item[i].licence_info.licence_number === data.licence_number)
        const sameIDNumber = allPendingDrivers.filter((item, i) => item[i].id === data.identity_number)
        const samePhone = allPendingDrivers.filter((item, i) => item[i].phone === data.phone)

        if(sameLicense.length > 0){
            setIsLicenseSame(true);
        }else if(sameIDNumber > 0){
            setIsIDSame(true);
        }else if(samePhone > 0){
            setIsPhoneSame(true);
        }
        else{
            firebase.database()
            .ref('pending_drivers/' + "27"+data?.phone?.substr(1))
            .update({
                "address":{
                    city: data.city,
                    complex: data.complex,
                    country: data.Country,
                    postal_code: data.postal,
                    province: data.Province,
                    street_name: data.street_name_number,
                    surburb: data.suburb
                },
                fleet: userUid,
                id: data.identity_number,
                identity_pdf: IdUrl,
                "licence_info":{
                    end_date: (endDate.toISOString().substring(0, 10)),
                    licence_code: data.license_code,
                    licence_number: data.license_number,
                    start_date: (startDate.toISOString().substring(0, 10))
                },
                licence_pdf: licenseUrl,
                name: data.firstName,
                phone: "27"+data.phone,
                photo_uri: profileUrl,
                identity_pdf:IdUrl,
                surname:data.lastName,
                start_date: ((new Date()).toISOString()).substring(0, 10),
            }).then(() => {
                setDriverSuccess(true)
                setTimeout(() => {
                    setAddDriverForm(false)
                }, 2000)
            })
        }

      }

      const handleIdUpload = (e,file) => {
        e.preventDefault()
        const storage = firebase.storage()
        if(file === null) return
        const fileRefId = storage.ref(`drivers_identity_doc/${file.name}`)
        setIdUpload(file.name)
        fileRefId.put(file).then((snapshot) => {
            const fileUrl = fileRefId.getDownloadURL()
            .then((url) => {
                setIdUrl(url)
            })
                .catch((error) => {
                });
        });
      }

      const handleDriverUpload = (e,file) => {
        e.preventDefault()
        const storage = firebase.storage()
        if(file === null) return
        const fileRefLicence = storage.ref(`drivers_licence_doc/${file.name}`)
        setDriverUpload(file.name)
        fileRefLicence.put(file).then((snapshot) => {
            const fileUrl = fileRefLicence.getDownloadURL()
            .then((url) => {
                setLicenseUrl(url)
            })
                .catch((error) => {
                });
        });

      }

      const handleOtherUpload = (e,file) => {
        e.preventDefault()
        const storage = firebase.storage()
        if(file === null) return
        const fileRefOther = storage.ref(`drivers_other_docs/${file.name}`)
        setOtherUpload(file.name)
        fileRefOther.put(file).then((snapshot) => {
            const fileUrl = fileRefOther.getDownloadURL()
            .then((url) => {
                setOtherUrl(url)
            })
                .catch((error) => {
                });
        });
      }

      const handleProfileUpload = (e, file) => {
        e.preventDefault()
        const storage = firebase.storage()
        if(file === null) return
        const fileRef = storage.ref(`drivers_image/${file.name}`)
        setProfileImage(file.name)
        fileRef.put(file).then((snapshot) => {
            const fileUrl = fileRef.getDownloadURL()
            .then((url) => {
                setProfileUrl(url)
            })
                .catch((error) => {
                });
        });
      }

      const handleChangeSameAddress = () => {
        setChecked((prev) => !prev);
      }

    //   const idOnChange = (e) => {

    //   }


      useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                setUserUidData(user.uid);
            }
        });
      }, [])

      useEffect(() => {
            firebase.database()
            .ref('/fleets/' + userUid)
            .on("value", (snapshot) => {
                setFleetData(snapshot.val());
            })
      }, [])

      useEffect(() => {
        firebase.database().ref("pending_drivers").on("value", (snapshot) =>{
            if(snapshot.exists()){
                setAllPendingDrivers([Object?.values(snapshot.val())]);
            }
        })
      },[])

      useEffect(() => {
        firebase.database().ref("drivers").orderByChild("fleet").equalTo(userUid).on("value", (snapshot) =>{
            if(snapshot.exists()){
                setAllTheDrivers([Object?.values(snapshot.val())]);
            }
        })
      },[])

      console.log("All the drivers", allTheDrivers)

  return (
    <form
        className='w-full flex justify-center scrollbar-hide pointer-events-auto items-center absolute top-0 left-0 right-0 bottom-0'
        style={{background:"#00000066", height:"100%", maxHeight:"100%", overflow:"scroll"}}
        onSubmit={handleSubmit(handleAddDriver)}
    >
        <div
            className='h-full cursor-pointer'
            style={{width:"12.5%"}}
            onClick={() => {
                setAddDriverForm(false)
            }}
        ></div>
        <div style={{width:"75%", height:"100%"}}>
            <div className='cursor-pointer' style={{width:"100%", height:"5%"}}onClick={() => {
                setAddDriverForm(false)
            }}></div>
            <div
                className='rounded-xl p-7 scrollbar-hide flex justify-between'
                style={{
                    background:"#F2F2F2",
                    height:"90%",
                    maxHeight:"90%",
                    overflow:"scroll",
                }}
            >
                    <div
                        className=''
                        style={{
                            width:"28%",
                            height:"100%",
                        }}
                    >
                        <div className='flex justify-center items-center w-full h-fit'>
                            <div
                                className='flex rounded-full justify-center items-center relative'
                                style={{background:"#DCDCDC", width:"7rem", height:"7rem"}}
                            >
                                    {profileUrl ?
                                        <div className='w-full h-full rounded-full'>
                                            <img className='rounded-full' style={{objectFit:"cover", width:"7rem", height:"7rem"}} src={profileUrl} alt="" />
                                        </div>
                                        :   <p className='' style={{fontSize:"4.5rem", color:"#fff"}}><ImUser/></p>
                                    }
                                <label
                                    className='absolute right-1 bottom-0 cursor-pointer h-6 w-6 flex justify-center items-center rounded-full'
                                    style={{fontSize:"1rem", background:"#fce200"}}
                                    htmlFor="profilefile"
                                    onChange={(e) => handleProfileUpload(e, e.target.files[0])}
                                >
                                    <input accept='image/*' type="file" id='profilefile' className='' />
                                    <AiFillCamera/>
                                </label>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold' style={{fontSize:"1rem", color:"rgb(96, 96, 96)"}}>Personal Details</p>
                            <input
                                type="text"
                                className='w-full mb-2 h-9 rounded-xl mt-1 p-2' placeholder='First Name'
                                style={{background:"#DCDCDC", fontSize:".9rem"}}
                                {...register("firstName", { required: true })}
                            />
                            {errors.firstName && <p style={{color:"red", marginTop:"0"}}>Please don't leave the first name blank.</p>}
                            <input
                                type="text"
                                className='w-full mb-2 h-9 rounded-xl mt-1 p-2'
                                placeholder='Last Name' style={{background:"#DCDCDC", fontSize:".9rem"}}
                                {...register("lastName", { required: true })}
                            />
                            {errors.lastName && <p style={{color:"red", marginTop:"0"}}>Please don't leave the last name blank.</p>}
                            <input
                                type="number"
                                className='w-full mb-2 h-9 rounded-xl mt-1 p-2'
                                // onChange={(e) => idOnChange(e)}
                                placeholder='ID/Passport' style={{background:"#DCDCDC", fontSize:".9rem"}}
                                {...register("identity_number", { required: true, pattern:/^(?=.{13}$)/ })}
                            />
                            {errors.identity_number && <p style={{color:"red", marginTop:"0"}}>Please don't leave the identity number blank, and the Id number should be 13 digits long.</p>}

                            <input
                                type="number"
                                className='w-full mb-2 h-9 rounded-xl mt-1 p-2'
                                // onChange={(e) => idOnChange(e)}
                                placeholder='Phone' style={{background:"#DCDCDC", fontSize:".9rem"}}
                                {...register("phone", { required: true, pattern:/^(?=.{10}$)/ })}
                            />
                            {errors.phone && <p style={{color:"red", marginTop:"0"}}>Please don't leave the phone number blank, and number should be 10 digits long.</p>}
                            {/* <Controller
                                name="phone"
                                control={control}
                                rules={{
                                    validate: (value) => isValidPhoneNumber(value)
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <PhoneInput
                                        style={{
                                            width: '100%',
                                            padding:"0.5rem",
                                            background: "rgb(220, 220, 220)",
                                            fontSize: "0.9rem",
                                            borderRadius: "0.75rem",

                                        }}
                                        placeholder="Phone"
                                        layout="second"
                                        value={value}
                                        onChange={onChange}
                                        country="ZA"
                                        id="phone"
                                    />
                                )}
                            />
                            {errors["phone"] && (<p style={{color:"red", marginTop:"0"}} className="error-message">Invalid Phone</p>)} */}
                        </div>
                    </div>
                    <div
                        className=''
                        style={{
                            width:"28%",
                            height:"100%",
                        }}
                    >
                        <p className='font-bold' style={{fontSize:"1rem", color:"rgb(96, 96, 96)"}}>Physical Address</p>
                        {/* <label className='flex items-center mt-2 mb-2' style={{fontSize:".7rem"}}>
                            <input
                                type='checkbox'
                                onChange={handleChangeSameAddress}
                                checked={checked}
                            />
                            Address same as the company address
                        </label> */}
                        <input
                            type="text"
                            disabled={checked ? true : false}
                            className='w-full mb-2 h-9 rounded-xl mt-1 p-2'
                            placeholder='Complex/Office Block (if any)'
                            style={{background:"#DCDCDC", fontSize:".9rem"}}
                            {...register("complex")}
                        />
                        {/* {errors.complex && <p style={{color:"red", marginTop:"0"}}>Please don't leave the office block blank.</p>} */}
                        <input
                            type="text"
                            className='w-full mb-2 h-9 rounded-xl mt-1 p-2'
                            placeholder='Street Name and Number'
                            style={{background:"#DCDCDC", fontSize:".9rem"}}
                            // value={checked ? ((fleetData.physical_address.split(","))[0]).replace(/[0-9]/g, '') : ""}
                            {...register("street_name_number", { required: true })}
                        />
                        {errors.street_name_number && <p style={{color:"red", marginTop:"0"}}>Please don't leave the street name and number  blank.</p>}
                        <input
                            type="text"
                            className='w-full mb-2 h-9 rounded-xl mt-1 p-2'
                            placeholder='Suburb' style={{background:"#DCDCDC", fontSize:".9rem"}}
                            // value={checked ? (fleetData.physical_address.split(","))[1] : ""}
                            {...register("suburb", { required: true })}
                        />
                        {errors.suburb && <p style={{color:"red", marginTop:"0"}}>Please don't leave the suburb blank.</p>}
                        <input
                            type="text"
                            className='w-full mb-2 h-9 rounded-xl mt-1 p-2'
                            // value={checked ? fleetData.city  : ""}
                            placeholder='City/Town' style={{background:"#DCDCDC", fontSize:".9rem"}}
                            {...register("city", { required: true })}
                        />
                        {errors.city && <p style={{color:"red", marginTop:"0"}}>Please don't leave the city blank.</p>}
                        <input
                            type="text"
                            // value={checked ? ((fleetData.physical_address.split(","))[0]).replace(/\D/g, "")  : ""}
                            className='w-full mb-2 h-9 rounded-xl mt-1 p-2'
                            placeholder='Postal Code' style={{background:"#DCDCDC", fontSize:".9rem"}}
                            {...register("postal", { required: true })}
                        />
                        {errors.postal && <p style={{color:"red", marginTop:"0"}}>Please don't leave the postal blank.</p>}
                        <Controller
                            name="Province"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Select
                                    styles={{
                                        control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        width: '100%',
                                        background: "rgb(220, 220, 220)",
                                        fontSize: "0.9rem",
                                        borderRadius: "0.75rem",
                                        marginBottom:"0.5rem"
                                        }),
                                    }}
                                    placeholder="Province"
                                    classNamePrefix="addl-class"
                                    options={proviceOptions}
                                    value={proviceOptions.find(c => c.value === value)}
                                    onChange={val => onChange(val.value)}
                                />
                            )}

                        />
                         <Controller
                            name="Country"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Select
                                    styles={{
                                        control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        width: '100%',
                                        background: "rgb(220, 220, 220)",
                                        fontSize: "0.9rem",
                                        borderRadius: "0.75rem",
                                        marginBottom:"0.5rem"
                                        }),
                                    }}
                                    placeholder="Country"
                                    classNamePrefix="addl-class"
                                    options={countryOptions}
                                    value={countryOptions.find(c => c.value === value)}
                                    onChange={val => {
                                        onChange(val.value)
                                        console.log("country value", val.value)
                                    }}
                                />
                            )}

                        />
                    </div>
                    <div
                        className='scrollbar-hide'
                        style={{
                            width:"28%",
                            height:"100%",
                            overflow:"scroll"
                        }}
                    >
                        <p className='font-bold' style={{fontSize:"1rem", color:"rgb(96, 96, 96)"}}>Documents Upload</p>
                        <div className='flex justify-between items-center w-full px-5 py-3 mt-3 h-13 rounded-lg' style={{background:"#FFFFFF"}}>
                            <label  className='w-full h-9 flex justify-between items-center cursor-pointer' htmlFor="idfile">
                                <input
                                    type="file"
                                    onChange={(e) => handleIdUpload(e, e.target.files[0])}
                                    style={{border:"1px solid", background:"black"}}
                                    id='idfile'
                                    accept='image/*, .pdf, .doc, .docx'
                                    // onChange={(e) => {setIdUpload(e.target.files[0])}}
                                />
                                <p className='cursor-pointer relative' style={{color:`${IdUpload ? "#02c202" : "#A7A7A7"}`, fontSize:"1.6rem"}}>
                                    <HiIdentification/>
                                    {IdUpload &&
                                        <span className='absolute top-0 right-0 left-0 bottom-0' style={{color:"#000"}}><AiOutlineCheck/></span>
                                    }
                                </p>
                                <p style={{color:"rgb(96, 96, 96)"}}>Upload ID/Passport</p>
                            </label>
                        </div>

                        <div className='flex justify-between items-center px-5 py-3 mt-3 h-13 rounded-lg' style={{background:"#FFFFFF"}}>
                            <label  className='w-full h-9 flex justify-between items-center cursor-pointer' htmlFor="driverfile">
                                <input
                                    type="file"
                                    onChange={(e) => handleDriverUpload(e, e.target.files[0])}
                                    style={{border:"1px solid", background:"black"}}
                                    id='driverfile'
                                    accept='image/*, .pdf, .doc, .docx'
                                />
                                <p className='cursor-pointer relative' style={{color:`${driverUpload ? "#02c202" : "#A7A7A7"}`, fontSize:"1.6rem"}}>
                                    <HiIdentification/>
                                    {driverUpload &&
                                        <span className='absolute top-0 right-0 left-0 bottom-0' style={{color:"#000"}}><AiOutlineCheck/></span>
                                    }
                                </p>
                                <p style={{color:"rgb(96, 96, 96)"}}>Upload Driver's License</p>
                            </label>
                        </div>
                        <input
                            type="text"
                            className='w-full h-9 rounded-xl mt-3 p-2'
                            placeholder='License Number'
                            style={{background:"#DCDCDC", fontSize:".9rem"}}
                            {...register("license_number", { required: true, pattern:/^(?=.{12}$)/})}
                        />
                        {errors.license_number && <p style={{color:"red", marginTop:"0"}}>Please don't leave the license number blank and number should be 12 characters long</p>}
                        <Controller
                            name="license_code"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Select
                                    styles={{
                                        control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        width: '100%',
                                        background: "rgb(220, 220, 220)",
                                        fontSize: "0.9rem",
                                        borderRadius: "0.75rem",
                                        marginBottom:"0.5rem",
                                        marginTop:"0.5rem"
                                        }),
                                    }}
                                    placeholder="License Code"
                                    classNamePrefix="addl-class"
                                    options={licenseCodeOptions}
                                    value={licenseCodeOptions.find(c => c.value === value)}
                                    onChange={val => {
                                        onChange(val.value)
                                    }}
                                />
                            )}

                        />
                        <div className='flex justify-between items-center mt-3'>
                            {showStartDate &&
                                <div
                                    type="text"
                                    className='h-9 cursor-pointer rounded-xl p-2 flex justify-content items-center'
                                    placeholder='License Start Date'
                                    onClick={() => setShowStartDate(false)}
                                    style={{background:"#DCDCDC", fontSize:".9rem", width:"48%"}}
                                    {...register("start_date", { required: true })}
                                >
                                    <h1 style={{color:"#A7A7A7", textAlign:"center"}}>License start Date</h1>
                                </div>
                            }
                           {!showStartDate &&
                                <div className='flex justify-center flex-col' style={{width:"48%"}}>
                                    <DatePicker wrapperClassName="datePickerStyle" selected={startDate} onChange={(date) => setStartDate(date)} />
                                    <p className='m-0 pl-2'>Select date</p>
                                </div>
                           }
                            {showEndDate &&
                                <div
                                    type="text"
                                    className='h-9 cursor-pointer rounded-xl p-2 flex justify-content items-center'
                                    placeholder='Start Date'
                                    onClick={() => setShowEndDate(false)}
                                    style={{background:"#DCDCDC", fontSize:".9rem", width:"48%"}}
                                    {...register("start_date", { required: true })}
                                >
                                    <h1 style={{color:"#A7A7A7", textAlign:"center"}}>License end Date</h1>
                                </div>
                            }
                            {!showEndDate &&
                                <div className='flex justify-center flex-col' style={{width:"48%"}}>
                                    <DatePicker wrapperClassName="datePickerStyle" selected={endDate} onChange={(date) => setEndDate(date)} />
                                    <p className='m-0 pl-2'>Select date</p>
                                </div>
                           }
                        </div>
                        <div className='flex justify-between items-center w-full px-5 py-3 mt-3 h-13 rounded-lg' style={{background:"#FFFFFF"}}>
                            <label  className='w-full h-9 flex justify-between items-center cursor-pointer' htmlFor="otherfile">
                                <input
                                    type="file"
                                    onChange={(e) => handleOtherUpload(e, e.target.files[0])}
                                    style={{border:"1px solid", background:"black"}}
                                    id='otherfile'
                                    accept='image/*, .pdf, .doc, .docx'
                                />
                                <p className='cursor-pointer relative' style={{color:`${otherUpload ? "#02c202" : "#A7A7A7"}`, fontSize:"1.6rem"}}>
                                    <HiIdentification/>
                                    {otherUpload &&
                                        <span className='absolute top-0 right-0 left-0 bottom-0' style={{color:"#000"}}><AiOutlineCheck/></span>
                                    }
                                </p>
                                <p style={{color:"rgb(96, 96, 96)"}}>Upload other documents</p>
                            </label>
                        </div>
                        <input
                            type="text"
                            className='w-full h-9 rounded-xl mt-3 p-2'
                            placeholder='Certification/License'
                            style={{background:"#DCDCDC", fontSize:".9rem"}}
                            {...register("certification", { required: true })}
                        />
                        {errors.certification && <p style={{color:"red", marginTop:"0px"}}>Please don't leave the Certification/License  blank.</p>}
                        <div className='flex justify-end cursor-pointer items-center mt-3'>
                            <div
                                className='flex justify-center items-center h-9  p-3 rounded-xl bg-white'
                                style={{border:"1px solid black", width:"8rem"}}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setAllDocs([
                                        IdUpload,
                                        driverUpload,
                                        otherUpload
                                    ])
                                }}
                            >
                                <p style={{fontSize:"1rem"}}>Upload</p>
                                {/* <p className='pl-5' style={{fontSize:"1.4rem"}}><AiOutlinePlus/></p> */}
                            </div>
                        </div>
                        <p className='mt-2' style={{fontSize:".9rem"}}>Added certificates/licenses</p>
                        <div className='flex'>
                            {AllDocs?.length && AllDocs.map((file) => (
                                <p
                                    className='p-1 px-2 mr-3 rounded-full bg-white'
                                    style={{
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        width: "79px",}}
                                >{file}</p>
                            ))
                            }
                        </div>
                        <input type="submit" id='sign-driver-in' placeholder='Enroll Driver' className='mt-3 w-full h-9 cursor-pointer rounded-xl ' style={{background:"#fce200"}}/>
                    </div>
            </div>
            <div className='cursor-pointer' style={{width:"100%", height:"5%"}}onClick={() => {
                setAddDriverForm(false)
            }}></div>
        </div>

        <div
            className='h-full cursor-pointer'
            style={{width:"12.5%"}}
            onClick={() => {
                setAddDriverForm(false)
            }}
        >

        </div>
        {driverSuccess &&
            <AddDriverSucess
                setDriverSuccess={setDriverSuccess}
                setAddDriverForm={setAddDriverForm}
            />
        }

        {isLicenseSame &&
            <div className='absolute h-full w-full flex items-center justify-center'>
                <div className='p-5 rounded-xl flex items-center justify-center flex-col' style={{width:"244px", height:"fit-content", background:"#fff"}}>
                    <p style={{fontSize:"1rem", textAlign:"center", marginBottom:"1rem"}}>Driver with that license number already exists in the system. Please add a different one</p>
                    <button className='rounded-xl px-2 py-1' style={{background:"#fce200", fontSize:".8rem"}} onClick={() => setIsLicenseSame(false)}>Ok</button>
                </div>
            </div>
        }

        {isIDSame &&
            <div className='absolute h-full w-full flex items-center justify-center'>
                <div className='p-5 rounded-xl flex items-center justify-center flex-col' style={{width:"244px", height:"fit-content", background:"#fff"}}>
                    <p style={{fontSize:"1rem", textAlign:"center", marginBottom:"1rem"}}>Driver with that identity number already exists in the system. Please add a different one</p>
                    <button className='rounded-xl px-2 py-1' style={{background:"#fce200", fontSize:".8rem"}} onClick={() => setIsIDSame(false)}>Ok</button>
                </div>
            </div>
        }

        {isPhoneSame &&
            <div className='absolute h-full w-full flex items-center justify-center'>
                <div className='p-5 rounded-xl flex items-center justify-center flex-col' style={{width:"244px", height:"fit-content", background:"#fff"}}>
                    <p style={{fontSize:"1rem", textAlign:"center", marginBottom:"1rem"}}>Driver with that phone number already exists in the system. Please add a different one</p>
                    <button className='rounded-xl px-2 py-1' style={{background:"#fce200", fontSize:".8rem"}} onClick={() => setIsPhoneSame(false)}>Ok</button>
                </div>
            </div>
        }

        {isPhoneSame &&
            <div className='absolute h-full w-full flex items-center justify-center'>
                <div className='p-5 rounded-xl flex items-center justify-center flex-col' style={{width:"244px", height:"fit-content", background:"#fff"}}>
                    <p style={{fontSize:"1rem", textAlign:"center", marginBottom:"1rem"}}>Driver with that phone number already exists in the system. Please add a different one</p>
                    <button className='rounded-xl px-2 py-1' style={{background:"#fce200", fontSize:".8rem"}} onClick={() => setIsPhoneSame(false)}>Ok</button>
                </div>
            </div>
        }

    </form>
  )
}
