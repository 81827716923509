import React from 'react'
import { ImUser } from 'react-icons/im'
import { AiFillCamera } from "react-icons/ai"
import { HiIdentification } from 'react-icons/hi'
import { AiOutlinePlus } from 'react-icons/ai'
import {useFleetStateContext} from '../context/FleetDealsContext'
import { height } from '@mui/system'
import { useStateContext } from '../context/DashboardStateContext'
import { AiOutlineArrowRight } from "react-icons/ai"
import { BiFilter } from "react-icons/bi"

export default function AssignDriver() {
    const {
        setAddDriverForm
    } = useFleetStateContext();

    const {
        setAssignDriverRoute,
    } = useStateContext();
  return (
    <div className='absolute w-full items-center flex justify-center flex-row items-end bottom-0 left-0 right-0 top-0' style={{background:"#00000066"}}>
        <div className='w-full flex justify-center flex-col items-center  cursor-pointer' style={{height:"100%", width:"73%"}} onClick={() => setAssignDriverRoute(false)}>
            <div className='flex justify-between items-center' style={{textAlign:"start", width:"60%"}}>
                <h1 style={{color:"#fff", fontSize:"4rem"}}>Select a driver</h1>
                <p className='' style={{fontSize:"4rem", color:"#fff"}}><AiOutlineArrowRight/></p>
            </div>
            <div style={{textAlign:"start", width:"60%", color:"#fff"}}>
                <p style={{color:"#fff", fontSize:"1.5rem"}}>Choose from the list who you would like to assign</p>            
            </div>
            
        </div>
        <div className='w-full mr-3 p-8 bg-white rounded-xl' style={{height:"95%", width:"27%"}}>
            <p className='' style={{fontSize:"1.4rem"}}>Select Driver</p>
            <div className='flex mt-3 w-full justify-between'>
                <input type="text" className='h-10 px-3 rounded-lg border-transparent' 
                    placeholder='Search' 
                    style={{width:"85%", background:"rgb(207 207 207)", color:"#000"}} 
                />
                <p className='ml-2 text-2xl flex justify-center items-center rounded-lg' style={{width:"15%", background:"rgb(207 207 207)"}}><BiFilter/></p>
            </div>
            <div className='mt-'>

            </div>
        </div>
    </div>
  )
}
