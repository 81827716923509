import React from 'react'
import '../css/loaderEnterprise.css'

export default function LoaderEnterprise() {
  return (
    <div className='loader-enterprise-container'>
        <div className='loader-enterprise'>

        </div>
    </div>
  )
}
