import React from 'react'
import { TbChevronLeft } from 'react-icons/tb'
import {useFleetStateContext} from '../context/FleetDealsContext'
import firebase from '../firebase-config';
import { useStateContext } from '../context/DashboardStateContext'

export default function SettleOfferUpdate({setIsOfferUpdate, setIsAssignDriverShown,bookingPartyCounterOffer
}) {
    const {
        setIsFirstDealAssign,
        counterOfferPrice,
        isDealSettleAccept,
        setIsDealSettleAccept,
        selectedBookingDeal,
    } = useFleetStateContext();

    const {
        userUid
    } = useStateContext();

    // const bookingPartyCounterOffer = selectedBookingDeal[0]?.biddingPrices?.price[1] || ""
    const bookingPartyAccepted = selectedBookingDeal[0]?.biddingPrices?.accepted || ""
    const lastPrice = selectedBookingDeal[0]?.biddingPrices?.price[selectedBookingDeal[0]?.biddingPrices?.price.length -1]

    console.log("the bookings deal selected", bookingPartyCounterOffer)
  return (
    <>
    {bookingPartyCounterOffer &&
        <div
            className='w-full flex-col rounded-tr-xl h-full rounded-br-xl absolute left-0 flex items-end right-0 bottom-0 top-0'
            style={{background:"#00000066", pointerEvents: "auto"}}
        >
            <div className='w-full cursor-pointer' style={{height:"50%"}}
                onClick={() => setIsOfferUpdate(false)}
            >

            </div>
            <div
                className='w-full p-4 rounded-br-xl'
                style={{height:"50%", background:"#fff", pointerEvents: "auto"}}
            >
                <div className='flex items-center mt-4'>
                    <p className='text-2xl font-bold cursor-pointer'
                    onClick={() =>{setIsOfferUpdate(false)}}><TbChevronLeft/></p>
                    <p className='font-bold' style={{fontSize:"1rem"}}>Offer Update</p>
                </div>
                <div className='mt-4 flex justify-center items-center flex-col'>
                    <p className='text-center'>The booking party has responded with the following counter offer</p>
                    <p className='mt-5' style={{fontSize:"1.4rem", fontWeight:"bold"}}>R{bookingPartyCounterOffer}</p>
                    <p className='text-center mt-3'>{!bookingPartyAccepted && "Would you like to accept this offer?"}</p>
                </div>
                <div className='w-full flex mt-11 justify-between items-center'>
                        <button
                            className='py-1 px-7 rounded-xl'
                            style={{
                                border:"1px solid"
                            }}
                            onClick={() => {
                                firebase.database().ref("/booking/" + selectedBookingDeal[0].booking_id).child("booking_bids_fleet_id").remove();
                                firebase.database().ref("/fleets/").child(userUid).child("booking_bids").child(selectedBookingDeal[0].booking_id).remove();
                                setIsOfferUpdate(false)
                            }}
                        >
                            Decline
                        </button>

                    <button className='py-1 px-7 rounded-xl'
                        style={{
                            background:"#fce200"
                        }}
                        onClick={() => {
                            setIsOfferUpdate(false)
                            // setIsFirstDealAssign(true)
                            setIsDealSettleAccept(true)
                        }}
                    >
                        Accept
                    </button>
                </div>
            </div>
        </div>
    }
    {bookingPartyAccepted &&
        <div
        className='w-full flex-col rounded-tr-xl h-full rounded-br-xl absolute left-0 flex items-end right-0 bottom-0 top-0'
        style={{background:"#00000066", pointerEvents: "auto"}}
    >
        <div className='w-full cursor-pointer' style={{height:"50%"}}
            onClick={() => setIsOfferUpdate(false)}
        >
        </div>
        <div
            className='w-full p-4 rounded-br-xl'
            style={{height:"50%", background:"#fff", pointerEvents: "auto"}}
        >
            <div className='flex items-center mt-4'>
                <p className='text-2xl font-bold cursor-pointer'
                onClick={() =>{setIsOfferUpdate(false)}}><TbChevronLeft/></p>
                <p className='font-bold' style={{fontSize:"1rem"}}>Offer Update</p>
            </div>
            <div className='mt-4 flex justify-center items-center flex-col'>
                <p className='text-center'>The booking party has accepted your offer please commit.</p>
                <p className='mt-5' style={{fontSize:"1.4rem", fontWeight:"bold"}}>R{lastPrice}</p>
                <p className='text-center mt-3'>{!bookingPartyAccepted && "Would you like to accept this offer?"}</p>
            </div>
            <div className='w-full flex mt-11 justify-between items-center' style={{justifyContent:"center"}}>
                {/* {!bookingPartyAccepted &&
                    <button
                        className='py-1 px-7 rounded-xl'
                        style={{
                            border:"1px solid"
                        }}
                        onClick={() => {
                            firebase.database().ref("/booking/" + selectedBookingDeal[0].booking_id).child("booking_bids_fleet_id").remove();
                            setIsOfferUpdate(false)
                        }}
                    >
                        Decline
                    </button>
                } */}
                <button className='py-1 px-7 rounded-xl'
                    style={{
                        background:"#fce200"
                    }}
                    onClick={() => {
                        setIsOfferUpdate(false)
                        // setIsFirstDealAssign(true)
                        setIsDealSettleAccept(true)
                    }}
                >
                    Commit
                </button>
            </div>
        </div>
    </div>
    }
    </>
  )
}
