import React, { useState } from 'react'
import { SlOptionsVertical } from 'react-icons/sl'
import { ImUser } from 'react-icons/im'
import { AiFillCamera } from 'react-icons/ai'
import { HiLocationMarker } from 'react-icons/hi'
import { BsFillChatFill } from 'react-icons/bs'
import { IoCallSharp } from 'react-icons/io5'
import { FaEdit } from 'react-icons/fa'
import {useFleetStateContext} from '../context/FleetDealsContext'
import { RiSteering2Fill } from 'react-icons/ri'
import {FaCoins} from 'react-icons/fa'
import {MdLocationPin} from 'react-icons/md'
import {AiFillClockCircle} from 'react-icons/ai'
import {BiEdit} from 'react-icons/bi'
import firebase from '../firebase-config'
import { useStateContext } from '../context/DashboardStateContext'

export default function VehicleDetails() {
  const [updateRW,setUpdateRW] = useState(false);
  const [updateRWUrl,setUpdateRWUrl] = useState(false);

  const {
    addVehiclesData, 
    setAddVehiclesData,
    selectVehicle, 
    setSelectVehicle
  } = useFleetStateContext();

  const {
    userUid, 
  } = useStateContext();

  console.log("all the vehicles", addVehiclesData)

  const updateRoadWorthyFnc = (e, file) => {
    e.preventDefault()
    if(file.name){
      const storage = firebase.storage()
      if(file === null) return
      const fileRefUpdateUrl = storage.ref(`enatis_pdf/${file?.name}`)
      setUpdateRW(file.name)
      fileRefUpdateUrl.put(file).then((snapshot) => {
          const fileUrl = fileRefUpdateUrl.getDownloadURL()
          .then((url) => {    
                        
            setUpdateRWUrl(url)
            firebase.database().ref('fleets/' +
            userUid +
            '/' +
            "horses" +
            '/' +
            selectVehicle[0].licence_number,).update({
              enatis_pdf: url
            })
          })
              .catch((error) => {
              });
      });
    }
  }


  return (
    <div className='rounded-lg vehicles-details-mobile flex scrollbar-hide' style={{width:"68%", background:"#fff", height:"100%", maxHeight:"100%", overflow:"scroll"}}>
        <div className='p-4' style={{width:"65%"}}>
            <p>Performance</p>
            <div className='p-3' style={{height:"", paddingTop:"12rem"}}>
                <div className='h-2 rounded-xl w-full' style={{background:"#CBCBCB"}}>
                    <div className='h-full rounded-xl' style={{width:"90%", background:"#5BBB1C"}}></div>
                </div>
                <div className='pt-1 flex justify-between'>
                  <p style={{fontSize:".8rem"}}>Response time</p>
                  <p style={{fontSize:".8rem"}}>100%</p>
                </div>
                <div className='flex justify-between pt-5'>
                  <div className='flex justify-center items-center flex-col'>
                    <div className='rounded-full flex justify-center items-center' style={{color:"#fff", width:"55px", height:"55px", background:"#C91818", fontSize:".8rem"}}>30</div>
                    <p className='pt-1'>Reports</p>
                  </div>

                  <div className='flex justify-center items-center flex-col'>
                    <div className='rounded-full flex justify-center items-center' style={{color:"#fff", width:"55px", height:"55px", background:"#873B01", fontSize:".8rem"}}>99+</div>
                    <p className='pt-1'>Stops</p>
                  </div>

                  <div className='flex justify-center items-center flex-col'>
                    <div className='rounded-full flex justify-center items-center' style={{color:"#fff", width:"55px", height:"55px", background:"#162659", fontSize:".8rem"}}>8hrs</div>
                    <p className='pt-1'>Av. Rest Time</p>
                  </div>

                  <div className='flex justify-center items-center flex-col'>
                    <div className='rounded-full flex justify-center items-center' style={{color:"#fff", width:"55px", height:"55px", background:"#19A41E", fontSize:".8rem"}}>R27k</div>
                    <p className='pt-1'>Av. Income</p>
                  </div>
                </div>

                <div className='flex justify-between pt-5'>
                  <div className='rounded-xl p-2 flex justify-between text-center items-center flex-col' style={{width:"6rem", height:"7rem", background:"#515151", color:"#fff"}}>
                    <div className='w-full flex justify-between items-end flex-col'>
                        <p className='font-bold' style={{fontSize:"1.5rem"}}><RiSteering2Fill/></p>
                        <p className='pt-2 font-bold' style={{fontSize:"1rem", textAlign:"end"}}>250 032</p>
                        <span style={{fontSize:".8rem"}}>km</span>
                    </div>
                    <div >
                        <p className='p-0 m-0' style={{fontSize:".7rem", textAlign:"center"}}>Distance Driven</p>
                    </div>
                  </div>

                  <div className='rounded-xl p-2 flex justify-between text-center items-center flex-col' style={{width:"6rem", height:"7rem", background:"#19A41E", color:"#fff"}}>
                    <div className='w-full flex justify-between items-end flex-col'>
                        <p className='font-bold' style={{fontSize:"1rem"}}><FaCoins/></p>
                        <p className='pt-2 font-bold' style={{fontSize:"1rem", textAlign:"end"}}>R70.50</p>
                    </div>
                    <div >
                        <p className='p-0 m-0' style={{fontSize:".7rem"}}>Rate/Kilometre</p>
                    </div>
                  </div>

                  <div className='rounded-xl p-2 flex justify-between text-center items-center flex-col' style={{width:"6rem", height:"7rem", background:"#43781F", color:"#fff"}}>
                    <div className='w-full flex justify-between items-end flex-col'>
                        <p className='font-bold' style={{fontSize:"1.3rem"}}><MdLocationPin/></p>
                        <p className='pt-2 font-bold' style={{fontSize:"1rem", textAlign:"end"}}>2 343</p>
                    </div>
                    <div >
                        <p className='p-0 m-0' style={{fontSize:".7rem"}}>Rate/Kilometre</p>
                    </div>
                  </div>

                  <div className='rounded-xl p-2 flex justify-between text-center items-center flex-col' style={{width:"6rem", height:"7rem", background:"#126C70", color:"#fff"}}>
                    <div className='w-full flex justify-between items-end flex-col'>
                        <p className='font-bold' style={{fontSize:"1.3rem", color:"#fff"}}><AiFillClockCircle/></p>
                        <p className='pt-2 font-bold' style={{fontSize:"1rem", textAlign:"end"}}>23 098</p>
                        <span style={{fontSize:".8rem"}}>Hour</span>
                    </div>
                    <div >
                        <p className='p-0 m-0' style={{fontSize:".7rem"}}>Work Duration</p>
                    </div>
                  </div>
                </div>
            </div>
        </div>
        <div className='p-4 ' style={{width:"35%", height:"100%", overflow:"hidden"}}>
            <div className='flex justify-between'>
              <p className='p-2 rounded-xl py-1 px-3' style={{background:"#fce200"}}>On Duty</p>
              <p className='hover:bg-gray-200 cursor-pointer rounded-full flex justify-center items-center'  style={{width:"1.5rem", height:"1.5rem"}} ><SlOptionsVertical/></p>
            </div>
            <div 
                className='flex rounded-full w-full justify-center m-auto items-center relative'
                style={{background:"#DCDCDC", width:"6rem", height:"6rem"}}
            >
                {selectVehicle[0].photo_uri ?
                  <img className='w-full h-full rounded-full' style={{objectFit:"cover"}} src={selectVehicle[0].photo_uri} alt="" />
                  : <p className='' style={{fontSize:"4.5rem", color:"#fff"}}><ImUser/></p>
                }
                <p 
                    className='absolute right-1 bottom-0 cursor-pointer h-6 w-6 flex justify-center items-center rounded-full' 
                    style={{fontSize:"1rem", background:"#fce200"}}
                    onClick={() =>{
                    }}
                >
                    <AiFillCamera/>
                </p>
            </div>
            <div className='flex flex-col justify-center items-center'>
              <p className="font-bold mt-4" style={{fontSize:"1.1rem"}}>{selectVehicle[0].year} {selectVehicle[0].make} {selectVehicle[0].model}</p>
              <p className='mt-1' style={{fontSize:".9rem"}}>{selectVehicle[0].licence_number}</p>
            </div>
            <div className='scrollbar-hide'style={{height:"340px", overflow:"scroll"}}>
              <div className='w-full mt-3 p-4 rounded-xl' style={{background:"#EBEBEB"}}>
                <p className='m-0 p-0'>VIN</p>
                <div>
                  <p className='font-bold' style={{fontSize:"1rem"}}>{selectVehicle[0].vin_number}</p>
                </div>
                <p style={{color:"#818181"}}>Exp. {selectVehicle[0].end_date}</p>
              </div>
              <div className='w-full mt-3 p-4 rounded-xl' style={{background:"#EBEBEB"}}>
                <p className='m-0 p-0' style={{color:"#646464"}}>Documents</p>
                <div className='mt-3 flex p-2 rounded-xl items-center justify-between' style={{background:"#F2F2F2"}}>
                  {/* <p className='p-0 font-bold' style={{fontSize:".9rem"}}>Road Worthy Certificate</p> */}
                  {selectVehicle[0].enatis_pdf &&
                      <a target='_blank' href={selectVehicle[0].enatis_pdf} className='flex justify-between rounded-xl mt-2 items-center w-full p-2' style={{background:"rgb(246 246 246)"}}>
                        <p className='font-bold'>Road Worthy Certificate</p>
                        <label  className='cursor-pointer' style={{color:"blue"}}  htmlFor="RWUpdate">
                          <input 
                              type="file" 
                              onChange={(e) => updateRoadWorthyFnc(e, e.target.files[0])} 
                              id='RWUpdate' 
                              hidden
                              accept='image/*, .pdf, .doc, .docx' 
                          />
                          <p style={{color:"blue", fontSize:".8rem"}}><FaEdit/></p>
                        </label>
                      </a>
                    }
                </div>
              </div>
              <div className='w-full mt-3 p-4 rounded-xl' style={{background:"#EBEBEB"}}>
                <p className='m-0 p-0' style={{color:"#646464"}}>Weight class</p>
                <p className='font-bold' style={{fontSize:".9rem"}}>Height Duty</p>
              </div>
              <div className='my-2'>
                <p className='w-full flex justify-end' style={{color:"blue"}}>Change</p>
              </div>
              <div className='flex justify-between w-full'>
                <div className='rounded-xl bg-gray-300 flex justify-center flex-col items-center' style={{width:"32%", background:"#EBEBEB"}}>
                    <div className='w-10 h-10 rounded-full bg-gray-500'></div>
                    <p className='m-0 p-0 mt-2 font-bold' style={{fontSize:".7rem"}}>Shane Brookly</p>
                </div>
                <div className='rounded-xl p-3 bg-gray-300' style={{width:"58%", background:"#EBEBEB"}}>
                    <div className='w-7 h-7 rounded-full bg-white'></div>
                    <p className='m-0 p-0 font-bold pt-1' style={{fontSize:".6rem"}}>2022 Range Rover</p>
                    <p className='m-0 p-0'>DT37CKGP</p>
                </div>
              </div>
            </div>
        </div>
    </div>
  )
}
