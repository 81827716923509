import React, {useEffect, useState, useRef} from 'react'
import emptyIcon from '../icons/box.png';
import { Link, useNavigate  } from 'react-router-dom'
import EnterpriseNav from '../components/EnterpriseNav';
import '../css/schedule.css'
import firebase from '../firebase-config';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import Stack from '@mui/material/Stack';
import { v4 as uuidv4 } from 'uuid';
import Alert from '../components/Alerts/Alert'
import Spinner from '../components/Spinner'
import axios from 'axios';
import Box from '@mui/material/Box';
import DateRangePick from '../components/DateRangePick'
import { useStateContext } from '../context/DashboardStateContext'
import { parseJSON } from 'date-fns/esm';
import { database } from 'firebase';
import { getNativeSelectUtilityClasses } from '@mui/material';
import Slider from '@mui/material/Slider';
import { useStateBidding } from '../context/BiddingStatesContext'
import {ImCross} from 'react-icons/im'
import {motion} from 'framer-motion'
import { useForm, Controller } from "react-hook-form";
import { fadeIn } from '../variantsFnc/variant';
import {AiOutlinePlus} from 'react-icons/ai'
import EnterpriseLoader from '../components/LoaderEnterprise'
import { useBookingLogic } from '../screens/saveBooking';
// import { Client, Status } from '@googlemaps/google-maps-services-js';// import {useFleetStateContext} from '../context/FleetDealsContext'
// import twilio from 'twilio'

export default function SchedulingPage({

}) {
    const [userEmail, setUserEmail] = useState("");
    const [userUid, setUserUid] = useState("");
    const [company, setCompany] = useState("");
    const [pickUpDetails , setPickUpDetails] = useState([])
    const [dropDetails , setDropDetails] = useState([])
    const [cargoDetails , setCargoDetails] = useState([])
    const [prerequisites , setPrerequisites] = useState([])
    const [fileUrl, setFileUrl] = useState("")
    const [cargoSchedule, setCargoSchedule] = useState([]);
    const [operationDays, setOperationDays] = useState([])
    const [rateIndication, setRateIndication] = useState(null)
    const [finalPick, setFinalPick] = useState([])
    const [finalDrop, setFinalDrop] = useState([])
    // const [prerequisitesArray, setPrerequisitesArray] = useState([]);
    const [vehicleType, setVehicleType] = useState([])
    // const [bays, setBays] = useState(0);
    const [loadCalChange,setLoadCalChange] = useState(0);
    const navigate = useNavigate();
    const [rangeValue, setRangeValue] = useState(0);
    const [loadsCount, setLoadsCount] = useState(0);
    const [numberOfDays, setNumberOfDays] = useState(0);
    const [numberChangeVal, setNumberChangeVal] = useState(numberOfDays);
    const [moredayss, setMoredayss] = useState(() => 0)
    const [testVar, setTestVar] = useState(0);
    const [isScheduleLoaded, setIsScheduleLoaded] = useState(false);
    const [actualLoad, setActualLoad] = useState();
    const [dropOffData, setDropOffData] = useState(null)
    const [pickUpData, setPickUpData] = useState(null)
    const [isCoordinates, setIsCoordinates] = useState(getNativeSelectUtilityClasses)
    const [askingPrice, setAskingPrice] = useState(false)
    const [validationOpsHours, setValidationOpsHours] = useState(false);
    const [selectDate, setSelectDate] = useState(false);
    const [isMon, setIsMon] = useState(false);
    const [isTue, setIsTue] = useState(false);
    const [isWed, setIsWed] = useState(false);
    const [isThu, setIsThu] = useState(false);
    const [isFri, setIsFri] = useState(false);
    const [isSat, setIsSat] = useState(false);
    const [isSun, setIsSun] = useState(false);
    const [isBookClicked,setIsBookClicked] = useState(false);
    const [distance, setDistance] = useState([])
    const [name, setName] = useState("")
    const { register, handleSubmit, formState: { errors }, control } = useForm();
    const [isPhoneSet, setIsPhoneSet] = useState();
    const [isPhoneSetUrl, setIsPhoneSetUrl] = useState();
    const [fleetContactData, setFleetContactData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [contact, setContact] = useState([])
    const [shipperData, setShipperData] = useState();
    const [result, setResult] = useState([]);
    const [isLoading , setIsLoading] = useState(false);
    const [infoShow,setInfoShow] = useState(false)
    const [selectContact,setSelectContact] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const { saveTheBooking } = useBookingLogic();
    // const [popUpQuote,setPopUpQuote] = useState(false)

    const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const addFleetOwnerContacts = (data) => {
        const databasePath = `/booking_party/${userUid}/myFleetContacts`;

        // Define the data to add
        const newData = {
            name: data.name,
            email: data.email,
            phone: data.phone,
            imageUrl: isPhoneSetUrl || ""
        };

        // Check if the data with the same email already exists
        firebase.database().ref(databasePath)
        .orderByChild('email')
        .equalTo(data.email)
        .once('value', (snapshot) => {
            if (!snapshot.exists()) {
            // Data doesn't exist, push the new data
            firebase.database().ref(databasePath).push(newData)
                .then(() => {
                // Data added successfully
                console.log('Data added successfully.');
                })
                .catch((error) => {
                // Handle errors if any
                console.error('Error adding data:', error);
                });
            } else {
            // Data with the same email already exists
            console.log('Data with the same email already exists.');
            }
        })
        .catch((error) => {
            // Handle errors if any
            console.error('Error checking data:', error);
        });

        console.log("the data", data)
    }

    const bookPartyFleetContact = (e, file) =>{
        setLoading(true);
        e.preventDefault()
        const storage = firebase.storage()
        if (file === null) {
            setLoading(false); // Set loading to false if file is null
            return;
          }
        const fileRef = storage.ref(`bookingPartyFleetContactImage/${file?.name}`)
        setIsPhoneSet(file.name)
        fileRef
        .put(file)
        .then((snapshot) => {
          fileRef
            .getDownloadURL()
            .then((url) => {
              setIsPhoneSetUrl(url);
              setLoading(false); // Set loading to false when the upload is complete
            })
            .catch((error) => {
              setLoading(false); // Set loading to false in case of an error
            });
        })
        .catch((error) => {
          setLoading(false); // Set loading to false in case of an error
        });
    }

    const handleCalculateDistance = async () => {
        const origin = "1288 Hlongwane Street, Choolorkop, Edenvale, South Africa";
        const destination = "794 Cascades Road, Little Falls, Roodepoort, South Africa";
        try {
          const response = await fetch(
            `https://duration-distance.onrender.com/calculate-distance?origin=${encodeURIComponent("1288 Hlongwane Street, Choolorkop, Edenvale, South Africa")}&destination=${encodeURIComponent("794 Cascades Road, Little Falls, Roodepoort, South Africa")}`
          );

          if (response.ok) {
            const data = await response.json();
            console.log("the data to see", data);
            setResult(data);
          } else {
            console.error('Error:', response.statusText);
          }
        } catch (error) {
          console.error('Error:', error.message);
        }
      };

      useEffect(() => {
        const fetchData = async () => {
          try {
            console.log("Fetching data...");
            await handleCalculateDistance();
            console.log("Data fetched successfully");
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };

        fetchData();
      }, []);

    //  setPrerequisitesArray({prerequisites:prerequisites[0].prerequisites})

    useEffect(() => {
        if (!fleetContactData.length) {
          const databasePath = `/booking_party/${userUid}/myFleetContacts`;
          firebase.database().ref(databasePath).on("value", (snapshot) => {
            const data = snapshot.val(); // Get the raw data

            if (data !== null) {
              const dataArray = Object.values(data) || []; // Convert data to an array
              setFleetContactData(dataArray);
            } else {
              // Handle the case when data is null (e.g., set an empty array)
              setFleetContactData([]);
            }
          });
        }
      }, [fleetContactData]); // fleetContactData as a dependency

    console.log("this is fleet data",userUid)

    const {
        isEnterprise,
        setIsEnterprise,
        isTracking,
        setIsTracking,
        isShowSchedule,
        setIsShowSchedule,
        setIsShowRequest,
        isShowRequest,
        setCargoLink,
        isQuote,
        setPopUpQuote,
        popUpQuote,
        cargoLink,
        fleetLink,
        setFleetLink,
        referalLink,
        setReferalLink,
        profileLink,
        setProfileLink,
        analysisLink,
        setAnalysisLink,
        walletLink,
        setWalletLink,
        getHelpLink,
        setGetHelpLink,
        contactLink,
        setContactLink,
        setIsQuote,
        endDate,
        setEndDate,
        startDate,
        setStartDate,
        operationDaysNew,
        setOperationDaysNew,
        includeHolidays,
        setIncludeHolidays,
        setCollectionBays,
        setDropOffBays,
        gateInOutDuration,
        setGateInOutDuration,
        setIsUseOwnTransport,
        transporterContact,
        setTransporterContact,
    } = useStateContext();

    const {
        puCity,
        doCity
    } = useStateBidding();

    console.log("doCity", doCity, "puCity", puCity)
    var loadcal = []
    var moreloadss = loadcal
    var moreloadCal = loadcal
    var moveload = 0

    const [openAlert, setAlert] = useState(false)
    const [openSpinner, setOpenSpinner] = useState(false);
    const [bookingSuccess, setBookingSuccess] = useState(false);

    // const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

    const rangeRef = useRef()

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
            setUserEmail(user.email);
              setUserUid(user.uid);
              var uid = user.uid
              firebase.database().ref('/booking_party/' + uid).once('value', (snapshot) => {
                    if(snapshot.exists()){
                        if(snapshot.val().companyName){
                            const company = snapshot.val()?.companyName
                            setCompany(company)
                        }else{
                            setCompany("")
                        }
                        const name = snapshot.val()?.firstName
                        setName(name);
                    }
                });
              // ...
            } else {
            }
        });
    }, [])

    useEffect(() => {
        if(localStorage.getItem("pickSelectd")){
            const storedList = JSON.parse(localStorage.getItem("pickSelectd"))
            setPickUpDetails(storedList);
            setFinalPick(storedList[0].details);
        }
    }, [])

    useEffect(() => {
        if(localStorage.getItem("dropSelectd")){
            const storedList = JSON.parse(localStorage.getItem("dropSelectd"))
            setDropDetails(storedList);
            setFinalDrop(storedList[0].details);
        }
    }, [])

    useEffect(() => {
        if(localStorage.getItem("Prerequisites")){
            const storedList = JSON.parse(localStorage.getItem("Prerequisites"))
            setPrerequisites(storedList);
        }
    }, [])

    useEffect(() => {
        if(localStorage.getItem("cargoSelectd")){
            const storedList = JSON.parse(localStorage.getItem("cargoSelectd"))
            const vehicleType = JSON.parse(localStorage.getItem("vehicleType"))
            storedList.details.vehicle_type = vehicleType[0]
            setCargoDetails([storedList]);
            console.log("This is the new cargo", storedList);
        }

    }, [])

    useEffect(() => {
        const startDateString = startDate.toString().substring(0,16)
        const endDateString = endDate.toString().substring(0,16)
        setCargoSchedule({
            pickUpDetails: pickUpDetails,
            dropDetails: dropDetails,
            prerequisites:prerequisites,
            startDateString: startDateString,
            endDateString: endDateString,
            operationDays: operationDays,
            includeHolidays: includeHolidays,
            rateIndication: rateIndication
        })
        const cargo = cargoSchedule
        // localStorage.setItem("cargoSchedule", JSON.stringify([cargo]))
    }, [])

    console.log("shipper data", shipperData)

    useEffect(() => {
        setTimeout(() =>{
            setIsScheduleLoaded(true);
        }, 800)
    },[])

        //Calculate number of days from start date to end date
        const d1 = startDate,
        d2 = endDate,
        diff = (d2-d1)/864e5,
        dateFormat = {weekday:'long',month:'short',day:'numeric'},
        dates = Array.from(
            {length: diff+1},
            (_,i) => {
            const date = new Date()
            date.setDate(d1.getDate()+i)
            const [weekdayStr, dateStr] = date.toLocaleDateString('en-US',dateFormat).split(', ')
            return `${dateStr} ${weekdayStr}`
            }
        )
    useEffect(() => {
        const google = window.google

        function calculateDistances() {
           var  origin = finalPick.Address; //Get the source string
           var destination = finalDrop.Address; //Get the destination string
            var service = new google.maps.DistanceMatrixService(); //initialize the distance service
            service.getDistanceMatrix(
              {
                origins: [origin], //set origin, you can specify multiple sources here
                destinations: [destination],//set destination, you can specify multiple destinations here
                travelMode: google.maps.TravelMode.DRIVING, //set the travelmode
                unitSystem: google.maps.UnitSystem.METRIC,//The unit system to use when displaying distance
                avoidHighways: false,
                avoidTolls: false
              }, calcDistance); // here calcDistance is the call back function
        }

        function calcDistance(response, status) {
            if (status != google.maps.DistanceMatrixStatus.OK) { // check if there is valid result
              alert('Error was: ' + status);
            } else {
              var origins = response.originAddresses;
              var destinations = response.destinationAddresses;
            //   deleteOverlays();

              for (var i = 0; i < origins.length; i++) {
                var results = response.rows[i].elements;

                for (var j = 0; j < results.length; j++) {
                    setDistance(results[j]?.distance.text);
                }
              }
            }
        }

        calculateDistances()

    }, [])

    useEffect(() => {
        isMon && setOperationDays([...operationDays, "Monday"])
        isTue && setOperationDays([...operationDays, "Tuesday"])
        isWed && setOperationDays([...operationDays, "Wednesday"])
        isThu && setOperationDays([...operationDays, "Thursday"])
        isFri && setOperationDays([...operationDays, "Friday"])
        isSat && setOperationDays([...operationDays, "Saturday"])
        isSun && setOperationDays([...operationDays, "Sunday"])

        const removeDuplicates = (arr) => {
            return arr.filter((item,
                index) => arr.indexOf(item) === index);
        }

        setOperationDaysNew(Array.from(new Set(operationDays)))

    }, [isMon, isTue, isWed, isThu, isFri, isSat])

    useEffect(() => {
        const cargoDimensions = {
          volume: (cargoDetails[0]?.details?.lengthValue * cargoDetails[0]?.details?.breadth * cargoDetails[0]?.details?.height) / 1000000,
          height: cargoDetails[0]?.details?.height
        };

        const vehicleDimensions = {
          ...prerequisites[0]?.prerequisites?.vehicleDimensions
        };

        if (cargoDimensions.volume > vehicleDimensions.volume) {
          setShowModal(true);
        }
    }, []);

  return (
    <div className={`duration-500 relative ease-in-out ${isScheduleLoaded ? 'open-schedule' : 'schedule-page'}`}>
        <div  style={{display:"flex", alignItems:"center", marginBottom:"17px"}}>
            <span className='schedule-navigation'>
                <p
                    style={{cursor: "pointer"}}
                    onClick={() => {
                        setIsShowSchedule(false);
                        setIsTracking(false);
                        setIsEnterprise(true);
                        setCargoLink(false);
                        setIsShowRequest(false);
                    }}
                >Booking</p>
                <p>/</p>
                <p>Schedule</p>
            </span>
        </div>
        <div className='schedule-sum'>
            <div>
                <div>
                    <p>Summary</p>
                    {/* <div>
                        <p>Empty Depot</p>
                    </div> */}
                    <div>
                        <p>Load Cargo</p>
                    </div>
                    {/* <div>
                        <p>Staging Area</p>
                    </div> */}
                    <div>
                        <p>Offload Cargo</p>
                    </div>
                    <div style={{height:"397px"}}>
                        <p>Cargo</p>
                    </div>
                    {/* <div style={{height:"199px"}}>
                        <p>Vehicle</p>
                    </div> */}
                    <div>
                        <p>Prerequisites</p>
                    </div>
                </div>
                <div>
                    <p style={{marginBottom:"36px"}}></p>
                    {/* <div style={{height: "69px"}}>
                       <p>Not selected</p>
                    </div> */}

                    {pickUpDetails.length > 0 ? pickUpDetails.map((pick, key) =>(
                        <React.Fragment key={key}>
                            <div style={{height: "69px"}}>
                                <p>{pick.details?.Name}</p>
                                <p>{pick.details?.CompanyName}</p>
                                <p>{pick.details?.Email}</p>
                            </div>
                        </React.Fragment>
                    ))

                    : <div style={{height: "69px"}}><p>Not selected</p></div>
                    }

                    {/* <div style={{height: "69px"}}>
                       <p>Not selected</p>
                    </div> */}
                    {dropDetails.length > 0 ? dropDetails.map((drop, key) =>(
                        <React.Fragment key={key}>
                            <div style={{height: "69px"}}>
                                <p>{drop.details?.Name}</p>
                                <p>{drop.details?.CompanyName}</p>
                                <p>{drop.details?.Email}</p>
                            </div>
                        </React.Fragment>
                    ))

                    : <div style={{height: "69px"}}><p>Not selected</p></div>
                    }

                    <div className='cargo-sum-sched'>
                        {cargoDetails.length > 0 ? cargoDetails.map((cargo, key) =>(
                            <React.Fragment key={key}>
                                <div>
                                    <h1>{cargo.details?.productName}</h1>
                                    <p>SKU: {cargo.details?.productCode}</p>
                                </div>
                            </React.Fragment>
                        ))

                            : <div>
                                <h1>Not selected</h1>
                                <p>Not selected</p>
                            </div>
                        }
                        <div>
                            <div className='quantity-plus-info'>

                                <div>
                                    <p>Quantity</p>
                                    <p>Length</p>
                                    <p>Breadth</p>
                                    <p>Height</p>
                                </div>

                                {cargoDetails.length > 0 ? cargoDetails.map((cargo, key) =>(
                                <React.Fragment key={key}>
                                    <div>
                                        <p>: {cargo.details?.quantity}</p>
                                        <p>: {cargo.details?.lengthValue} cm</p>
                                        <p>: {cargo.details?.breadth} cm</p>
                                        <p>: {cargo.details?.height} cm</p>
                                    </div>
                                </React.Fragment>
                                ))

                                    : <div>
                                        <p>Not selected</p>
                                        <p>Not selected</p>
                                        <p>Not selected</p>
                                        <p>Not selected</p>
                                    </div>
                                }
                            </div>

                            <div className='quantity-plus-info'>
                                {cargoDetails.length > 0 ? cargoDetails.map((cargo, key) =>(
                                <React.Fragment key={key}>
                                    <div>
                                        <p>{
                                                (cargo.details?.lengthValue *
                                                cargo.details?.breadth *
                                                cargo.details?.height)/1000000

                                            }m&#179; :</p>
                                        <p>{
                                                (((cargo.details?.lengthValue *
                                                cargo.details?.breadth *
                                                cargo.details?.height)/1000000)*cargo.details?.quantity).toFixed(2)

                                            }&#x33a5; :</p>
                                        <p>{parseFloat((cargo.details?.weight)/1000).toFixed(2)}mt :</p>
                                        <p>{parseFloat(((cargo.details?.weight)*cargo.details?.quantity)/1000).toFixed(2)}mt :</p>
                                    </div>
                                </React.Fragment>
                                ))

                                    : <div>
                                        <p>Not selected</p>
                                        <p>Not selected</p>
                                        <p>Not selected</p>
                                        <p>Not selected</p>
                                    </div>
                                }

                                <div>
                                    <p>Net Volume</p>
                                    <p>Total Volume</p>
                                    <p>Net Weight</p>
                                    <p>Total Weight</p>
                                </div>
                            </div>
                        </div>

                        <div className='hazardous-cargo'>
                            <div>
                                <p>Hazardous Cargo</p>
                            </div>
                            <div className='hazardous-wrapper'>
                                <div className='hazardous-second-child'>
                                    <div>
                                        <p>Package Type</p>
                                        <p>Fragile Cargo</p>
                                    </div>
                                    {cargoDetails.length > 0 ? cargoDetails.map((cargo, key) =>(
                                    <React.Fragment key={key}>
                                        <div style={{textAlign:"start"}}>
                                            <p>: {cargo.details?.packageType}</p>
                                        <p>: No</p>
                                        </div>
                                    </React.Fragment>
                                    ))

                                        : <div>
                                            <p>Not selected</p>
                                            <p>Not selected</p>
                                        </div>
                                    }

                                </div>

                                <div className='hazardous-second-child'>
                                    {cargoDetails.length > 0 ? cargoDetails.map((cargo, key) =>(
                                    <React.Fragment key={key}>
                                        <div style={{justifyContent:"end", textAlign:"end"}}>
                                            {cargo.details.imoClass &&
                                                <p>{cargo.details?.imoClass} :</p>
                                            }
                                            {cargo.details.unNumber &&
                                                <p>{cargo.details?.unNumber} :</p>
                                            }
                                        </div>
                                    </React.Fragment>
                                    ))

                                        : <div>
                                            <p>Not selected</p>
                                            <p>Not selected</p>
                                        </div>
                                    }

                                    <div>
                                        {cargoDetails[0]?.details?.imoClass &&
                                            <p>IMO</p>
                                         }
                                        {cargoDetails[0]?.details?.unNumber &&
                                            <p>UN Number</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='temperature-controlled'>
                            <div>
                                <p>Temperature Controlled Cargo</p>
                            </div>
                            <div className='hazardous-second-child'>
                                <div>
                                    <p>Min</p>
                                    <p>Max</p>
                                </div>
                                {cargoDetails.length > 0 ? cargoDetails.map((cargo, key) =>(
                                    <React.Fragment key={key}>
                                        <div style={{justifyContent:"start", textAlign:"start"}}>
                                            <p>: {cargo.details.hazard?.min_temp}</p>
                                            <p>: {cargo.details.hazard?.max_temp}</p>
                                        </div>
                                    </React.Fragment>
                                    ))

                                        : <div>
                                            <p>Not selected</p>
                                            <p>Not selected</p>
                                        </div>
                                }
                            </div>
                        </div>

                        {cargoDetails.length > 0 ? cargoDetails.map((cargo, key) =>(
                            <React.Fragment key={key}>
                                 <a href={cargo?.sds_url} target="_black"  className='view-sds'>View SDS <i className="fa-solid fa-eye"></i></a>
                            </React.Fragment>
                            ))

                                : <a href="" className='view-sds'>View SDS <i className="fa-solid fa-eye"></i></a>
                        }
                    </div>

                    <div className='prerequisites'>
                        <h2>{vehicleType}</h2>
                        <div>
                            {prerequisites[0]?.prerequisites?.ad_hoc_services[0] !== "Not selected" &&
                                <div>
                                    <p style={{fontWeight:"bold"}}>Ad-hoc Services</p>
                                    {prerequisites.length > 0  ? prerequisites[0].prerequisites.ad_hoc_services.map((pre, key) =>(
                                    <React.Fragment key={key}>
                                        { pre && <p>{pre}</p> }
                                    </React.Fragment>
                                    ))

                                        :
                                            <p>Not selected</p>
                                    }
                                </div>
                            }

                            {prerequisites[0]?.prerequisites?.documentation[0] !== "Not selected" &&
                                <div>
                                    <p style={{fontWeight:"bold"}}>Documentation</p>
                                    {prerequisites.length > 0 ? prerequisites[0].prerequisites.documentation.map((pre, key) =>(
                                    <React.Fragment key={key}>
                                            <p>{pre}</p>
                                    </React.Fragment>
                                    ))

                                        :
                                            <p>Not selected</p>
                                    }
                                </div>
                            }
                        </div>

                        <div>
                            {prerequisites[0]?.prerequisites?.personal_protective[0] !== "Not selected" &&
                                <div>
                                    <p style={{fontWeight:"bold"}}>Personal Protective Equipment</p>
                                    {prerequisites.length > 0 ? prerequisites[0].prerequisites.personal_protective.map((pre, key) =>(
                                    <React.Fragment key={key}>
                                            <p>{pre}</p>
                                    </React.Fragment>
                                    ))

                                        :
                                            <p>Not selected</p>
                                    }
                                </div>
                            }
                            {prerequisites[0]?.prerequisites?.vehicle_equipment[0] !== "Not selected" &&
                                <div>
                                    <p style={{fontWeight:"bold"}}>Vehicle Equipment</p>
                                    <div>
                                        {prerequisites.length > 0 ? prerequisites[0].prerequisites.vehicle_equipment.map((pre, key) =>{
                                        return(
                                            <p key={key}>{pre}</p>
                                        )})
                                            : <p>Not selected</p>
                                        }
                                    </div>

                                </div>
                            }
                        </div>
                        {prerequisites[0]?.prerequisites?.goods_in_transit &&
                            <div className='insurance'>
                                <h2>Insurance (Goods in Transit)</h2>
                                {prerequisites.length > 0 ? prerequisites.map((pre, key) =>(
                                    <React.Fragment key={key}>
                                            <p>R{pre.prerequisites.goods_in_transit}</p>
                                    </React.Fragment>
                                    ))

                                        :
                                            <p>Not selected</p>
                                    }
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className='schedule-the-cargo'>
                <h2>Schedule this cargo</h2>
                <p>When would you like to move this cargo?</p>
                <div className='pick-the-date'>
                    <DateRangePick
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        numberOfDays={numberOfDays}
                        setNumberOfDays={setNumberOfDays}
                    />
                </div>
                <p style={{marginTop:"10px", marginBottom:"10px"}}>Handling Operation Days</p>
                <div className='days-of-week' style={{marginBottom:"10px"}}>
                    <button
                        style={{background: `${isMon ? "#f9dd07" : "#fff"}`}}
                        onClick={(e) => {
                            setIsMon(!isMon);
                        }
                    } value="Monday">M</button>
                    <button
                        style={{background: `${isTue ? "#f9dd07" : "#fff"}`}}
                        onClick={(e) => {
                            setIsTue(!isTue);
                        }
                    } value="Tuesday">T</button>
                    <button
                        style={{background: `${isWed ? "#f9dd07" : "#fff"}`}}
                        onClick={(e) => {
                            setIsWed(!isWed);
                        }
                    } value="Wednesday">W</button>
                    <button
                        style={{background: `${isThu ? "#f9dd07" : "#fff"}`}}
                        onClick={(e) => {
                            setIsThu(!isThu);
                        }
                    }value="Thursday">T</button>
                    <button
                        style={{background: `${isFri ? "#f9dd07" : "#fff"}`}}
                        onClick={(e) => {
                            setIsFri(!isFri);
                        }
                    } value="Friday">F</button>
                    <button
                        style={{background: `${isSat ? "#f9dd07" : "#fff"}`}}
                        onClick={(e) => {
                            setIsSat(!isSat);
                        }
                    } value="Saturday">S</button>
                    <button
                        style={{background: `${isSun ? "#f9dd07" : "#fff"}`}}
                        onClick={(e) => {
                            setIsSun(!isSun);
                        }
                    } value="Sunday">S</button>
                </div>
                <label htmlFor="" style={{fontSize:"12px"}}>
                    <input type="checkbox" name="" id="" style={{marginRight:"8px"}} onClick={() => setIncludeHolidays(true)}/>
                    Include public holidays
                </label>

                <div className='gate-duration'>
                    <p>Gate in - gate out duration</p>
                    <div className='gate-in-out'>
                        <label htmlFor="15 mins">
                            <input value="15 mins" name='transit' type="radio" onClick={(e) => setGateInOutDuration(e.target.value)}/>
                            15 mins
                        </label>
                        <label htmlFor="30 mins">
                            <input value="30 mins" name='transit' type="radio" onClick={(e) => setGateInOutDuration(e.target.value)}/>
                            30 mins
                        </label>
                        <label htmlFor="60 mins">
                            <input value="60 mins" name='transit' type="radio" onClick={(e) => setGateInOutDuration(e.target.value)}/>
                            60 mins
                        </label>
                    </div>

                    <div className='gate-in-out'>
                        <label htmlFor="90 mins">
                            <input value="90 mins" name='transit' type="radio" onClick={(e) => setGateInOutDuration(e.target.value)}/>
                            90 mins
                        </label>
                        <label htmlFor="2 hrs">
                            <input value="2 hrs" name='transit' type="radio" onClick={(e) => setGateInOutDuration(e.target.value)}/>
                            2 hrs
                        </label>
                        <label htmlFor="3 hrs">
                            <input value="3 hrs" name='transit' type="radio" onClick={(e) => setGateInOutDuration(e.target.value)}/>
                            3 hrs
                        </label>
                    </div>
                    <div className='other-mins'>
                        <input type="text" placeholder='Other mins' onChange={(e) => setGateInOutDuration(e.target.value)}/>
                    </div>
                    <div className='bay-container'>
                        <div className='flex flex-col'>
                            <div className='bays'>
                                <p className='text-[1rem] font-[500]'>Bays - Collection</p>
                                <p>Enter the number of allocated bays at the collection address</p>
                                <input type="number" placeholder='e.g 5' onChange={(e) => {
                                    setCollectionBays(e.target.value)
                                }}/>
                            </div>
                            <div className='bays mt-6'>
                                <p className='text-[1rem] font-[500]'>Bays - Drop-Off</p>
                                <p>Enter the number of allocated bays at the drop-off address</p>
                                <input type="number" placeholder='e.g 5' onChange={(e) => {
                                    setDropOffBays(e.target.value)
                                }}/>
                            </div>
                        </div>
                        <div>
                            {/* <p>Daily Load Capacity</p> */}
                            {/* {pickUpDetails.length > 0 ? pickUpDetails.map((booking) =>{
                                const Closehours = booking?.details?.OperatingHours?.close.substring(0,2)
                                const Openhours =  booking?.details?.OperatingHours?.open.substring(0,2)
                                const OH = (Closehours - Openhours)*(60)
                                  loadcal.push(((OH)/(parseFloat(gateInOutDuration)))*(bays))
                                console.log("loadsCount: ", loadcal);
                                return(
                                    <></>
                                )})
                                    : <></>
                            } */}
                        </div>
                    </div>
                    <div className='time-loads'>
                        {/* <input
                            type="range"
                            max={dates.length}
                            min={1}
                            onChange={(e) =>{
                                setRangeValue(e.target.value)
                                var i
                                for(i=1; i < moreloadCal; i++) {
                                    console.log("logged numbers", i)
                                    setTestVar((i+1))
                                }
                            }}
                            className='range-input'
                        /> */}
                        {/* <Box>
                            <Slider
                                defaultValue={50}
                                aria-label="Default"
                                valueLabelDisplay="off"
                                onChange={(e) => {
                                    // console.log("heeeelp me win this fucker", e.target.value)
                                }}
                            />
                        </Box> */}
                        <div className='track-label'>
                            {/* <div>
                                <p>More days</p>
                                <p>{rangeValue} days</p>
                            </div> */}
                            {/* <div> */}
                                {/* <p>More loads</p> */}
                                {/* <p>{
                                        prerequisites[0].prerequisites.vehicle_type[0] === "Car Carrier" ? "something" : "something else"
                                    }
                                </p> */}
                                {/* {cargoDetails.length > 0 ? cargoDetails.map((cargo) => (
                                <p key={uuidv4()} style={{color: 'red'}}>{rangeValue === 0 ? ((parseFloat((cargo.details.weight)).toFixed(3))/32) : (((parseFloat((cargo.details.weight)).toFixed(3))/32)/rangeValue).toFixed(3)} per day</p>
                                ))
                                    : <></>
                                } */}
                            {/* </div> */}
                        </div>
                        <div className='load-required-date'>
                            <p style={{fontSize:".78rem"}}>Loads Required for the Cargo</p>
                            {/* {cargoDetails.length > 0 ? cargoDetails.map((cargo) => (
                                <p>{(parseFloat((cargo.details.weight)).toFixed(3))/32}t</p>
                            ))
                                : <></>
                            } */}

                            <p >
                            {
                            cargoDetails.length > 0
                                ? cargoDetails.map((cargo) => {
                                    const weight = cargo.details?.weight || 0; // Default to 0 if weight is empty or undefined
                                    const quantity = cargo.details?.quantity || 1; // Default to 1 if quantity is empty or undefined

                                    const result = parseInt(((parseFloat(weight) * quantity).toFixed(2)) / 34000);
                                    return result === 0 ? 1 : result;
                                })
                                : ""
                            }
                            </p>

                            <p style={{fontSize: '.8rem'}}>Est. Date of Completion</p>
                            <p style={{fontSize: '1rem'}}>{`${endDate.getDate()} ${months[endDate.getMonth()]} ${endDate.getFullYear()}`}</p>
                        </div>
                    </div>
                </div>
                <span style={{ display:"flex"}} className='flex-col'>
                    {/* <span className='flex items-center'>
                        <h2 style={{fontSize:".7rem", fontWeight:"normal", marginBottom:"10px"}}>Rate Indication per Truck</h2>
                        <i className="fa-solid fa-info" title=''></i>
                    </span> */}
                    {/* <p className='mb-3'>Click Add Transporters button if you have your own transporters, click bid to bid with the transporters for your cargo or click book to request for a quote.</p> */}
                </span>

                <span className="!rounded-xl w-full flex justify-center items-center mb-2">
                    <button
                        onClick={() => {
                            setIsUseOwnTransport("not using own transport");
                            setPopUpQuote(true)
                        }}
                        className='!w-[250px] rounded-xl bg-[#f9dd07] mt-3 mb-4 flex justify-center items-center relative'
                    >
                        Request for Quote
                    </button>
                </span>

                {/* <span style={{display:"flex"}} className="book-btn-proceed mb-4">
                    <input type="number" name="" placeholder='e.g 17 00' id="" onChange={(e) => {
                        setRateIndication(e.target.value)
                        setAskingPrice(true)
                    }}/>
                    <button onClick={() => {
                                schedule()
                            }}
                    >Bid<i className="fa-solid fa-chevron-right ml-4"></i></button>
                </span> */}

                <span className="!rounded-xl flex justify-center items-center w-full mb-2">
                    <button
                        onClick={() => {
                            setIsUseOwnTransport("use own transport");
                            setIsBookClicked(true)
                        }}
                        className='!w-[250px] rounded-xl bg-[#f9dd07] flex justify-center items-center relative'
                    >
                        Use Own Transporter
                    </button>
                </span>
            </div>
            {validationOpsHours &&
                <div className='ops-validation'>
                    <div style={{width:"200px", textAlign:"center"}}>
                        <p>Please make sure to input every field on this section</p>
                        <button onClick={() => {setValidationOpsHours(false)} }>Ok</button>
                    </div>
                </div>
            }
        </div>
        {openSpinner && <Spinner/>}
        {openAlert &&
            <Alert >
                <div style={{width:"256px"}}>
                    <p style={{
                        position:"absolute",
                        right:"0px",
                        top:"0px",
                        padding:"7px",
                        cursor:"pointer",
                        fontWeight:"bold"
                    }}
                    onClick={() => setAlert(false)}
                    >X</p>
                    <h1 style={{fontSize:"14px", fontWeight:"normal"}}>Request Successfully made</h1>
                </div>
            </Alert>
        }
        {showModal &&
            <div
                className="absolute right-0 bottom-0 p-3 rounded-xl flex flex-col justify-center items-center"
                style={{top:"50%", left:"50%", transform:"translate(-50%, -50%)", width:"250px", background:"white", height:"fit-content"}}
            >
                <p style={{textAlign:"center", fontSize:"1rem"}}>
                    Please be advised that your cargo may need two {prerequisites[0]?.prerequisites?.vehicle_type}, to be able to fit, which might affect your invoice!!
                </p>
                <button className='rounded-xl px-2 mt-2' style={{background:"#fce300"}} onClick={() => setShowModal(false)}>Close</button>
            </div>
        }
        {isBookClicked &&
            <motion.div
                className='absolute left-[50%] flex justify-center items-center top-[50%] translate-x-[-50%] w-full h-full translate-y-[-50%]'
            >
                <div className='bg-white border-[.01rem] border-[#b8b8b8] shadow-2xl  h-fit w-[75%] rounded-xl p-5'>
                    <div className='w-full flex justify-end items-center'>
                        <ImCross
                            className='cursor-pointer'
                            onClick={() => {
                                setIsBookClicked(false)
                            }}
                        />
                    </div>
                    <h1 className='text-lg font-bold'>Transporter contacts</h1>
                    <div>
                        <div className='flex w-full h-fit flex-row justify-between items-center'>
                            <div className='flex flex-col items-center w-[50%] pr-4 h-[450px]'>
                                <form onSubmit={handleSubmit(addFleetOwnerContacts)} action="" className='w-full flex justify-center items-center flex-col h-full p-4 relative'>
                                    <h2 className='font-bold text-center text-[1.3rem] mb-9'>Add contacts</h2>
                                    <input
                                        type="text"
                                        name=""
                                        id=""
                                        placeholder={errors.name ? errors.name.message : 'name'}
                                        {...register('name', { required: 'Name is required' })}
                                        className='w-full px-4 text-black py-1 rounded-xl bg-[#dddddd]'
                                    />
                                    <input
                                        type="email"
                                        name=""
                                        id=""
                                        placeholder={errors.email ? errors.email.message : 'email'}
                                        {...register('email', { required: 'Email is required' })}
                                        className='w-full px-4 mt-3 text-black py-1 rounded-xl bg-[#dddddd]'
                                    />
                                    <input
                                        type="tel"
                                        name=""
                                        id=""
                                        placeholder={errors.phone ? errors.phone.message : 'phone'}
                                        {...register('phone', { required: 'Phone is required' })}
                                        className='w-full px-4 mt-3 text-black py-1 rounded-xl bg-[#dddddd]'
                                    />
                                    <label  className='w-full bg-[#dddddd] mt-4 mr-0 rounded-xl p-4 h-16 flex justify-between items-center cursor-pointer' htmlFor="idfile">
                                    <input
                                        type="file"
                                        onChange={(e) => bookPartyFleetContact(e, e.target.files[0])}
                                        style={{border:"1px solid", background:"black"}}
                                        id='idfile'
                                        accept='image/*, .pdf, .doc, .docx'
                                    />

                                        <p style={{color:"rgb(96, 96, 96)"}}>Upload Image</p>
                                        {loading ? (
                                            <div className='loader'>
                                            {/* Display a loading spinner or message here */}
                                            <span className="animate-dots mr-4">...</span>
                                            </div>
                                        ) : isPhoneSetUrl ? (
                                            <p className='w-[2rem] h-[2rem] rounded-full bg-[#a8a8a8]'>
                                            <img src={isPhoneSetUrl} alt="" className='rounded-full object-cover w-full h-full' />
                                            </p>
                                        ) : null}
                                    </label>
                                    <button
                                        className='flex justify-center bottom-0 right-0 items-center absolute w-[2.5rem] h-[2.5rem] rounded-full bg-[#f9dd07]'

                                    >
                                        <AiOutlinePlus className='font-bold text-[1.6rem]'/>
                                    </button>
                                </form>
                            </div>
                            <div className='scrollbar-hide relative flex overflow-scroll flex-col items-center w-[50%] p-4 h-[450px]'>
                            {fleetContactData && fleetContactData.length ? (
                                fleetContactData.map((item, index) => (
                                    <div
                                        key={index}
                                        className={`cursor-pointer w-full h-fit flex p-2 rounded-xl mb-4 flex-row items-center ${
                                        contact === item ? 'bg-[#dddddd]' : '' // Apply background color when selected
                                        }`}
                                        onClick={() => {
                                            console.log("Setting contact:", item);
                                            setContact(item);
                                            setTransporterContact(item);
                                        }}
                                    >
                                        <div className='w-[3rem] h-[3rem] rounded-full mr-5 bg-[#dddddd]'><img src={item.imageUrl} className='object-cover w-full h-full rounded-full' alt="" /></div>
                                        <div>
                                            <p className='font-bold m-0 p-0'>{item.name}</p>
                                            <p className='m-0 p-0'>{item.email}</p>
                                            <p className='m-0 p-0'>{item.phone}</p>
                                        </div>
                                    </div>
                            ))
                            ) : <div className='dash-date' style={{
                                width:"100%",
                                height:"100%",
                                display:"flex",
                                justifyContent:"center",
                                alignItems:"center",
                                flexDirection:"column",
                                top:"30%",
                                textAlign:"center",
                                fontSize:"1rem"
                              }}>
                                <img src={emptyIcon} width={100} height={100} style={{marginBottom:"3rem"}}/>
                                There are no contacts at the moment please add contacts.
                              </div>
                            }

                            </div>
                        </div>
                        <div className='w-full flex justify-end mt-7'>
                            <div className='w-[40%]'>
                                <p>You have selected <span className='font-bold text-[.9rem]'>{contact.name}</span> as your transporter, they will get a link to the app and register if they do not have an account otherwise, login and assign drivers to deliver your booking.</p>
                                <div className='w-full flex justify-end'>
                                    <button
                                        className='px-4 py-2 mt-3 bg-[#f9dd07] text-black rounded-xl'
                                        onClick={() => {
                                            if (Array.isArray(contact) && contact.length === 0) {
                                                // It's an empty array
                                                setSelectContact(true);
                                            } else {
                                                // It's not an empty array (might be an object or a non-empty array)
                                                saveTheBooking()
                                            }
                                        }}
                                    >
                                        Make the booking
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {selectContact &&
                    <div className='absolute flex justify-center items-center left-0 right-0 top-0 bottom-0 w-full h-full'>
                        <div className='w-fit h-fit flex-col flex p-4 justify-center items-center rounded-xl bg-[#fff]'>
                            <p className='text-center'>Please select contact before you proceed</p>
                            <button
                                className='bg-[#f9dd07] px-[.3rem] mt-3 text-[.9rem] rounded-xl'
                                onClick={() => setSelectContact(false)}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                }
            </motion.div>
        }
        {isLoading  &&
            <EnterpriseLoader/>
        }
            {/* {popUpQuote &&
            <div className='w-full beforeEffect flex-col h-full absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center' style={{zIndex:"1"}}>
                <div className='w-[600px] flex justify-end font-bold text-[1.6rem] text-[white] cursor-pointer' onClick={() => setPopUpQuote(false)}>X</div>
                <div className='w-[600px] relative flex-col h-[230px] bg-[#E8E8E8] flex items-center rounded-xl'>
                    <div className='flex items-end'>
                        <h1 className='mt-6 font-bold text-[1.1rem]'>Do you have a rate indication for this booking?</h1>
                        <p className='h-[12px] w-[12px] rounded-full border border-[1] border-black cursor-pointer flex justify-center items-center mb-4 ml-2' onClick={() => setInfoShow(!infoShow)}>i</p>
                    </div>
                    {infoShow &&
                        <div className='absolute right-2 top-3 bottom-0 p-2 bg-white w-[108px] cursor-pointer rounded-xl h-[110px]' onClick={() => setInfoShow(false)}>
                            <p className='text-[.6rem] text-center' style={{lineHeight:"10px"}}>Enter a number you would like a transport to match</p>
                            <p  className='text-[.6rem] text-center' style={{lineHeight:"10px"}}>Please note that your requested rate may not always be matched and transporters may counter- offer or reject your request rate.</p>
                        </div>
                    }
                    <div className='mt-5 w-[250px] flex justify-between rounded-xl h-[30px] bg-white'>
                        <input
                            type="number"
                            className='rounded-xl w-[50%] text-[.8rem] px-3'
                            placeholder='e.g. 17000'
                            onChange={(e) => {
                                setRateIndication(e.target.value)
                                setAskingPrice(true)
                            }}
                        />
                        <div className='cursor-pointer h-full justify-center items-center rounded-xl flex bg-[#f9dd07] w-[50%]'>
                            <p
                                className='m-0 p-0 font-bold text-[.9rem]'
                                onClick={() =>{
                                    schedule()
                                }}
                            >
                                    Submit
                            </p>
                        </div>
                    </div>
                    <p className='mt-4 text-[1rem] font-bold'>Or</p>
                    <div className='mt-4 w-[250px] h-[30px] rounded-xl flex justify-center items-center cursor-pointer bg-[#f9dd07]'>Submit RFQ</div>
                </div>
            </div>
            } */}

    </div>
  )
}
