import React, {useState, useRef} from 'react'
import { useStateContext } from '../context/DashboardStateContext'
import { FiUpload } from "react-icons/fi";
import { FaPlus } from "react-icons/fa6";
import firebase from '../firebase-config';
import { parse } from 'date-fns';

const FleetOwnerUploadInvoice = () => {

    const [receiptUploaded, setReceiptUploaded] = useState(false);
    const [invoiceUrl,setInvoiceUrl] = useState("");
    const fileInputRef = useRef(null);
    const [allTheFiles, setAllTheFiles] = useState([]);
    const [showAllFiles, setShowAllFiles] = useState(false);

    //Making horizontal scroll
    const containerRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);

    const onDragStart = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - containerRef.current.offsetLeft);
        e.target.style.cursor = 'grabbing';
    };

    const onDragEnd = () => {
        setIsDragging(false);
        containerRef.current.style.cursor = 'grab';
    };

    const onDragMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - containerRef.current.offsetLeft;
        const walk = (x - startX) * 2; // The * 2 is the scroll speed
        containerRef.current.scrollLeft -= walk;
    };

    const {
        userUid,
        selectedBookInvoice,
        setIsLoading,
        setIsFleetOwnerUpload
    } = useStateContext();

    const handleSubmit = async () => {
        setIsLoading(true)
        const fileUrls = allTheFiles.map(file => file.url);
        await firebase
            .database()
            .ref("booking")
            .child(selectedBookInvoice.booking_id)
            .update({
                invoice: fileUrls
             });
        setTimeout(() => {
            setIsLoading(false);
            setIsFleetOwnerUpload(false)
        },2000)
        fileInputRef.current.value = null;
    }

    const handleInvoiceUpload = async (file) => {
        if (!file) return;

        const generateRandomString = () => {
            // This will create a random string of 16 characters
            return Math.random().toString(36).substring(2, 18);
        };

        const randomFileName = generateRandomString()

        try {
            const storage = firebase.storage();
            const fileRefId = storage.ref(`files/${userUid}/invoice/${selectedBookInvoice.booking_id}/${randomFileName}`);
            const snapshot = await fileRefId.put(file);
            const url = await snapshot.ref.getDownloadURL();

            setAllTheFiles((prev) => [
                ...prev,
                {
                  name: file.name,
                  url: url
                }
            ]);

        } catch (error) {
          console.error("Error uploading invoice:", error);
        }
    };

    console.log("The invoice", selectedBookInvoice)

    // const handleReceiptUpload = async (file) => {
    //     setUploadedinvoice(true);
    //     if (!file) return;
    //     try {
    //       const storage = firebase.storage();
    //       const fileRefId = storage.ref(`files/${userUid}/invoice/${bookingUid}`);
    //       const snapshot = await fileRefId.put(file);
    //       const url = await snapshot.ref.getDownloadURL();

    //       if (url) {
    //         await firebase
    //           .database()
    //           .ref("booking")
    //           .child(bookingUid)
    //           .update({ receipt_url: url });

    //         setInvoiceUrl(url);
    //         fileInputRefSeven.current.value = null;
    //         setTimeout(() => {
    //           setUploadedinvoice(false)
    //         }, 2000)
    //       }
    //     } catch (error) {
    //       console.error("Error uploading invoice:", error);
    //     }
    //   };

    return (
        <div className='w-full beforeEffect flex-row h-full absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center' style={{zIndex:"1"}}>
            <div className='w-[22.5%] h-full cursor-pointer' onClick={() => setIsFleetOwnerUpload(false)}></div>
            <div className='h-full flex justify-center items-center flex-col w-[55%]'>

                <div className='h-[25%] w-full cursor-pointer' onClick={() => setIsFleetOwnerUpload(false)}></div>

                <div className='w-[790px] relative h-[50%] bg-[#F2F2F2] p-8 flex flex-row items-center justify-between rounded-xl'>
                    <div className='w-[62%] h-[100%]'>
                        <p className='text-[1rem] mb-3'><b>Booking Reference: </b>{selectedBookInvoice.booking_ref}</p>
                        <p>Upload the invoice for the selected booking</p>
                        <div className='w-[100%] p-2 relative mt-5 h-fit bg-[white] rounded-2xl'>
                            <div className='absolute left-0 h-full w-[15px] right-0 bottom-0 top-0 bg-[#D2D2D2] rounded-l-2xl'>
                            </div>
                            <div className='pl-4'>
                                <p className='font-bold'>{selectedBookInvoice.booking_ref}</p>
                                <p className='text-[.7rem]'>3 loads booked</p>
                                <p className='text-[.7rem]'>Confirmed Net Rate: R{selectedBookInvoice?.AmountAccepted}</p>
                                <p className='text-[.7rem]'>Value Added Tax: R{((selectedBookInvoice?.AmountAccepted) * (15/100)).toFixed(2)}</p>
                                <p className='text-[.7rem]'>Total: <b>R{(parseFloat(selectedBookInvoice?.AmountAccepted)) + parseFloat(((selectedBookInvoice?.AmountAccepted) * (15/100)))}</b></p>
                            </div>
                        </div>
                    </div>
                    <div className='w-[32%] flex flex-col h-[100%]'>
                        <div className='w-full h-[50%] flex justify-center items-center'>
                            <input
                                type="file"
                                style={{ position: 'absolute', top: 0, left: 0, opacity: 0, zIndex: -1 }}
                                onChange={(e) => handleInvoiceUpload(e.target.files[0])}
                                ref={fileInputRef}
                            />
                            <div
                                className='bg-[#fff] flex flex-col cursor-pointer justify-center items-center w-full h-[40%] rounded-2xl'
                                onClick={() => {
                                    fileInputRef.current.click()
                                }}
                            >
                                <p className='text-[#878787] text-[1.1rem]'><FiUpload /></p>
                                <p>Upload Invoice</p>
                            </div>
                        </div>
                        <div className='w-full h-[50%] flex justify-end'>
                            <div className='w-[90%]'>
                                <p>Added document/s</p>
                                <div
                                    ref={containerRef}
                                    className='flex flex-row overflow-x-auto cursor-grab scrollbar-hide'
                                    onMouseDown={onDragStart}
                                    onMouseUp={onDragEnd}
                                    onMouseLeave={onDragEnd}
                                    onMouseMove={onDragMove}
                                >
                                    {setShowAllFiles && allTheFiles && allTheFiles.map((item, i) => (
                                        <div key={i} className='mt-[.4rem] px-2 flex items-center w-[40%] mr-2 h-[25px] rounded-2xl bg-white'>
                                            <p className='m-0 p-0 select-none'>X</p>
                                            <p className='p-0 ml-1 select-none whitespace-nowrap overflow-hidden text-ellipsis'>{item?.name}</p>
                                        </div>
                                        ))
                                    }
                                </div>
                                <div className='mt-4 flex justify-end items-center'>
                                    <div
                                        className='w-[35%] py-[.35rem] rounded-xl cursor-pointer border-black border-[1px] flex justify-center items-center bg-white'
                                        onClick={() => setShowAllFiles(true)}
                                    >
                                        <p className='m-0 p-0 font-bold text-[1rem]'>Add</p>
                                        <p className='m-0 p-0 ml-2 text-[1rem]'><FaPlus /></p>
                                    </div>
                                </div>
                                <div className='mt-6 flex justify-end items-center'>
                                    <div className='w-[40%] py-[.7rem] cursor-pointer rounded-2xl flex justify-center items-center bg-[#FFE200]'>
                                        <p
                                            className='m-0 p-0 font-bold text-[1rem]'
                                            onClick={() => handleSubmit()}
                                        >
                                            Submit
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='h-[25%] w-full cursor-pointer' onClick={() => setIsFleetOwnerUpload(false)}></div>

            </div>
            <div className='w-[22.5%] h-full cursor-pointer' onClick={() => setIsFleetOwnerUpload(false)}></div>
        </div>
    )
}

export default FleetOwnerUploadInvoice
