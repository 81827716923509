import React, {useState} from 'react'
import {RxCross2} from 'react-icons/rx'
import {useFleetStateContext} from '../context/FleetDealsContext';
import {AiOutlineSearch} from 'react-icons/ai'
import SetLoadsToAssignDriver from './SetLoadsToAssignDriver';

export default function FirstDealRespondAssignDriver() {
    const [isDriverAssign, setIsDriverAssign] = useState(false)
    const {
        setIsFirstDealAssign,
        addDriversData,
        selectedBookingDeal,
        values,
        DriverToAssign,
        setDriverToAssign
    } = useFleetStateContext();

    console.log("the driver assigned", DriverToAssign)

  return (
    <div className='absolute w-full flex flex-col p-4 top-0 left-0 right-0 bottom-0 scrollbar-hide' style={{background:"#fff", zIndex:"2", height:"650px", maxHeight:"650px", overflow:"scroll"}}>
        <div className='flex items-center justify-between mt-3'>
            <div className=' flex flex-row items-center'>
                <p className='text-2xl mr-2 font-bold cursor-pointer'
                onClick={() =>{
                    setIsFirstDealAssign(false)
                }}><RxCross2/></p>
                <p className='font-bold' style={{fontSize:"1rem"}}>Assign Drivers</p>
            </div>
            <div>
                <p className='text-2xl font-bold cursor-pointer'><AiOutlineSearch/></p>
            </div>
        </div>
        <div className='flex justify-between mt-4 items-center'>
            <div className='flex flex-col justify-center items-center'>
                <p className='p-0 m-0' style={{color: "grey", fontSize:".7rem"}}>Round trip</p>
                <p className='font-bold' style={{fontSize:".9rem"}}>{(selectedBookingDeal[0]?.total_duration)*2} hours</p>
            </div>
            <div className='flex flex-col justify-center items-center'>
                <p className='p-0 m-0' style={{color: "grey", fontSize:".7rem"}}>Duration</p>
                <p className='font-bold' style={{fontSize:".9rem"}}>{(values.length)} days</p>
            </div>
            <div className='flex flex-col justify-center items-center'>
                <p className='p-0 m-0' style={{color: "grey", fontSize:".7rem"}}>Single trip</p>
                <p className='font-bold' style={{fontSize:".9rem"}}>{selectedBookingDeal[0]?.total_duration} hours</p>
            </div>
        </div>
        <div className='mt-5'>
            {addDriversData ? addDriversData.map((driver, i) => (
                <div key={i} className='flex items-center mt-6 cursor-pointer'
                    onClick={() => {
                        setIsDriverAssign(true)
                        setDriverToAssign(driver)
                    }}
                >
                    <p className='w-9 h-9 m-0 p-0 rounded-full' style={{background:"#dadada"}}><img className='w-full h-full rounded-full' style={{objectFit:"cover"}} src={driver.photo_uri} alt="" /></p>
                    <div className='ml-2'>
                        <p className='m-0 p-0 font-bold text-sm'>{driver.name}</p>
                        <p className='m-0 p-0 text-gray-500'>{driver.phone}</p>
                    </div>
                </div>
                )): <div className='p-4'>There are no drivers available please add drivers</div>
            }

        </div>
        {isDriverAssign &&
            <SetLoadsToAssignDriver  setIsDriverAssign={setIsDriverAssign}/>
        }
        {/* <div className='w-full'>
            <button
                className='p-3 py-1 rounded-xl' style={{background:"#fce200", width:"100%"}}
                onClick={() => {

                }}
            >
                Continue
            </button>
        </div> */}
    </div>
  )
}
