import React, {useEffect, useState} from 'react'
import { TbChevronLeft } from 'react-icons/tb'
import {AiOutlineArrowRight} from 'react-icons/ai'
import { lineHeight } from '@mui/system'
import DealRequests from './DealRequests'
import DealSettlements from './DealSettlements'
import DealBooking from './DealBooking'
import { BiFilter } from 'react-icons/bi'
import {useFleetStateContext} from '../context/FleetDealsContext'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { FiAlertOctagon } from 'react-icons/fi'
import { TfiDropbox } from 'react-icons/tfi'
import { AiOutlinePlus } from 'react-icons/ai'
import AddDriverFormMod from './AddDriverFormMod'
import { useStateContext } from '../context/DashboardStateContext'
import DriverDetailsPage from './DriverDetailsPage'
import emptyIcon from '../icons/box.png'
import firebase from '../firebase-config';
import { ImUser } from 'react-icons/im'

export default function AddDrivers() {
    const [peddingDriverLogin,setPeddingDriverLogin] = useState(false);
    const {
        addDriversData,
        addDriverForm,
        setAddDriverForm,
        selectDriver,
        setSelectDriver,
        pendingDrivers,
        setPendingDrivers,
        setAddDriversData,
        userUid,
        userData
      } = useFleetStateContext();

      const {
        driver,
        setDriver,
        setNewDeals,
        setShowFleet,
        setShowRoutes,
        setShowAddVehicle,
        setShowAddTrailer,
        showAssign,
        setShowAssign
    } = useStateContext();

    useEffect(() => {
      // if (userData) {
        const fleetUid = userData.uid
        firebase.database().ref('/pending_drivers/').orderByChild('fleet').equalTo(fleetUid).on('value', (snapshot) => {
            if(snapshot.exists()){
                setPendingDrivers(Object?.values(snapshot.val()));
            }
        });

        firebase.database().ref('/drivers/').orderByChild('fleet').equalTo(fleetUid).on('value', (snapshot) => {
          if(snapshot.exists()){
              setAddDriversData(Object?.values(snapshot.val()));
          }
      });
    // }
    },[])

    console.log("the selected driver", selectDriver)

  return (
    <div className='new-deals-page flex justify-between relative' style={{height:"80vh", maxHeight:"80vh"}}>
        <div
          className='h-fit drivers-mobile rounded-lg p-4 scrollbar-hide relative'
          style={{
              width:"30%",
              background:"#dadada",
              maxheight:"574px",
              height:"100%",
              overflow:"scroll"
          }}
        >
          <div className='flex items-center'>
            <p className='text-2xl font-bold cursor-pointer' onClick={() => {
              setDriver(false)
              setNewDeals(false)
              setShowFleet(true)
              setShowRoutes(false)
              setShowAddVehicle(false)
              setShowAddTrailer(false)
              setShowAssign(false)
            }}><TbChevronLeft/></p>
            <p className='font-bold' style={{fontSize:"1rem"}}>Drivers</p>
          </div>
          <div className='flex mt-3 w-full justify-between'>
            <input type="text" className='h-8 px-3 rounded-lg border-transparent'
                placeholder='Search'
                style={{width:"85%", background:"rgb(207 207 207)", color:"#000"}}
            />
            <p className='ml-2 text-2xl flex justify-center items-center' style={{width:"15%"}}><BiFilter/></p>
          </div>

          <div className="scrollbar-hide" style={{height:"574px", maxHeight:"574px", overflow:"scroll"}}>
            {addDriversData.length > 0 && addDriversData.map((driver) => (
              <>
              <div
                className='mt-3 mb-2 h-fit cursor-pointer w-full bg-white rounded-lg px-3 py-1'
                onClick={() => setSelectDriver([driver])}
              >
                  <div className='flex justify-between'>
                      <div className='flex items-center'>
                          <p className='w-9 h-9 m-0 p-0 rounded-full' style={{background:"#dadada"}}>
                            {driver.photo_uri ?
                                <img className='w-full h-full rounded-full' style={{objectFit:"cover"}} src={driver.photo_uri} alt="" />
                                :<p className='h-full w-full flex items-center justify-center m-0 p-0' style={{fontSize:"1.5rem", color:"#fff"}}><ImUser/></p>
                            }
                          </p>
                          <div className='ml-2'>
                              <p className='m-0 p-0 font-bold text-sm'>{driver.name}</p>
                              <p className='m-0 p-0 text-gray-500'>{driver.phone}</p>
                          </div>
                      </div>
                      <div className='flex items-center'>
                          <p><AiOutlineClockCircle/></p>
                      </div>
                  </div>
              </div>
              </>
            ))
            }
            {pendingDrivers.length > 0 && pendingDrivers.map((driver) => (
              <>
                <div
                  className='mt-3 mb-2 h-fit cursor-pointer w-full bg-white rounded-lg px-3 py-1'
                  onClick={() => setPeddingDriverLogin(true)}
                >
                    <div className='flex justify-between'>
                        <div className='flex items-center'>
                            <p className='w-9 h-9 m-0 p-0 rounded-full' style={{background:"#dadada"}}>
                            {driver.photo_uri ?
                                <img className='w-full h-full rounded-full' style={{objectFit:"cover"}} src={driver.photo_uri} alt="" />
                                :<p className='h-full w-full flex items-center justify-center m-0 p-0' style={{fontSize:"1.5rem", color:"#fff"}}><ImUser/></p>
                            }
                            </p>
                            <div className='ml-2'>
                                <p className='m-0 p-0 font-bold text-sm'>{driver.name}</p>
                                <p className='m-0 p-0 text-gray-500'>{driver.phone}</p>
                            </div>
                        </div>
                        <div className='flex items-center'>
                            <p><AiOutlineClockCircle/></p>
                            <p className='ml-2 text-red-600' title='pending driver'><FiAlertOctagon/></p>
                        </div>
                    </div>
                </div>
              </>
              ))
            }
            {addDriversData.length === 0  && pendingDrivers.length === 0 &&
              <div className='mt-4 flex justify-center items-center flex-col'>
                  <p style={{fontSize:"4rem", color:"#a7a7a7"}}><TfiDropbox/></p>
                  <p className='text-lg' style={{color:"grey"}}>Add drivers</p>
              </div>
            }
          </div>

        <p
            className='rounded-full cursor-pointer absolute flex justify-center items-center'
            onClick={() => {
              setAddDriverForm(true)
            }}
            style={{
                fontSize:"2rem",
                background:"#fce200",
                width:"48px",
                height:"48px",
                bottom:".6rem",
                right:".6rem"
            }}><AiOutlinePlus/></p>

        </div>
        {selectDriver.length ?
          <DriverDetailsPage/>
        :
          <div className='dash-date' style={{
            width:"68%",
            height:"100%",
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            flexDirection:"column",
            top:"30%",
            textAlign:"center",
            fontSize:"1rem"
          }}>
            <img src={emptyIcon} width={100} height={100} style={{marginBottom:"3rem"}}/>
            Please select driver on the left to see they're details.
          </div>
        }

        {addDriverForm &&
          <AddDriverFormMod/>
        }

        {peddingDriverLogin &&
          <div className='absolute h-full w-full flex items-center justify-center'>
            <div className='p-5 rounded-xl flex items-center justify-center flex-col' style={{width:"244px", height:"fit-content", background:"#fff"}}>
                <p style={{fontSize:"1rem", textAlign:"center", marginBottom:"1rem"}}>The Driver has to login first before you can see their information.</p>
                <button className='rounded-xl px-2 py-1' style={{background:"#fce200", fontSize:".8rem"}} onClick={() => setPeddingDriverLogin(false)}>Ok</button>
            </div>
          </div>
        }
    </div>
  )
}
