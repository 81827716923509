import React, {useState, useEffect, useRef} from 'react';
import logo from '../icons/logo.png';
import '../css/dashboard.css';
import firebase from '../firebase-config';
import { Link, useNavigate  } from 'react-router-dom'
import loader from '../icons/loader.png';
import time from '../icons/clock.gif';
import check from '../icons/check.gif';
import conversation from '../icons/conversation.png';
import setting from '../icons/settings.png';
import crossDash from '../icons/cross.png';
import Loader from '../components/loader/Loader';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Enterprise from './Enterprise';
import TrackingPage from '../components/TrackingPage';
import Schedule from './Schedule';
import Quote from '../components/Quote';
import BiddingPageTwo from '../screens/BiddingPageTwo';
import EnterpriseNav from '../components/EnterpriseNav'
import { useStateContext } from '../context/DashboardStateContext'
import Myfleet from '../components/Myfleet';
import Myreferrals from '../components/Myreferrals';
import GetHelp from '../components/GetHelp';
import { useStateContextBookings } from '../context/AllBookingsContext'
import emptyIcon from '../icons/box.png'
import MonthlyComponent from '../components/MonthlyComponent';
import YearlyFilter from '../components/YearlyFilter';
import BiddingFinalPage from '../components/BiddingFinalPage';
import { useMediaQuery } from 'react-responsive'
import Profile from '../components/Profile';
import BookingSuccess from '../components/BookingSuccess';
import QuoteRatePage from '../components/QuoteRatePage';
import LoaderEnterprise from '../components/LoaderEnterprise';
import { HiLocationMarker } from "react-icons/hi";
import { AiFillSchedule } from "react-icons/ai";
import { IoMdHome } from "react-icons/io";
import { FaCodePullRequest } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa6";
import { FaAngleUp } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import {FaListUl, FaChartPie} from 'react-icons/fa'
import {ImMap} from 'react-icons/im'
import { GrBeacon } from 'react-icons/gr'
import { ImUser } from 'react-icons/im'
import { RiSteering2Fill } from 'react-icons/ri'
import { FaTrailer } from 'react-icons/fa'
import { MdAssignmentInd } from 'react-icons/md'
import dashIcons from '../icons/dashboard-icon.png'
import newBookIcons from '../icons/Vector.png'
import bookIcons from '../icons/booking.png'
import bookTrackingIcons from '../icons/tracking-booking.png'
import archiveIcons from '../icons/archive.png'
import contactIcon from '../icons/contact.png'
import chart from '../icons/chart-pie.png'

// import {FaListUl} from 'react-icons/fa'

import TrackingFilter from '../components/TrackingFilter';

import ShipperUploadPOP from '../components/ShipperUploadPOP';
import FleetOwnerUploadInvoice from '../components/FleetOwnerUploadInvoice';
import BookingSummary from '../components/BookingSummary';

export default function Dashboard() {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [isCross, setIsCross] = useState(false)
    const [isChevUp, setIsChevUp] = useState(false)
    const [isBookChevUp, setBookIsChevUp] = useState(false)
    const [isSpinner, setIsSpinner] = useState(true)
    const [userEmail,setUserEmail] = useState("")
    const [userUid, setUserUid] = useState("");
    const navigate = useNavigate();

    const [dashTitle, setDashTitle] = useState("");
    const [showNavigation, setShowNavigation] = useState(false);
    const [showNavigationX, setShowNavigationX] = useState(false);
    const [monthFilter, setMonthFilter] = useState(true);
    const [yearFilter, setYearFilter] = useState(false);
    const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' })
    const [offersCount, setOffersCount] = useState([]);
    const [completedBookings, setCompletedBookings] = useState([])
    const [infoShow,setInfoShow] = useState(false);
    // const [rateIndication,setRateIndication] = useState(false)
    const [askingPrice,setAskingPrice] = useState(0);
    const [finalPick,setFinalPick] = useState([])
    const [pickUpDetails,setPickUpDetails] = useState([])
    const [contact, setContact] = useState([])
    const [cargoDetails , setCargoDetails] = useState([])
    const [finalDrop, setFinalDrop] = useState([])
    const [dropDetails , setDropDetails] = useState([])
    const [prerequisites , setPrerequisites] = useState([])
    const [prerequisitesArray, setPrerequisitesArray] = useState([]);
    const [bookingPartyInfo, setBookingPartyInfo] = useState([]);

    const {
        isEnterprise,
        setIsEnterprise,
        isTracking,
        setIsTracking,
        isShowSchedule,
        isQuote,
        setIsShowSchedule,
        setIsShowRequest,
        isShowRequest,
        cargoLink,
        setCargoLink,
        fleetLink,
        setFleetLink,
        referalLink,
        setReferalLink,
        profileLink,
        setProfileLink,
        analysisLink,
        setAnalysisLink,
        walletLink,
        setWalletLink,
        getHelpLink,
        setGetHelpLink,
        contactLink,
        setContactLink,
        newDeals,
        setNewDeals,
        showFleet,
        setShowFleet,
        driver,
        setDriver,
        showNoFleet,
        setShowNoFleet,
        showAddVehicle,
        setShowAddVehicle,
        name,
        setName,
        isEmailVerified,
        setIsEmailVerified,
        setShowAddTrailer,
        setShowAssign,
        setIsQuote,
        setPopUpQuote,
        popUpQuote,
        endDate,
        setEndDate,
        startDate,
        setStartDate,
        operationDaysNew,
        setOperationDaysNew,
        includeHolidays,
        setIncludeHolidays,
        dropOffBays,
        collectionBays,
        gateInOutDuration,
        setGateInOutDuration,
        bookingSuccess,
        setBookingSuccess,
        isLoading,
        setIsLoading,
        rateIndication,
        setRateIndication,
        trackingLink,
        setTrackingLink,
        scheduleLink,
        setScheduleLink,
        requestLink,
        setRequestLink,
        homeLink,
        setHomeLink,
        isShipperUpload,
        isFleetOwnerUpload,
        setIsFleetOwnerUpload,
        isBookingSummary,
        showTrackFilter,
        setShowTrackFilter,
        fleetTracking,
        setFleetTracking,
        setIsCargoMenu,
        setIsDashboard,
        isDashboard,
        isCargoMenu,
    } = useStateContext();

    const {
        allOfTheBooking,
        setAllOfTheBooking,
        getJanuaryMonthlyBookings,
        getFebruaryMonthlyBookings,
        getMarchMonthlyBookings,
        getAprilMonthlyBookings,
        getMayMonthlyBookings,
        getJuneMonthlyBookings,
        getJulyMonthlyBookings,
        getAugustMonthlyBookings,
        getSeptemberMonthlyBookings,
        getOctoberMonthlyBookings,
        getNovemberMonthlyBookings,
        getDecemberMonthlyBookings,
    } = useStateContextBookings();

    useEffect(() => {
        setShowNavigation(false);
        setShowNavigationX(false);
    }, [])

    const [company, setCompany] = useState("")

    console.log("Show summary", isBookingSummary)

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            console.log("this is the user", user)
            if (!user || !user.emailVerified) {
                firebase.auth().signOut().then(() => {
                    navigate('/login')
                    }).catch((error) => {
                    alert(error)
                });
            }
            else {
                setUserEmail(user.email);
                setUserUid(user.uid);
                var uid = user.uid;
                localStorage.setItem("userUid", JSON.stringify(uid));
                firebase.database().ref('/booking_party/' + uid).once('value', (snapshot) => {
                    const userInfo = snapshot.val();
                    const company = snapshot.val()?.firstName;
                    setName(snapshot.val()?.firstName);
                    localStorage.setItem("userInformation", JSON.stringify(userInfo));
                    setCompany(company)
                });
            }
        });
    }, [])

    useEffect(() => {
        if(localStorage.getItem("Prerequisites")){
            const storedList = JSON.parse(localStorage.getItem("Prerequisites"))
            setPrerequisites(storedList);
        }
    }, [])

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                setUserEmail(user.email);
                setUserUid(user.uid);
              var uid = user.uid;
                firebase.database().ref('/booking/').orderByChild('booking_party_uid').equalTo(uid).on('value', (snapshot) => {
                    if(snapshot.exists()){
                        setAllOfTheBooking(Object?.values(snapshot.val()));
                    }else{
                        setAllOfTheBooking([])
                    }
                });
                firebase.database().ref('/booking_party/' + uid).on('value', (snapshot) => {
                    if(snapshot.exists()){
                         setBookingPartyInfo(snapshot.val());
                        if(snapshot.val().serviceOptions.includes("Fleet Owner")){
                            setShowFleet(true)
                            setShowNoFleet(false)
                        }else{
                            setShowFleet(false)
                            setShowNoFleet(true)
                        }
                    }
                });
              // ...
            } else {
                navigate('/login');
            }
        });

    }, [userUid])

    useEffect(() => {
      const messaging = firebase.messaging();
      messaging.requestPermission().then((token) => {
        return messaging.getToken()
      }).then((token) => {
        console.log('Token: ', token);
      }).catch((err) => {
        console.log(err);
      });
    }, []);

    console.log("this is the booking test", allOfTheBooking)

    useEffect(() => {
        const timeoutId = setTimeout(() => {
          setIsSpinner(false);
        }, 3000);

        setMonthFilter(true);

        return () => {
          clearTimeout(timeoutId);
        };
    }, []);

    useEffect(() => {
        setFleetLink(false)
        setNewDeals(false)
        setPopUpQuote(false)
        console.log("the monthly state", monthFilter)
    }, [])

    useEffect(() => {
        var count = 0
        allOfTheBooking.map((booking) => {
            if(booking.bidsCount){
                return setOffersCount(++count);
            }
        })

    }, [allOfTheBooking])

    // useEffect(() => {
    //     setCargoLink(true)
    //     setFleetLink(false)
    //     setReferalLink(false)
    //     setProfileLink(false)
    //     setAnalysisLink(false)
    //     setWalletLink(false)
    //     setGetHelpLink(false)
    //     setContactLink(false)
    //     setIsEnterprise(false)
    // }, [])

    useEffect(() => {
        firebase.database().ref("/booking_party/" + userUid).on("value", (snapshot) => {
            if(snapshot.exists()){
                // setBookingPartyInfo(snapshot.val());
                const completed = snapshot.val().completed_bookings
                if(completed){
                    const ObjectifiedCompleted = Object.values(completed)
                    setCompletedBookings(ObjectifiedCompleted)
                    console.log("completed bookings", ObjectifiedCompleted)
                }
            }
        })
    },[])

    console.log("Booking Party Info", bookingPartyInfo)

    console.log("the bids count is :", offersCount);
    console.log("cargoLink", cargoLink, "enterprise", isEnterprise, "tracking", isTracking, "schedule", isShowSchedule, "request", isShowRequest, "fleetLink", fleetLink, "referalLink", referalLink, "getHelpLink", getHelpLink )
    console.log("this user uid", userUid)

    return (
    <div className='dashboard relative'>
        {!isSmallScreen &&
        <div className="left-dash myLinks">
            <div>
                <img className="zipiicon" src={logo} />
            </div>
            <div>
                <div>
                    {/* <div className={`transition-all ease-in-out cursor-pointer ${cargoLink && "active"} options`}
                        onClick={() => {
                            setCargoLink(true)
                            setFleetLink(false)
                            setReferalLink(false)
                            setProfileLink(false)
                            setAnalysisLink(false)
                            setWalletLink(false)
                            setGetHelpLink(false)
                            setContactLink(false)
                            setIsEnterprise(false)
                        }}
                    >
                        <i class="fa-sharp fa-solid fa-cube dash-link-icon"></i>My cargo
                    </div> */}
                        <div className={` transition-all cursor-pointer ease-in-out ${cargoLink && "active"} options`}
                            onClick={() => {
                                setCargoLink(true)
                                setIsCargoMenu(true)
                                setIsDashboard(false)
                                setFleetLink(false)
                                setReferalLink(false)
                                setProfileLink(false)
                                setAnalysisLink(false)
                                setWalletLink(false)
                                setGetHelpLink(false)
                                setContactLink(false)
                                setIsEnterprise(false)
                                setIsShowRequest(false)
                                setIsTracking(false)
                                setIsShowSchedule(false)
                                setNewDeals(false)
                                setDriver(false)
                                setShowFleet(false)
                                setShowAddVehicle(false)
                                setShowAddTrailer(false)
                                setShowAssign(false)
                                setBookIsChevUp(false)
                                setHomeLink(false)
                                setTrackingLink(false);
                                setScheduleLink(false);
                                setScheduleLink(false);
                                setRequestLink(false);
                            }}
                        >
                            <i class="fa-sharp fa-solid fa-cube dash-link-icon"></i>My cargo
                        </div>
                        {/* <div className={`${!isBookChevUp ? "account-menu" : "account-menu-open"} transition-all ease-in-out`}>
                            <div className="moreOptions">
                            <div onClick={() => {
                                    setFleetLink(false)
                                    setReferalLink(false)
                                    setAnalysisLink(false)
                                    setWalletLink(false)
                                    setGetHelpLink(false)
                                    setContactLink(false)
                                    setProfileLink(false);
                                    setIsShowSchedule(false);
                                    setIsTracking(false);
                                    setIsEnterprise(false);
                                    setIsShowRequest(false);
                                    setIsQuote(false);
                                    setCargoLink(true);
                                    setHomeLink(true)
                                    setTrackingLink(false);
                                    setScheduleLink(false);
                                    setRequestLink(false)
                                }} className={`options ${homeLink && "active"} flex justify-center items-center`}>
                                        <i className={`fa-solid dash-link-icon text justify-center items-center`}><IoMdHome  className='text-[1rem]'/></i>Home
                                </div>
                                <div onClick={() => {
                                    setFleetLink(false)
                                    setReferalLink(false)
                                    setAnalysisLink(false)
                                    setWalletLink(false)
                                    setGetHelpLink(false)
                                    setContactLink(false)
                                    setIsEnterprise(false);
                                    setCargoLink(false);
                                    setIsShowSchedule(false);
                                    setIsShowRequest(false);
                                    setProfileLink(false);
                                    setIsQuote(false);
                                    setIsTracking(true);
                                    setTrackingLink(true);
                                    setScheduleLink(false);
                                    setRequestLink(false)
                                    setHomeLink(false)
                                }} className={`options ${trackingLink && "active"} flex justify-center items-center`}>
                                        <i className={`fa-solid dash-link-icon text justify-center items-center`}><HiLocationMarker className='text-[1rem]'/></i>Tracking
                                </div>
                                <div onClick={() => {
                                    setFleetLink(false)
                                    setReferalLink(false)
                                    setProfileLink(false)
                                    setAnalysisLink(false)
                                    setWalletLink(false)
                                    setGetHelpLink(false)
                                    setContactLink(false)
                                    setTrackingLink(false)
                                    setIsTracking(false);
                                    setIsEnterprise(false);
                                    setCargoLink(false);
                                    setIsShowRequest(false);
                                    setIsQuote(false);
                                    setIsShowSchedule(true);
                                    setScheduleLink(true);
                                    setRequestLink(false)
                                    setHomeLink(false)
                                }} className={`options ${scheduleLink && "active"} flex justify-center items-center`}>
                                        <i className={`fa-solid dash-link-icon text justify-center items-center`}><AiFillSchedule  className='text-[1rem]'/></i>Schedule
                                </div>
                                <div onClick={() => {
                                    setFleetLink(false)
                                    setReferalLink(false)
                                    setAnalysisLink(false)
                                    setWalletLink(false)
                                    setGetHelpLink(false)
                                    setContactLink(false)
                                    setProfileLink(false);
                                    setTrackingLink(false)
                                    setIsShowRequest(false);
                                    setIsShowSchedule(false);
                                    setScheduleLink(false);
                                    setIsTracking(false);
                                    setIsEnterprise(false);
                                    setCargoLink(false);
                                    setIsQuote(false);
                                    setIsShowRequest(true);
                                    setRequestLink(true)
                                    setHomeLink(false)
                                }} className={`options ${requestLink && "active"} flex justify-center items-center`}>
                                        <i className={`fa-solid dash-link-icon text justify-center items-center`}><FaCodePullRequest  className='text-[1rem]'/></i>Requests
                                </div>
                            </div>
                        </div> */}
                        <div className={` transition-all cursor-pointer ease-in-out ${fleetLink && "active"} options`}
                            onClick={() => {
                                setCargoLink(false)
                                setFleetLink(true)
                                setReferalLink(false)
                                setProfileLink(false)
                                setAnalysisLink(false)
                                setWalletLink(false)
                                setGetHelpLink(false)
                                setContactLink(false)
                                setIsEnterprise(false)
                                setIsShowRequest(false)
                                setIsTracking(false)
                                setIsShowSchedule(false)
                                setNewDeals(false)
                                setDriver(false)
                                setShowFleet(true)
                                setShowAddVehicle(false)
                                setShowAddTrailer(false)
                                setShowAssign(false)
                                setBookIsChevUp(false)
                                setHomeLink(false)
                                setTrackingLink(false);
                                setScheduleLink(false);
                                setScheduleLink(false);
                                setRequestLink(false);
                            }}
                        >
                            <i class="fa-sharp fa-solid fa-bus dash-link-icon"></i>My fleet
                        </div>
                    {/* <div
                        className={`transition-all cursor-pointer ease-in-out ${referalLink && "active"} options`}
                        onClick={() => {
                            setCargoLink(false)
                            setFleetLink(false)
                            setReferalLink(true)
                            setProfileLink(false)
                            setAnalysisLink(false)
                            setWalletLink(false)
                            setGetHelpLink(false)
                            setContactLink(false)
                        }}
                    >AIzaSyAiS-wNj3d2m2LYryOSg4tG4NTei2TA5Os
                        <i class={`fa-solid fa-user dash-link-icon`}></i>My referrals
                    </div> */}
                    <div className="option1">
                        <Link
                        to="/"
                        className="drop-down-dash-anc transition-all ease-in-out options1"
                        onClick={() => setIsChevUp(!isChevUp)}
                        >
                        <i class="fa-solid fa-briefcase dash-link-icon"></i>Account
                        <i className={`fa-solid arrow-chev ${
                            isChevUp ? "fa-chevron-up" : "fa-chevron-down"
                            }`} onClick={() => setIsChevUp(!isChevUp)}
                        ></i>
                        </Link>
                    </div>
                    <div className={`${!isChevUp ? "account-menu" : "account-menu-open"} transition-all ease-in-out`}>
                        <div className="moreOptions">
                            <div onClick={() => {
                                setCargoLink(false)
                                setFleetLink(false)
                                setReferalLink(false)
                                setProfileLink(true)
                                setAnalysisLink(false)
                                setWalletLink(false)
                                setGetHelpLink(false)
                                setContactLink(false)
                                setIsEnterprise(false)
                                setIsShowSchedule(false)
                                setIsTracking(false);
                                setIsShowRequest(false)
                                setBookIsChevUp(false)
                                setHomeLink(false)
                                setTrackingLink(false);
                                setScheduleLink(false);
                                setScheduleLink(false);
                                setRequestLink(false);
                            }} className={`options ${profileLink && "active"}`}>
                                <i className={`fa-solid fa-briefcase dash-link-icon`}></i>Profile
                            </div>
                            {/* <Link to='/wallet' className="options">
                                <i class="fa-sharp fa-solid fa-wallet dash-link-icon"></i>Wallet
                            </Link> */}
                            <Link to='/'  className="options"
                                    onClick={()=> {
                                        firebase.auth().signOut().then(() => {
                                            navigate('/login')
                                        }).catch((error) => {
                                            alert(error)
                                            console.log(error)
                                        });
                                        // alert("hello")
                                    }}
                                ><i className="fa-sharp fa-solid fa-right-from-bracket dash-link-icon"></i>Sign out
                            </Link>
                        </div>
                    </div>
                    <div
                        className={`transition-all cursor-pointer ease-in-out ${contactLink && "active"} options`}
                        onClick={() => {
                            navigate('/contactLink')
                        }}
                    >
                        <i className={`fa-solid fa-address-book dash-link-icon`}></i>Contact
                    </div>
                    <div
                        className={`transition-all cursor-pointer ease-in-out ${getHelpLink && "active"} options`}
                        onClick={() => {
                            navigate('/gethelp')
                        }}
                    >
                        <i className={`fa-solid fa-circle-info dash-link-icon`}></i>Get help
                    </div>

                </div>
                <div className="settings-menu">
                    <img src={setting} alt="settings icon" className="theIcon" />
                    <p>Version 1.0.1</p>
                </div>
            </div>
        </div>
        }
        {isSmallScreen &&
        cargoLink &&
        !isEnterprise &&
        !isTracking &&
        !isShowSchedule &&
        !isShowRequest &&
        !walletLink &&
        !profileLink &&
        !analysisLink &&
        !fleetLink &&
        !referalLink &&
        !contactLink &&
        !getHelpLink &&
        <div className="left-dash myLinks" style={{background:"#fff"}}>
            <div style={{background:"#fff", height:"20px"}}>
                <img className="zipiicon" src={logo} />
                {!isMenuOpen &&
                    <i class="fa-solid fa-bars-staggered" style={{fontSize:"25px"}} onClick={() => setIsMenuOpen(!isMenuOpen)}></i>
                }
                {isMenuOpen &&
                    <img src={crossDash} height={19} width={19} onClick={() => setIsMenuOpen(!isMenuOpen)}></img>
                }
            </div>
            <div style={{background:"#fff", height:`${isMenuOpen ? "100vh" : "0"}`}}>
                <div>
                    <div className={`transition-all ease-in-out cursor-pointer ${cargoLink && "active"} options`}
                        onClick={() => {
                            setCargoLink(true)
                            setFleetLink(false)
                            setReferalLink(false)
                            setProfileLink(false)
                            setAnalysisLink(false)
                            setWalletLink(false)
                            setGetHelpLink(false)
                            setContactLink(false)
                            setIsEnterprise(false)
                            setIsTracking(false)
                            setIsShowSchedule(false)
                            setIsShowRequest(false)
                        }}
                    >
                        <i class="fa-sharp fa-solid fa-cube dash-link-icon"></i>My cargo
                    </div>
                    <div className={` transition-all cursor-pointer ease-in-out ${fleetLink && "active"} options`}
                        onClick={() => {
                            setCargoLink(false)
                            setFleetLink(true)
                            setReferalLink(false)
                            setProfileLink(false)
                            setAnalysisLink(false)
                            setWalletLink(false)
                            setGetHelpLink(false)
                            setContactLink(false)
                            setIsEnterprise(false)
                            setShowAddVehicle(false)
                        }}
                    >
                        <i class="fa-sharp fa-solid fa-bus dash-link-icon"></i>My fleet
                    </div>
                    <div
                        className={`transition-all cursor-pointer ease-in-out ${referalLink && "active"} options`}
                        onClick={() => {
                            setCargoLink(false)
                            setFleetLink(false)
                            setReferalLink(true)
                            setProfileLink(false)
                            setAnalysisLink(false)
                            setWalletLink(false)
                            setGetHelpLink(false)
                            setContactLink(false)
                        }}
                    >
                        <i class={`fa-solid fa-user dash-link-icon`}></i>My referrals
                    </div>
                    <div className="option1">
                        <Link
                        to="/"
                        className="drop-down-dash-anc transition-all ease-in-out options1"
                        onClick={() => setIsChevUp(!isChevUp)}
                        >
                        <i class="fa-solid fa-briefcase dash-link-icon"></i>Account
                        <i className={`fa-solid arrow-chev ${
                            isChevUp ? "fa-chevron-up" : "fa-chevron-down"
                            }`} onClick={() => setIsChevUp(!isChevUp)}
                        ></i>
                        </Link>
                    </div>
                    <div className={`${!isChevUp ? "account-menu" : "account-menu-open"} transition-all ease-in-out`}>
                        <div className="moreOptions">
                            <Link to='/profile' class="options">
                                    <i class={`fa-solid fa-briefcase dash-link-icon ${profileLink && "active"}`}></i>Profile
                            </Link>
                            <Link to='/wallet' class="options">
                                <i class="fa-sharp fa-solid fa-wallet dash-link-icon"></i>Wallet
                            </Link>
                            <Link to='/'  class="options"
                                    onClick={()=> {
                                        firebase.auth().signOut().then(() => {
                                            navigate('/')
                                            }).catch((error) => {
                                            alert(error)
                                            });
                                    }}
                                ><i class="fa-sharp fa-solid fa-right-from-bracket dash-link-icon"></i>Sign out
                            </Link>
                        </div>
                    </div>
                    <div
                        className={`transition-all cursor-pointer ease-in-out ${contactLink && "active"} options`}
                        onClick={() => {
                            navigate('/contactLink')
                        }}
                    >
                        <i class={`fa-solid fa-address-book dash-link-icon`}></i>Contact
                    </div>
                    <div
                        className={`transition-all cursor-pointer ease-in-out ${getHelpLink && "active"} options`}
                        onClick={() => {
                            navigate('/gethelp')
                        }}
                    >
                        <i class={`fa-solid fa-circle-info dash-link-icon`}></i>Get help
                    </div>

                </div>
                <div className="settings-menu">
                    <img src={setting} alt="settings icon" className="theIcon" />
                    <p>Version 1.0.1</p>
                </div>
            </div>
        </div>
        }
        {cargoLink &&
            <>
                {isCargoMenu &&
                    <div style={{height:"100%", overflowY:"auto", width:"81%", position:"relative"}}>
                        <div className='top-fleet-head'>
                            <h1>My cargo</h1>
                            <p>{bookingPartyInfo.companyName ? bookingPartyInfo.companyName.toUpperCase() : ""}</p>
                        </div>
                        {/* {showFleet && !showNoFleet && */}
                            <div className='body-fleet scrollbar-hide sm:w-full sm:h-full md:w-full md:h-full h-[85vh]  overflow-auto'>
                                <div className='deals-container'>
                                <div className='new-deals !justify-start'>
                                    <div
                                        className='w-[250px] h-[100px] bg-[#fff] mx-[10px] rounded-[14px] p-[1.5rem] cursor-pointer'
                                        onClick={() => {
                                            setIsCargoMenu(false);
                                            setIsDashboard(true);
                                        }}
                                    >
                                        <div className='pill-top'>
                                            <div
                                                className='w-[22px] h-[22px]'
                                                style={{fontSize:"1.5rem"}}
                                            >
                                                <img src={dashIcons} width={34} height={34} className='object-cover w-full h-full' />
                                            </div>
                                        </div>
                                        <p className='below-text'>Dashboard</p>
                                    </div>
                                    <div
                                        onClick={() => {
                                            setIsEnterprise(true)
                                            setIsShowRequest(false)
                                            setCargoLink(false)
                                            setIsShowSchedule(false)
                                            setIsTracking(false)
                                            setIsQuote(false)
                                        }}
                                    >
                                        <div className='pill-top'>
                                            <div
                                                className='w-[22px] h-[22px]'
                                                style={{fontSize:"1.5rem"}}
                                            >
                                                <img src={newBookIcons} width={34} height={34} className='object-cover w-full h-full' />
                                            </div>
                                        </div>
                                        <p className='below-text'>New Booking</p>
                                    </div>

                                    <div
                                        onClick={() => {
                                            setFleetLink(false)
                                            setReferalLink(false)
                                            setAnalysisLink(false)
                                            setWalletLink(false)
                                            setGetHelpLink(false)
                                            setContactLink(false)
                                            setProfileLink(false);
                                            setTrackingLink(false)
                                            setIsShowRequest(false);
                                            setIsShowSchedule(false);
                                            setScheduleLink(false);
                                            setIsTracking(false);
                                            setIsEnterprise(false);
                                            setCargoLink(false);
                                            setIsQuote(false);
                                            setIsShowRequest(true);
                                            setRequestLink(true)
                                            setHomeLink(false)
                                        }}
                                    >
                                        <div className='pill-top'>
                                            <div
                                                className='w-[22px] h-[22px]'
                                                style={{fontSize:"1.5rem"}}
                                            >
                                                <img src={bookIcons} width={34} height={34} className='object-cover w-full h-full' />
                                            </div>
                                            <p style={{color:"#0fce0f"}}>15</p>
                                        </div>
                                        <p className='below-text'>Bookings</p>
                                    </div>

                                    <div
                                        onClick={() => {
                                            setFleetLink(false)
                                            setReferalLink(false)
                                            setAnalysisLink(false)
                                            setWalletLink(false)
                                            setGetHelpLink(false)
                                            setContactLink(false)
                                            setIsEnterprise(false);
                                            setCargoLink(false);
                                            setIsShowSchedule(false);
                                            setIsShowRequest(false);
                                            setProfileLink(false);
                                            setIsQuote(false);
                                            setIsTracking(true);
                                            setTrackingLink(true);
                                            setScheduleLink(false);
                                            setRequestLink(false)
                                            setHomeLink(false)
                                        }}
                                    >
                                        <div className='pill-top'>
                                            <div
                                                className='w-[22px] h-[22px]'
                                                style={{fontSize:"1.5rem"}}
                                            >
                                                <img src={bookTrackingIcons} width={34} height={34} className='object-cover w-full h-full' />
                                            </div>
                                            <span className='flex'>
                                                <p className="text-[#0fce0f]">11</p>
                                                <p className="text-[#FF9900] ml-2">2</p>
                                                <p className="text-[#C91818] ml-2">1</p>
                                            </span>
                                        </div>
                                        <p className='below-text'>Tracking</p>
                                    </div>
                                </div>
                                <div className='new-deals !justify-start'>
                                    <div>
                                        <div className='pill-top'>
                                            <div
                                                className='w-[22px] h-[22px]'
                                                style={{fontSize:"1.5rem"}}
                                            >
                                                <img src={chart} width={34} height={34} className='object-cover w-[90%] h-[90%]' />
                                            </div>
                                            <p style={{color:"#0fce0f"}}>1 new insight</p>
                                        </div>
                                        <p className='below-text'>Statistics</p>
                                    </div>
                                    <div>
                                        <div className='pill-top'>
                                            <div
                                                className='w-[22px] h-[22px]'
                                                style={{fontSize:"1.5rem"}}
                                            >
                                                <img src={archiveIcons} width={34} height={34} className='object-cover w-full h-full' />
                                            </div>
                                        </div>
                                        <p className='below-text'>Archives</p>
                                    </div>
                                    <div>
                                        <div className='pill-top'>
                                            <div
                                                className='w-[22px] h-[22px]'
                                                style={{fontSize:"1.5rem"}}
                                            >
                                                <img src={contactIcon} width={34} height={34} className='object-cover w-full h-full' />
                                            </div>
                                        </div>
                                        <p className='below-text'>Contacts</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                        {/* } */}
                    </div>
                }
                {isDashboard &&
                    <div style={{height:"100%", overflowY:"auto", width:"81%", position:"relative"}}>
                        <div className='right-top-section'>
                            <div className='dash-top-links'>
                                <h2 className='dash-title'>My Cargo</h2>
                                <ul className='dash-act-links'>
                                    {/* <li><Link to='/' className='hover:decoration-yellow	 transition-all ease-in-out'>Dashboard</Link></li> */}
                                    <li style={{marginRight:"0px"}}>
                                        <div
                                        onClick={() => {
                                            setIsEnterprise(true)
                                            setIsShowRequest(false)
                                            setCargoLink(false)
                                            setIsShowSchedule(false)
                                            setIsTracking(false)
                                            setIsQuote(false)
                                        }}
                                        className='book-load hover:bg-white transition-all ease-in-out'>
                                        Schedule Booking</div></li>
                                </ul>
                            </div>
                            <p>{bookingPartyInfo.companyName ? bookingPartyInfo.companyName.toUpperCase() : ""}</p>
                            <div
                                className="dash-select-container relative w-fit mt-[5rem]" id='collapse-sel'>
                                <select className='filter-select' name="" id="" onChange={(e) => {
                                    if(e.target.value === "Monthly") {
                                        setMonthFilter(true)
                                        setYearFilter(false)
                                        console.log("month:", monthFilter, "day:", yearFilter);
                                    }
                                    if(e.target.value === "Yearly") {
                                        setMonthFilter(false)
                                        setYearFilter(true)
                                        console.log("month:", monthFilter, "day:", yearFilter);

                                    }
                                }}>
                                    <option value="Monthly">Monthly</option>
                                    <option value="Yearly">Yearly</option>
                                </select>
                                <i className="fa-solid fa-angle-down dash-select-arrow"></i>
                            </div>
                        </div>
                        <div className='stat-card'>
                            <div>
                                <div className='transition ease-in-out hover:scale-105 hover:cursor-pointer' id="tshili">
                                    <div className='stat-ico'>
                                        {/* <Box sx={{ display: 'flex' }}> */}
                                            <CircularProgress variant="determinate" color='success' value={1} />
                                        {/* </Box> */}
                                        {/* <img src={loader} style={{transform:"rotate(279deg)"}} alt="" /> */}
                                        <p style={{color:"#2cdc00"}}>+2.5% (+3)</p>
                                    </div>
                                    <div className='dash-mid-values'>
                                        <p style={{
                                                fontSize:"2rem",
                                                fontWeight:"bold",
                                                marginTop:"8px"
                                        }}>0</p>
                                    </div>
                                    <p style={{
                                            textTransform:"uppercase",
                                            color:"#9a9a9a",
                                            marginTop:"3px",
                                            fontSize:"1rem",
                                            fontWeight:"bold",
                                    }}>success rate</p>
                                </div>
                                <div className='transition ease-in-out hover:scale-105 hover:cursor-pointer'>
                                    <div className='stat-ico'>
                                        <img src={time} alt="" />
                                        <p style={{color:"#2cdc00"}}>+1.8% (2 min)</p>
                                    </div>
                                    <div className='dash-mid-values'>
                                        <p
                                            style={{
                                                fontSize:"2rem",
                                                fontWeight:"bold",
                                                marginTop:"8px"
                                            }}
                                            >
                                                0 hours
                                        </p>
                                    </div>
                                    <p style={{
                                            textTransform:"uppercase",
                                            color:"#9a9a9a",
                                            marginTop:"3px",
                                            fontSize:"1rem",
                                            fontWeight:"bold",
                                    }}>average trip time</p>
                                </div>
                                <div className='transition ease-in-out hover:scale-105 hover:cursor-pointer'>
                                    <div className='stat-ico'>
                                        <img src={check} alt="" />
                                        <p style={{color:"#2cdc00"}}>+1.8% (2 min)</p>
                                    </div>
                                    <div className='dash-mid-values'>
                                        <p style={{
                                                fontSize:"2rem",
                                                fontWeight:"bold",
                                                marginTop:"8px"
                                        }}>{completedBookings ? completedBookings.length : 0}</p>
                                    </div>
                                    <p style={{
                                            textTransform:"uppercase",
                                            color:"#9a9a9a",
                                            marginTop:"3px",
                                            fontSize:"1rem",
                                            fontWeight:"bold",
                                    }}>deliveries</p>
                                </div>
                                <div className='transition ease-in-out hover:scale-105 hover:cursor-pointer'
                                    onClick={() => {
                                        setIsShowSchedule(false);
                                        setIsTracking(false);
                                        setIsEnterprise(false);
                                        setCargoLink(false);
                                        setIsShowRequest(true);
                                        setFleetLink(false);
                                        setNewDeals(false);
                                    }}
                                >
                                    <div className='stat-ico'>
                                        <img src={conversation} alt="" />
                                    </div>
                                    <div className='dash-mid-values'>
                                        <p style={{
                                                fontSize:"2rem",
                                                fontWeight:"bold",
                                                marginTop:"8px"
                                        }}>{allOfTheBooking.length ? allOfTheBooking.length : "0"}</p>
                                    </div>
                                    <p style={{
                                            textTransform:"uppercase",
                                            color:"#9a9a9a",
                                            marginTop:"3px",
                                            fontSize:"1rem",
                                            fontWeight:"bold",
                                    }}>bids and offers</p>
                                    <p className='bids-i-p'><i class="fa-regular fa-clock"></i>{allOfTheBooking.length ? allOfTheBooking.filter((item) => !(item.booking_bids_fleet_id || item.drivers || item.biddingPrices) ).length : "0"} pending</p>
                                </div>
                            </div>
                        </div>
                        {monthFilter &&
                            <MonthlyComponent/>
                        }

                        {yearFilter &&
                            <YearlyFilter/>
                        }

                        {!allOfTheBooking.length > 0 &&
                            <div className='dash-date'
                                style={{
                                    width:"100%",
                                    height:"100%",
                                    display:"flex",
                                    justifyContent:"center",
                                    alignItems:"center",
                                    flexDirection:"column",
                                    top:"30%",
                                    height:"fit-content",
                                    marginTop:"10%",
                                    marginBottom:"9%",
                                    fontSize:"1.3rem",
                                }}
                            >
                                <img src={emptyIcon} width={100} height={100}/>
                                Please make some bookings, it would appear you have none.
                            </div>
                        }

                    </div>
                }
            </>
        }

        {!cargoLink &&
        <div style={{width:"81%"}} className='flex flex-col h-screen'>
        {!fleetLink && !profileLink &&
            <EnterpriseNav
                name={company}
            />
        }

        {isEnterprise &&
            <Enterprise/>
        }
        {isTracking &&
            <TrackingPage/>
        }
        {isShowSchedule &&
            <Schedule/>
        }
        {isQuote &&
            <Quote/>
        }
        {isShowRequest &&
            <BiddingFinalPage/>
        }
        {fleetLink &&
            <Myfleet/>
        }
        {referalLink &&
            <Myreferrals/>
        }
        {getHelpLink &&
            <GetHelp/>
        }
        {profileLink &&
            <Profile/>
        }
        </div>
        }
        {isSpinner && <Loader/>}
        {popUpQuote &&
            <QuoteRatePage
                infoShow={infoShow}
                setInfoShow={setInfoShow}
                setPopUpQuote={setPopUpQuote}
            />
        }
        {bookingSuccess &&
            <BookingSuccess
                setCargoLink={setCargoLink}
                setIsEnterprise={setIsEnterprise}
                setIsTracking={setIsTracking}
                setIsShowSchedule={setIsShowSchedule}
                setIsShowRequest={setIsShowRequest}
                setFleetLink={setFleetLink}
                setReferalLink={setReferalLink}
                setProfileLink={setProfileLink}
                setAnalysisLink={setAnalysisLink}
                setWalletLink={setWalletLink}
                setGetHelpLink={setGetHelpLink}
                setContactLink={setContactLink}
                setIsQuote={setIsQuote}
                setPopUpQuote={setPopUpQuote}
                setBookingSuccess={setBookingSuccess}
            />
        }

        {isShipperUpload &&
            <ShipperUploadPOP/>
        }

        {isFleetOwnerUpload &&
            <FleetOwnerUploadInvoice/>
        }

        {showTrackFilter &&
            <TrackingFilter/>
        }

        {isBookingSummary &&
            <BookingSummary/>
        }

        {isLoading &&
           <div className='loader-enterprise-container' style={{zIndex:"999"}}>
                <div className='loader-enterprise'>
                </div>
            </div>
        }
    </div>
  )
}
