import React, {useState, useEffect} from 'react'
import { ImUser } from 'react-icons/im'
import { AiFillCamera } from "react-icons/ai"
import { HiIdentification } from 'react-icons/hi'
import { AiOutlinePlus } from 'react-icons/ai'
import {useFleetStateContext} from '../context/FleetDealsContext'
import { height } from '@mui/system'
import { useStateContext } from '../context/DashboardStateContext'
import { AiOutlineArrowRight } from "react-icons/ai"
import { BiFilter } from "react-icons/bi"
import {IoCheckmarkCircleOutline} from 'react-icons/io5'
import firebase from '../firebase-config';

export default function AssignSelectPanel() {
    const [driverSet, setDriverSet] = useState(false)
    const [vehicleSet, setVehicleSet] = useState(false)
    const [trailerSet, setTrailerSet] = useState(false)
    const {
        driverPanel,
        setDriverPanel,
        vehiclePanel,
        setVehiclePanel,
        trailerPanel,
        setTrailerPanel,
        addDriversData,
        addVehiclesData,
        AddTrailerData,
        setDriverAssigned,
        setVehicleAssigned,
        setTrailersAssigned,
        trailersAssigned,
        setAddTrailerData,
        setAddVehiclesData,
        trailerPanelSecond,
        setTrailerPanelSecond,
        secondTrailersAssigned,
        setSecondTrailersAssigned
    } = useFleetStateContext();
    const {
        setShowAssignPanel,
        userUid
      } = useStateContext();

    // arr.slice(0,5)

    useEffect(() => {
        firebase.database().ref('/fleets/' + userUid + "/trailers").on('value', (snapshot) => {
            if(snapshot.exists()){
                setAddTrailerData(Object?.values(snapshot.val()));
            }
        });
        firebase.database().ref('/fleets/' + userUid + "/horses").on('value', (snapshot) => {
            if(snapshot.exists()){
                setAddVehiclesData(Object?.values(snapshot.val()));
            }
        });
    }, [])


    console.log("all the trailers", trailersAssigned)

  return (
    <div className='absolute w-full select-assign-mobile items-center flex justify-center flex-row items-end bottom-0 left-0 right-0 top-0' style={{background:"#00000066", zIndex:"4"}}>
        <div className='w-full instructions-select-mob flex justify-center flex-col items-center  cursor-pointer' style={{height:"100%", width:"73%"}} onClick={() => setShowAssignPanel(false)}>
            <div className='flex justify-between items-center select-title-container-mob' style={{textAlign:"start", width:"60%"}}>
                {driverPanel &&
                    <h1 style={{color:"#fff", fontSize:"4rem"}}>Select a driver</h1>
                }
                {vehiclePanel &&
                    <h1 style={{color:"#fff", fontSize:"4rem"}}>Assign a Vehicle</h1>
                }
                {trailerPanel &&
                    <h1 style={{color:"#fff", fontSize:"4rem"}}>Select a Traler</h1>
                }

                <p className='select-icon-mob' style={{fontSize:"4rem", color:"#fff"}}><AiOutlineArrowRight/></p>
            </div>
            <div className='select-subtitle-mob' style={{textAlign:"start", width:"60%", color:"#fff"}}>
                {driverPanel &&
                    <p style={{color:"#fff", fontSize:"1.5rem"}}>Choose from the list who you would like to assign</p>
                }
                {vehiclePanel &&
                    <p style={{color:"#fff", fontSize:"1.5rem"}}>Choose from the list a vehicle you would like to assign.</p>
                }
                {trailerPanel &&
                    <p style={{color:"#fff", fontSize:"1.5rem"}}>Choose from the list a trailer you would like to assign.</p>
                }
            </div>

        </div>
        <div className='w-full mr-3 p-6 bg-white rounded-xl driver-pan-mob' style={{height:"95%", width:"27%"}}>
            <p className='' style={{fontSize:"1rem"}}>Select Driver</p>
            <div className='flex mt-3 w-full justify-between'>
                <input type="text" className='h-10 px-3 rounded-lg border-transparent'
                    placeholder='Search'
                    style={{width:"85%", background:"rgb(207 207 207)", color:"#000"}}
                />
                <p className='ml-2 text-2xl flex justify-center items-center rounded-lg' style={{width:"15%", background:"rgb(207 207 207)"}}><BiFilter/></p>
            </div>
            <div className='mt-2 scrollbar-hide ' style={{height:"355px", maxHeight:"355px", overflow:"scroll"}}>
                {driverPanel && addDriversData.map((driver) => (
                    <div className='w-full mt-3 flex cursor-pointer'
                        onClick={() => {
                            setDriverAssigned(driver)
                            setShowAssignPanel(false)
                            // setDriverSet(true)
                            setTimeout(() => {
                                setDriverSet(false)
                                setShowAssignPanel(false)
                            }, 2000)
                        }}
                    >
                        <div className='w-9 h-9 rounded-full'>
                            <img className='w-full h-full rounded-full' style={{objectFit:"cover"}} src={driver.photo_uri} alt="driver image" />
                        </div>
                        <div className='ml-2'>
                            <p className='font-bold' style={{fontSize:"1rem"}}>{driver.name}</p>
                            <p className='' style={{fontSize:".8rem", color:"grey"}}>{driver.phone}</p>
                        </div>
                    </div>
                    ))
                }
                {vehiclePanel && addVehiclesData.map((vehicle) => (
                    <div className='w-full mt-3 flex cursor-pointer'
                        onClick={() => {
                            setVehicleAssigned(vehicle)
                            setVehicleSet(true)
                            setTimeout(() => {
                                setVehicleSet(false)
                                setShowAssignPanel(false)
                            }, 2000)
                        }}
                    >
                        <div className='w-9 h-9 rounded-full'>
                            <img className='w-full h-full rounded-full' style={{objectFit:"cover"}} src={vehicle.photo_uri} alt="driver image" />
                        </div>
                        <div className='ml-2'>
                            <p className='font-bold' style={{fontSize:"1rem"}}>{vehicle.year} {vehicle.make} {vehicle.model}</p>
                            <p className='' style={{fontSize:".8rem", color:"grey"}}>{vehicle.licence_number}</p>
                        </div>
                    </div>
                    ))
                }
                {trailerPanel && AddTrailerData.map((trailer) => (
                    <div className='w-full mt-3 flex cursor-pointer'
                        onClick={() => {
                            if(trailerPanelSecond){
                                setSecondTrailersAssigned(trailer)
                            }else if(trailerPanel){
                                setTrailersAssigned(trailer)
                            }
                            setTrailerSet(true)
                            setTimeout(() => {
                                setTrailerSet(false)
                                setShowAssignPanel(false)
                            }, 2000)
                        }}
                    >
                        <div className='w-9 h-9 rounded-full'>
                            <img className='w-full h-full rounded-full' style={{objectFit:"cover"}} src={trailer.photo_uri} alt="driver image" />
                        </div>
                        <div className='ml-2'>
                            <p className='font-bold' style={{fontSize:"1rem"}}>{trailer.year} {trailer.make} {trailer.model}</p>
                            <p className='' style={{fontSize:".8rem", color:"grey"}}>{trailer.licence_number}</p>
                        </div>
                    </div>
                    ))

                }
            </div>
        </div>
        {driverSet &&
            <div className='p-4 flex justify-center items-center rounded-xl absolute h-full w-full right-0 bottom-0' style={{width:"fit-content", height:"fit-content", background:"#fff", top:"50%", left:"50%"}}>
                <p style={{fontSize:"3rem", color:"#000"}}><IoCheckmarkCircleOutline/></p>
                <p className='font-bold' style={{fontSize:"1rem"}}>Driver set Successfully</p>
            </div>
        }
        {vehicleSet &&
            <div className='p-4 flex justify-center items-center rounded-xl absolute h-full w-full right-0 bottom-0' style={{width:"fit-content", height:"fit-content", background:"#fff", top:"50%", left:"50%"}}>
                <p style={{fontSize:"3rem", color:"#000"}}><IoCheckmarkCircleOutline/></p>
                <p className='font-bold' style={{fontSize:"1rem"}}>Vehicle set Successfully</p>
            </div>
        }
        {trailerSet &&
            <div className='p-4 flex justify-center items-center rounded-xl absolute h-full w-full right-0 bottom-0' style={{width:"fit-content", height:"fit-content", background:"#fff", top:"50%", left:"50%"}}>
                <p style={{fontSize:"3rem", color:"#000"}}><IoCheckmarkCircleOutline/></p>
                <p className='font-bold' style={{fontSize:"1rem"}}>Trailer set Successfully</p>
            </div>
        }
    </div>
  )
}
