import React, {useState, useEffect} from 'react'
import EnterpriseNav from './EnterpriseNav'
import { useParams, Link, useNavigate  } from 'react-router-dom'
import '../css/prerequisites.css'
import { useStateContext } from '../context/DashboardStateContext'

export default function Summary({
   // vehicleType,
   openPre,
   setOpenPre,
   alertQuantity,
   pickSelected,
   setAlertQuantity
}) {
   const [vehicleEquipment, setVehicleEquipment] = useState([]);
   const [adHocServices, setAdHocServices] = useState([]);
   const [documentation, setDocumentation] = useState([]);
   const [personalProtective, setPersonalProtective] = useState([]);
   const [goodsInTransit, setGoodsInTransit] = useState([]);
   const [prerequisites, setPrerequisites] = useState([]);
   const [openSpinner, setOpenSpinner] = useState(false)
   const [openAlert, setOpenAlert] = useState(false)
   const [close, setclose] =useState(false);
   const [vehicleType, setVehicleType] = useState(["Tri-axle"])
   const navigate = useNavigate();
   const {
       isEnterprise,
       setIsEnterprise,
       isTracking,
       setIsTracking,
       isShowSchedule,
       setIsShowSchedule,
       setIsShowRequest,
       isShowRequest,
       setCargoLink,
       cargoLink
   } = useStateContext();

   //The button states
   const [adhocStates, setAdhocStates] = useState({
       solas: true,
       storage: true,
       crane : true,
   });

   const [documentationStates, setDocumentationStates] = useState({
       passport: true,
       license: true,
       permit : true,
       medical: true
   });

   const [protectiveStates, setprotectiveStates] = useState({
       hat: true,
       goggles: true,
       reflector : true,
       overall: true,
       plugs: true,
       mask:true,
       genset:true
   });

   const [vehicleStates, setVehicleStates] = useState({
       blocks: true,
       hooter: true,
       extinguisher : true,
       light: true,
       dunnage: true,
       straps: true,
       tarpaulin: true,
       chains: true,
   });

   //The handle button click
   const handleAdhocClick = (buttonName) => {
       setAdhocStates((prevButtonStates) => ({
           ...prevButtonStates,
           [buttonName]: !prevButtonStates[buttonName]
       }));
   }

   const handleDocumentationClick = (buttonName) => {
       setDocumentationStates((prevButtonStates) => ({
           ...prevButtonStates,
           [buttonName]: !prevButtonStates[buttonName]
       }));
   }

   const handleProtectiveClick = (buttonName) => {
       setprotectiveStates((prevButtonStates) => ({
           ...prevButtonStates,
           [buttonName]: !prevButtonStates[buttonName]
       }));
   }

   const handleVehicleClick = (buttonName) => {
       setVehicleStates((prevButtonStates) => ({
           ...prevButtonStates,
           [buttonName]: !prevButtonStates[buttonName]
       }));
   }

   //Inset button data into specific array
   const adhocFnc = (buttonName, buttonText) => {
       if (adhocStates[buttonName]) {
           setAdHocServices((prevSelectedTexts) => [
             ...prevSelectedTexts,
             buttonText
           ]);
         } else {
           setAdHocServices((prevSelectedTexts) =>
             prevSelectedTexts.filter((text) => text !== buttonText)
           );
       }
   }

   const documentationFnc = (buttonName, buttonText) => {
       if (documentationStates[buttonName]) {
           setDocumentation((prevSelectedTexts) => [
             ...prevSelectedTexts,
             buttonText
           ]);
         } else {
           setDocumentation((prevSelectedTexts) =>
             prevSelectedTexts.filter((text) => text !== buttonText)
           );
       }
   }

   const protectiveFnc = (buttonName, buttonText) => {
       if (protectiveStates[buttonName]) {
           setPersonalProtective((prevSelectedTexts) => [
             ...prevSelectedTexts,
             buttonText
           ]);
         } else {
           setPersonalProtective((prevSelectedTexts) =>
             prevSelectedTexts.filter((text) => text !== buttonText)
           );
       }
   }

   const vehicleFnc = (buttonName, buttonText) => {
       if (vehicleStates[buttonName]) {
           setVehicleEquipment((prevSelectedTexts) => [
             ...prevSelectedTexts,
             buttonText
           ]);
         } else {
           setVehicleEquipment((prevSelectedTexts) =>
             prevSelectedTexts.filter((text) => text !== buttonText)
           );
       }
   }

   const handleContinue = () =>{
       if(localStorage.getItem("cargoSelectd")){
           const selectedCargoDetails = JSON.parse(localStorage.getItem("cargoSelectd"))
           if(!selectedCargoDetails.details.quantity || pickSelected.length < 1){
               setAlertQuantity(true);
               setOpenPre(false);
           }else{
               setPrerequisites({
                   vehicleEquipment:vehicleEquipment,
                   adHocServices:adHocServices,
                   documentation:documentation,
                   personalProtective:personalProtective,
                   goodsInTransit:goodsInTransit,
                   prerequisites:prerequisites,
                   vehicle_type:vehicleType,
                   vehicleDimensions:{
                        volume:43,
                        width:12
                    }
               })

               const selectPrerequis = [{prerequisites:{
                           vehicle_equipment:vehicleEquipment,
                           ad_hoc_services:adHocServices,
                           documentation:documentation,
                           personal_protective:personalProtective,
                           goods_in_transit:goodsInTransit,
                           vehicle_type:vehicleType,
                           vehicleDimensions:{
                                volume:43,
                                width:12
                            }
                       },
                       date:new Date()
               }]

               localStorage.setItem("Prerequisites", JSON.stringify(selectPrerequis));
               setOpenSpinner(true)
               setTimeout(() => {
                   setOpenSpinner(false)
               },1000)
               setIsShowSchedule(true);
               setIsTracking(false);
               setIsEnterprise(false);
               setCargoLink(false);
               setIsShowRequest(false);
           }
       }
   }

   // const closeModal = () => {
   //     setOpenPre(prev => !prev)
   // }

   useEffect(() => {
       localStorage.setItem("vehicleType", JSON.stringify(vehicleType));
}, [])

  return (
    <>{ openPre ?
            (<div className='summary'>
            <div className='modal-summary'>
              <h3 className='container-prerequiz'>Tri-axle Prerequisites</h3>
              <div className="summary-wrapper">
                  <div>
                      <div>
                          <p className='summary-title'>Ad-hoc Services Required</p>
                      </div>
                      <div>
                          <p className='summary-title'>Documentation</p>
                      </div>
                      <div>
                          <p className='summary-title'>Personal Protective Equipment</p>
                      </div>
                      <div>
                          <p className='summary-title'>Vehicle Equipment</p>
                      </div>
                      <div>
                          <p className='summary-title'>Insurance (Goods in Transit)</p>
                      </div>
                  </div>
                  <div>
                      <div style={{height:"70px"}}>
                          <div>
                              <p
                                  onClick={() => {
                                      handleAdhocClick("solas")
                                      adhocFnc('solas', 'Solas / Verified Gross Mass');
                                  }}
                                  style={{
                                      backgroundColor: !adhocStates.solas ? '#ffe201' : '#fff'
                                  }}
                              >
                                  Solas / Verified Gross Mass
                              </p>
                              <p
                                  onClick={() => {
                                      handleAdhocClick("storage")
                                      adhocFnc('storage', 'Storage');
                                  }}
                                  style={{
                                      backgroundColor: !adhocStates.storage ? '#ffe201' : '#fff'
                                  }}
                              >
                                  Storage
                              </p>
                              <p
                                  onClick={() => {
                                      handleAdhocClick("crane")
                                      adhocFnc('crane', 'Crane truck (Load/Offload Container)');
                                  }}
                                  style={{
                                      backgroundColor: !adhocStates.crane ? '#ffe201' : '#fff'
                                  }}
                              >
                                  Crane truck (Load/Offload Container)
                              </p>
                          </div>
                          <div>
                              <label htmlFor="Rear Mount Container" className='summary-label'>
                                  <input type="radio" style={{marginRight:"10px"}} value="Rear Mount Container" onChange={(e) => {
                                  setAdHocServices([...adHocServices, e.target.value])
                                      e.target.style.background="#ffe201"
                                  }}/>
                                  Rear Mount Container
                              </label>
                          </div>
                      </div>

                      <div>
                          <div>
                              <p
                                  onClick={() => {
                                      handleDocumentationClick("passport")
                                      documentationFnc('passport', 'ID/Passport');
                                  }}
                                  style={{
                                      backgroundColor: !documentationStates.passport ? '#ffe201' : '#fff'
                                  }}
                              >
                                  ID/Passport
                              </p>
                              <p
                                  onClick={() => {
                                      handleDocumentationClick("license")
                                      documentationFnc('license', "Driver's License");
                                  }}
                                  style={{
                                      backgroundColor: !documentationStates.license ? '#ffe201' : '#fff'
                                  }}
                              >
                                  Driver's License
                              </p>
                              <p
                                  onClick={() => {
                                      handleDocumentationClick("permit")
                                      documentationFnc('permit', "Public Driving Permit");
                                  }}
                                  style={{
                                      backgroundColor: !documentationStates.permit ? '#ffe201' : '#fff'
                                  }}
                              >
                                      Public Driving Permit
                              </p>
                              <p
                                  onClick={() => {
                                      handleDocumentationClick("medical")
                                      documentationFnc('medical', "Medical Certificate");
                                  }}
                                  style={{
                                      backgroundColor: !documentationStates.medical ? '#ffe201' : '#fff'
                                  }}
                              >
                                  Medical Certificate
                              </p>
                          </div>
                      </div>

                      <div>
                          <div>
                              <p
                                  onClick={() => {
                                      handleProtectiveClick("hat")
                                      protectiveFnc('hat', "Safety Hat");
                                  }}
                                  style={{
                                      backgroundColor: !protectiveStates.hat ? '#ffe201' : '#fff'
                                  }}
                              >
                                  Safety Hat
                              </p>
                              <p
                                  onClick={() => {
                                      handleProtectiveClick("goggles")
                                      protectiveFnc('goggles', "Safety Goggles");
                                  }}
                                  style={{
                                      backgroundColor: !protectiveStates.goggles ? '#ffe201' : '#fff'
                                  }}
                              >
                                  Safety Goggles
                              </p>
                              <p
                                  onClick={() => {
                                      handleProtectiveClick("reflector")
                                      protectiveFnc('goggles', "Reflector Jacket");
                                  }}
                                  style={{
                                      backgroundColor: !protectiveStates.reflector ? '#ffe201' : '#fff'
                                  }}
                              >
                                  Reflector Jacket
                              </p>
                              <p
                                  onClick={() => {
                                      handleProtectiveClick("overall")
                                      protectiveFnc('overall', "Full Overall");
                                  }}
                                  style={{
                                      backgroundColor: !protectiveStates.overall ? '#ffe201' : '#fff'
                                  }}
                              >
                                  Full Overall
                              </p>
                              <p
                                  onClick={() => {
                                      handleProtectiveClick("plugs")
                                      protectiveFnc('plugs', "Ear Plugs");
                                  }}
                                  style={{
                                      backgroundColor: !protectiveStates.plugs ? '#ffe201' : '#fff'
                                  }}
                              >
                                  Ear Plugs
                              </p>
                              <p
                                  onClick={() => {
                                      handleProtectiveClick("mask")
                                      protectiveFnc('mask', "Dust Mask");
                                  }}
                                  style={{
                                      backgroundColor: !protectiveStates.mask ? '#ffe201' : '#fff'
                                  }}
                              >
                                      Dust Mask
                              </p>
                              <p
                                  onClick={() => {
                                      handleProtectiveClick("genset")
                                      protectiveFnc('genset', "Genset");
                                  }}
                                  style={{
                                      backgroundColor: !protectiveStates.genset ? '#ffe201' : '#fff'
                                  }}
                              >
                                  Genset
                              </p>
                          </div>
                      </div>
                      <div>
                          <div>
                              <p
                                  onClick={() => {
                                      handleVehicleClick("blocks")
                                      vehicleFnc('blocks', "2 Stop Blocks");
                                  }}
                                  style={{
                                      backgroundColor: !vehicleStates.blocks ? '#ffe201' : '#fff'
                                  }}
                              >
                                  2 Stop Blocks
                              </p>
                              <p
                                  onClick={() => {
                                      handleVehicleClick("hooter")
                                      vehicleFnc('hooter', "Reverse Hooter");
                                  }}
                                  style={{
                                      backgroundColor: !vehicleStates.hooter ? '#ffe201' : '#fff'
                                  }}
                              >
                                  Reverse Hooter
                              </p>
                              <p
                                  onClick={() => {
                                      handleVehicleClick("extinguisher")
                                      vehicleFnc('extinguisher', "9kg Fire Extinguisher");
                                  }}
                                  style={{
                                      backgroundColor: !vehicleStates.extinguisher ? '#ffe201' : '#fff'
                                  }}
                              >
                                  9kg Fire Extinguisher
                              </p>
                              <p
                                  onClick={() => {
                                      handleVehicleClick("light")
                                      vehicleFnc('light', "Beacon Light");
                                  }}
                                  style={{
                                      backgroundColor: !vehicleStates.light ? '#ffe201' : '#fff'
                                  }}
                              >
                                  Beacon Light
                              </p>
                              <p
                                  onClick={() => {
                                      handleVehicleClick("dunnage")
                                      vehicleFnc('dunnage', "Dunnage");
                                  }}
                                  style={{
                                      backgroundColor: !vehicleStates.dunnage ? '#ffe201' : '#fff'
                                  }}
                              >
                                  Dunnage
                              </p>
                              <p
                                  onClick={() => {
                                      handleVehicleClick("straps")
                                      vehicleFnc('straps', "Straps");
                                  }}
                                  style={{
                                      backgroundColor: !vehicleStates.straps ? '#ffe201' : '#fff'
                                  }}
                              >
                                  Straps
                              </p>
                              <p
                                  onClick={() => {
                                      handleVehicleClick("tarpaulin")
                                      vehicleFnc('tarpaulin', "Tarpaulin");
                                  }}
                                  style={{
                                      backgroundColor: !vehicleStates.tarpaulin ? '#ffe201' : '#fff'
                                  }}
                              >
                                  Tarpaulin
                              </p>
                              <p
                                  onClick={() => {
                                      handleVehicleClick("chains")
                                      vehicleFnc('chains', "Chains");
                                  }}
                                  style={{
                                      backgroundColor: !vehicleStates.chains ? '#ffe201' : '#fff'
                                  }}
                              >
                                  Chains
                              </p>
                          </div>
                      </div>

                      <div>
                          <div>
                              <label htmlFor="Rear Mount Container" className='summary-label'>
                                  <input type="radio" style={{marginRight:"10px"}} name="goods" value="350000" onChange={(e) => {
                                  setGoodsInTransit(parseInt(e.target.value))
                                  e.target.style.background="#ffe201"
                              }}/>
                                  R350 000
                              </label>

                              <label htmlFor="Rear Mount Container" className='summary-label'>
                                  <input type="radio" style={{marginRight:"10px"}} name="goods" value="500000" onChange={(e) => {
                                  setGoodsInTransit(parseInt(e.target.value))
                                  e.target.style.background="#ffe201"
                              }}/>
                                  R500 000
                              </label>
                              <input type="text" placeholder='Other' className='other' onChange={(e) => {
                                  setGoodsInTransit(parseInt(e.target.value))
                                  e.target.style.background="#ffe201"
                              }}/>
                          </div>

                            {/* <p className='flex font-bold justify-center items-center'>
                                Select all
                                <input
                                    type="radio"
                                    className='ml-2'
                                    onClick={() =>{
                                        setAdhocStates({
                                            solas: false,
                                            storage: false,
                                            crane: false,
                                        });
                                        setDocumentationStates({
                                            passport: false,
                                            license: false,
                                            permit : false,
                                            medical: false
                                        });
                                        setVehicleStates({
                                            blocks: false,
                                            hooter: false,
                                            extinguisher : false,
                                            light: false,
                                            dunnage: false,
                                            straps: false,
                                            tarpaulin: false,
                                            chains: false,
                                        });
                                        setprotectiveStates({
                                            hat: false,
                                            goggles: false,
                                            reflector : false,
                                            overall: false,
                                            plugs: false,
                                            mask:false,
                                            genset:false
                                        });
                                    }}
                                />
                            </p> */}
                      </div>
                          {/* {openSpinner && <Alert >
                              <div style={{width:"256px"}}>
                                  <p style={{
                                      position:"absolute",
                                      right:"0px",
                                      top:"0px",
                                      padding:"7px",
                                      cursor:"pointer",
                                      fontWeight:"bold"
                                  }}
                                  onClick={() => setOpenAlert(false)}
                                  >X</p>
                                  <h1 style={{fontSize:"14px", fontWeight:"normal"}}>Prerequisites successfully added</h1>

                              </div>
                          </Alert>} */}
                  </div>
              </div>
              <div className='continue-btn-container' style={{width:"100%"}}>
                  <button
                      style={{
                              marginRight:"10px",
                              padding:"3px 19px",
                              borderRadius:"10px",
                              boxShadow:"0px 0px 2px 0px",
                              fontSize:"1rem",
                              backgroundColor:"#fff"
                      }} onClick={() => setOpenPre(false)}>Cancel</button>
                  <button
                      className='summary-one-close'
                      onClick={handleContinue}
                  >
                      Continue &nbsp; <i class="fa-solid fa-chevron-right"></i>
                  </button>
              </div>
            </div>
          </div>)
        : null}
    </>
  )
}
