import React, {useEffect, useState, useRef, useCallback, useMemo } from 'react'
// import {Avatar} from '@mui/material';
import {IoLogoWhatsapp} from 'react-icons/io'
import {BiFilter} from 'react-icons/bi'
import bg from '../icons/bg.jpg'
import firebase from '../firebase-config';
import { fetchDriverData, fetchShipperData, fetchCoordinatesInbound, calculateDistance} from '../getdriverInfo';
import emptyIcon from '../icons/box.png'
import {AiFillPrinter} from 'react-icons/ai'
import {AiOutlineDownload} from 'react-icons/ai'
import {FiLogOut} from 'react-icons/fi'
import html2canvas from 'html2canvas';
import { Link, useNavigate  } from 'react-router-dom'
import axios from 'axios';

function PickDropTracking() {

    const [isInbound, setIsInbound] = useState(true);
    const [isOutbound, setIsOutbound] = useState(false);
    const [allbookings, setAllbookings] = useState([]);
    const [inBoundBookings, setInBoundBookings] = useState([])
    const [outBoundBookings, setOutBoundBookings] = useState([])
    const [driverDataInbound,setDriverDataInbound] = useState([])
    const [driverDataOutbound,setDriverDataOutbound] = useState([])
    const [shipperData, setShipperData] = useState([])
    const [userUid, setUserUid] = useState("")
    const [userEmail, setUserEmail] = useState("")
    const [selectedBook, setSelectedBook] = useState([]);
    const [selectedBookParty, setSelectedBookParty] = useState([]);
    const [selectedDriver, setSelectedDriver] = useState([])
    const [coordsIn, setCoordsIn] = useState([])
    const [inDistance, setInDistance] = useState(0)
    const captureRef = useRef(null);
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    const [dropOffCoordinates,setDropOffCoordinates] = useState("");
    const apiKey = 'AIzaSyCDv9ViM5f03ZnI9i8vKz9xI_conVCyKx8'; // Replace with your actual API key
    const [distance,setDistance] = useState(0)
    const [driversCoords, setDriversCoords] = useState([])
    const [inboundDriverDistance, setInboundDriverDistance] = useState([])

    const handleFetchAndPromise = (state, data) => {
        Promise.all(data)
        .then((results) => {
            // Now, `results` is an array containing the resolved values of the promises
            console.log("These are the results", results);
            state(results);
            // const selectedDriverVar = results.find(driver => driver.uid === selectedBook[0]);
        })
        .catch((error) => {
            // Handle any errors that might occur during promise resolution
            console.error(error);
        });
    }

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                setUserEmail(user.email);
                setUserUid(user.uid);
                firebase.database().ref("booking").on('value', (snapshot) => {
                    if(snapshot.exists()){
                        setAllbookings(Object.values(snapshot.val()))
                        const allbook = Object.values(snapshot.val())
                        const inBookings = allbook.filter((item) => item.puDetails.puEmail === user.email)
                        const outBookings = allbook.filter((item) => item.doDetails.doEmail === user.email)
                        setInBoundBookings(inBookings);
                        console.log("The email", user.email)
                         //Getting data for the inbound drivers
                        const InData = inBookings.map((item) => {
                            return fetchDriverData(item.drivers)
                        })

                        const OutData = outBookings.map((item) => {
                            return fetchDriverData(item.drivers)
                        })

                        handleFetchAndPromise(setDriverDataInbound, InData)
                        handleFetchAndPromise(setDriverDataOutbound, OutData)

                        setOutBoundBookings(outBookings);
                        //Getting data for the inbound drivers
                        const shipper = outBookings.map((item) => {
                            return fetchShipperData(item.booking_party_uid)
                        })
                        Promise.all(shipper)
                        .then((results) => {
                            // Now, `results` is an array containing the resolved values of the promises
                            console.log("These are the results for shipper", results);
                            setShipperData(results);
                        })
                        .catch((error) => {
                            // Handle any errors that might occur during promise resolution
                            console.error(error);
                        });

                        console.log("The below is the shipper Info", shipper)
                    }
                })
            }else{
                // navigate('/loginPickDrop');
            }
        });
    },[])

    useEffect(() => {
        if(selectedBook.length > 0){
            firebase.database()
            .ref("booking_party").child(selectedBook[0]?.booking_party_uid)
            .on("value", snapshot =>{
                setSelectedBookParty(snapshot.val())
           })
        }
    },[selectedBook])

    //convert address into coordinates
    useEffect(() => {
        if(selectedBook.length > 0){
            const coords = fetchCoordinatesInbound(selectedBook[0].puDetails.puAddress)
            coords
            .then((result) => {
                // `result` now contains the resolved value (the object)
                setCoordsIn(result)

            })
            .catch((error) => {
                // Handle any errors that might occur during promise resolution
                console.error(error);
            })
        }
    },[])

    const openWhatsApp = () => {
        if(selectedDriver.length > 0){
            var whatsappURL = 'https://wa.me/' + selectedDriver[0].phone;
            window.open(whatsappURL, '_blank');
        }
    }

    const handleCapture = () => {
        if (captureRef.current) {
          html2canvas(captureRef.current).then((canvas) => {
            // Convert canvas to a data URL
            const dataUrl = canvas.toDataURL('image/png');

            // Create a download link
            const a = document.createElement('a');
            a.href = dataUrl;
            a.download = 'screenshot.png';
            document.body.appendChild(a);

            // Trigger a click on the download link to initiate the download
            a.click();

            // Clean up
            document.body.removeChild(a);
          });
        }
    };

    const handleCaptureAndPrint = () => {
        if (captureRef.current) {
          html2canvas(captureRef.current).then((canvas) => {
            // Convert canvas to a data URL
            const dataUrl = canvas.toDataURL('image/png');

            // Create a download link for the screenshot
            const a = document.createElement('a');
            a.href = dataUrl;
            a.download = 'screenshot.png';
            document.body.appendChild(a);

            // Trigger a click on the download link to initiate the download
            a.click();

            // Clean up
            document.body.removeChild(a);

            // Create a new Image element for printing
            const img = new Image();
            img.src = dataUrl;
            img.onload = () => {
              // Open a new window and print the image
              const printWindow = window.open('', '_blank');
              printWindow.document.open();
              printWindow.document.write('<img src="' + img.src + '" />');
              printWindow.document.close();
              printWindow.print();
              printWindow.close();
            };
          });
        }
      };


      //log out
      const handleSignOut = () => {
        firebase.auth().signOut().then(() => {
            navigate('/loginPickDrop')
            }).catch((error) => {
            alert(error)
        });
      }

        // Filter function
        const filteredInBookings = useMemo(() => {
            return inBoundBookings.filter((booking) =>
                booking.booking_ref.toLowerCase().includes(searchQuery?.toLowerCase())
            );
        }, [inBoundBookings, searchQuery]);

        // For fetching coordinates from address
        const fetchCoordinates = useCallback(async (address) => {
            try {
                const response = await axios.get(
                    `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apiKey}`
                );
                const result = response.data;
                if (result.status === 'OK') {
                    const { lat, lng } = result.results[0].geometry.location;
                    // Use lat and lng for further calculations or state update
                    return { lat, lng };
                } else {
                    console.error('Error fetching coordinates:', result.status);
                    return null;
                }
            } catch (error) {
                console.error('Error fetching coordinates:', error);
                return null;
            }
        }, [apiKey]);

        // For fetching driver locations
        const fetchDriverLocations = useCallback(async () => {
            const locations = [];
            driverDataInbound.forEach((driver) => {
                const location = driver.driver_location;
                if (location) {
                    locations.push(location);
                }
            });
            return locations;
        }, [driverDataInbound]);

        // useEffect to fetch coordinates and driver locations
        useEffect(() => {
            // Create an array of promises to fetch coordinates for each address
            const promises = filteredInBookings.map(async (booking) => {
                const address = booking.doDetails.doAddress;
                const booking_uid = booking.booking_id
                const booking_date = booking.dates_time_selection.start_date_string
                const coordinates = await fetchCoordinates(address);
                return { address, booking_uid,coordinates, booking_date };
            });

            Promise.all(promises)
                .then((coordinatesArray) => {
                    // Flatten the array of objects into a single array
                    const combinedCoordinates = coordinatesArray.flat();

                    console.log("checking the for the time", combinedCoordinates)

                    // Update the state with the combined coordinates
                    setDropOffCoordinates(combinedCoordinates);

                    // Log the coordinates
                    console.log('All Coordinates:', combinedCoordinates);
                })
                .catch((error) => {
                    // Handle errors if any of the promises fail
                    console.error('Error fetching coordinates:', error);
                });

                // Filter out empty arrays and flatten non-empty arrays into a single array
                const filteredAndFlattened = driverDataInbound
                .filter((array) => array.length > 0) // Remove empty arrays
                .flatMap((array) => array); // Flatten non-empty arrays

                // Now you have a single flattened array without multiple arrays
                console.log('Filtered and Flattened Array:', filteredAndFlattened);
                setDriversCoords(filteredAndFlattened)

        }, [filteredInBookings, fetchCoordinates, fetchDriverLocations]);

        const calculateDistance = (lat1, lon1, lat2, lon2) =>{
            const R = 6371; // Radius of the earth in km
            const dLat = deg2rad(lat2 - lat1); // deg2rad below
            const dLon = deg2rad(lon2 - lon1);
            const a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
                Math.sin(dLon / 2) * Math.sin(dLon / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            const distance = R * c; // Distance in km
            return distance;
        }

        const deg2rad = (deg) => {
            return deg * (Math.PI / 180);
        }

        //now match the drivers and the bookings
        // useEffect(() => {
        //     if (dropOffCoordinates && driversCoords) {
        //         console.log("checking if drop off coords has something", dropOffCoordinates);

        //         // const matchingBookings = [];

        //         dropOffCoordinates.forEach(dropOffBooking => {
        //             const matchingDriver = driversCoords.find(driver => {
        //                 return driver.current_booking_id === dropOffBooking.booking_uid;
        //             });

        //                 console.log("the matching data", matchingDriver)

        //             // if (matchingDriver) {
        //             //     // Match found
        //             //     console.log(`Matching driver found for booking_uid: ${dropOffBooking.booking_uid}`);
        //             //     matchingBookings.push({
        //             //         booking_uid: dropOffBooking.booking_uid,
        //             //         // Include other details from the booking or driverData if needed
        //             //         driver_location: matchingDriver.driver_location,
        //             //         // ...other relevant data
        //             //     });
        //             // } else {
        //             //     // No match found
        //             //     console.log(`No matching driver found for booking_uid: ${dropOffBooking.booking_uid}`);
        //             // }
        //         });

        //         // console.log(matchingBookings);
        //     }
        // }, [dropOffCoordinates, driversCoords]);

        // useEffect(() => {
        //     firebase.auth().onAuthStateChanged((user) => {
        //         console.log("the user", user)
        //         if (user) {
        //           // ...
        //         } else {
        //             navigate('/loginPickDrop');
        //         }
        //     });
        // }, [])

        console.log("The bookings coords", dropOffCoordinates)
        console.log("The drivers, info", driversCoords)

  return (
    <div ref={captureRef} className='flex flex-col'>
        <div
            className='w-full cursor-pointer pt-4 h-[30px] flex items-center justify-between px-10'
        >
            <h1>{userEmail}</h1>
            <p
                onClick={() => {
                    handleSignOut()
                }}
                className='cursor-pointer flex justify-between items-center'
            >
                <h1 className='pr-4 text-[grey]'>Logout</h1><FiLogOut className='text-[grey]'/>
            </p>
        </div>
        <div className='flex justify-between p-7'>
      <div className=' w-[70%] h-fit flex flex-col mr-4'>
        <div className='flex justify-center after:absolute after:left-0 after:right-0 after:bottom-0 after:top-0 after:w-full after:h-full after:bg-black/50 after:rounded-xl items-center relative  text-[white] h-[150px] w-full rounded-xl'>
            <img src={bg} className='absolute rounded-xl top-0 left-0 bottom-0 right-0 w-full h-full object-cover z-[1]' alt="" />
            {/* <h1 className='font-bold z-[1]'>Promotions</h1> */}
        </div>
        {selectedBook.length > 0 ?
        <div className='p-4 h-[70%] mt-4 w-full rounded-xl bg-white'>
            <div className='flex justify-between items-center'>
                <div className='flex justify-start items-center'>
                    <h1 className='font-bold mr-4 text-[.9rem]'>Load Summary</h1>
                    <p className='text-[.7rem]'>Trip ID: {selectedBook ? selectedBook[0]?.booking_ref : ""}</p>
                </div>
                <div>
                    <p className='text-[.8rem]'>{selectedBook ? selectedBook[0]?.date_created :""}</p>
                </div>
            </div>
            {/* {isInbound && inBoundBookings.length > 0 && inBoundBookings.map((item, i) => { */}
                <div className='flex justify-between w-full'>
                    {/* {selectedBook && selectedBook.map((item) => { */}
                        <div className='p-3 w-[32%] bg-white rounded-xl h-[120px] shadow-xl'>
                            <p className='text-[.8rem] text-[#6e6e6e]'>Booking Party</p>
                            <p className='text-[.9rem] font-bold'>{selectedBookParty?.companyName}</p>
                            <p className='text-[.8rem]'>{selectedBookParty?.firstName}</p>
                            <p className='text-[.8rem]'>{selectedBookParty?.Telephone}</p>
                            <p className='text-[.8rem]'>{selectedBookParty?.companyEmailData}</p>
                         </div>
                        {/* })
                    } */}
                    <div className='p-3 w-[32%] bg-white rounded-xl h-[120px] shadow-xl'>
                        <p className='text-[.8rem] text-[#6e6e6e]'>Pick-up</p>
                        <p className='text-[.9rem] font-bold'>{selectedBook[0]?.puDetails?.puName}</p>
                        <p className='text-[.8rem]'>{selectedBook[0]?.puDetails?.puAddress}</p>
                    </div>
                    <div className='p-3 w-[32%] bg-white rounded-xl h-[120px] shadow-xl'>
                        <p className='text-[.8rem] text-[#6e6e6e]'>Drop-off</p>
                        <p className='text-[.9rem] font-bold'>{selectedBook[0]?.doDetails?.doName}</p>
                        <p className='text-[.8rem]'>{selectedBook[0]?.doDetails?.doAddress}</p>
                    </div>
                </div>
            {/* })} */}
                <div className='mt-5 flex justify-between w-full'>
                    <div className='bg-white p-3 shadow-xl rounded-xl w-[49%]'>
                        <p className='text-[.8rem] text-[#6e6e6e]'>Cargo</p>
                        <p className='text-[.9rem] font-bold'>{selectedBook[0]?.cargoInformation?.productName}</p>
                        <p className='text-[.8rem] mb-2'>SKU: {selectedBook[0]?.cargoInformation?.productCode}</p>
                        <p className='text-[.8rem] text-[#6e6e6e]'>Packaging</p>
                        <div className='w-full text-[.9rem] flex flex-row justify-between'>
                            <div className='w-[50%]'>
                                <p>Package Type:</p>
                                <p>Dimensions:</p>
                                <p>Quantity:</p>
                                <p>Total Weight:</p>
                            </div>
                            <div className='w-[50%]'>
                                <p>{selectedBook[0]?.cargoInformation?.productCode}</p>
                                <p>{selectedBook[0]?.cargoInformation?.breadth} x {selectedBook[0]?.cargoInformation?.height} x {selectedBook[0]?.cargoInformation?.lengthValue}</p>
                                <p>{selectedBook[0]?.cargoInformation?.quantity}</p>
                                <p>{selectedBook[0]?.cargoInformation?.weight}</p>
                            </div>
                        </div>
                        {/* <div className='mt-2 text-[red]'><p>This cargo is fragile, must be contained in temperatures between -20&#176;C</p></div> */}

                    </div>
                    <div className='bg-white p-3 rounded-xl shadow-xl w-[49%]'>
                        <p className='text-[.8rem] text-[#6e6e6e]'>vehicle</p>
                        <p className='text-[.9rem] font-bold'>{selectedBook[0]?.prerequisites?.vehicle_type}</p>
                        {/* <p className='text-[.8rem]'>Mercedes Benz Actross</p> */}
                        <div className='w-full text-[.9rem] flex flex-row justify-between'>
                            <div className='w-[50%]'>
                                <p>Net Weight:</p>
                                {/* <p>Horse:</p> */}
                                {/* <p>Trailer:</p> */}
                                {/* <p>VIN:</p> */}
                            </div>
                            <div className='w-[50%]'>
                                <p>34t</p>
                                {/* <p>757JCYGP</p> */}
                                {/* <p>HL76BDMP</p> */}
                                {/* <p>123456789</p> */}
                            </div>
                        </div>
                        <p className='text-[.8rem] mt-2 text-[#6e6e6e]'>Transit</p>
                        <p className='text-[.9rem] font-bold'>In transit</p>
                        <div className='w-full text-[.9rem] flex flex-row justify-between'>
                            <div className='w-[50%]'>
                                <p>Estimated Time of Arrival:</p>
                            </div>
                            <div className='w-[50%]'>
                                <p>{selectedBook[0].total_duration}</p>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
                :<div className='flex justify-center flex-col items-center h-[391px]'>
                <img src={emptyIcon} width={65} className='mb-4' height={65}/>
                No Booking selected at the moment please select one on your right, if you have any.
            </div>
        }
        <div className='w-full flex justify-between items-center mt-6 py-2 px-3'>
            <div className='flex items-center justify-center'>
                <p className='mr-6 flex justify-between items-center'>
                    <AiFillPrinter
                        className='mr-3 bg-[#333333] p-1 cursor-pointer rounded-full text-[#fce200] text-[1.5rem]'
                        onClick={handleCaptureAndPrint}
                    />
                    Print
                </p>
                <p className='flex justify-between items-center'>
                    <AiOutlineDownload
                        className="mr-3 cursor-pointer bg-[#333333] p-1 rounded-full text-[#fce200] text-[1.5rem]"
                        onClick={handleCapture}
                    />
                    Download DPF
                </p>
            </div>
            {selectedDriver.length > 0 &&
                <div className='flex h-full p-2 rounded-xl items-center justify-between bg-[white] shadow-2xl'>
                    <div className='mr-3 w-[2.5rem] h-[2.5rem] rounded-full bg-[#C5C5C5] flex justify-center items-center text-[white]'><p className='text-[1.2rem]'>{(selectedDriver[0]?.name.substring(0,2)).toUpperCase()}</p></div>
                    <div className='mr-7'>
                        <p className='text-[.9rem] font-bold'>{selectedDriver[0]?.name}</p>
                        <p className='text-[.8rem]'>757JCYGP</p>
                    </div>
                    <p
                        className='text-[2rem] cursor-pointer text-[green]'
                        onClick={() => {
                            openWhatsApp()
                        }}
                    ><IoLogoWhatsapp/></p>
                </div>
            }
        </div>
      </div>
      <div className=' w-[30%] h-fit'>
        <div>
            <h1 className=''>Overview</h1>
            <div className='flex px-5 py-4 justify-between w-full items-center'>
                <div
                    className='bg-[white] text-[.8rem] cursor-pointer relative w-[45%] flex justify-center items-center py-1 rounded-xl'
                    onClick={() => {
                        setIsInbound(true)
                        setIsOutbound(false)
                    }}
                >{isInbound && <p className='w-[1rem] h-[1rem] absolute left-2 bg-[#FFE200] rounded-full'></p>}Inbound</div>
                <div
                    className='bg-[#fff] text-[.8rem] cursor-pointer relative w-[45%] flex justify-center items-center py-1 rounded-xl'
                    onClick={() => {
                        setIsInbound(false)
                        setIsOutbound(true)
                    }}
                >{isOutbound && <p className='w-[1rem] h-[1rem] absolute left-2 bg-[#FFE200] rounded-full'></p>}Outbound</div>
            </div>
            <div className='bg-white rounded-xl px-5 pb-8 py-2 h-[531px] max-h-[531px] scrollbar-hide overflow-scroll'>
                <p>Tracking</p>
                <div className='flex mt-2 justify-between items-center'>
                    <input
                        type="text"
                        placeholder='Advanced search'
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className='bg-[#E1E1E1] h-[40px] outline-none w-[80%] py-2 px-4 rounded-xl'
                        name=""
                        id=""
                    />
                    <p className='p-3 bg-[#E1E1E1] h-[40px] flex justify-center items-center text-[1.6rem] rounded-xl'><BiFilter/></p>
                </div>

                <div className='max-h-[500px] scrollbar-hide overflow-scroll'>
                    {isInbound && filteredInBookings.length > 0 && filteredInBookings.map((item, i) => {
                    return (
                        <>
                            <div
                                key={i}
                                className="mt-6 border-b-[.1rem] pb-4 border-[#b4b4b4] cursor-pointer"
                                onClick={() => {
                                    setSelectedDriver(driverDataInbound[i])
                                    setSelectedBook([item])
                                }}
                            >
                                <div className="flex justify-between items-center">
                                    <div className="flex items-center w-full flex-row">
                                    <p className="text-[.9rem] mr-3 font-bold">{shipperData[i] && shipperData[i].companyName}</p>
                                    <p className="text-[.8rem] mr-3 text-[#6e6e6e]">Trip ID: {item.booking_ref}</p>
                                    {/* <p className="text-[.8rem] text-[#6e6e6e]">Booking ref: {item.booking_ref}</p> */}
                                    </div>
                                </div>
                                {
                                    // Assuming you want to update driverDataOutbound with distance information
                                    driverDataInbound && driverDataInbound[i]?.map((item, i) => {
                                        // Find the drop-off coordinates for the current driver's booking_uid
                                        const dropOffBooking = dropOffCoordinates.find(dropOff => dropOff.booking_uid === item.current_booking_id);
                                        console.log("The driver", dropOffBooking)

                                        if (dropOffBooking) {
                                            // Calculate distance between driver location and drop-off point
                                            const distance = calculateDistance(
                                                item.driver_location.lat,
                                                item.driver_location.lng,
                                                dropOffBooking.coordinates.lat,
                                                dropOffBooking.coordinates.lng
                                            );

                                            // Calculate width based on distance (example: linear mapping)
                                            const maxWidth = 100; // Maximum width when distance is 0
                                            const width = Math.max(maxWidth - (distance * 10), 0); // Adjust multiplier as needed

                                            return (
                                                <div key={i} className="w-full mt-3 h-[.4rem] bg-[grey] rounded-xl">
                                                    <div
                                                        className="bg-[#FFE200] rounded-xl"
                                                        style={{ width: `${0}%` }} // Set the width dynamically
                                                    ></div>
                                                </div>
                                            );
                                        }

                                        return null; // No matching drop-off coordinates found
                                    })

                                }
                            </div>
                        </>
                    );
                    })}

                    {isOutbound && outBoundBookings.length > 0 && outBoundBookings.map((item, i) => {
                        return (
                            <>
                                <div
                                    key={i}
                                    className="mt-6 border-b-[.1rem] pb-4 border-[#b4b4b4] cursor-pointer"
                                    onClick={() => {
                                        setSelectedDriver(driverDataOutbound[i])
                                        setSelectedBook([item])
                                    }}
                                >
                                    <div className="flex justify-between items-center">
                                        <div className="flex items-center w-full flex-row">
                                        <p className="text-[.9rem] mr-3 font-bold">{shipperData[i] && shipperData[i].companyName}</p>
                                        <p className="text-[.8rem] mr-3 text-[#6e6e6e]">Trip ID: {item.booking_ref}</p>
                                        {/* <p className="text-[.8rem] text-[#6e6e6e]">Booking ref: {item.booking_ref}</p> */}
                                        </div>
                                    </div>
                                    {
                                    // Assuming you want to update driverDataOutbound with distance information
                                    driverDataOutbound && driverDataOutbound[i]?.map((item, i) => {
                                        // Find the drop-off coordinates for the current driver's booking_uid
                                        const dropOffBooking = dropOffCoordinates.find(dropOff => dropOff.booking_uid === item.current_booking_id);
                                        console.log("The driver", dropOffBooking)

                                        if (dropOffBooking) {
                                            // Calculate distance between driver location and drop-off point
                                            const distance = calculateDistance(
                                                item.driver_location.lat,
                                                item.driver_location.lng,
                                                dropOffBooking.coordinates.lat,
                                                dropOffBooking.coordinates.lng
                                            );

                                            // Calculate width based on distance (example: linear mapping)
                                            const maxWidth = 100; // Maximum width when distance is 0
                                            const width = Math.max(maxWidth - (distance * 10), 0); // Adjust multiplier as needed

                                            return (
                                                <div key={i} className="w-full mt-3 h-[.4rem] bg-[grey] rounded-xl">
                                                    <div
                                                        className="bg-[#FFE200] rounded-xl"
                                                        style={{ width: `${width}%` }} // Set the width dynamically
                                                    ></div>
                                                </div>
                                            );
                                        }

                                        return null; // No matching drop-off coordinates found
                                    })

                                }
                                </div>
                            </>
                        );
                        })
                    }
                </div>

                {/* {isOutbound && outBoundBookings.length > 0 && outBoundBookings.map((item, i) => {
                // Initialize shipperName as an empty string
                let shipperName = "";
                const shipperId = item.booking_party_uid

                //Get driver details
                const driverIds = item.drivers

                const datadriver = fetchDriverData(driverIds)

                console.log("data driver", datadriver)

                // Fetch shipperName from the database
                firebase
                    .database()
                    .ref("booking_party").child(shipperId)
                    .on("value", (snapshot) => {
                    // Update shipperName with the fetched value
                    const data = snapshot.val();
                    if (data) {
                        shipperName = data.companyName;
                    }
                });

                return (
                    <div
                    key={i}
                    className="mt-6 border-b-[.1rem] pb-4 border-[#b4b4b4] cursor-pointer"
                    onClick={() => setSelectedBook(item)}
                    >
                        <div className="flex justify-between items-center">
                            <div className="flex items-center w-full flex-row">
                            <p className="text-[.9rem] mr-3 font-bold">{shipperName}</p>
                            <p className="text-[.8rem] mr-3 text-[#6e6e6e]">Trip ID: {}</p>
                            <p className="text-[.8rem] text-[#6e6e6e]">Booking ref: {}</p>
                            </div>
                        </div>
                        {datadriver.length > 0 && datadriver.map((item, i) => (
                            <div className="w-full mt-3 h-[.6rem] bg-[grey] rounded-xl">
                                <div className="bg-[#FFE200] rounded-xl w-[50%] h-full"></div>
                            </div>
                        ))
                        }
                    </div>
                );
                })} */}

            </div>
        </div>
      </div>
    </div>
</div>
  )
}

export default PickDropTracking
