import React, { useState, useRef } from 'react'
import { SlOptionsVertical } from 'react-icons/sl'
import { ImUser } from 'react-icons/im'
import { AiFillCamera } from 'react-icons/ai'
import { HiLocationMarker } from 'react-icons/hi'
import { BsFillChatFill } from 'react-icons/bs'
import { IoCallSharp } from 'react-icons/io5'
import { FaEdit } from 'react-icons/fa'
import {useFleetStateContext} from '../context/FleetDealsContext'
import {FaCoins} from 'react-icons/fa'
import {MdLocationPin} from 'react-icons/md'
import {AiFillClockCircle} from 'react-icons/ai'
import {BiEdit} from 'react-icons/bi'
import { RiSteering2Fill } from 'react-icons/ri'
import { BiCopy } from 'react-icons/bi'
import { useStateContext } from '../context/DashboardStateContext'
import firebase from '../firebase-config'

export default function DriverDetailsPage() {
  const [updateId, setUpdateId] = useState("");
  const [updateIdUrl, setUpdateIdUrl] = useState("");
  const [updateLicense, setupdateLicense] = useState("");
  const [updateLicenseUrl, setUpdateLicenseUrl] = useState("");
  const [isTextCopied, setIsTextCopied] = useState(false);
  const [letterLoader,setLetterLoader] = useState(false);

  const fileInputRefOne = useRef(null);

  const {
    selectDriver,
    setSelectDriver,
    addDriversData,
    setAddDriversData,
  } = useFleetStateContext();

  const {
    userUid
  } = useStateContext();

  //for uploading profile image
  const handleProfileUpload = (file) => {
    const storage = firebase.storage()
    setLetterLoader(true);
    if(file === null) return
    const fileRefId = storage.ref(`files/${userUid}/profileImage`)
    // setIdUpload(file.name)
    fileRefId.put(file).then((snapshot) => {
        const fileUrl = fileRefId.getDownloadURL()
        .then((url) => {
          firebase.database().ref("booking_party").child(userUid).update({
            profileImageUrl: url
          })
          setLetterLoader(false);
          fileInputRefOne.current.value = null;
        })
        .catch((error) => {
        });
    });
  }

  // console.log("the selected driver", selectDriver)

  const updateIdFnc = (e, file) => {
    e.preventDefault()
    if(file.name){
      const storage = firebase.storage()
      if(file === null) return
      const fileRefUpdateUrl = storage.ref(`drivers_identity_doc/${file?.name}`)
      setUpdateId(file.name)
      fileRefUpdateUrl.put(file).then((snapshot) => {
          const fileUrl = fileRefUpdateUrl.getDownloadURL()
          .then((url) => {

            setUpdateIdUrl(url)
            firebase.database().ref("/drivers/" + selectDriver[0].uid).update({
              identity_pdf: url
            })
          })
              .catch((error) => {
              });
      });
    }
  }

  const updateLicenseFnc = (e, file) => {
    e.preventDefault()
    if(file.name){
      const storage = firebase.storage()
      if(file === null) return
      const fileRefUpdateUrl = storage.ref(`drivers_licence_doc/${file.name}`)
      setupdateLicense(file.name)
      fileRefUpdateUrl.put(file).then((snapshot) => {
          const fileUrl = fileRefUpdateUrl.getDownloadURL()
          .then((url) => {
            setUpdateLicenseUrl(url)
            firebase.database().ref("/drivers/" + selectDriver[0].uid).update({
              licence_pdf: url
            })
          })
              .catch((error) => {
          });
      });
    }
  }

  const copyToClip = () => {
    navigator.clipboard.writeText(`${selectDriver[0].address.street_name}, ${selectDriver[0].address.province}, ${selectDriver[0].address.city}, ${selectDriver[0].address.postal_code}`);
    setIsTextCopied(true);
    setTimeout(() => {
      setIsTextCopied(false);
    }, 2000)
  }

  return (
    <div className='rounded-lg flex scrollbar-hide drivers-details-mobile' style={{width:"68%", background:"#fff", height:"100%", maxHeight:"100%", overflow:"scroll"}}>
        <div className='p-4' style={{width:"65%"}}>
            <p>Performance</p>
            <div className='p-3' style={{height:"", paddingTop:"8rem"}}>
                <div className='w-full mb-5'>
                  <div className='h-2 rounded-xl w-full' style={{background:"#CBCBCB"}}>
                      <div className='h-full rounded-xl' style={{width:"16%", background:"#C91818"}}></div>
                  </div>
                  <div className='pt-1 flex justify-between'>
                    <p style={{fontSize:".8rem"}}>On time delivery</p>
                    <p style={{fontSize:".8rem"}}>16%</p>
                  </div>
                </div>

                <div className='w-full mb-5'>
                  <div className='h-2 rounded-xl w-full' style={{background:"#CBCBCB"}}>
                      <div className='h-full rounded-xl' style={{width:"100%", background:"#5BBB1C"}}></div>
                  </div>
                  <div className='pt-1 flex justify-between'>
                    <p style={{fontSize:".8rem"}}>Response time</p>
                    <p style={{fontSize:".8rem"}}>100%</p>
                  </div>
                </div>

                <div className='w-full mb-5'>
                  <div className='h-2 rounded-xl w-full' style={{background:"#CBCBCB"}}>
                      <div className='h-full rounded-xl' style={{width:"40%", background:"#5BBB1C"}}></div>
                  </div>
                  <div className='pt-1 flex justify-between'>
                    <p style={{fontSize:".8rem"}}>Average stops</p>
                    <p style={{fontSize:".8rem"}}>12 Stops per trip</p>
                  </div>
                </div>

                <div className='flex justify-between pt-5'>
                  <div className='flex justify-center items-center flex-col'>
                    <div className='rounded-full flex justify-center items-center' style={{color:"#fff", width:"55px", height:"55px", background:"#C91818", fontSize:".8rem"}}>30</div>
                    <p className='pt-1'>Reports</p>
                  </div>

                  <div className='flex justify-center items-center flex-col'>
                    <div className='rounded-full flex justify-center items-center' style={{color:"#fff", width:"55px", height:"55px", background:"#873B01", fontSize:".8rem"}}>99+</div>
                    <p className='pt-1'>Stops</p>
                  </div>

                  <div className='flex justify-center items-center flex-col'>
                    <div className='rounded-full flex justify-center items-center' style={{color:"#fff", width:"55px", height:"55px", background:"#162659", fontSize:".8rem"}}>8hrs</div>
                    <p className='pt-1'>Av. Rest Time</p>
                  </div>

                  <div className='flex justify-center items-center flex-col'>
                    <div className='rounded-full flex justify-center items-center' style={{color:"#fff", width:"55px", height:"55px", background:"#19A41E", fontSize:".8rem"}}>R27k</div>
                    <p className='pt-1'>Av. Income</p>
                  </div>
                </div>

                <div className='flex justify-between pt-5'>
                  <div className='rounded-xl p-2 flex justify-between text-center items-center flex-col' style={{width:"6rem", height:"7rem", background:"#515151", color:"#fff"}}>
                    <div className='w-full flex justify-between items-end flex-col'>
                        <p className='font-bold' style={{fontSize:"1.5rem"}}><RiSteering2Fill/></p>
                        <p className='pt-2 font-bold' style={{fontSize:"1rem", textAlign:"end"}}>250 032</p>
                        <span style={{fontSize:".8rem"}}>km</span>
                    </div>
                    <div >
                        <p className='p-0 m-0' style={{fontSize:".7rem", textAlign:"center"}}>Distance Driven</p>
                    </div>
                  </div>

                  <div className='rounded-xl p-2 flex justify-between text-center items-center flex-col' style={{width:"6rem", height:"7rem", background:"#19A41E", color:"#fff"}}>
                    <div className='w-full flex justify-between items-end flex-col'>
                        <p className='font-bold' style={{fontSize:"1rem"}}><FaCoins/></p>
                        <p className='pt-2 font-bold' style={{fontSize:"1rem", textAlign:"end"}}>R70.50</p>
                    </div>
                    <div >
                        <p className='p-0 m-0' style={{fontSize:".7rem"}}>Rate/Kilometre</p>
                    </div>
                  </div>

                  <div className='rounded-xl p-2 flex justify-between text-center items-center flex-col' style={{width:"6rem", height:"7rem", background:"#43781F", color:"#fff"}}>
                    <div className='w-full flex justify-between items-end flex-col'>
                        <p className='font-bold' style={{fontSize:"1.3rem"}}><MdLocationPin/></p>
                        <p className='pt-2 font-bold' style={{fontSize:"1rem", textAlign:"end"}}>2 343</p>
                    </div>
                    <div >
                        <p className='p-0 m-0' style={{fontSize:".7rem"}}>Rate/Kilometre</p>
                    </div>
                  </div>

                  <div className='rounded-xl p-2 flex justify-between text-center items-center flex-col' style={{width:"6rem", height:"7rem", background:"#126C70", color:"#fff"}}>
                    <div className='w-full flex justify-between items-end flex-col'>
                        <p className='font-bold' style={{fontSize:"1.3rem", color:"#fff"}}><AiFillClockCircle/></p>
                        <p className='pt-2 font-bold' style={{fontSize:"1rem", textAlign:"end"}}>23 098</p>
                        <span style={{fontSize:".8rem"}}>Hour</span>
                    </div>
                    <div >
                        <p className='p-0 m-0' style={{fontSize:".7rem"}}>Work Duration</p>
                    </div>
                  </div>
                </div>
            </div>
        </div>
        <div className='p-4 scrollbar-hide relative' style={{width:"35%", height:"100%", overflow:"scroll"}}>
            <div className='flex justify-between'>
              <p className='p-2 rounded-xl py-1 px-3' style={{background:"#fce200"}}>On Duty</p>
              <p className='hover:bg-gray-200 cursor-pointer rounded-full flex justify-center items-center' style={{width:"1.5rem", height:"1.5rem"}} ><SlOptionsVertical/></p>
            </div>
            <input
              type="file"
              style={{ position: 'absolute', top: 0, left: 0, opacity: 0, zIndex: -1 }}
              onChange={(e) => handleProfileUpload(e.target.files[0])}

              ref={fileInputRefOne} // Assigning the ref to the fileInputRef variable
            />
            <div
                className='flex rounded-full w-full justify-center m-auto items-center relative'
                style={{background:"#DCDCDC", width:"6rem", height:"6rem"}}
            >
                {selectDriver[0].photo_uri ?
                  <img className='w-full h-full rounded-full' style={{objectFit:"cover"}} src={selectDriver[0].photo_uri}  />
                  : <p className='' style={{fontSize:"4.5rem", color:"#fff"}}><ImUser/></p>
                }
                <p
                    className='absolute right-1 bottom-0 cursor-pointer h-6 w-6 flex justify-center items-center rounded-full'
                    style={{fontSize:"1rem", background:"#fce200"}}
                    onClick={() =>{
                    }}
                >
                    <AiFillCamera/>
                </p>
            </div>
            <div className='flex flex-col justify-center items-center'>
              <p className='mt-3 font-bold' style={{fontSize:"1.1rem"}}>{selectDriver[0].name} {selectDriver[0].surname}</p>
              <p className='mt-1' style={{fontSize:".9rem"}}>{selectDriver[0].phone}</p>
              <p className='' style={{fontSize:".7rem", color:"#818181"}}>Enrolled 20/05/2022</p>
              <p className='' style={{fontSize:".7rem", color:"#818181"}}>0 Trips - 0 km - 0 days</p>
            </div>
            <div className='flex mt-2 justify-center items-center mb-3'>
              <div className='P-2 flex justify-center items-center rounded-full mr-6' style={{fontSize:"1rem", width:"1.2rem", height:"1.2rem", border:"1px solid"}}><p className='flex m-0 justify-center items-center'><HiLocationMarker/></p></div>
              <div className='P-2 flex justify-center items-center rounded-full mr-6' style={{fontSize:"1rem", width:"1.2rem", height:"1.2rem", border:"1px solid"}}><p className='flex m-0 justify-center items-center'><BsFillChatFill/></p></div>
              <div className='P-2 flex justify-center items-center rounded-full mr-6' style={{fontSize:"1rem", width:"1.2rem", height:"1.2rem", border:"1px solid"}}><p className='flex m-0 justify-center items-center'><IoCallSharp/></p></div>
            </div>
            <div className='scrollbar-hide'style={{overflow:"scroll", height:"340px"}}>
                <div className='mt-3 w-full relative flex justify-start flex-col items-start rounded-lg p-3 bg-white' style={{background:"#EBEBEB"}}>
                    <p className='' style={{color:"#818181"}}>Physical Address</p>
                    {selectDriver.length ?
                        <div className='w-full flex justify-between items-center'><p style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          width: "180px",
                          height: "1.2em",
                          textAlign:"start",
                          whiteSpace: "nowrap",
                          fontSize:".9rem",
                          fontWeight:"bold",
                        }}>
                          {`${selectDriver[0].address.street_name}, ${selectDriver[0].address.province}, ${selectDriver[0].address.city}, ${selectDriver[0].address.postal_code}`}  </p> <p onClick={copyToClip} className='cursor-pointer'><BiCopy/></p></div>
                        :""
                    }
                    {selectDriver.length ?
                      <p style={{color:"#818181"}}>{selectDriver[0].address.province}, {selectDriver[0].address.country}</p>
                      : ""
                    }
                    {isTextCopied &&
                      <div className='absolute top-0 right-0 bottom-0 left-0 h-full w-full flex items-center justify-center'>
                        <div className='py-3 px-5 rounded-xl flex items-center justify-center flex-col' style={{width:"fit-content", height:"fit-content", background:"#fff"}}>
                            <p className='p-0 m-0' style={{fontSize:"1rem", textAlign:"center"}}>Text Copied</p>
                        </div>
                      </div>
                    }
                </div>

                <div className='mt-3 w-full flex justify-start flex-col items-start rounded-lg p-3 bg-white' style={{background:"#EBEBEB"}}>
                    <p className='' style={{color:"#818181"}}>License Number</p>
                    <p style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "180px",
                        height: "1.2em",
                        whiteSpace: "nowrap",
                        textAlign:"start",
                        fontSize:"1rem"
                    }}>{selectDriver[0].licence_info.licence_number}</p>
                    <p style={{color:"#818181"}}>License Code</p>
                    <p style={{fontSize:"1rem"}}>{selectDriver[0].licence_info.licence_code}</p>
                    <div className='flex justify-between w-full'>
                      <div>
                        <p style={{color:"#818181", textAlign:"start"}}>Start date</p>
                        <p style={{fontSize:".9rem"}}>{selectDriver[0].licence_info.start_date}</p>
                      </div>
                      <div>
                        <p style={{color:"#818181", textAlign:"start"}}>Start date</p>
                        <p style={{fontSize:".9rem"}}>{selectDriver[0].licence_info.end_date}</p>
                      </div>
                    </div>
                </div>

                <div className='mt-3 w-full flex justify-start flex-col items-start rounded-lg p-3 bg-white' style={{background:"#EBEBEB"}}>
                    <p className='' style={{color:"#818181"}}>Documents</p>
                    {selectDriver[0].identity_pdf &&
                      <a target='_blank' href={selectDriver[0].identity_pdf} className='flex justify-between rounded-xl mt-2 items-center w-full p-2' style={{background:"rgb(246 246 246)"}}>
                        <p className='font-bold'>ID/Passport</p>
                        <label  className='cursor-pointer' style={{color:"blue"}}  htmlFor="idUpdate">
                          <input
                              type="file"
                              onChange={(e) => updateIdFnc(e, e.target.files[0])}
                              id='idUpdate'
                              hidden
                              accept='image/*, .pdf, .doc, .docx'
                          />
                          <p style={{color:"blue", fontSize:".8rem"}}><FaEdit/></p>
                        </label>
                      </a>
                    }

                    {selectDriver[0].licence_pdf &&
                      <a target='_blank' href={selectDriver[0].identity_pdf} className='flex justify-between rounded-xl mt-2 items-center w-full p-2' style={{background:"rgb(246 246 246)"}}>
                        <p className='font-bold'>License</p>
                        <label  className='cursor-pointer' style={{color:"blue"}}  htmlFor="licenseUpdate">
                          <input
                              type="file"
                              onChange={(e) => updateLicenseFnc(e, e.target.files[0])}
                              id='licenseUpdate'
                              hidden
                              accept='image/*, .pdf, .doc, .docx'
                          />
                          <p style={{color:"blue", fontSize:".8rem"}}><FaEdit/></p>
                        </label>
                      </a>
                    }
                </div>

                <div className='flex w-full justify-center flex-col items-center text-center'>
                  <div className='mt-3 w-full flex justify-start flex-col items-start rounded-lg p-3 bg-white' style={{background:"#EBEBEB"}}>
                      <p className='' style={{color:"#818181"}}>Vehicle</p>
                      <p>{selectDriver[0].horse_id || ""}</p>
                      <p className='' style={{color:"#818181"}}>Trailer</p>
                      {selectDriver[0].trailer_id ?
                        <p className='relative' style={{width:"fit-content"}}>{selectDriver[0]?.trailer_id[0]} <span className='p-2 h-3 w-3 flex items-center justify-center absolute left-16 bottom-0 rounded-full' style={{background:"#126c70", color:"#fff"}}>{selectDriver[0].trailer_id[1] !== "" ? selectDriver[0].trailer_id.length : 1}</span></p>
                      :""
                      }
                  </div>
                </div>
            </div>

        </div>
    </div>
  )
}
