import React, { useEffect, useState } from 'react'
import emptyIcon from '../icons/box.png';
import firebase from '../firebase-config'

const NewOffer = ({fleetIds, selectedBook, onUpdateSelectedBook}) => {
  const [Offers, setOffers] = useState([])
  const [allFleets, setAllFleets] = useState([])
  const [settleAmount, setSettleAmount] = useState('')
  const [driverAssigned, setDriverAssigned] = useState([])
  const [accepted, setAccepted] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // Function to fetch and update offers
  const fetchAndUpdateOffers = () => {
    if (selectedBook && selectedBook.booking_bids_fleet_id) {
      const selectedBookFleetID = selectedBook.booking_bids_fleet_id;
      setAllFleets(selectedBookFleetID);

      const dataFetchPromises = selectedBookFleetID.map(fleet => {
        return new Promise((resolve, reject) => {
          const ref = firebase.database().ref(`/fleets/${fleet}/booking_bids/${selectedBook.booking_id}`);
          ref.once('value', snapshot => {
            resolve(snapshot.val());
          }, reject);
        });
      });

      Promise.all(dataFetchPromises)
        .then(fetchedOffers => {
          const combinedOffers = fetchedOffers.filter(offer => offer != null);
          setOffers(combinedOffers);
        })
        .catch(error => {
          console.error("Error fetching data:", error);
        });
    } else {
      setOffers([]);
    }
  };

  const handleSettleClick = (fleet) => {
    if (isButtonDisabled) return; // Prevent further action if the button is already disabled

    setIsButtonDisabled(true); // Disable the button

    const fleetRef = firebase.database().ref(`/fleets/${fleet}/booking_bids/${selectedBook.booking_id}`);
    fleetRef.transaction((data) => {
      if (!data) {
        data = {}; // Initialize data if it doesn't exist
      }
      if (!data.price) {
        data.price = {}; // Initialize price if it doesn't exist
      }
      const prices = data.price;
      const nextIndex = Object.keys(prices).length;
      // Append settleAmount to the prices list
      prices[nextIndex] = settleAmount;
      // Update the existing data with the modified prices list
      data.price = prices;
      return data; // Return the updated data
    }).then(() => {
      fetchAndUpdateOffers(); // Re-fetch offers after update
      setIsButtonDisabled(false); // Re-enable the button
    });
  };

  useEffect(() => {
    fetchAndUpdateOffers();
  }, [selectedBook]);

  const handleAccept = (fleet) => {
    setIsButtonDisabled(true); // Disable the button when accept is clicked

    const fleetRef = firebase.database().ref(`/fleets/${fleet}/booking_bids/${selectedBook.booking_id}`);
    fleetRef.update({ accepted: true });

    const bookingRef = firebase.database().ref(`/booking/${selectedBook.booking_id}`);
    bookingRef.update({
      AmountAccepted: selectedBook.rate_required,
      accepted: true
    });
    // Update the local state to reflect the acceptance
    setAccepted(true);

    // Update the parent component's state
    onUpdateSelectedBook({ ...selectedBook, accepted: true, AmountAccepted: selectedBook.rate_required });
    console.log("The selected booking", selectedBook)
  };


  return (
    <>
      {!selectedBook?.booking_bids_fleet_id &&  !selectedBook?.accepted && !driverAssigned?.length > 0?
        <div className='dash-date' style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            top: '30%',
            height: 'fit-content',
            marginTop: '4%',
            marginBottom: '4%',
            textAlign: 'center',
            fontSize: '13px'
          }}>
            <img src={emptyIcon} width={70} height={70} alt='No deals' />
            No offers at the moment
        </div>
        :
        <>
        {selectedBook?.booking_bids_fleet_id &&
          // ==========================================================BOOKINGS===========================================================================================
          allFleets?.map((fleet, i) => (
            <>
              {Offers[0]?.price?.length === 1 && !selectedBook?.accepted &&
                <div className='w-full h-fit flex relative justify-between bg-[#f5f5f5] mt-2' style={{padding:"10px 10px 10px 30px", borderRadius:"18px"}}>
                  <div className='absolute left-0 right-0 bottom-0 w-[17px] h-full bg-[#fce303] top-0' style={{borderRadius:"18px 0px 0px 18px"}}></div>
                  <div className=''>
                    <h1>{selectedBook.booking_ref}</h1>
                    <p>10 loads offered</p>
                    <p>Transporter Quotation: R{Offers[0]?.price}</p>
                  </div>
                  <div className='flex'>
                    <div className='w-[200px] flex bg-[#E8E8E8] border h-[28px] rounded-full'>
                      <div className='w-[50%] h-full'>
                        <input
                          type="text"
                          className='rounded-s-full bg-[inherit]  h-full w-full border-none text-[.8rem] px-3'
                          placeholder='Amount (R)'
                          onChange={(e) => {
                            setSettleAmount(e.target.value)
                          }}
                        />
                      </div>
                      <div
                        className={`w-[50%] h-full flex text-[.9rem] justify-center items-center bg-[#fff] border-solid border-black border-[1px] cursor-pointer rounded-e-full ${isButtonDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                        onClick={() => handleSettleClick(fleet)}
                      >
                        Settle
                      </div>
                    </div>
                    <div
                      className='w-[100px] ml-5 cursor-pointer flex h-[25px] text-[.9rem] rounded-full justify-center items-center bg-[#fce303]'
                      onClick={() => handleAccept(fleet)}
                    >
                      Accept
                    </div>
                  </div>
                </div>
              }

              {Offers[0]?.price?.length === 2 && !selectedBook?.accepted &&
                <div className='w-full h-fit flex relative justify-between bg-[#f5f5f5] mt-2' style={{padding:"10px 10px 10px 30px", borderRadius:"18px"}}>
                  <div className='absolute left-0 right-0 bottom-0 w-[17px] h-full bg-[#213164] top-0' style={{borderRadius:"18px 0px 0px 18px"}}></div>
                  <div className=''>
                    <h1>{selectedBook?.booking_ref}</h1>
                    <p>10 loads offered</p>
                    <p>Transporter Quotation: R{Offers[0]?.price[0]}</p>
                    <p>Counter Offer: <b>R{Offers[0]?.price[1]}</b></p>
                  </div>
                  <div className='h-full flex flex-col justify-end'>
                    <p className='h-[50%]'>Rate submitted</p>
                  </div>
                </div>
              }

              {Offers[0]?.price?.length === 3 && !selectedBook?.accepted &&
                <div className='w-full h-fit flex relative justify-between bg-[#f5f5f5] mt-2' style={{padding:"10px 10px 10px 30px", borderRadius:"18px"}}>
                    <div className='absolute left-0 right-0 bottom-0 w-[17px] h-full bg-[#213164] top-0' style={{borderRadius:"18px 0px 0px 18px"}}></div>
                    <div className=''>
                      <h1>{selectedBook.booking_ref}</h1>
                      <p>10 loads offered</p>
                      <p>Transporter Quotation: R{Offers[0]?.price[0]}</p>
                      <p>Counter Offer: R{Offers[0]?.price[1]}</p>
                      <p>Final Offer: <b>R{Offers[0]?.price[2]}</b></p>
                    </div>
                    <div className='flex'>
                      <div className='w-[100px] ml-5 bg-[E8E8E8] cursor-pointer flex h-[25px] text-[.9rem] rounded-full border-solid border-black border-[1px] justify-center items-center bg-[#fff]'>Decline</div>
                      <div className='w-[100px] ml-5 bg-[E8E8E8] cursor-pointer flex h-[25px] text-[.9rem] rounded-full justify-center items-center bg-[#fce303]'>Accept</div>
                    </div>
                  </div>
              }
            </>
          ))
        }
      </>
      }
      {
        (driverAssigned?.length > 0  ||
        selectedBook?.accepted) &&
        <div className='w-full h-fit flex relative justify-between bg-[#f5f5f5] mt-2' style={{padding:"10px 10px 10px 30px", borderRadius:"18px"}}>
          <div className='absolute left-0 right-0 bottom-0 w-[17px] h-full bg-[#1d9f00] top-0' style={{borderRadius:"18px 0px 0px 18px"}}></div>
            <div className=''>
              <h1>{selectedBook?.booking_ref}</h1>
              <p>10 loads booked</p>
              <p>Confirmed Net Rate: R{selectedBook?.AmountAccepted}</p>
              <p>Value Added Tax: R{(selectedBook?.AmountAccepted * (15/100)).toFixed(2)}</p>
              <p>Total: <b>R{parseFloat(selectedBook?.AmountAccepted * (15/100)) + parseFloat(selectedBook?.AmountAccepted)}</b></p>
            </div>
        </div>
        }
    </>
  )
}

export default NewOffer