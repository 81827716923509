import React from 'react'
import { useStateContextBookings } from '../context/AllBookingsContext'

export default function MonthlyComponent() {

    const {
        getJanuaryMonthlyBookings,
        getFebruaryMonthlyBookings,
        getMarchMonthlyBookings,
        getAprilMonthlyBookings,
        getMayMonthlyBookings,
        getJuneMonthlyBookings,
        getJulyMonthlyBookings,
        getAugustMonthlyBookings,
        getSeptemberMonthlyBookings,
        getOctoberMonthlyBookings,
        getNovemberMonthlyBookings,
        getDecemberMonthlyBookings,
        allOfTheBooking
    } = useStateContextBookings();
    var counter = 0;

    const januaryYear = getJanuaryMonthlyBookings.length > 0
        ? new Date(getJanuaryMonthlyBookings[0].date_created).getFullYear()
        : (new Date()).getFullYear();

    const februaryYear = getFebruaryMonthlyBookings.length > 0
        ? new Date(getFebruaryMonthlyBookings[0].date_created).getFullYear()
        : (new Date()).getFullYear();

    const marchYear = getMarchMonthlyBookings.length > 0
        ? new Date(getMarchMonthlyBookings[0].date_created).getFullYear()
        : (new Date()).getFullYear();

    const aprilYear = getAprilMonthlyBookings.length > 0
        ? new Date(getAprilMonthlyBookings[0].date_created).getFullYear()
        : (new Date()).getFullYear();

    const mayYear = getMayMonthlyBookings.length > 0
        ? new Date(getMayMonthlyBookings[0].date_created).getFullYear()
        : (new Date()).getFullYear();

    const juneYear = getJuneMonthlyBookings.length > 0
        ? new Date(getJuneMonthlyBookings[0].date_created).getFullYear()
        : (new Date()).getFullYear();

    const julyYear = getJulyMonthlyBookings.length > 0
        ? new Date(getJulyMonthlyBookings[0].date_created).getFullYear()
        : (new Date()).getFullYear();

    const augustYear = getAugustMonthlyBookings.length > 0
        ? new Date(getAugustMonthlyBookings[0].date_created).getFullYear()
        : (new Date()).getFullYear();

    const septemberYear = getSeptemberMonthlyBookings.length > 0
        ? new Date(getSeptemberMonthlyBookings[0].date_created).getFullYear()
        : (new Date()).getFullYear();

    const octoberYear = getOctoberMonthlyBookings.length > 0
        ? new Date(getOctoberMonthlyBookings[0].date_created).getFullYear()
        : (new Date()).getFullYear();

    const novemberYear = getNovemberMonthlyBookings.length > 0
        ? new Date(getNovemberMonthlyBookings[0].date_created).getFullYear()
        : (new Date()).getFullYear();

    const DecemberYear = getDecemberMonthlyBookings.length > 0
        ? new Date(getDecemberMonthlyBookings[0].date_created).getFullYear()
        : (new Date()).getFullYear();

  return (
    <div className='dash-date' style={{display:`${allOfTheBooking.length ? "block" : "none"}`}}>
        <div className='dash-date-title' style={{display: `${!getJanuaryMonthlyBookings.length > 0 ? "none" : "block"}`}}>
            <h2>January {januaryYear}</h2>
            <div className='w-full h-6 px-[22px] bg-black flex text-white justify-between'>
                <div className='left-book-name'>
                    <p className='m-0 p-0'>Cargo</p>
                </div>
                <div className='right-dash-name'>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Vehicle</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Route</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Reference</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Status</p>
                    </div>
                </div>
            </div>
            <div className='booking-dash' style={{height: `${!getFebruaryMonthlyBookings.length > 0 && getJanuaryMonthlyBookings.length > 3 ? " 121px 200px" :""}`, maxHeight: `${!getFebruaryMonthlyBookings.length > 0 && getJanuaryMonthlyBookings.length > 3 ? "200px" :"121px"}`}}>
                {getJanuaryMonthlyBookings.length > 0 && getJanuaryMonthlyBookings.map((booking, i) => (
                    <div className='book-name' key={i}>
                        <div className='left-book-name'>
                            <h2 style={{fontWeight:"bold", marginRight:"15px"}}>{counter += 1}</h2>
                            <p style={{fontSize:"12px", textAlign:"center", fontSize:"11px", marginBottom:"0"}}>{booking.cargoInformation.productName}</p>
                        </div>
                        <div className='right-dash-name'>
                            <div style={{width:"100px"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{booking.cargoInformation.vehicle_type}</p>
                            </div>
                            <div style={{width:"100px", textAlign:"end"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{((booking.puDetails.puCityName).substring(0,3)).toUpperCase()} - {((booking.doDetails.doCityName).substring(0,3)).toUpperCase()}</p>
                            </div>
                            <div style={{width:"100px"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{booking.booking_ref}</p>
                            </div>
                            <div style={{width:"100px"}}>
                                <p style={{ marginBottom:"0", color:"#f90500", fontWeight:"bold", textAlign:"end"}}>{booking?.undelivered_loads === 0 ? "delivered" : "Not delivered"}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>

        <div className='dash-date-title' style={{display: `${!getFebruaryMonthlyBookings.length > 0 ? "none" : "block"}`}}>
            <h2>February {februaryYear}</h2>
            <div className='w-full h-6 px-[22px] bg-black flex text-white justify-between'>
                <div className='left-book-name'>
                    <p className='m-0 p-0'>Cargo</p>
                </div>
                <div className='right-dash-name'>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Vehicle</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Route</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Reference</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Status</p>
                    </div>
                </div>
            </div>
            <div className='booking-dash' style={{height: `${!getFebruaryMonthlyBookings.length > 0 && getFebruaryMonthlyBookings.length > 3 ? " 121px 200px" :""}`, maxHeight: `${!getFebruaryMonthlyBookings.length > 0 && getFebruaryMonthlyBookings.length > 3 ? "200px" :"121px"}`}}>
                {getFebruaryMonthlyBookings.length > 0 && getFebruaryMonthlyBookings.map((booking, i) => (
                    <div className='book-name' key={i}>
                        <div className='left-book-name'>
                            <h2 style={{fontWeight:"bold", marginRight:"15px"}}>{counter += 1}</h2>
                            <p style={{fontSize:"12px", textAlign:"center", fontSize:"11px", marginBottom:"0"}}>{booking.cargoInformation.productName}</p>
                        </div>
                        <div className='right-dash-name'>
                            <div style={{width:"100px"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{booking.cargoInformation.vehicle_type}</p>
                            </div>
                            <div style={{width:"100px", textAlign:"end"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{((booking.puDetails.puCityName).substring(0,3)).toUpperCase()} - {((booking.doDetails.doCityName).substring(0,3)).toUpperCase()}</p>
                            </div>
                            <div style={{width:"100px"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{booking.booking_ref}</p>
                            </div>
                            <div style={{width:"100px"}}>
                                <p style={{ marginBottom:"0", color:"#f90500", fontWeight:"bold", textAlign:"end"}}>{booking?.undelivered_loads === 0 ? "delivered" : "Not delivered"}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>

        <div className='dash-date-title' style={{display: `${!getMarchMonthlyBookings.length > 0 ? "none" : "block"}`}}>
            <h2>March {marchYear}</h2>
            <div className='w-full h-6 px-[22px] bg-black flex text-white justify-between'>
                <div className='left-book-name'>
                    <p className='m-0 p-0'>Cargo</p>
                </div>
                <div className='right-dash-name'>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Vehicle</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Route</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Reference</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Status</p>
                    </div>
                </div>
            </div>
            <div className='booking-dash' style={{height: `${!getFebruaryMonthlyBookings.length > 0 && getMarchMonthlyBookings.length > 3 ? " 121px 200px" :""}`, maxHeight: `${!getMarchMonthlyBookings.length > 0 && getMarchMonthlyBookings.length > 3 ? "200px" :"121px"}`}}>
                {getMarchMonthlyBookings.length > 0 && getMarchMonthlyBookings.map((booking, i) => (
                    <div className='book-name' key={i}>
                        <div className='left-book-name'>
                            <h2 style={{fontWeight:"bold", marginRight:"15px"}}>{counter += 1}</h2>
                            <p style={{fontSize:"12px", textAlign:"center", fontSize:"11px", marginBottom:"0"}}>{booking.cargoInformation.productName}</p>
                        </div>
                        <div className='right-dash-name'>
                            <div style={{width:"100px"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{booking.cargoInformation.vehicle_type}</p>
                            </div>
                            <div style={{width:"100px", textAlign:"end"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{((booking.puDetails.puCityName).substring(0,3)).toUpperCase()} - {((booking.doDetails.doCityName).substring(0,3)).toUpperCase()}</p>
                            </div>
                            <div style={{width:"100px"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{booking.booking_ref}</p>
                            </div>
                            <div style={{width:"100px"}}>
                                <p style={{ marginBottom:"0", color:"#f90500", fontWeight:"bold", textAlign:"end"}}>{booking?.undelivered_loads === 0 ? "delivered" : "Not delivered"}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>

        <div className='dash-date-title' style={{display: `${!getAprilMonthlyBookings.length > 0 ? "none" : "block"}`}}>
            <h2>April {aprilYear}</h2>
            <div className='w-full h-6 px-[22px] bg-black flex text-white justify-between'>
                <div className='left-book-name'>
                    <p className='m-0 p-0'>Cargo</p>
                </div>
                <div className='right-dash-name'>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Vehicle</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Route</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Reference</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Status</p>
                    </div>
                </div>
            </div>
            <div className='booking-dash' style={{height: `${!getFebruaryMonthlyBookings.length > 0 && getAprilMonthlyBookings.length > 3 ? " 121px 200px" :""}`, maxHeight: `${!getAprilMonthlyBookings.length > 0 && getAprilMonthlyBookings.length > 3 ? "200px" :"121px"}`}}>
                {getAprilMonthlyBookings.length > 0 && getAprilMonthlyBookings.map((booking, i) => (
                    <div className='book-name' key={i}>
                        <div className='left-book-name'>
                            <h2 style={{fontWeight:"bold", marginRight:"15px"}}>{counter += 1}</h2>
                            <p style={{fontSize:"12px", textAlign:"center", fontSize:"11px", marginBottom:"0"}}>{booking.cargoInformation.productName}</p>
                        </div>
                        <div className='right-dash-name'>
                            <div style={{width:"100px"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{booking.cargoInformation.vehicle_type}</p>
                            </div>
                            <div style={{width:"100px", textAlign:"end"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{((booking.puDetails.puCityName).substring(0,3)).toUpperCase()} - {((booking.doDetails.doCityName).substring(0,3)).toUpperCase()}</p>
                            </div>
                            <div style={{width:"100px"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{booking.booking_ref}</p>
                            </div>
                            <div style={{width:"100px"}}>
                                <p style={{ marginBottom:"0", color:"#f90500", fontWeight:"bold", textAlign:"end"}}>{booking?.undelivered_loads === 0 ? "delivered" : "Not delivered"}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>

        <div className='dash-date-title' style={{display: `${!getMayMonthlyBookings.length > 0 ? "none" : "block"}`}}>
            <h2>May {mayYear}</h2>
            <div className='w-full h-6 px-[22px] bg-black flex text-white justify-between'>
                <div className='left-book-name'>
                    <p className='m-0 p-0'>Cargo</p>
                </div>
                <div className='right-dash-name'>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Vehicle</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Route</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Reference</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Status</p>
                    </div>
                </div>
            </div>
            <div className='booking-dash' style={{height: `${!getFebruaryMonthlyBookings.length > 0 && getMayMonthlyBookings.length > 3 ? " 121px 200px" :""}`, maxHeight: `${!getMayMonthlyBookings.length > 0 && getMayMonthlyBookings.length > 3 ? "200px" :"121px"}`}}>
                {getMayMonthlyBookings.length > 0 && getMayMonthlyBookings.map((booking, i) => (
                    <div className='book-name' key={i}>
                        <div className='left-book-name'>
                            <h2 style={{fontWeight:"bold", marginRight:"15px"}}>{counter += 1}</h2>
                            <p style={{fontSize:"12px", textAlign:"center", fontSize:"11px", marginBottom:"0"}}>{booking.cargoInformation.productName}</p>
                        </div>
                        <div className='right-dash-name'>
                            <div style={{width:"100px"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{booking.cargoInformation.vehicle_type}</p>
                            </div>
                            <div style={{width:"100px", textAlign:"end"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{((booking.puDetails.puCityName).substring(0,3)).toUpperCase()} - {((booking.doDetails.doCityName).substring(0,3)).toUpperCase()}</p>
                            </div>
                            <div style={{width:"100px"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{booking.booking_ref}</p>
                            </div>
                            <div style={{width:"100px"}}>
                                <p style={{ marginBottom:"0", color:"#f90500", fontWeight:"bold", textAlign:"end"}}>{booking?.undelivered_loads === 0 ? "delivered" : "Not delivered"}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>

        <div className='dash-date-title' style={{display: `${!getJuneMonthlyBookings.length > 0 ? "none" : "block"}`}}>
            <h2>June {juneYear}</h2>
            <div className='w-full h-6 px-[22px] bg-black flex text-white justify-between'>
                <div className='left-book-name'>
                    <p className='m-0 p-0'>Cargo</p>
                </div>
                <div className='right-dash-name'>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Vehicle</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Route</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Reference</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Status</p>
                    </div>
                </div>
            </div>
            <div className='booking-dash' style={{height: `${!getFebruaryMonthlyBookings.length > 0 && getJuneMonthlyBookings.length > 3 ? " 121px 200px" :""}`, maxHeight: `${!getJuneMonthlyBookings.length > 0 && getJuneMonthlyBookings.length > 3 ? "200px" :"121px"}`}}>
                {getJuneMonthlyBookings.length > 0 && getJuneMonthlyBookings.map((booking, i) => (
                    <div className='book-name' key={i}>
                        <div className='left-book-name'>
                            <h2 style={{fontWeight:"bold", marginRight:"15px"}}>{counter += 1}</h2>
                            <p style={{fontSize:"12px", textAlign:"center", fontSize:"11px", marginBottom:"0"}}>{booking.cargoInformation.productName}</p>
                        </div>
                        <div className='right-dash-name'>
                            <div style={{width:"100px"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{booking.cargoInformation.vehicle_type}</p>
                            </div>
                            <div style={{width:"100px", textAlign:"end"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{((booking.puDetails.puCityName).substring(0,3)).toUpperCase()} - {((booking.doDetails.doCityName).substring(0,3)).toUpperCase()}</p>
                            </div>
                            <div style={{width:"100px"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{booking.booking_ref}</p>
                            </div>
                            <div style={{width:"100px"}}>
                                <p style={{ marginBottom:"0", color:"#f90500", fontWeight:"bold", textAlign:"end"}}>{booking?.undelivered_loads === 0 ? "delivered" : "Not delivered"}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>

        <div className='dash-date-title' style={{display: `${!getJulyMonthlyBookings.length > 0 ? "none" : "block"}`}}>
            <h2>July {julyYear}</h2>
            <div className='w-full h-6 px-[22px] bg-black flex text-white justify-between'>
                <div className='left-book-name'>
                    <p className='m-0 p-0'>Cargo</p>
                </div>
                <div className='right-dash-name'>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Vehicle</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Route</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Reference</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Status</p>
                    </div>
                </div>
            </div>
            <div className='booking-dash'>
                {getJulyMonthlyBookings.length > 0 && getJulyMonthlyBookings.map((booking, i) => (
                    <div className='book-name' key={i}>
                        <div className='left-book-name'>
                            <h2 style={{fontWeight:"bold", marginRight:"15px"}}>{counter += 1}</h2>
                            <p style={{fontSize:"12px", textAlign:"center", fontSize:"11px", marginBottom:"0"}}>{booking.cargoInformation.productName}</p>
                        </div>
                        <div className='right-dash-name'>
                            <div style={{width:"100px"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{booking.cargoInformation.vehicle_type}</p>
                            </div>
                            <div style={{width:"100px", textAlign:"end"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{((booking.puDetails.puCityName).substring(0,3)).toUpperCase()} - {((booking.doDetails.doCityName).substring(0,3)).toUpperCase()}</p>
                            </div>
                            <div style={{width:"100px"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{booking.booking_ref}</p>
                            </div>
                            <div style={{width:"100px"}}>
                                <p style={{ marginBottom:"0", color:"#f90500", fontWeight:"bold", textAlign:"end"}}>{booking?.undelivered_loads === 0 ? "delivered" : "Not delivered"}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>

        <div className='dash-date-title' style={{display: `${!getAugustMonthlyBookings.length > 0 ? "none" : "block"}`}}>
            <h2>August {augustYear}</h2>
            <div className='w-full h-6 px-[22px] bg-black flex text-white justify-between'>
                <div className='left-book-name'>
                    <p className='m-0 p-0'>Cargo</p>
                </div>
                <div className='right-dash-name'>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Vehicle</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Route</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Reference</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Status</p>
                    </div>
                </div>
            </div>
            <div className='booking-dash'>
                {getAugustMonthlyBookings.length > 0 && getAugustMonthlyBookings.map((booking, i) => (
                    <div className='book-name' key={i}>
                        <div className='left-book-name'>
                            <h2 style={{fontWeight:"bold", marginRight:"15px"}}>{counter += 1}</h2>
                            <p style={{fontSize:"12px", textAlign:"center", fontSize:"11px", marginBottom:"0"}}>{booking.cargoInformation.productName}</p>
                        </div>
                        <div className='right-dash-name'>
                            <div style={{width:"100px"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{booking.cargoInformation.vehicle_type}</p>
                            </div>
                            <div style={{width:"100px", textAlign:"end"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{((booking.puDetails.puCityName).substring(0,3)).toUpperCase()} - {((booking.doDetails.doCityName).substring(0,3)).toUpperCase()}</p>
                            </div>
                            <div style={{width:"100px"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{booking.booking_ref}</p>
                            </div>
                            <div style={{width:"100px"}}>
                                <p style={{ marginBottom:"0", color:"#f90500", fontWeight:"bold", textAlign:"end"}}>{booking?.undelivered_loads === 0 ? "delivered" : "Not delivered"}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>

        <div className='dash-date-title' style={{display: `${!getSeptemberMonthlyBookings.length > 0 ? "none" : "block"}`}}>
            <h2>September {septemberYear}</h2>
            <div className='w-full h-6 px-[22px] bg-black flex text-white justify-between'>
                <div className='left-book-name'>
                    <p className='m-0 p-0'>Cargo</p>
                </div>
                <div className='right-dash-name'>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Vehicle</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Route</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Reference</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Status</p>
                    </div>
                </div>
            </div>
            <div className='booking-dash'>
                {getSeptemberMonthlyBookings.length > 0 && getSeptemberMonthlyBookings.map((booking, i) => (
                    <div className='book-name' key={i}>
                        <div className='left-book-name'>
                            <h2 style={{fontWeight:"bold", marginRight:"15px"}}>{counter += 1}</h2>
                            <p style={{fontSize:"12px", textAlign:"center", fontSize:"11px", marginBottom:"0"}}>{booking.cargoInformation.productName}</p>
                        </div>
                        <div className='right-dash-name'>
                            <div style={{width:"100px"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{booking.cargoInformation.vehicle_type}</p>
                            </div>
                            <div style={{width:"100px", textAlign:"end"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{((booking.puDetails.puCityName).substring(0,3)).toUpperCase()} - {((booking.doDetails.doCityName).substring(0,3)).toUpperCase()}</p>
                            </div>
                            <div style={{width:"100px"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{booking.booking_ref}</p>
                            </div>
                            <div style={{width:"100px"}}>
                                <p style={{ marginBottom:"0", color:"#f90500", fontWeight:"bold", textAlign:"end"}}>{booking?.undelivered_loads === 0 ? "delivered" : "Not delivered"}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>

        <div className='dash-date-title' style={{display: `${!getOctoberMonthlyBookings.length > 0 ? "none" : "block"}`}}>
            <h2>October {octoberYear}</h2>
            <div className='w-full h-6 px-[22px] bg-black flex text-white justify-between'>
                <div className='left-book-name'>
                    <p className='m-0 p-0'>Cargo</p>
                </div>
                <div className='right-dash-name'>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Vehicle</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Route</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Reference</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Status</p>
                    </div>
                </div>
            </div>
            <div className='booking-dash'>
                {getOctoberMonthlyBookings.length > 0 && getOctoberMonthlyBookings.map((booking, i) => (
                    <div className='book-name' key={i}>
                        <div className='left-book-name'>
                            <h2 style={{fontWeight:"bold", marginRight:"15px"}}>{counter += 1}</h2>
                            <p style={{fontSize:"12px", textAlign:"center", fontSize:"11px", marginBottom:"0"}}>{booking.cargoInformation.productName}</p>
                        </div>
                        <div className='right-dash-name'>
                            <div style={{width:"100px"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{booking.cargoInformation.vehicle_type}</p>
                            </div>
                            <div style={{width:"100px", textAlign:"end"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{((booking.puDetails.puCityName).substring(0,3)).toUpperCase()} - {((booking.doDetails.doCityName).substring(0,3)).toUpperCase()}</p>
                            </div>
                            <div style={{width:"100px"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{booking.booking_ref}</p>
                            </div>
                            <div style={{width:"100px"}}>
                                <p style={{ marginBottom:"0", color:"#f90500", fontWeight:"bold", textAlign:"end"}}>{booking?.undelivered_loads === 0 ? "delivered" : "Not delivered"}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>

        <div className='dash-date-title' style={{display: `${!getNovemberMonthlyBookings.length > 0 ? "none" : "block"}`}}>
            <h2>November {novemberYear}</h2>
            <div className='w-full h-6 px-[22px] bg-black flex text-white justify-between'>
                <div className='left-book-name'>
                    <p className='m-0 p-0'>Cargo</p>
                </div>
                <div className='right-dash-name'>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Vehicle</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Route</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Reference</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Status</p>
                    </div>
                </div>
            </div>
            <div className='booking-dash'>
                {getNovemberMonthlyBookings.length > 0 && getNovemberMonthlyBookings.map((booking, i) => (
                    <div className='book-name' key={i}>
                        <div className='left-book-name'>
                            <h2 style={{fontWeight:"bold", marginRight:"15px"}}>{counter += 1}</h2>
                            <p style={{fontSize:"12px", textAlign:"center", fontSize:"11px", marginBottom:"0"}}>{booking.cargoInformation.productName}</p>
                        </div>
                        <div className='right-dash-name'>
                            <div style={{width:"100px"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{booking.cargoInformation.vehicle_type}</p>
                            </div>
                            <div style={{width:"100px", textAlign:"end"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{((booking.puDetails.puCityName).substring(0,3)).toUpperCase()} - {((booking.doDetails.doCityName).substring(0,3)).toUpperCase()}</p>
                            </div>
                            <div style={{width:"100px"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{booking.booking_ref}</p>
                            </div>
                            <div style={{width:"100px"}}>
                                <p style={{ marginBottom:"0", color:"#f90500", fontWeight:"bold", textAlign:"end"}}>{booking?.undelivered_loads === 0 ? "delivered" : "Not delivered"}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>

        <div className='dash-date-title' style={{display: `${!getDecemberMonthlyBookings.length > 0 ? "none" : "block"}`}}>
            <h2>December {DecemberYear}</h2>
            <div className='w-full h-6 px-[22px] bg-black flex text-white justify-between'>
                <div className='left-book-name'>
                    <p className='m-0 p-0'>Cargo</p>
                </div>
                <div className='right-dash-name'>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Vehicle</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Route</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Reference</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Status</p>
                    </div>
                </div>
            </div>
            <div className='booking-dash'>
                {getDecemberMonthlyBookings.length > 0 && getDecemberMonthlyBookings.map((booking, i) => (
                    <div className='book-name' key={i}>
                        <div className='left-book-name'>
                            <h2 style={{fontWeight:"bold", marginRight:"15px"}}>{counter += 1}</h2>
                            <p style={{fontSize:"12px", textAlign:"center", fontSize:"11px", marginBottom:"0"}}>{booking.cargoInformation.productName}</p>
                        </div>
                        <div className='right-dash-name'>
                            <div style={{width:"100px"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{booking.cargoInformation.vehicle_type}</p>
                            </div>
                            <div style={{width:"100px", textAlign:"end"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{((booking.puDetails.puCityName).substring(0,3)).toUpperCase()} - {((booking.doDetails.doCityName).substring(0,3)).toUpperCase()}</p>
                            </div>
                            <div style={{width:"100px"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{booking.booking_ref}</p>
                            </div>
                            <div style={{width:"100px"}}>
                                <p style={{ marginBottom:"0", color:"#f90500", fontWeight:"bold", textAlign:"end"}}>{booking?.undelivered_loads === 0 ? "delivered" : "Not delivered"}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
  )
}
