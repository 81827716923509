import React, {useRef, useState, useEffect} from 'react'
import { ImUser } from 'react-icons/im'
import { AiFillCamera } from "react-icons/ai"
import { HiIdentification } from 'react-icons/hi'
import { AiOutlinePlus } from 'react-icons/ai'
import {useFleetStateContext} from '../context/FleetDealsContext'
import { height } from '@mui/system'
import { useForm, Controller } from "react-hook-form";
import PhoneInput, { isValidPhoneNumber, formatPhoneNumber } from "react-phone-number-input";
import 'react-phone-number-input/style.css'
import Select from "react-select";
import firebase from '../firebase-config'
import DatePicker from "react-datepicker";
import { useStateContext } from '../context/DashboardStateContext'
import {AiOutlineCheck} from 'react-icons/ai';

// verify pin
const AddDriverSucess = ({
setDriverSuccess,
setAddDriverForm
}) => {
    return (
        <div className='absolute h-full w-full'>
            <div className='p-5' style={{width:"fit-content", height:"20%", background:"#fff"}}>
                <p>Driver Successfully Added</p>
                <button  className=''>Ok</button>
            </div>
        </div>
    )
}

export default function AddVehicleFormMod() {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [roadWorthyUpload, setRoadWorthyUpload] = useState("");
    const [goodsUpload, setGoodsUpload] = useState("");
    const [otherUpload, setOtherUpload] = useState("")
    const [roadWorthyUrl,  setRoadWorthyUrl] = useState(null);
    const [otherUrl,  setOtherUrl] = useState(null);
    const [goodsUrl,  setGoodsUrl] = useState(null);
    const [AllDocs, setAllDocs] = useState([])
    const [showStartDate, setShowStartDate] = useState(true);
    const [showEndDate, setShowEndDate] = useState(true);
    const [verificationCode, setVerificationCode] = useState("");
    const [driverSuccess, setDriverSuccess] = useState(false)
    const [horseImage, setHorseImage] = useState("")
    const [horseUrl, setHorseUrl] = useState("")
    const [isRoadWorthy, setIsRoadWorthy] = useState(false);
    const [vehicleType, setVehicleType] = useState(false);
    const [vehicleAddedSuccess, setVehicleAddedSuccess] = useState();
    const [allVehicles, setAllVehicles] = useState([]);
    const [vinNumberSame, setVinNumberSame] = useState(false);
    const [licenseNumber, setLicenseNumber] = useState(false);

    firebase.auth().useDeviceLanguage();

    const { register, handleSubmit, formState: { errors }, control } = useForm();
    const {
        selectedBookingDeal,
        setSelectedBookingDeal,
        allDeals,
        setAllDeals,
        addDriversData,
        setAddDriversData,
        addDriverForm,
        setAddDriverForm
      } = useFleetStateContext();
      const {
        showAddVMod,
        setShowAddVMod,
        userUid,
        setUserUid
    } = useStateContext();

    const  VehicleTypeOptions = [
        { value:"Car Carrier", label:"Car Carrier" },
        { value:"Container", label:"Container" },
        { value:"Refrigerated", label:"Refrigerated" },
        { value:"Tanker", label:"Tanker" },
        { value:"Tautliner", label:"Tautliner" },
        { value:"Abnormal (34+ Tons)", label:"Abnormal (34+ Tons)" },
        { value:"Abnormal", label:"Abnormal" },
    ]

    const getDriverIDFnc = async ()=>{
        return  await firebase.database().ref().push()
    }

    const handleAddVehicle = (data) => {
        const licenseSame = allVehicles.filter((item, i) => item.licence_number === data.licence_number);

        if(!roadWorthyUpload){
            setIsRoadWorthy(true);
        }else if(data.Vehicle_type === undefined){
            setVehicleType(true);
        }else if(licenseSame.length > 0){
            setLicenseNumber(true);
        }else{
            const storage = firebase.storage()
            console.log("not the same", licenseSame)
            // upload road worthy doc
            const fileRefRoad = storage.ref(`enatis_pdf/${data.phone}`)
            fileRefRoad.put(roadWorthyUpload).then((snapshot) => {
                const fileUrl = fileRefRoad.getDownloadURL()
                .then((url) => {
                    setRoadWorthyUrl(url)
                })
                    .catch((error) => {
                });
            });

            // upload goods in transit cover doc
            const fileRefGoods = storage.ref(`goods_in_transit_pdf/${data.phone}`)
            fileRefGoods.put(goodsUpload).then((snapshot) => {
                const fileUrl = fileRefGoods.getDownloadURL()
                .then((url) => {
                    setGoodsUrl(url)
                })
                    .catch((error) => {
                });
            });

            // firebase.database().ref("nooo").set({
            //     name:"uyaphapha"
            // })

            //store everything to database
            getDriverIDFnc(data).then((item ) => {
                var driver_id

                driver_id = item?.key
                firebase.database()
                .ref('fleets/' +
                userUid +
                '/' +
                "horses" +
                '/' +
                data.licence_number,)
                .update({
                    end_date: (endDate.toISOString().substring(0, 10)),
                    engine_nmber: data.engine_number,
                    goods_in_transit_pdf: goodsUrl,
                    licence_number: data.licence_number,
                    make: data.make,
                    model: data.model,
                    enatis_pdf: roadWorthyUrl,
                    photo_uri: horseUrl,
                    vehicle_type: data.Vehicle_type,
                    vin_number: data.vin_number,
                    year: data.year,
                }).then(() => {
                    setVehicleAddedSuccess(true)
                    setTimeout(() => {
                        setShowAddVMod(false)
                        setVehicleAddedSuccess(false)
                    }, 2000)
                }).catch((error) => {
                    console.log(error);
                })
            })

        }

    }

      const handleRoadWorthyUpload = (e,file) => {
        e.preventDefault()
        if(file === null) return
        setRoadWorthyUpload(file)
      }

      const handleGoodsUpload = (e,file) => {
        e.preventDefault()
        if(file === null) return
        setGoodsUpload(file)
      }

      const handleHorseImageUpload = (e, file) => {
        e.preventDefault()
        const storage = firebase.storage()
        if(file === null) return
        const fileRef = storage.ref(`horse_image/${file.name}`)
        setHorseImage(file.name)
        fileRef.put(file).then((snapshot) => {
            const fileUrl = fileRef.getDownloadURL()
            .then((url) => {
                setHorseUrl(url)
            })
                .catch((error) => {
                });
        });
      }

      useEffect(() => {
        firebase.database().ref("/fleets/" + userUid + "/horses/").on("value", (snapshot) =>{
            if(snapshot.exists()){
                setAllVehicles(Object.values(snapshot.val()));
            }
        })
      },[])

  return (
    <form
        className='w-full flex justify-center scrollbar-hide pointer-events-auto items-center absolute top-0 left-0 right-0 bottom-0'
        style={{background:"#00000066", height:"100%", maxHeight:"100%", overflow:"scroll"}}
        onSubmit={handleSubmit(handleAddVehicle)}
    >
        <div
            className='h-full cursor-pointer'
            style={{width:"25%"}}
            onClick={() => {
                setShowAddVMod(false)
            }}
        ></div>
        <div style={{width:"50%", height:"100%"}}>
            <div className='cursor-pointer' style={{width:"100%", height:"5%"}}onClick={() => {
                setShowAddVMod(false)
            }}></div>
            <div
                className='rounded-xl p-7 scrollbar-hide flex justify-between'
                style={{
                    background:"#F2F2F2",
                    height:"90%",
                    maxHeight:"90%",
                    overflow:"scroll",
                }}
            >
                    <div
                        className=''
                        style={{
                            width:"45%",
                            height:"100%",
                        }}
                    >
                        <div className='flex justify-center items-center w-full h-fit'>
                            <div
                                className='flex rounded-full justify-center items-center relative'
                                style={{background:"#DCDCDC", width:"7rem", height:"7rem"}}
                            >
                                    {horseUrl ?
                                        <div className='w-full h-full rounded-full'>
                                            <img className='rounded-full' style={{objectFit:"cover", width:"7rem", height:"7rem"}} src={horseUrl} alt="" />
                                        </div>
                                        :   <p className='' style={{fontSize:"4.5rem", color:"#fff"}}></p>
                                    }
                                <label
                                    className='absolute right-1 bottom-0 cursor-pointer h-6 w-6 flex justify-center items-center rounded-full'
                                    style={{fontSize:"1rem", background:"#fce200"}}
                                    htmlFor="profilefile"
                                    onChange={(e) => handleHorseImageUpload(e, e.target.files[0])}
                                >
                                    <input accept='image/*' type="file" id='profilefile' className='' />
                                    <AiFillCamera/>
                                </label>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold' style={{fontSize:"1rem", color:"rgb(96, 96, 96)"}}>Vehicle Details</p>
                            <Controller
                                name="Vehicle_type"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        styles={{
                                            control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            width: '100%',
                                            background: "rgb(220, 220, 220)",
                                            fontSize: "0.9rem",
                                            borderRadius: "0.75rem",
                                            marginBottom:"0.5rem"
                                            }),
                                        }}
                                        placeholder="Vehicle Type"
                                        classNamePrefix="addl-class"
                                        options={VehicleTypeOptions}
                                        value={VehicleTypeOptions.find(c => c.value === value)}
                                        onChange={val => onChange(val.value)}
                                    />
                                )}
                            />
                            {/* {errors.vehicle && <p style={{color:"red", marginTop:"0"}}>Please don't leave vehicle type blank.</p>} */}
                            <input
                                type="text"
                                className='w-full mb-2 h-9 rounded-xl mt-1 p-2'
                                placeholder='Make' style={{background:"#DCDCDC", fontSize:".9rem"}}
                                {...register("make", { required: true })}
                            />
                            {errors.make && <p style={{color:"red", marginTop:"0"}}>Please don't leave the make blank.</p>}
                            <input
                                type="text"
                                className='w-full mb-2 h-9 rounded-xl mt-1 p-2'
                                placeholder='Model' style={{background:"#DCDCDC", fontSize:".9rem"}}
                                {...register("model", { required: true })}
                            />
                            {errors.model && <p style={{color:"red", marginTop:"0"}}>Please don't leave the model blank.</p>}
                            <input
                                type="number"
                                className='w-full mb-2 h-9 rounded-xl mt-1 p-2'
                                placeholder='Year' style={{background:"#DCDCDC", fontSize:".9rem"}}
                                {...register("year", { required: true })}
                            />
                            {errors.year && <p style={{color:"red", marginTop:"0"}}>Please don't leave the year blank.</p>}

                            <input
                                type="text"
                                className='w-full h-9 rounded-xl p-2'
                                placeholder='License Number'
                                style={{background:"#DCDCDC", fontSize:".9rem"}}
                                {...register("licence_number", { required: true })}
                            />
                            {errors.licence_number && <p style={{color:"red", marginTop:"0"}}>Please don't leave the license number blank.</p>}

                        </div>
                    </div>

                    <div
                        className='scrollbar-hide'
                        style={{
                            width:"45%",
                            height:"100%",
                            overflow:"scroll"
                        }}
                    >
                        <input
                            type="text"
                            className='w-full h-9 rounded-xl p-2'
                            placeholder='VIN Number'
                            style={{background:"#DCDCDC", fontSize:".9rem"}}
                            {...register("vin_number", { required: true, pattern:/^(?=.{17}$)/ })}
                        />
                        {errors.vin_number && <p style={{color:"red", marginTop:"0"}}>Please don't leave the vin number blank and number should be 17 characters long</p>}

                        <input
                            type="text"
                            className='w-full h-9 rounded-xl mt-3 mb-3 p-2'
                            placeholder='Engine Number'
                            style={{background:"#DCDCDC", fontSize:".9rem"}}
                            {...register("engine_number", { required: true, pattern:/^(?=.{9}$)/ })}
                        />
                        {errors.engine_number && <p style={{color:"red", marginTop:"0"}}>Please don't leave the engine number blank and it must be 9 characters long.</p>}
                        <p className='font-bold' style={{fontSize:"1rem", color:"rgb(96, 96, 96)"}}>Documents Upload</p>
                        <div className='flex justify-between items-center w-full px-5 py-3 mt-3 h-13 rounded-lg' style={{background:"#FFFFFF"}}>
                            <label  className='w-full h-9 flex justify-between items-center cursor-pointer' htmlFor="idfile">
                                <input
                                    type="file"
                                    onChange={(e) => handleRoadWorthyUpload(e, e.target.files[0])}
                                    style={{border:"1px solid", background:"black"}}
                                    id='idfile'
                                    accept='image/*, .pdf, .doc, .docx'
                                    // onChange={(e) => {setIdUpload(e.target.files[0])}}
                                />
                                <p className='cursor-pointer relative' style={{color:`${roadWorthyUpload ? "#02c202" : "#A7A7A7"}`, fontSize:"1.6rem"}}>
                                    <HiIdentification/>
                                    {roadWorthyUpload &&
                                        <span className='absolute top-0 right-0 left-0 bottom-0' style={{color:"#000"}}><AiOutlineCheck/></span>
                                    }
                                </p>
                                <p style={{color:"rgb(96, 96, 96)"}}>Road Worthy Certificate</p>
                            </label>
                        </div>

                        <div className='flex justify-between items-center px-5 py-3 mt-3 h-13 rounded-lg' style={{background:"#FFFFFF"}}>
                            <label  className='w-full h-9 flex justify-between items-center cursor-pointer' htmlFor="driverfile">
                                <input
                                    type="file"
                                    onChange={(e) => handleGoodsUpload(e, e.target.files[0])}
                                    style={{border:"1px solid", background:"black"}}
                                    id='driverfile'
                                    accept='image/*, .pdf, .doc, .docx'
                                />
                                <p className='cursor-pointer relative' style={{color:`${goodsUpload ? "#02c202" : "#A7A7A7"}`, fontSize:"1.6rem"}}>
                                    <HiIdentification/>
                                    {goodsUpload &&
                                        <span className='absolute top-0 right-0 left-0 bottom-0' style={{color:"#000"}}><AiOutlineCheck/></span>
                                    }
                                </p>
                                <p style={{color:"rgb(96, 96, 96)"}}>Goods in transit</p>
                            </label>
                        </div>

                        {/* <div className='flex justify-between items-center mt-3'>
                            {showEndDate &&
                                <div
                                    type="text"
                                    className='h-9 cursor-pointer rounded-xl p-2 flex justify-content items-center'
                                    placeholder='Start Date'
                                    onClick={() => setShowEndDate(false)}
                                    style={{background:"#DCDCDC", fontSize:".9rem", width:"100%"}}
                                    {...register("end_date", { required: true })}
                                >
                                    <h1 style={{color:"#A7A7A7", textAlign:"center"}}>End Date</h1>
                                </div>
                            }
                            {!showEndDate &&
                                <div className='flex justify-center flex-col' style={{width:"100%"}}>
                                    <DatePicker wrapperClassName="datePickerStyle" selected={endDate} onChange={(date) => setEndDate(date)} />
                                    <p className='m-0 pl-2'>Select date</p>
                                </div>
                           }
                        </div> */}
                        {errors.certification && <p style={{color:"red", marginTop:"0px"}}>Please don't leave the Certification/License  blank.</p>}
                        <div className='flex justify-end cursor-pointer items-center mt-3'>
                            <div
                                className='flex justify-end items-center h-9  p-3 rounded-xl bg-white'
                                style={{border:"1px solid black", width:"8rem"}}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setAllDocs([
                                        roadWorthyUpload.name,
                                        goodsUpload.name,
                                    ])
                                }}
                            >
                                <p style={{fontSize:"1rem"}}>Add</p> <p className='pl-5' style={{fontSize:"1.4rem"}}><AiOutlinePlus/></p>
                            </div>
                        </div>
                        <p className='mt-2' style={{fontSize:".9rem"}}>Added certificates/licenses</p>
                        <div className='flex'>
                            {AllDocs?.length && AllDocs.map((file) => (
                                <p
                                    className='p-1 px-2 mr-3 rounded-full bg-white'
                                    style={{
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        width: "79px",}}
                                >{file}</p>
                            ))
                            }
                        </div>
                        <input type="submit" id='sign-driver-in' placeholder='Enroll Driver' className='mt-3 w-full h-9 cursor-pointer rounded-xl ' style={{background:"#fce200"}}/>
                    </div>
            </div>
            <div className='cursor-pointer' style={{width:"100%", height:"5%"}}onClick={() => {
                setShowAddVMod(false)
            }}></div>
        </div>

        <div
            className='h-full cursor-pointer'
            style={{width:"25%"}}
            onClick={() => {
                setShowAddVMod(false)
            }}
        >

        </div>
        {/* {driverSuccess &&
            <AddDriverSucess
                setDriverSuccess={setDriverSuccess}
                setAddDriverForm={setAddDriverForm}
            />
        } */}

        {isRoadWorthy &&
            <div className='absolute h-full w-full flex items-center justify-center'>
                <div className='p-5 rounded-xl flex items-center justify-center flex-col' style={{width:"244px", height:"fit-content", background:"#fff"}}>
                    <p style={{fontSize:"1rem", textAlign:"center", marginBottom:"1rem"}}>Please Upload Road Worthy Certificate</p>
                    <button className='rounded-xl px-2 py-1' style={{background:"#fce200", fontSize:".8rem"}} onClick={() => setIsRoadWorthy(false)}>Ok</button>
                </div>
            </div>
        }

        {vehicleType &&
            <div className='absolute h-full w-full flex items-center justify-center'>
                <div className='p-5 rounded-xl flex items-center justify-center flex-col' style={{width:"244px", height:"fit-content", background:"#fff"}}>
                    <p style={{fontSize:"1rem", textAlign:"center", marginBottom:"1rem"}}>Please select vehicle type</p>
                    <button className='rounded-xl px-2 py-1' style={{background:"#fce200", fontSize:".8rem"}} onClick={() => setVehicleType(false)}>Ok</button>
                </div>
            </div>
        }

        {vehicleAddedSuccess &&
            <div className='absolute h-full w-full flex items-center justify-center'>
                <div className='p-5 rounded-xl flex items-center justify-center flex-col' style={{width:"fit-content", height:"fit-content", background:"#fff"}}>
                    <p style={{fontSize:"1rem", textAlign:"center", marginBottom:"1rem"}}>Vehicle Added Successfully!</p>
                </div>
            </div>
        }

        {vinNumberSame &&
            <div className='absolute h-full w-full flex items-center justify-center'>
                <div className='p-5 rounded-xl flex items-center justify-center flex-col' style={{width:"244px", height:"fit-content", background:"#fff"}}>
                    <p style={{fontSize:"1rem", textAlign:"center", marginBottom:"1rem"}}>That vin number already exist in the system please enter a different number!</p>
                    <button className='rounded-xl px-2 py-1' style={{background:"#fce200", fontSize:".8rem"}} onClick={() => setVinNumberSame(false)}>Ok</button>
                </div>
            </div>
        }

        {licenseNumber &&
            <div className='absolute h-full w-full flex items-center justify-center'>
                <div className='p-5 rounded-xl flex items-center justify-center flex-col' style={{width:"244px", height:"fit-content", background:"#fff"}}>
                    <p style={{fontSize:"1rem", textAlign:"center", marginBottom:"1rem"}}>That license number already exist in the system please enter a different number!</p>
                    <button className='rounded-xl px-2 py-1' style={{background:"#fce200", fontSize:".8rem"}} onClick={() => setLicenseNumber(false)}>Ok</button>
                </div>
            </div>
        }
    </form>
  )
}
