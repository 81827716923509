import React, {useState, useEffect} from 'react'
import { useForm, Controller } from 'react-hook-form';
import '../css/login.css'
import firebase from '../firebase-config';
import { Link, useNavigate  } from 'react-router-dom';

function LoginPickDrop() {

  const [isRegister, setIsRegister] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const { register, handleSubmit, formState: { errors }, control } = useForm();
  const navigate = useNavigate()

  const loginFnc = (data) => {
    firebase.auth().signInWithEmailAndPassword(data.email, data.password)
      .then((userCredential) => {
        const user = userCredential.user;
        const uid = user.uid;

        // Log the user and UID to verify the successful login
        console.log('Logged in user:', user);
        console.log('User UID:', uid);

        if (userCredential.user) {
          firebase.database().ref('/pickDropParty/' + uid).once('value', (snapshot) => {
            if (snapshot.exists()) {
              navigate('/pickDropTracking');
            } else {
              setErrorMessage("This is not the right platform for this user type");
              setAlert(true);
            }
          });
        } else {
          // User object not available, handle the case
          setErrorMessage("User login failed");
          setAlert(true);
        }
      })
      .catch((error) => {
        const errorMessage = error.message;
        // Log and handle authentication errors
        console.error('Authentication error:', errorMessage);
        setErrorMessage("The email or password you entered is incorrect, Try again or reset your password");
        setAlert(true);
      });
  };

  const registerFnc = (data) => {
    firebase.auth().createUserWithEmailAndPassword(data.email, data.password)
      .then((userCredential) => {
        const user = userCredential.user;
        const uid = user.uid;
        firebase.database().ref('/pickDropParty/' + uid).set({
          ...data,
          user_type: 'pick drop party'
        });
        navigate('/pickDropTracking');
      })
      .catch((error) => {
        const errorMessage = error.message;
        if (errorMessage === "The email address is already in use by another account.") {
          setErrorMessage("This email address is already attached to an existing account, would you like to log in instead?");
        }
        setAlert(true);
      });
  };

//   useEffect(() => {
//     // Check for the user after authentication
//     firebase.auth().onAuthStateChanged((user) => {
//       if (user) {
//         // If a user is available, redirect based on their existence in the database
//         const uid = user.uid;
//         firebase.database().ref('/pickDropParty/' + uid).once('value', (snapshot) => {
//           if (snapshot.exists()) {
//             navigate('/pickDropTracking');
//           } else {
//             // If the user doesn't exist in the database, set error message and alert
//             setErrorMessage("This is not the right platform for this user type");
//             setAlert(true);
//           }
//         });
//       } else {
//         // Handle cases where user is null
//         // You might want to reset states or perform any specific actions here
//         console.log("No user is signed in.");
//       }
//     });

//     // // Clean up the listener
//     // return () => unsubscribe();
//   }, []);

//   useEffect(() => {
    console.log("The state", isRegister)
//   },[])

  return (
    <div className='bg w-[100vw] flex justify-center items-center h-[100vh] relative'>
        <form onSubmit={handleSubmit(isRegister ? registerFnc : loginFnc)} className='w-[20rem] relative transition-all duration-300 h-fit rounded-xl p-4 shadow-xl bg-[#fff]'>
            <div className='w-full flex justify-center mt-9'>
              <h1 className='font-bold text-[1.7rem]'>Login</h1>
            </div>
            <div className='mt-5'>
              {isRegister &&
                <input
                  type="text"
                  name=""
                  id="name"
                  className='px-3 py-1 w-full outline-none rounded-xl bg-[#dedede]'
                  placeholder={errors.name ? errors.name.message : 'name'}
                  {...register("name", {required: 'name is required', })}
                />
              }
              {isRegister &&
                <input
                  type="number"
                  name=""
                  id="phone"
                  className='px-3 py-1 mt-3 outline-none w-full rounded-xl bg-[#dedede]'
                  placeholder={errors.phone ? errors.phone.message : 'Phone'}
                  {...register("phone", { required: 'Phone is required', })}
                />
              }
              <input
                type="email"
                name=""
                id="email"
                className='px-3 py-1 mt-3 w-full outline-none rounded-xl bg-[#dedede]'
                placeholder={errors.email ? errors.email.message : 'Email'}
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                })}
              />
              <input
                type="password"
                name=""
                id="password"
                className='px-3 mt-3 py-1 w-full outline-none rounded-xl bg-[#dedede]'
                placeholder={errors.password ? errors.password.message : 'password'}
                {...register("password", {
                  required: 'Password is required',
                  pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15}$/
                })}
              />
            </div>
            <div className='mt-4 mb-5 w-full flex justify-center items-center'>
              <button className='bg-[yellow] py-1 rounded-xl w-full '>{isRegister ? "Register" : "Login"}</button>
            </div>
            <div className='w-full flex justify-center items-center'>
              <p className='text-[1rem]'>Or</p>
            </div>
            <div className='w-full flex mt-2 mb-9 justify-center items-center'>
              <p
                className='text-[.8rem] text-[#24241c] font-bold cursor-pointer'
                onClick={() => setIsRegister(!isRegister)}
              >{isRegister ? "Login" : "Create an account"}</p>
            </div>
            {alert &&
              <div className='absolute top-0 left-0 right-0 bottom-0  h-full w-full flex items-center justify-center'>
                <div className='p-5 rounded-xl flex items-center justify-center flex-col' style={{width:"244px", height:"fit-content", background:"#fff"}}>
                    <p style={{fontSize:"1rem", textAlign:"center", marginBottom:"1rem"}}>{errorMessage}</p>
                    <button className='rounded-xl px-2 py-1' style={{background:"#fce200", fontSize:".8rem"}} onClick={() => setAlert(false)}>Ok</button>
                </div>
              </div>
            }
        </form>
    </div>
  )
}

export default LoginPickDrop
