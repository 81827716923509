import React, { useState, useEffect, useRef} from 'react'
import {Avatar} from '@mui/material';
import logo from '../icons/zipilogoo.png'
import {Link, useNavigate } from "react-router-dom";
import '../css/enterprisenav.css'
import firebase from '../firebase-config';
import { useStateContext } from '../context/DashboardStateContext'
import avatar from '../icons/wena.jpg'
import LoaderEnterprise from './LoaderEnterprise';

export default function EnterpriseNav({
  name,
}) {

  const [letterLoader,setLetterLoader] = useState(false);
  const [profileImageUrl,setProfileImageUrl] = useState("");
  const {
    isEnterprise,
    setIsEnterprise,
    isTracking,
    setIsTracking,
    isShowSchedule,
    setIsShowSchedule,
    setIsShowRequest,
    isShowRequest,
    setCargoLink,
    cargoLink,
    fleetLink,
    setFleetLink,
    referalLink,
    setReferalLink,
    profileLink,
    setProfileLink,
    analysisLink,
    setAnalysisLink,
    walletLink,
    setWalletLink,
    getHelpLink,
    setGetHelpLink,
    contactLink,
    setContactLink,
    setIsQuote,
    userUid
  } = useStateContext();

   //for uploading profile image
   const handleProfileUpload = (file) => {
    const storage = firebase.storage()
    setLetterLoader(true);
    if(file === null) return
    const fileRefId = storage.ref(`files/${userUid}/profileImage`)
    // setIdUpload(file.name)
    fileRefId.put(file).then((snapshot) => {
        const fileUrl = fileRefId.getDownloadURL()
        .then((url) => {
          firebase.database().ref("booking_party").child(userUid).update({
            profileImageUrl: url
          })
          setLetterLoader(false);
          fileInputRefOne.current.value = null;
        })
        .catch((error) => {
        });
    });
  }

    useEffect(() => {
      if(userUid){
        firebase.database().ref("booking_party").child(userUid).on("value", snapshot => {
          setProfileImageUrl(snapshot.val().profileImageUrl);
        })
      }
    },[])

    const iconName = name?.substring(0,2);
    const navigate = useNavigate();
    const fileInputRefOne = useRef(null);
    const [mobNav, setMobNav] = useState(false);

  return (
    <div className='nav-container duration-500 ease-in-out' style={{width:"100%"}}>
        <div className='nav duration-500 ease-in-out'>
          <div  style={{height:"100%",cursor:"pointer", display:"flex", alignItems:"center"}}>
            <i class="fa-solid fa-chevron-left"
              onClick={() => {
                setCargoLink(true)
                setIsEnterprise(false)
                setIsTracking(false)
                setIsShowSchedule(false)
                setIsShowRequest(false)
                setFleetLink(false)
                setReferalLink(false)
                setProfileLink(false)
                setAnalysisLink(false)
                setWalletLink(false)
                setGetHelpLink(false)
                setContactLink(false)
                setIsQuote(false)
             }}
            ></i><p style={{color:"#fff", marginLeft:"10px", fontSize:"1.8rem", marginBottom:"0px"}}>My Cargo</p>
          </div>
            {/* <div className='logo'>
                <Link to={'/enterprise'}><img src={logo} alt="" /></Link>
                <p>enterprise</p>
            </div> */}
            {!mobNav ?
              <i class="fa-solid fa-bars" onClick={() => {
                if(mobNav === false){
                  setMobNav(true)
                }else{
                  setMobNav(false)
                }
                }}></i>

                :
                <i class="fa-solid fa-xmark" onClick={() => {
                  if(mobNav === false){
                    setMobNav(true)
                  }else{
                    setMobNav(false)
                  }
                  }}></i>

            }
            {/* <ul className={`duration-500 ease-in-out ${!mobNav? 'dropdown-wrapper mobile-dropdown' : 'dropdown-wrapper mobile-dropdown-show'}`}> */}
            {/* <li><div className="nav-nav"
                    onClick={() => {
                      setIsTracking(true);
                      setIsEnterprise(false);
                      setCargoLink(false);
                      setIsShowSchedule(false);
                      setIsShowRequest(false);
                      setIsQuote(false);
                    }}><i className="fa-solid fa-location-arrow nav-awe"></i>Tracking</div></li> */}
                    {/* <li><div onClick={() => {
                      setIsShowSchedule(true);
                      setIsTracking(false);
                      setIsEnterprise(false);
                      setCargoLink(false);
                      setIsShowRequest(false);
                      setIsQuote(false);
                    }}className="nav-nav"><i className="fa-solid fa-calendar nav-awe"></i>Schedule</div></li> */}
                    {/* <li><div onClick={() => {
                      setIsShowSchedule(false);
                      setIsTracking(false);
                      setIsEnterprise(false);
                      setCargoLink(false);
                      setIsShowRequest(true);
                      setIsQuote(false);
                    }}className="nav-nav"><i className="fa-solid fa-clipboard-list nav-awe"></i>Requets</div></li> */}
                    {/* <li><div onClick={() => {
                      setIsShowSchedule(false);
                      setIsTracking(false);
                      setIsEnterprise(false);
                      setCargoLink(true);
                      setIsShowRequest(false);
                      setIsQuote(false)
                    }}className="nav-nav" to="/dashboard"><i class="fa-solid fa-table-columns nav-awe"></i>Dashboard</div></li> */}
                    {/* <li><p>Pick drop page</p></li> */}
                    {/* <li><div className="nav-nav" onClick={()=> {
                        firebase.auth().signOut().then(() => {
                            navigate('/Login')
                          }).catch((error) => {
                            alert(error)
                          });
                    }}><i className="fa-solid fa-arrow-right-from-bracket nav-awe"></i>Logout</div></li> */}
                {/* </ul> */}
            <div className='profile'>
                <p className='m-0 p-0'>{name}</p>
                <input
                  type="file"
                  style={{ position: 'absolute', top: 0, left: 0, opacity: 0, zIndex: -1 }}
                  onChange={(e) => handleProfileUpload(e.target.files[0])}

                  ref={fileInputRefOne} // Assigning the ref to the fileInputRef variable
                />
                <div
                  className='w-[50px] h-[50px] bg-[#e3e3e3] rounded-full relative'
                  onClick={() => fileInputRefOne.current.click()}
                >
                    {profileImageUrl ?
                      <img className='w-full h-full rounded-full object-cover' src={profileImageUrl} alt="" />
                      :
                      <div
                        className='Enterprise-icon w-[50px] h-[50px] bg-[#e3e3e3] rounded-full flex justify-center items-center'
                      >
                        <p className='m-0 p-0 font-bold text-[1.4rem] text-[grey]'>{iconName?.toUpperCase()}</p>
                      </div>
                    }
                    {letterLoader &&
                        <div className='flex justify-center items-center absolute left-0 right-0 top-0 bottom-0 w-[50px] h-[50px]'>
                          <div className='loader-enterprise'></div>
                        </div>
                    }
                </div>
                {/* <i className="fa-solid fa-chevron-down chearrow"></i> */}
                <ul className='dropdown-wrapper'>
                    {/* <li><div className="nav-nav"
                    onClick={() => {
                      setIsTracking(true);
                      setIsEnterprise(false);
                      setCargoLink(false);
                      setIsShowSchedule(false);
                      setIsShowRequest(false);
                      setProfileLink(false);
                      setIsQuote(false);
                    }}><i className="fa-solid fa-location-arrow nav-awe"></i>Tracking</div></li> */}
                    {/* <li><div onClick={() => {
                      setIsShowSchedule(true);
                      setIsTracking(false);
                      setIsEnterprise(false);
                      setCargoLink(false);
                      setIsShowRequest(false);
                      setIsQuote(false);
                    }}className="nav-nav"><i className="fa-solid fa-calendar nav-awe"></i>Schedule</div></li> */}
                    {/* <li><div onClick={() => {
                      setIsShowSchedule(false);
                      setIsTracking(false);
                      setIsEnterprise(false);
                      setCargoLink(false);
                      setIsShowRequest(true);
                      setIsQuote(false);
                    }}className="nav-nav"><i className="fa-solid fa-clipboard-list nav-awe"></i>Requets</div></li> */}
                    {/* <li><div onClick={() => {
                      setIsShowSchedule(false);
                      setIsTracking(false);
                      setIsEnterprise(false);
                      setCargoLink(true);
                      setIsShowRequest(false);
                      setIsQuote(false);
                    }}className="nav-nav" to="/dashboard"><i class="fa-solid fa-table-columns nav-awe"></i>Dashboard</div></li> */}

                </ul>
            </div>
        </div>
    </div>
  )
}

