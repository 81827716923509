import React, { useEffect, useState } from 'react'
import construction from '../icons/construction.png'
import { useStateContext } from '../context/DashboardStateContext';
import { Link, useNavigate  } from 'react-router-dom'
import firebase from '../firebase-config';
import {BsCheck} from 'react-icons/bs';
import CustomerSection from './CustomerSection';
import FleetSection from './FleetSection';

export default function Profile() {
  const navigate = useNavigate();
  const [tabText, setTabText] = useState("about you");
  const [tabTextFleet, setTabTextFleet] = useState("about you");

  const [bookPart, setBookParty] = useState([]);
  const [isAboutYouUpdateClicked, setIsAboutYouUpdateClicked] = useState(false);
  const [isCompanyUpdateClicked, setIsCompanyUpdateClicked] = useState(false);
  const [isContactUpdateClicked, setIsContactUpdateClicked] = useState(false);
  const [aboutYouUpdated, setAboutYouUpdated] = useState(false);
  const [companyUpdated, setCompanyUpdated] = useState(false);
  const [contactUpdated, setContactUpdated] = useState(false);
  const [fleetSection, setFleetSection] = useState(false);
  const [customerSection, setCustomerSection] = useState(true);

  const [fleet, setFleet] = useState([]);
  const [isAboutYouUpdateClickedFleet, setIsAboutYouUpdateClickedFleet] = useState(false);
  const [isCompanyUpdateClickedFleet, setIsCompanyUpdateClickedFleet] = useState(false);
  const [isContactUpdateClickedFleet, setIsContactUpdateClickedFleet] = useState(false);
  const [aboutYouUpdatedFleet, setAboutYouUpdatedFleet] = useState(false);
  const [companyUpdatedFleet, setCompanyUpdatedFleet] = useState(false);
  const [contactUpdatedFleet, setContactUpdatedFleet] = useState(false);
  const [fleetSectionFleet, setFleetSectionFleet] = useState(false);
  const [customerSectionFleet, setCustomerSectionFleet] = useState(true);

  const [vehicleUpdateClicked, setIsVehicleUpdateClicked] = useState(false)
  const [isSouthProvinces, setIsSouthProvinces] = useState(false);
  const [updatedRegions, setUpdatedRegions] = useState(false);

  const [heavyVehicles, setHeavyVehicles] = useState("car carrier");
  const [southernCountries,setSouthernCountries] = useState({
    south_africa: true,
    namibia: false,
    botswana: false,
    zimbabwe: false,
    mozambique: false,
    tanzania: false,
    kenya:false
  })
  const [southProvince, setSouthProvince] = useState({
    eastern_cape: true,
    freestate: false,
    gauteng: false,
    kwazulu_natal: false,
    limpopo: false,
    mpumalanga: false,
    northern_cape: false,
    north_west: false,
    western_cape: false
  });

  // Customer section
  const [aboutYouValues, setAboutYouValues] = useState({
    firstName: bookPart?.firstName || "",
    lastName: bookPart?.lastName || "",
    email: bookPart?.email || ""
  });

  const [companyValues, setCompanyValues] = useState({
    companyName: bookPart?.companyName || "",
    companyEmail: bookPart?.companyEmailData || "",
    companyRegistration: bookPart?.companyRegistration || "",
    telephone: bookPart?.Telephone || "",
    phone: bookPart?.phone || "",
    vat_number: bookPart?.phone || "",
  });

  const [contactValues, setContactValues] = useState({
    Telephone: bookPart?.Telephone || "",
    phone: bookPart?.phone || "",
    email: bookPart?.email || "",
    companyEmail: bookPart?.companyEmail || "",
    physicalAddress: bookPart?.physical_address || ""
  });

  // Fleet owner
  const [aboutYouValuesFleet, setAboutYouValuesFleet] = useState({
    name: fleet?.name || "",
    lastName: fleet?.lastName || "",
    email: fleet?.email || "",
    Telephone: fleet?.Telephone || ""
  });

  const [companyValuesFleet, setCompanyValuesFleet] = useState({
    fleet_name: fleet?.fleet_name || "",
    companyEmail: fleet?.email || "",
    registration_number: fleet?.registration_number || "",
    telephone: fleet?.Telephone || "",
    phone: fleet?.phone || "",
    complex: fleet?.complex || "",
    physical_address: fleet?.physical_address || "",
    city: fleet?.city || "",
    PO_box: fleet?.PO_box || "",
    vat_number: fleet?.vat_number || "",
    cipcUrl: fleet?.cipcUrl || "",
    vatUploadUrl: fleet?.vatUploadUrl || "",
    letterUrl: fleet?.letterUrl || ""
  });

  const [contactValuesFleet, setContactValuesFleet] = useState({
    telephone: fleet?.Telephone || "",
    physical_address: fleet?.physical_address || "",
    companyEmail: fleet?.email || "",
  });




  // Calculate the completion percentage based on the completion of different sections
  // Function to calculate the completion percentage based on field comparisons
  const calculateCompletionPercentage = () => {
    const totalFields = 12; // Total number of fields to be completed
    let completedFields = 0;

    if (customerSection) {
      const customerFields = [
        aboutYouValues.firstName,
        aboutYouValues.lastName,
        aboutYouValues.email,
        companyValues.companyName,
        companyValues.companyEmail,
        companyValues.companyRegistration,
        companyValues.telephone,
        contactValues.phone,
        contactValues.physicalAddress,
        companyValues.vat_number,
        companyValues.cipcUrl,
        companyValues.vatUploadUrl,
        companyValues.letterOfGoodStandUrl
      ];

      const customerFirebaseData = [
        bookPart.firstName,
        bookPart.lastName,
        bookPart.email,
        bookPart.companyName,
        bookPart.companyEmailData,
        bookPart.companyRegistration,
        bookPart.Telephone,
        bookPart.Telephone,
        bookPart.physical_address
      ];

      for (let i = 0; i < totalFields; i++) {
        if (customerFields[i] && customerFields[i] === customerFirebaseData[i]) {
          completedFields++;
        }
      }
    } else if (fleetSection) {
      const fleetFields = [
        aboutYouValuesFleet.firstName,
        aboutYouValuesFleet.lastName,
        aboutYouValuesFleet.email,
        companyValuesFleet.companyName,
        companyValuesFleet.companyEmail,
        companyValuesFleet.companyRegistration,
        companyValuesFleet.telephone,
        contactValuesFleet.telephone,
        contactValuesFleet.physicalAddress
      ];

      const fleetFirebaseData = [
        fleet.name,
        fleet.lastName,
        fleet.email,
        fleet.companyName,
        fleet.email,
        fleet.companyRegistration,
        fleet.phone,
        fleet.phone,
        fleet.physical_address
      ];

      for (let i = 0; i < totalFields; i++) {
        if (fleetFields[i] && fleetFields[i] === fleetFirebaseData[i]) {
          completedFields++;
        }
      }
    }

    return (completedFields / totalFields) * 100;
  };

  const {
    userUid,
  } = useStateContext();

  const updateAreas = () => {
    setIsSouthProvinces(true);

    const selectedProvinces = Object.entries(southProvince)
      .filter(([province, isSelected]) => isSelected)
      .map(([province]) => province);

    const selectedSouthernCountries = Object.entries(southernCountries)
      .filter(([country, isSelected]) => isSelected)
      .map(([country]) => country);

    const southAfricanProvincesRef = firebase.database().ref(`/fleets/${userUid}/operationAreas/southAfricanProvinces`);
    const southernAfricanCountriesRef = firebase.database().ref(`/fleets/${userUid}/operationAreas/southernAfricanCountries`);

    // Fetch existing provinces from the database
    southAfricanProvincesRef.once('value')
      .then(snapshot => {
        const existingProvinces = snapshot.val() || [];
        const newProvinces = selectedProvinces.filter(province => !existingProvinces.includes(province));

        if (newProvinces.length > 0) {
          const updatedProvinces = [...existingProvinces, ...newProvinces];

          // Update southAfricanProvinces
          return southAfricanProvincesRef.set(updatedProvinces);
        }
      })
      .then(() => {
        // Check if southernAfricanCountries exists before updating it
        if (selectedSouthernCountries.length > 0) {
          return southernAfricanCountriesRef.set(selectedSouthernCountries);
        }
      })
      .then(() => {
        setTimeout(() => {
          setIsSouthProvinces(false);
        }, 2000);
      })
      .catch(error => {
        console.error("Error updating areas:", error);
        setIsSouthProvinces(false);
      });
  };

  // Customer section
  useEffect(() => {
    firebase.database().ref("/booking_party/" + userUid).on("value", snapshot => {
      if(snapshot.exists()){
        setBookParty(snapshot.val());
      }
      if (!isAboutYouUpdateClicked) {
        setAboutYouValues({
          firstName: snapshot.val()?.firstName || "",
          lastName: snapshot.val()?.lastName || "",
          email: snapshot.val()?.email || "",
          Telephone: snapshot.val()?.Telephone
        });
      }
      if (!isCompanyUpdateClicked) {
        setCompanyValues({
          companyName: snapshot.val()?.companyName || "",
          companyEmail: snapshot.val()?.companyEmailData || "",
          companyRegistration: snapshot.val()?.companyRegistration || "",
          telephone: snapshot.val()?.Telephone || "",
          phone: snapshot.val()?.phone || "",
          vat_number: snapshot.val()?.vat_number || "",
          complex: snapshot.val()?.complex || "",
          physical_address: snapshot.val()?.physical_address || "",
          city: snapshot.val()?.city,
          PO_box: snapshot.val()?.PO_box
        });
      }
      if (!isContactUpdateClicked) {
        setContactValues({
          Telephone: snapshot.val()?.Telephone || "",
          phone: snapshot.val()?.phone || "",
          email: snapshot.val()?.email || "",
          companyEmail: snapshot.val()?.companyEmail || "",
          physicalAddress: snapshot.val()?.physical_address || ""
        });
      }
    });
  }, [isAboutYouUpdateClicked, isCompanyUpdateClicked, isContactUpdateClicked]);

  // Fleet owner section
  useEffect(() => {
    firebase.database().ref("/fleets/" + userUid).on("value", snapshot => {
      if(snapshot.exists()){
        setFleet(snapshot.val());
      }
      if (!isAboutYouUpdateClickedFleet) {
        setAboutYouValuesFleet({
          name: snapshot.val()?.name || "",
          lastName: snapshot.val()?.lastName || "",
          email: snapshot.val()?.email || "",
          Telephone: snapshot.val()?.Telephone || "",
        });
      }
      if (!isCompanyUpdateClickedFleet) {
        setCompanyValuesFleet({
          fleet_name: snapshot.val()?.fleet_name || "",
          registration_number: snapshot.val()?.registration_number || "",
          companyEmail: snapshot.val()?.email || "",
          Telephone: snapshot.val()?.Telephone || "",
          phone: snapshot.val()?.phone || "",
          complex: snapshot.val()?.complex || "",
          physical_address: snapshot.val()?.physical_address || "",
          city: snapshot.val()?.city || "",
          PO_box: snapshot.val()?.PO_box || "",
          vat_number: snapshot.val()?.vat_number || "",
          cipcUrl: snapshot.val()?.cipcUrl || "",
          vatUploadUrl: snapshot.val()?.vatUploadUrl || "",
          letterUrl: snapshot.val()?.letterUrl || ""
        });
      }
      if (!isContactUpdateClickedFleet) {
        setContactValuesFleet({
          Telephone: snapshot.val()?.Telephone || "",
          phone: snapshot.val()?.phone || "",
          physical_address: snapshot.val()?.physical_address || "",
          companyEmail: snapshot.val()?.email || "",
        });
      }

    });
  }, [isAboutYouUpdateClickedFleet, isCompanyUpdateClickedFleet, isContactUpdateClickedFleet]);

  console.log("the contact details", contactValues)

  return (
    <div>
         <div className='my-referals'>
          <div
            className='w-full'
              style={{
                height:"42vh",
                background:"#333333",
                padding: "3.6rem",

              }}
            >
              <div>
                <h1
                  className='font-bold'
                  style={{
                    color:"#fff",
                    fontSize:"1.8rem",
                    marginBottom:"1.6rem"
                  }}>
                    Profile
                </h1>
                <h1
                  className='font-bold'
                  style={{
                    color:"#fff",
                    fontSize:"1rem",
                    textTransform:"uppercase"
                  }}>
                    {calculateCompletionPercentage().toFixed(0)}% complete
                </h1>
                <p
                  style={{
                    color:"#fff",
                    marginTop:"1rem"
                  }}
                >Complete your profile start transacting on Zipi</p>
              </div>
              <div
                style={{
                  width:"50%",
                  height:"7px",
                  borderRadius:"10px",
                  background:"black",
                  marginTop:"1.5rem"
                }}
              >
                <div
                  style={{
                    width:`${calculateCompletionPercentage().toFixed(0)}%`,
                    height:"100%",
                    background:"yellow",
                    borderRadius:"10px",
                  }}
                ></div>
              </div>
              <p style={{color:"#fff", marginTop:".8rem"}}>Progress</p>
          </div>

            <div className='flex w-full mt-4 px-[3.6rem] py-[.6rem]'>
              <div className='flex mr-3 justify-center items-center'>
                <p
                  className='mr-3 cursor-pointer'
                  style={{
                    height:"1rem",
                    width:"1rem",
                    borderRadius:"100%",
                    background:fleetSection && !customerSection ? "#fce200":"black",
                    border:fleetSection && !customerSection ? "1px solid black":"none"
                  }}
                  onClick={() => {
                    setFleetSection(true)
                    setCustomerSection(false)
                  }}
                ></p>
                <p style={{color:"#000"}}>Fleet Owner</p>
              </div>
              <div className='flex mr-3 justify-center items-center'>
                <p
                  className='mr-3 cursor-pointer'
                  style={{
                    height:"1rem",
                    width:"1rem",
                    borderRadius:"100%",
                    background:customerSection && !fleetSection ? "#fce200":"black",
                    border:customerSection && !fleetSection ? "1px solid black":"none"
                  }}
                  onClick={() => {
                    setCustomerSection(true)
                    setFleetSection(false)
                  }}
                ></p>
                <p style={{color:"#000"}}>Shipper</p>
              </div>
            </div>

          {/* The customer section */}
          {customerSection && bookPart &&
            <CustomerSection
              contactValues={contactValues}
              userUid={userUid}
              setContactUpdated={setContactUpdated}
              setIsContactUpdateClicked={setIsContactUpdateClicked}
              contactUpdated={contactUpdated}
              setTabText={setTabText}
              tabText={tabText}
              aboutYouValues={aboutYouValues}
              isAboutYouUpdateClicked={isAboutYouUpdateClicked}
              setAboutYouValues={setAboutYouValues}
              companyValues={companyValues}
              isCompanyUpdateClicked={isCompanyUpdateClicked}
              setCompanyValues={setCompanyValues}
              setContactValues={setContactValues}
              setIsAboutYouUpdateClicked={setIsAboutYouUpdateClicked}
              setAboutYouUpdated={setAboutYouUpdated}
              setCompanyUpdated={setCompanyUpdated}
              setIsCompanyUpdateClicked={setIsCompanyUpdateClicked}
              companyUpdate={companyUpdated}
              isContactUpdateClicked={isContactUpdateClicked}
              aboutYouUpdated={aboutYouUpdated}
              companyUpdated={companyUpdated}
            />
          }
          {/* The fleet owner */}
          {fleetSection && fleet &&
            <FleetSection
              setTabText={setTabText}
              tabText={tabText}
              aboutYouValuesFleet={aboutYouValuesFleet}
              isAboutYouUpdateClickedFleet={isAboutYouUpdateClickedFleet}
              setAboutYouValuesFleet={setAboutYouValuesFleet}
              userUid={userUid}
              companyValuesFleet={companyValuesFleet}
              isCompanyUpdateClickedFleet={isCompanyUpdateClickedFleet}
              setIsCompanyUpdateClickedFleet={setIsCompanyUpdateClickedFleet}
              heavyVehicles={heavyVehicles}
              setHeavyVehicles={setHeavyVehicles}
              southProvince={southProvince}
              setSouthernCountries={setSouthernCountries}
              setIsAboutYouUpdateClickedFleet={setIsAboutYouUpdateClickedFleet}
              setAboutYouUpdatedFleet={setAboutYouUpdatedFleet}
              aboutYouUpdatedFleet={aboutYouUpdatedFleet}
              setCompanyValuesFleet={setCompanyValuesFleet}
              setCompanyUpdatedFleet={setCompanyUpdatedFleet}
              isContactUpdateClickedFleet={isContactUpdateClickedFleet}
              contactValuesFleet={contactValuesFleet}
              setIsContactUpdateClicked={setIsContactUpdateClicked}
              setContactValuesFleet={setContactValuesFleet}
              contactUpdatedFleet={contactUpdatedFleet}
              BsCheck={BsCheck}
              fleet={fleet}
              setIsVehicleUpdateClicked={setIsVehicleUpdateClicked}
              vehicleUpdateClicked={vehicleUpdateClicked}
              setSouthProvince={setSouthProvince}
              southernCountries={southernCountries}
              updateAreas={updateAreas}
              isSouthProvinces={isSouthProvinces}
              setContactUpdatedFleet={setContactUpdatedFleet}
              companyUpdatedFleet={companyUpdatedFleet}
              setIsContactUpdateClickedFleet={setIsContactUpdateClickedFleet}
            />
          }

          {/* here */}
        </div>
    </div>
  )
}
