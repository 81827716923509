import React, { createContext, useContext, useState, useEffect } from "react";
import firebase from '../firebase-config'

const Context = createContext();
export const StateContext = ({ children }) => {
    const [isTracking, setIsTracking] = useState(false);
    const [isShowSchedule, setIsShowSchedule] = useState(false);
    const [isShowRequest, setIsShowRequest] = useState(false);
    const [cargoLink, setCargoLink] = useState(true);
    const [isEnterprise, setIsEnterprise] = useState(false);
    const [fleetLink, setFleetLink] = useState(false);
    const [referalLink, setReferalLink] = useState(false);
    const [profileLink, setProfileLink] = useState(false);
    const [analysisLink, setAnalysisLink] = useState(false);
    const [walletLink, setWalletLink] = useState(false);
    const [getHelpLink, setGetHelpLink] = useState(false);
    const [contactLink, setContactLink] = useState(false);
    const [newDeals, setNewDeals] = useState(false);
    const [driver, setDriver] = useState(false);
    const [showFleet, setShowFleet] = useState(false)
    const [showRoutes, setShowRoutes] = useState(false)
    const [showAddRouteMod, setShowAddRouteMod] = useState(false)
    const [manualRoute, setManualRoute] = useState(false)
    const [assignDriverRoute, setAssignDriverRoute] = useState(false)
    const [showNoFleet, setShowNoFleet] = useState(false)
    const [showFirstDealProcess, setShowFirstDealProcess] = useState(false)
    const [bookingSelectedDates, setBookingSelectedDates] = useState(null)
    const [showAddVehicle, setShowAddVehicle] = useState(false);
    const [showAddTrailer, setShowAddTrailer] = useState(false);
    const [ShowVehicleDetails, setShowVehicleDetails] = useState(false);
    const [selected, setSelected] = useState(null)
    const [showAddVMod, setShowAddVMod] = useState(false);
    const [showAddTrailerMod, setShowAddTrailerMod] = useState(false);
    const [userUid, setUserUid] = useState(null)
    const [showAssign, setShowAssign] = useState(false);
    const [assignPanel, setAssignPanel] = useState([]);
    const [showAssignPanel, setShowAssignPanel] = useState(false);
    const [routeName, setRouteName] = useState('')
    const [isDealRequests, setIsDealRequests] = useState(false);
    const [isDealSettlement, setIsDealSettlement] = useState(false);
    const [isDealBooking, setIsDealBooking] = useState(false);
    const [allContacts,setAllContacts] = useState([]);
    const [name, setName] = useState("")
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [isQuote, setIsQuote] = useState(false);
    const [popUpQuote, setPopUpQuote] = useState(false)
    const [endDate, setEndDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [operationDaysNew, setOperationDaysNew] = useState([])
    const [includeHolidays, setIncludeHolidays] = useState(false)
    const [collectionBays,setCollectionBays] = useState(0)
    const [dropOffBays,setDropOffBays] = useState(0)
    const [gateInOutDuration, setGateInOutDuration] = useState([]);
    const [bookingSuccess, setBookingSuccess] = useState(false);
    const [isLoading,setIsLoading] = useState(false)
    const [rateIndication,setRateIndication] = useState(false)
    const [isBookClicked,setIsBookClicked] = useState(false);
    const [trackingLink, setTrackingLink] = useState(false);
    const [scheduleLink, setScheduleLink] = useState(false);
    const [requestLink, setRequestLink] = useState(false);
    const [homeLink, setHomeLink] = useState(false);
    const [isShipperUpload, setIsShipperUpload] = useState(false);
    const [selectedBookPOD, setSelectedBookPOD] = useState([]);
    const [isFleetOwnerUpload, setIsFleetOwnerUpload] = useState(false);
    const [selectedBookInvoice, setSelectedBookInvoice] = useState([])
    const [isBookingSummary,setIsBookingSummary] = useState(false);
    const [universalSelectedBook, setUniversalSelectedBook] = useState([]);
    const [isUseOwnTransport,setIsUseOwnTransport] = useState("");
    const [transporterContact, setTransporterContact] = useState([]);
    const [showTrackFilter,setShowTrackFilter] = useState(false);
    const [fleetTracking, setFleetTracking] = useState(false);
    const [isDashboard, setIsDashboard] = useState(false);
    const [isCargoMenu, setIsCargoMenu] = useState(true);

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                setUserUid(user.uid);
                firebase.database().ref("/fleets/" + user.uid).on('value', (snapshot) => {
                    if(snapshot.exists()){
                        setName(snapshot.val().fleet_name)
                    }
                })
            }
        });
    }, [])

    // useEffect(() => {
    //     firebase.auth().onAuthStateChanged((user) => {
    //         if (user) {
    //             var uid = user.uid;
    //             firebase.database().ref('/booking_party/' + uid).child("contacts").on('value', (snapshot) => {
    //                 if(snapshot.exists()){
    //                     setAllContacts(Object?.values(snapshot.val()));
    //                 }
    //             });
    //         }
    //     });
    // }, [])

    return (
        <Context.Provider
            value={{
                isEnterprise,
                setIsEnterprise,
                isTracking,
                setIsTracking,
                isShowSchedule,
                setIsShowSchedule,
                setIsShowRequest,
                isShowRequest,
                cargoLink,
                setCargoLink,
                fleetLink,
                setFleetLink,
                referalLink,
                setReferalLink,
                profileLink,
                setProfileLink,
                analysisLink,
                setAnalysisLink,
                walletLink,
                setWalletLink,
                getHelpLink,
                setGetHelpLink,
                contactLink,
                setContactLink,
                newDeals,
                setNewDeals,
                driver,
                setDriver,
                showFleet,
                setShowFleet,
                showRoutes,
                setShowRoutes,
                showAddRouteMod,
                setShowAddRouteMod,
                manualRoute,
                setManualRoute,
                assignDriverRoute,
                setAssignDriverRoute,
                showNoFleet,
                setShowNoFleet,
                showFirstDealProcess,
                setShowFirstDealProcess,
                bookingSelectedDates,
                setBookingSelectedDates,
                showAddVehicle,
                setShowAddVehicle,
                ShowVehicleDetails,
                setShowVehicleDetails,
                selected,
                setSelected,
                showAddVMod,
                setShowAddVMod,
                userUid,
                setUserUid,
                showAddTrailer,
                setShowAddTrailer,
                showAddTrailerMod,
                setShowAddTrailerMod,
                showAssign,
                setShowAssign,
                setAssignPanel,
                assignPanel,
                showAssignPanel,
                setShowAssignPanel,
                routeName,
                setRouteName,
                isDealRequests,
                setIsDealRequests,
                isDealSettlement,
                setIsDealSettlement,
                isDealBooking,
                setIsDealBooking,
                name,
                setName,
                allContacts,
                setAllContacts,
                isEmailVerified,
                setIsEmailVerified,
                isQuote,
                setIsQuote,
                setPopUpQuote,
                popUpQuote,
                endDate,
                setEndDate,
                startDate,
                setStartDate,
                operationDaysNew,
                setOperationDaysNew,
                includeHolidays,
                setIncludeHolidays,
                collectionBays,
                setCollectionBays,
                setDropOffBays,
                dropOffBays,
                gateInOutDuration,
                setGateInOutDuration,
                bookingSuccess,
                setBookingSuccess,
                isLoading,
                setIsLoading,
                rateIndication,
                setRateIndication,
                isBookClicked,
                setIsBookClicked,
                trackingLink,
                setTrackingLink,
                scheduleLink,
                setScheduleLink,
                requestLink,
                setRequestLink,
                homeLink,
                setHomeLink,
                isShipperUpload,
                setIsShipperUpload,
                selectedBookPOD,
                setSelectedBookPOD,
                isFleetOwnerUpload,
                setIsFleetOwnerUpload,
                setSelectedBookInvoice,
                selectedBookInvoice,
                isBookingSummary,
                setIsBookingSummary,
                setUniversalSelectedBook,
                universalSelectedBook,
                isUseOwnTransport,
                setIsUseOwnTransport,
                transporterContact,
                setTransporterContact,
                showTrackFilter,
                setShowTrackFilter,
                fleetTracking,
                setFleetTracking,
                setIsCargoMenu,
                setIsDashboard,
                isDashboard,
                isCargoMenu,
            }}
        >
            {children}
        </Context.Provider>
    )
}
export const useStateContext = () => useContext(Context);