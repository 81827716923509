import React, {useState, useEffect} from 'react'
import PlacesAutocomplete from 'react-places-autocomplete';
import '../css/locationAuto.css';
import { useStateContext } from '../context/BookingAddress'

export default function LocationSearchInputThree() {
  // // const [address, setAddress] = useState("");
  const { 
    addressRegistration,
    setAddressRegistration
  } = useStateContext();

  const handleChange = (value) => {
    setAddressRegistration(value);
  }
  const handleSelect = (value) => {
    setAddressRegistration(value);
  }
  return (
    <div>
      <PlacesAutocomplete value={addressRegistration} onChange={handleChange} onSelect={handleSelect}>
          {({getInputProps, suggestions, getSuggestionItemProps, loading})=>(
            <div className='scrollbar-hide' style={{maxHeight:suggestions.length ? "150px" : "fit-content", height: suggestions.length ? "150px" : "fit-content", overflow:"scroll"}}>
              <input {...getInputProps({
                placeholder: 'Physical address',
                className: 'location-search-input-reg',
              })}/>
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const style = suggestion.active ? 
                {background: "#f9dd07", cursor: "pointer"} :
                {background: "white", cursor: "pointer"}

                return(
                  <div style={{fontSize:"1rem"}} {...getSuggestionItemProps(suggestion, {style})}>
                    {suggestion.description}
                  </div>
                )
              })}
            </div>
          )}
      </PlacesAutocomplete>
    </div>
  )
}
