import React, {useState, useEffect} from 'react'
import {BsChevronLeft} from 'react-icons/bs'
import { addDays, differenceInCalendarDays } from "date-fns";
import { useStateContext } from '../context/DashboardStateContext'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { PickersDay } from '@mui/x-date-pickers/PickersDay'
import moment from 'moment'
import dayjs from 'dayjs';
import startOfDay from "date-fns/startOfDay";
import {useFleetStateContext} from '../context/FleetDealsContext';
import '../css/fleetdeal.css';
import firebase from 'firebase';
import {IoCheckmarkCircleOutline} from 'react-icons/io5'
import FirstDealRespondAssignDriver from './FirstDealRespondAssignDriver';

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) => prop !== "selected"
  })(({ theme, selected }) => ({
    ...(selected && {
      backgroundColor: "#fce200",
      color: "black",
      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary.dark
      },
      borderTopLeftRadius: "50%",
      borderBottomLeftRadius: "50%",
      borderTopRightRadius: "50%",
      borderBottomRightRadius: "50%"
    })
  }));

export default function DealSettleAccept() {
    const [fleetCounter, setFleetCounter] = useState('')
    const [isCounterSuccess, setIsCounterSuccess] = useState(false);
    const {
        allDeals,
        selectedBookingDeal,
        isFirstDealAssign,
        setIsFirstDealAssign,
        values,
        setValues,
        firstBatch,
        setFirstBatch,
        setIsDealSettleAccept,
        datesProperlyFormated,
        setDatesProperlyFormated
    } = useFleetStateContext();

    const {
        userUid,
        setShowFirstDealProcess,
    } = useStateContext();

    const startDate = selectedBookingDeal ? selectedBookingDeal[0].dates_time_selection.start_date_string : new Date();
    const endDate = selectedBookingDeal ? selectedBookingDeal[0].dates_time_selection.end_date_string : new Date();

    const findDate = (dates, date) => {
      const dateTime = date.getTime();
      return dates.find((item) => item.getTime() === dateTime);
    };

    const findIndexDate = (dates, date) => {
      const dateTime = date.getTime();
      return dates.findIndex((item) => item.getTime() === dateTime);
    };

    const renderPickerDay = (date, selectedDates, pickersDayProps) => {
      if (!values) {
        return <PickersDay {...pickersDayProps} />;
    }

    const selected = findDate(values, date);

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        selected={selected}
      />
    );
  };

  return (
    <div className='w-full h-full top-0 right-0 absolute scrollbar-hide left-0 bottom-0 bg-white rounded-tr-lg rounded-br-lg' style={{overflow:"scroll"}}>
        <div className='w-full h-full p-4'>
            <div className='w-full  relative flex justify-center items-center'>
                <p
                    className='absolute left-0 cursor-pointer font-bold'
                    style={{fontSize:"1.5rem", }}
                    onClick={() => setIsDealSettleAccept(false)}
                ><BsChevronLeft/>
                </p>
                <p className='' style={{fontSize:"1.1rem"}}>Loading Dates</p>
            </div>
            <div className='flex justify-center items-center flex-col mt-3'>
                <p className='mt-3' style={{fontSize:".9rem"}}>Select dates</p>
                <div className='mt-1 flex justify-center items-center' style={{width:"100%",background:"#F2F2F2", height:"fit-content"}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <StaticDatePicker
                            defaultValue={dayjs('2022-04-17')}
                            displayStaticWrapperAs="desktop"
                            label="Week picker"
                            // value={values}
                            onChange={(newValue) => {
                            //copying the values array
                            const array = [...values];
                            const date = startOfDay(newValue);
                            const index = findIndexDate(array, date);
                            if (index >= 0) {
                                array.splice(index, 1);
                            } else {
                                array.push(date);
                            }
                            setValues(array);
                            }}
                            maxDate={endDate}
                            minDate={startDate}
                            renderDay={renderPickerDay}
                            renderInput={(params) => <TextField {...params} />}
                            inputFormat="'Week of' MMM d"
                        />
                    </LocalizationProvider>
                </div>
                <p className='my-9' style={{fontSize:"1rem"}}>Select dates and click "Accept"</p>
                <button
                    className='p-3 py-1 rounded-xl' style={{background:"#fce200", width:"100%"}}
                    onClick={() => {
                      setIsFirstDealAssign(true)
                      const eachLoads = values.map((item, i) => {
                        return {dates : item, loads : item.loads = 0}
                      })
                      const proper = eachLoads.map(element => {
                        var obj = {};
                         obj[element.dates.getFullYear() + "-" + element.dates.getMonth() + "-" + element.dates.getDay()] = {
                            0: {
                                booking_id: selectedBookingDeal[0].booking_id,
                                date: ((new Date(element.dates)).getFullYear() + "-" + (new Date(element.dates)).getMonth() + "-" + (new Date(element.dates)).getDay()),
                                duration: selectedBookingDeal[0].total_duration,
                                loads:  element.loads
                            }
                        }
                         return obj
                     });

                     const bookingRef = firebase.database().ref("booking").child(selectedBookingDeal[0].booking_id)
                     const fleetRef = firebase.database().ref("fleets").child(userUid).child("booking_bids").child(selectedBookingDeal[0].booking_id)

                     fleetRef.on("value", snapshot => {
                        const agreedPrice = snapshot.val().price
                        bookingRef.update({
                          AmountAccepted: agreedPrice[agreedPrice.length - 1]
                        })
                     })

                      proper.forEach(item =>  {
                          setDatesProperlyFormated(prev => ({
                                  ...prev,
                                  ...item,
                          }))
                      })

                      setFirstBatch(eachLoads)
                      fetch("https://zipimail.onrender.com/invoiceEmail", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                           email: "rambudatshilidzi1@gmail.com",
                           name:"tshilidzi"
                        })
                    })
                        .then(response => response.json())
                        .then(response => console.log(JSON.stringify(response)))
                    }}
                >
                    Accept
                </button>

            </div>
        </div>
        {isCounterSuccess &&
          <div
            className='absolute flex justify-center items-center rounded-xl p-4 w-full h-fit right-0 bottom-0'
            style={{
                top:"50%",
            }}>
              <div
                style={{
                  background:"#fff",
                  top:"50%",
                  height:"fit-content"
                }}
                className='flex rounded-xl flex-col justify-center items-center p-4'
              >
                  <p style={{fontSize:"2.5rem", color:"#000"}}><IoCheckmarkCircleOutline/></p>
                  <p className='text-center' style={{fontSize:"1rem"}}>Counter offer successfully sent</p>
              </div>
          </div>
        }
        {isFirstDealAssign &&
          <FirstDealRespondAssignDriver/>
        }
    </div>
  )
}
