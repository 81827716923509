import firebase from './firebase-config';

export const fetchDriverData = (driverIds) => {
    if (!driverIds || driverIds.length === 0) {
      // Handle the case when the array is empty or not provided
      return Promise.resolve([]); // Return an empty array or handle it as needed
    }

    const driverDataPromises = driverIds.map((item) => {
      return new Promise((resolve, reject) => {
        if (!item) {
          // Handle the case when the UID is missing
          console.log("No driver UID provided");
          resolve(null); // Resolve with null or handle it as needed
        } else {
          firebase
            .database()
            .ref("drivers")
            .child(item)
            .once("value")
            .then((snapshot) => {
              const driverInfo = snapshot.val();
              resolve(driverInfo);
            })
            .catch((error) => {
              console.error("Error fetching driver data:", error);
              reject(error);
            });
        }
      });
    });

    return Promise.all(driverDataPromises);
};

export const fetchShipperData = async (shipperUID) => {
    try {
        const snapshot = await firebase
          .database()
          .ref('booking_party')
          .child(shipperUID)
          .once('value');

        if (snapshot.exists()) {
          const shipperData = snapshot.val();
          return shipperData;
        } else {
          // Handle the case where shipper data does not exist for the given UID
          return null;
        }
      } catch (error) {
        // Handle any errors that occur during the database fetch
        console.error('Error fetching shipper data:', error);
        throw error; // Optionally re-throw the error to handle it elsewhere
      }
}

export const fetchCoordinatesInbound = (address) => {
  const apiKey = 'AIzaSyCDv9ViM5f03ZnI9i8vKz9xI_conVCyKx8'; // Replace with your Google Maps API key

  // Construct the URL for the geocoding API request
  const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`;

  // Return a promise
  return new Promise((resolve, reject) => {
      // Send a GET request to the API
      fetch(apiUrl)
          .then(response => response.json())
          .then(data => {
              if (data.status === 'OK' && data.results.length > 0) {
                  // Extract latitude and longitude from the response
                  const location = data.results[0].geometry.location;
                  const latitude = location.lat;
                  const longitude = location.lng;

                  const coords = { lat: latitude, lng: longitude };
                  resolve(coords); // Resolve the promise with coordinates
              } else {
                  console.log('Address not found or API error');
                  reject('Address not found or API error'); // Reject the promise on error
              }
          })
          .catch(error => {
              console.error('Error:', error);
              reject(error); // Reject the promise on error
          });
  });
};




