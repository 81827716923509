import React from 'react'
import { ImUser } from 'react-icons/im'
import { AiFillCamera } from "react-icons/ai"
import { HiIdentification } from 'react-icons/hi'
import { AiOutlinePlus } from 'react-icons/ai'
import {useFleetStateContext} from '../context/FleetDealsContext'
import { height } from '@mui/system'
import { useStateContext } from '../context/DashboardStateContext'
import RouteMap from './AddRouteManuallyMap'
import PlacesAutoCompleteStart from './AutoComManualMapStart'
import PlacesAutoCompleteEnd from './AutoComManualMapEnd'


export default function ManualRouteAdd() {

    const {
        setAddDriverForm
    } = useFleetStateContext();

    const {
        setManualRoute,
        assignDriverRoute, 
        setAssignDriverRoute,
        routeName
    } = useStateContext();

  return (
    <div 
        className='w-full h-full flex justify-center pointer-events-auto items-center scrollbar-hide absolute top-0 left-0 right-0 bottom-0'
        style={{background:"#00000066"}}
    >
        <div 
            className='h-full cursor-pointer' 
            style={{width:"12.5%"}}   
            onClick={() => {
                setManualRoute(false)
            }}
        ></div>
        <div style={{width:"75%", height:"100%"}}>
            <div className='cursor-pointer' style={{width:"100%", height:"5%"}}onClick={() => {
                setManualRoute(false)
            }}></div>
            <div 
                className='rounded-xl flex scrollbar-hide justify-between'
                style={{
                    background:"#F2F2F2", 
                    height:"90%"
                }}
            >
                    <div className='rounded-tl-xl rounded-bl-xl flex justify-center items-center' style={{width:"65%"}}>
                        <RouteMap/>
                    </div>
                    <div className='p-4 scrollbar-hide' style={{width:"35%", height:"auto", overflow:"scroll"}}>
                        <p className='' style={{fontSize:"1.1rem"}}>Create a route</p>
                        <div className='mt-3 flex justify-center items-end' style={{height:"16rem"}}>
                            <p className='' style={{textAlign:"center", fontSize:"1.1rem"}}>Enter addresses below or click on the map to create a point or stop.</p>
                        </div>
                        <div className='mt-10 scrollbar-hide'>
                            <p className='mb-2' style={{fontSize:".9rem", width:"100%"}}>{routeName}</p>
                            {/* <div className='w-full h-9 mb-3 px-3 rounded-xl' style={{background:"rgb(214, 214, 214)"}}> */}
                                <PlacesAutoCompleteStart/>
                                <PlacesAutoCompleteEnd/>

                            {/* </div> */}
                            <input type="text" className='w-full h-9 mb-3 px-3 rounded-xl' placeholder='Waypoints' style={{background:"rgb(214, 214, 214)"}}/>
                            <input type="text" className='w-full h-9 mb-3 px-3 rounded-xl' placeholder='Destination' style={{background:"rgb(214, 214, 214)"}}/>
                            <div className='flex justify-center items-center w-full'>
                                <button className="px-7 py-2 rounded-xl" style={{background:"#fce200"}}>Save route</button>
                            </div>
                        </div>
                    </div>
            </div>
            <div className='cursor-pointer' style={{width:"100%", height:"5%"}}onClick={() => {
                setManualRoute(false)
            }}></div>
        </div>
        
        <div 
            className='h-full cursor-pointer' 
            style={{width:"12.5%"}}
            onClick={() => {
                setManualRoute(false)
            }}
        >

        </div>
    </div>
  ) 
}
