import React, {useEffect, useState} from 'react'
import {useFleetStateContext} from '../context/FleetDealsContext'
import SettleOfferUpdate from './SettleOfferUpdate';
import FirstDealRespondAssignDriver from './FirstDealRespondAssignDriver';
import FirstDealRespond from './FirstDealRespond';
import { useStateContext } from '../context/DashboardStateContext'
import DealSettleAccept from './DealSettleAccept';
import firebase from '../firebase-config'

export default function DealSettlements() {
    const [isOfferUpdate, setIsOfferUpdate] = useState(false)
    const [offerStillPending, setOfferStillPending] = useState(false);
    const [allFleets,setAllFleets] = useState([]);
    const [allPrices, setAllPrices] = useState([])
    // const [isAssignDriverShown, setIsAssignDriverShown] = useState(false)
    const {
        allDeals,
        selectedBookingDeal,
        counterOfferPrice,
        setCounterOfferPrice,
        setIsFirstDealAssign,
        isFirstDealAssign,
        isDealSettleAccept,
        setIsDealSettleAccept
    } = useFleetStateContext();

    const {
        showFirstDealProcess,
        setShowFirstDealProcess,
        userUid
    } = useStateContext();

    const adhoc = selectedBookingDeal[0].prerequisites.ad_hoc_services
    const documentation = selectedBookingDeal[0].prerequisites.documentation
    const protective = selectedBookingDeal[0].prerequisites.personal_protective
    const vehicle = selectedBookingDeal[0].prerequisites.vehicle_equipment

    console.log("the selected book deal", selectedBookingDeal)

    const [bookingPartyCounterOffer, setBookingPartyCounterOffer] = useState(0)

    useEffect(() => {
        if(selectedBookingDeal){
          const selectedBookFleetID = selectedBookingDeal[0].booking_bids_fleet_id
          // Check if userUid is present in the array
          const theFleet = selectedBookFleetID?.filter(fleetId => fleetId === userUid)[0];
          console.log("the fleet", theFleet)

          if(theFleet){
              //now get the offer price from the fleet for the booking
              firebase.database().ref("/fleets/").child(userUid).child("booking_bids").child(selectedBookingDeal[0].booking_id).on("value", snapshot => {
                setAllPrices(snapshot.val())
                setBookingPartyCounterOffer(snapshot.val()?.price[1])
              })

          }

        }
      },[selectedBookingDeal])

    // let bookingPartyCounterOffer = selectedBookingDeal[0]?.biddingPrices?.price[1] || ""
    const bookingPartyAccepted = selectedBookingDeal[0]?.biddingPrices?.accepted || ""

    console.log("booking party accepted", bookingPartyCounterOffer)
    // useEffect(() => {
    //   firebase.database().ref('/fleets/' + selectedBookingDeal[0].booking_bids_fleet_id[0] + "/booking_bids/" + "/" + selectedBookingDeal[0].booking_id + "/").on('value', snapshot => {
    //     setCounterOfferPrice(snapshot.val().price[1])
    //   })
    // }, [])

    // console.log("the counter offer", counterOffer)
    console.log("the offer update", isOfferUpdate)

  return (
    <div className='h-fit rounded-lg flex justify-between relative' style={{width:"68%", background:"rgb(218, 218, 218)", height:"644px"}}>
        <div className='p-3 scrollbar-hide px-4' style={{width:"65%", overflow:"scroll"}}>
            <div style={{background:"#fff"}} className='p-3 rounded-xl'>
                <p style={{color:"#606060"}}>Start</p>
                <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}>{selectedBookingDeal.length && selectedBookingDeal[0].puDetails.puAddress}</p>
                <p style={{color:"#606060"}}>Gate in - gate out time: {selectedBookingDeal.length && selectedBookingDeal[0]?.puDetails?.puGateInGateOut} Min</p>
            </div>
            <div style={{background:"#fff"}} className='p-3 mt-4 rounded-xl relative'>
                <p style={{color:"#606060"}}>End</p>
                <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}>{selectedBookingDeal.length && selectedBookingDeal[0]?.doDetails?.doAddress}</p>
                <p>Gate in - gate out time: {selectedBookingDeal.length && selectedBookingDeal[0]?.doDetails?.doGateInGateOut} Min</p>
                <div className='absolute font-bold right-0 bottom-0 p-4 px-5 flex justify-between items-center rounded-full w-fit'
                style={{background:"#fff", left:"40%", top:"85%", color:"#606060", boxShadow: "0px 0px 60px #00000029"}
                }
                >
                 {selectedBookingDeal.length && selectedBookingDeal[0]?.total_distance} km
                </div>
            </div>
            <div className='flex mt-8 justify-between'>
                <div className='p-3 rounded-xl' style={{width:"32%", background:"#fff"}}>
                    <p style={{color:"#606060"}}>Vehicle Type</p>
                    <p className='font-bold' style={{fontSize:"1rem",color:"#606060"}}>{selectedBookingDeal.length && selectedBookingDeal[0]?.prerequisites.vehicle_type[0]}</p>
                </div>
                <div className='p-3 rounded-xl' style={{width:"30%", background:"#fff"}}>
                    <p style={{color:"#606060"}}>Loads</p>
                    <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}>{selectedBookingDeal.length && selectedBookingDeal[0]?.actual_loads_for_cargo}</p>
                </div>
                <div className='p-3 rounded-xl' style={{width:"32.5%", background:"#fff"}}>
                    <p style={{color:"#606060"}}>Date created</p>
                    <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}><p>{selectedBookingDeal.length && selectedBookingDeal[0]?.date_created}</p></p>
                </div>
            </div>
            <div
                className='mt-4 p-3 rounded-xl scrollbar-hide'
                style={{
                    background:"#fff",
                    maxHeight:
                    !selectedBookingDeal[0].prerequisites.ad_hoc_services &&
                    !selectedBookingDeal[0].prerequisites.documentation &&
                    !selectedBookingDeal[0].prerequisites.personal_protective &&
                    !selectedBookingDeal[0].prerequisites.vehicle_equipment ?
                    "100px"
                    : "164px",
                    height:
                    !selectedBookingDeal[0].prerequisites.ad_hoc_services &&
                    !selectedBookingDeal[0].prerequisites.documentation &&
                    !selectedBookingDeal[0].prerequisites.personal_protective &&
                    !selectedBookingDeal[0].prerequisites.vehicle_equipment ?
                    "100px"
                    : "164px",
                    overflow:"scroll"
                }}
            >
                {selectedBookingDeal.length && selectedBookingDeal[0].prerequisites.ad_hoc_services &&
                    <>
                        <p className='font-bold' style={{fontSize:".9rem"}}>Ad hoc services</p>
                        {adhoc.map(item => (
                            <p>{item}</p>
                        ))}
                    </>
                }
                {selectedBookingDeal.length && selectedBookingDeal[0].prerequisites.documentation &&
                    <>
                        <p className='font-bold' style={{fontSize:".9rem"}}>Documentation</p>
                        {documentation.map(item => (
                            <p>{item}</p>
                        ))}
                    </>
                }
                {selectedBookingDeal.length && selectedBookingDeal[0].prerequisites.personal_protective &&
                    <>
                        <p className='font-bold' style={{fontSize:".9rem"}}>Personal Protective Equipment</p>
                        {protective.map(item => (
                            <p>{item}</p>
                        ))}
                    </>
                }
                {selectedBookingDeal.length && selectedBookingDeal[0].prerequisites.vehicle_equipment &&
                    <>
                        <p className='font-bold' style={{fontSize:".9rem"}}>Vehicle Equipment</p>
                        {vehicle.map(item => (
                            <p>{item}</p>
                        ))}
                    </>
                }
            </div>
            <div className='mt-4'>
                <button className='rounded-full' style={{background:"#213164", color:"#fff", padding:".1rem .9rem", fontSize:".8rem"}}>
                Progress
                </button>
                <p className='mt-2'  style={{color:"#606060"}}>
                    There is a counter offer that was made on this deal, see below for details.
                </p>
                {counterOfferPrice &&
                    <div className='p-3 mt-4 w-6/12 rounded-xl' style={{background:"#fff"}}>
                        {bookingPartyAccepted &&
                            <p>Offer accepted</p>
                        }
                        {bookingPartyCounterOffer &&
                            <p>Counter Offer</p>
                        }
                        {!bookingPartyCounterOffer && !bookingPartyAccepted &&
                            <p>Offer pending....</p>
                        }
                        {bookingPartyAccepted || bookingPartyCounterOffer &&
                            <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}>R{bookingPartyCounterOffer ? bookingPartyCounterOffer : allPrices?.price[0] }</p>
                        }
                    </div>
                }
            </div>
        </div>

        <div className='p-4 rounded-br-lg relative rounded-tr-lg scrollbar-hide' style={{width:"35%", background:"#efefef", overflow:"scroll"}}>
            <div className='p-3 px-4 rounded-xl' style={{background:"#fff"}}>
                <p style={{color:"#606060"}}>Rate required</p>
                <p className='font-bold mt-2 mb-2' style={{fontSize:"1.5rem", color:"#606060"}}>R{selectedBookingDeal.length && selectedBookingDeal[0]?.rate_required}</p>
                <p style={{color:"#606060"}}>Goods in Transit</p>
                <p className='font-bold' style={{fontSize:"1.1rem",color:"#606060"}}>R{selectedBookingDeal.length && selectedBookingDeal[0]?.prerequisites?.goods_in_transit || "1 000 000"}</p>
            </div>
            <div className='mt-4 pb-3' style={{borderBottom:"1px solid #bebebe", lineHeight:"23px"}}>
                <p style={{color:"#606060"}}>Cargo</p>
                <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}>{selectedBookingDeal.length && selectedBookingDeal[0]?.cargoInformation?.productName}</p>
                {/* <p style={{color:"#606060"}}>HS Code</p> */}
                {/* <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}></p> */}
                <div className='flex justify-between'>
                    <div>
                        <p  style={{color:"#606060"}}>Packaging</p>
                        <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}>{selectedBookingDeal.length && selectedBookingDeal[0]?.cargoInformation?.packageType}</p>
                    </div>
                    <div style={{width:"26%"}}>
                        <p  style={{color:"#606060"}}>Quantity</p>
                        <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}>{selectedBookingDeal.length && selectedBookingDeal[0]?.cargoInformation?.quantity}</p>
                    </div>
                </div>
                <div className='flex justify-between'>
                    <div>
                        <p  style={{color:"#606060"}}>Total Weight</p>
                        <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}>{selectedBookingDeal.length && ((selectedBookingDeal[0]?.cargoInformation?.weight)* selectedBookingDeal[0]?.cargoInformation?.quantity)}mt</p>
                        <p style={{color:"#606060"}}>Net Weight per value</p>
                        <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}>{selectedBookingDeal.length && (parseFloat(selectedBookingDeal[0]?.cargoInformation?.weight)).toFixed(2)}mt</p>
                    </div>
                    <div>
                        <p style={{color:"#606060"}}>Total Volume</p>
                        <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}>{selectedBookingDeal.length && ((parseFloat((selectedBookingDeal[0]?.cargoInformation?.volume)* selectedBookingDeal[0]?.cargoInformation?.quantity))).toFixed(2)}m&#179;</p>
                        <p style={{color:"#606060"}}>Net Volume</p>
                        <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}>{selectedBookingDeal.length && (parseFloat(selectedBookingDeal[0]?.cargoInformation?.volume)).toFixed(2)}m&#179;</p>
                    </div>
                </div>
            </div>
            <div>
                {/* <p style={{color:"#606060"}}>Hazardous Rating</p>
                <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}>Hazardous</p>
                <div className='flex justify-between'>
                    <div>
                    <p style={{color:"#606060"}}>IMDG Number</p>
                    <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}>IMD57457</p>
                    </div>
                    <div> */}
                    <p style={{color:"#606060"}}>UN Number</p>
                    <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}>UN328746</p>
                    {/* </div>
                </div>
                <p style={{color:"#606060"}}>HS Code</p>
                <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}>2345776567899</p> */}
                <p style={{color:"#606060"}}>Temp Control</p>
                <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}>{selectedBookingDeal.length && selectedBookingDeal[0]?.cargoInformation?.hazard?.min_temp}&#176;C to {selectedBookingDeal.length && selectedBookingDeal[0]?.cargoInformation?.hazard?.max_temp}&#176;C</p>
            </div>
            <div className='flex absolute left-0 right-0 mb-4 bottom-0 justify-center item-center'>
                <button
                    className='p-3 py-1 rounded-xl'
                    style={{background:"#fce200", width:"92%"}}
                    onClick={() => {
                        if(!bookingPartyAccepted && !bookingPartyCounterOffer){
                            setOfferStillPending(true);
                        }else{
                            setIsOfferUpdate(true)
                        }
                    }}
                >
                    Respond
                </button>
            </div>
            {isOfferUpdate &&
                <SettleOfferUpdate
                    setIsOfferUpdate={setIsOfferUpdate}
                    setIsFirstDealAssign={setIsFirstDealAssign}
                    bookingPartyCounterOffer={bookingPartyCounterOffer}
                />
            }

            {isFirstDealAssign &&
                <FirstDealRespondAssignDriver/>
            }

            {/* {showFirstDealProcess &&
                <FirstDealRespond/>
            } */}
            {isDealSettleAccept &&
                <DealSettleAccept/>
            }

            {offerStillPending &&
                <div className='absolute h-full w-full flex items-center justify-center top-0 right-0 bottom-0 left-0'>
                    <div className='p-5 rounded-xl flex items-center justify-center flex-col' style={{width:"244px", height:"fit-content", background:"#fff"}}>
                        <p style={{fontSize:"1rem", textAlign:"center", marginBottom:"1rem"}}>The booking party has not yet accepted or countered your offer yet please wait.</p>
                        <button className='rounded-xl px-2 py-1' style={{background:"#fce200", fontSize:".8rem"}} onClick={() => setOfferStillPending(false)}>Ok</button>
                    </div>
                </div>
            }
        </div>

    </div>
  )
}
