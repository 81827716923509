import React, {useState, useEffect} from 'react'
import { FaAngleDown } from "react-icons/fa6";
import { FaAngleUp } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import { useStateContext } from '../context/DashboardStateContext'

const TrackingFilter = () => {
    const [arrivalTime, setArrivalTime] = useState(false);
    const [companyName, setCompanyName] = useState(false);
    const [companyNameOff, setCompanyNameOff] = useState(false);
    const [bookingDate, setBookingDate] = useState(false);
    const [arrivalTimeText, setArrivalTimeText] = useState("Arrival Time");
    const [companyNameText, setCompanyNameText] = useState("Company Name (Loading)");
    const [companyNameTextOff, setCompanyNameTextOff] = useState("Company Name (Off-loading)");
    const [bookingDateText, setBookingDateText] = useState("Booking Date");

    const {
        showTrackFilter,
        setShowTrackFilter
    } = useStateContext();

  return (
    <div className='w-full h-full bg-[#0000007e] absolute flex flex-col justify-center items-center'>
        <div className='bg-[#ffffff] rounded-2xl w-[50%] h-[150px] p-4'>
            <h1 className='text-[1.1rem] font-bold'>Filters</h1>

            <div className='flex justify-start items-center'>
                <div
                    className='relative flex justify-between items-center w-fit px-3 cursor-pointer rounded-xl pr-10 py-1 h-fit bg-[#e8e8e8]'
                    onClick={() => {
                        setArrivalTime(!arrivalTime);
                    }}
                >
                    <p
                        className='m-0 p-0 text-[.8rem] w-[70px] h-[15px] whitespace-nowrap overflow-hidden text-ellipsis'
                    >
                        {arrivalTimeText}
                    </p>
                    {arrivalTime ?
                        <p className='p-0 m-0 absolute right-0 mr-3'><FaAngleUp /></p>
                        :
                        <p className='p-0 m-0 absolute right-0 mr-3'><FaAngleDown/></p>
                    }
                    {arrivalTime &&
                        <div
                            className='absolute left-0 top-8 text-[.9rem] w-[230px] border bg-[#fff]'
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <div
                                className={`py-1 px-2 ${arrivalTimeText === "Distance: Closest to furthest" && 'bg-[#DEDEDE]'}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setArrivalTimeText("Distance: Closest to furthest")
                                    setArrivalTime(false);
                                }}
                            >
                                Distance: Closest to furthest
                            </div>
                            <div
                                className={`py-1 px-2 ${arrivalTimeText === "Distance: Furthest to closest" && 'bg-[#DEDEDE]'}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setArrivalTimeText("Distance: Furthest to closest")
                                    setArrivalTime(false);
                                }}
                            >
                                Distance: Furthest to closest
                            </div>
                        </div>
                    }
                </div>

                <div
                    className='relative ml-4 flex justify-between items-center w-fit px-3 cursor-pointer rounded-xl pr-10 py-1 h-fit bg-[#E8E8E8]'
                    onClick={() => {
                        setCompanyName(!companyName);
                    }}
                >
                    <p
                        className='m-0 p-0 text-[.8rem] w-[150px] h-[15px] whitespace-nowrap overflow-hidden text-ellipsis'
                    >
                        {companyNameText}
                    </p>
                    {companyName ?
                        <p className='p-0 m-0 absolute right-0 mr-3'><FaAngleUp /></p>
                        :
                        <p className='p-0 m-0 absolute right-0 mr-3'><FaAngleDown/></p>
                    }
                    {companyName &&
                        <div
                            className='absolute left-0 top-8 text-[.9rem] w-[230px] border bg-[#fff]'
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <div
                                className={`py-1 px-2 border-b ${companyNameText === "Distance: Closest to furthest" && 'bg-[#DEDEDE]'}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setCompanyNameText("Zipi logistics")
                                    setCompanyName(false);
                                }}
                            >
                                Zipi logistics
                            </div>
                            <div
                                className={`py-1 px-2 ${companyNameText === "Distance: Furthest to closest" && 'bg-[#DEDEDE]'}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setCompanyNameText("Land and sea shipping")
                                    setCompanyName(false);
                                }}
                            >
                                Land and sea shipping
                            </div>
                        </div>
                    }
                </div>
                <div
                    className='relative ml-4 flex justify-between items-center w-fit px-3 cursor-pointer rounded-xl pr-10 py-1 h-fit bg-[#E8E8E8]'
                    onClick={() => {
                        setCompanyNameOff(!companyNameOff);
                    }}
                >
                    <p
                        className='m-0 p-0 text-[.8rem] w-[180px] h-[15px] whitespace-nowrap overflow-hidden text-ellipsis'
                    >
                        {companyNameTextOff}
                    </p>
                    {companyNameOff ?
                        <p className='p-0 m-0 absolute right-0 mr-3'><FaAngleUp /></p>
                        :
                        <p className='p-0 m-0 absolute right-0 mr-3'><FaAngleDown/></p>
                    }
                    {companyNameOff &&
                        <div
                            className='absolute left-0 top-8 text-[.9rem] w-[230px] border bg-[#fff]'
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <div
                                className={`py-1 px-2 border-b ${companyNameTextOff === "Distance: Closest to furthest" && 'bg-[#DEDEDE]'}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setCompanyNameTextOff("Zipi logistics")
                                    setCompanyNameOff(false);
                                }}
                            >
                                Zipi logistics
                            </div>
                            <div
                                className={`py-1 px-2 ${companyNameTextOff === "Distance: Furthest to closest" && 'bg-[#DEDEDE]'}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setCompanyNameTextOff("Land and sea shipping")
                                    setCompanyNameOff(false);
                                }}
                            >
                                Land and sea shipping
                            </div>
                        </div>
                    }
                </div>
                <div
                    className='relative ml-4 flex justify-between items-center w-fit px-3 cursor-pointer rounded-xl pr-10 py-1 h-fit bg-[#E8E8E8]'
                    onClick={() => {
                        setBookingDate(!bookingDate);
                    }}
                >
                    <p
                        className='m-0 p-0 text-[.8rem] w-[80px] h-[15px] whitespace-nowrap overflow-hidden text-ellipsis'
                    >
                        {bookingDateText}
                    </p>
                    {bookingDate ?
                        <p className='p-0 m-0 absolute right-0 mr-3'><FaAngleUp /></p>
                        :
                        <p className='p-0 m-0 absolute right-0 mr-3'><FaAngleDown/></p>
                    }
                    {bookingDate &&
                        <div
                            className='absolute right-0 top-8 text-[.9rem] w-[230px] border bg-[#fff]'
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <div
                                className={`py-1 px-2 border-b ${bookingDateText === "Date: Oldest to latest" && 'bg-[#DEDEDE]'}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setBookingDateText("Date: Oldest to latest")
                                    setBookingDate(false);
                                }}
                            >
                                Date: Oldest to latest
                            </div>
                            <div
                                className={`py-1 px-2 ${bookingDateText === "Date: Latest to oldest" && 'bg-[#DEDEDE]'}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setBookingDateText("Date: Latest to oldest")
                                    setBookingDate(false);
                                }}
                            >
                                Date: Latest to oldest
                            </div>
                        </div>
                    }
                </div>
            </div>

            <div className='mt-5'>
                <p className='m-0 p-0'>Applied Filters</p>
                <div className='flex flex-row items-center'>
                    {arrivalTimeText !== "Arrival Time" &&
                        <div className='p-1 ml h-[23px] rounded-[5px] flex justify-between items-center mt-1 w-[150px] border bg-[#ffffff]'>
                            <p className='m-0 p-0 w-[120px] whitespace-nowrap overflow-hidden text-ellipsis'>{arrivalTimeText}</p>
                            <p
                                className='m-0 p-0 cursor-pointer'
                                onClick={() => setArrivalTimeText("Arrival Time")}
                            >
                                <RxCross2 />
                            </p>
                        </div>
                    }
                    {companyNameText !== "Company Name (Loading)" &&
                        <div className='p-1 ml-2 h-[23px] rounded-[5px] flex justify-between items-center mt-1 w-[150px] border bg-[#ffffff]'>
                            <p className='m-0 p-0 w-[120px] whitespace-nowrap overflow-hidden text-ellipsis'>{companyNameText}</p>
                            <p
                                className='m-0 p-0 cursor-pointer'
                                onClick={() => setCompanyNameText("Company Name (Loading)")}
                            >
                                <RxCross2 />
                            </p>
                        </div>
                    }
                    {companyNameTextOff !== "Company Name (Off-loading)" &&
                        <div className='p-1 ml-2 h-[23px] rounded-[5px] flex justify-between items-center mt-1 w-[150px] border bg-[#ffffff]'>
                            <p className='m-0 p-0 w-[120px] whitespace-nowrap overflow-hidden text-ellipsis'>{companyNameTextOff}</p>
                            <p
                                className='m-0 p-0 cursor-pointer'
                                onClick={() => setCompanyNameTextOff("Company Name (Off-loading)")}
                            >
                                <RxCross2 />
                            </p>
                        </div>
                    }

                    {bookingDateText !== "Booking Date" &&
                        <div className='p-1 ml-2 h-[23px] rounded-[5px] flex justify-between items-center mt-1 w-[150px] border bg-[#ffffff]'>
                            <p className='m-0 p-0 w-[120px] whitespace-nowrap overflow-hidden text-ellipsis'>{bookingDateText}</p>
                            <p
                                className='m-0 p-0 cursor-pointer'
                                onClick={() => setBookingDateText("Booking Date")}
                            >
                                <RxCross2 />
                            </p>
                        </div>
                    }

                </div>
            </div>
        </div>
        <div className='w-[50%] mt-3 flex justify-end'>
            <p
                className='py-1 px-4 rounded-xl bg-[#f2f2f2] cursor-pointer'
                onClick={() => {
                    setArrivalTimeText("Arrival Time")
                    setCompanyNameText("Company Name (Loading)")
                    setCompanyNameTextOff("Company Name (Off-loading)")
                    setBookingDateText("Booking Date")
                }}
            >
                Clear All
            </p>
            <p
                className='py-1 ml-3 px-4 rounded-xl bg-[#f2f2f2] cursor-pointer'
                onClick={() => setShowTrackFilter(false)}
            >
                Cancel
            </p>
            <p
                className='py-1 ml-3 px-10 rounded-xl bg-[#ffe200] cursor-pointer'
                onClick={() => setShowTrackFilter(false)}
            >
                Apply
            </p>
        </div>
    </div>
  )
}

export default TrackingFilter
