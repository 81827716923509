import React from 'react'
import construction from '../icons/construction.png'

export default function Myreferrals() {
  return (
    <div className='my-referals'>
      <img src={construction} alt="construction-icon" width={50} height={50}/>
      <p style={{marginTop:"30px"}}>This page is still under construction</p>
    </div>
  )
}
