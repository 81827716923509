import React, {useState, useEffect} from 'react'
import {BsChevronLeft} from 'react-icons/bs'
import { addDays, differenceInCalendarDays } from "date-fns";
import { useStateContext } from '../context/DashboardStateContext'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { PickersDay } from '@mui/x-date-pickers/PickersDay'
import moment from 'moment'
import dayjs from 'dayjs';
import startOfDay from "date-fns/startOfDay";
import {useFleetStateContext} from '../context/FleetDealsContext';
import { StylesProvider } from "@material-ui/core/styles";
import '../css/fleetdeal.css';
import '../App.css'
import firebase from '../firebase-config'
import {IoCheckmarkCircleOutline} from 'react-icons/io5'
import FirstDealRespondAssignDriver from './FirstDealRespondAssignDriver';
// import nodemailer from "nodemailer"


const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) => prop !== "selected"
  })(({ theme, selected }) => ({
    ...(selected && {
      backgroundColor: "#fce200",
      color: "black",
      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary.dark
      },
      borderTopLeftRadius: "50%",
      borderBottomLeftRadius: "50%",
      borderTopRightRadius: "50%",
      borderBottomRightRadius: "50%"
    })
  }));

export default function FirstDealRespond() {
    const [fleetCounter, setFleetCounter] = useState('')
    const [price, setPrice] = useState("");
    const [isCounterSuccess, setIsCounterSuccess] = useState(false);
    const [emailData, setEmailData] = useState([]);

    const {
        allDeals,
        selectedBookingDeal,
        isFirstDealAssign,
        setIsFirstDealAssign,
        values,
        setValues,
        firstBatch,
        setFirstBatch,
        setDatesProperlyFormated,
        setSelectedBookingDeal,
        prevSelectedBookingDeal,
        setPrevSelectedBookingDeal,
        userData
    } = useFleetStateContext();

    const {
        userUid,
        setShowFirstDealProcess,
        isDealRequests,
        setIsDealRequests,
        isDealSettlement,
        setIsDealSettlement,
        isDealBooking,
        setIsDealBooking,
        setName,
        name
    } = useStateContext();

    const startDate = selectedBookingDeal ? selectedBookingDeal[0]?.dates_time_selection?.start_date_string : new Date();
    const endDate = selectedBookingDeal ? selectedBookingDeal[0]?.dates_time_selection?.end_date_string : new Date();

    const findDate = (dates, date) => {
      const dateTime = date.getTime();
      return dates.find((item) => item.getTime() === dateTime);
    };

    const findIndexDate = (dates, date) => {
      const dateTime = date.getTime();
      return dates.findIndex((item) => item.getTime() === dateTime);
    };

    const renderPickerDay = (date, selectedDates, pickersDayProps) => {
      if (!values) {
        return <PickersDay {...pickersDayProps} />;
    }

    const selected = findDate(values, date);

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        selected={selected}
      />
    );
   };

   useEffect(() => {
      if(selectedBookingDeal.length > 0){
        if(selectedBookingDeal[0].booking_bids_fleet_id && selectedBookingDeal[0].booking_bids_fleet_id.includes(userUid)){
          firebase.database().ref("/fleets/" + userUid).on("value", (snapshot) => {
            const allBookings = snapshot.val()?.bookings?.booking_id
            console.log("This is the data", allBookings)
          })
        }else{
          setPrice(selectedBookingDeal[0]?.rate_required)
        }
      }
   },[])

  return (
    <div className='w-full h-full top-0 right-0 absolute scrollbar-hide left-0 bottom-0 bg-white rounded-tr-lg rounded-br-lg' style={{overflow:"scroll"}}>
        <div className='w-full h-full p-4'>
            <div className='w-full  relative flex justify-center items-center'>
                <p
                    className='absolute left-0 cursor-pointer font-bold'
                    style={{fontSize:"1.5rem", }}
                    onClick={() => setShowFirstDealProcess(false)}
                ><BsChevronLeft/>
                </p>
                <p className='' style={{fontSize:"1.1rem"}}>Loading Dates</p>
            </div>
            <div className='flex justify-center items-center flex-col mt-3'>
                <p style={{fontSize:"1.1rem"}}>Asking rate excl. VAT</p>
                <p className='mt-2' style={{fontSize:"1.7rem"}}>R{selectedBookingDeal.length ? selectedBookingDeal[0]?.rate_required: allDeals[0].rate_required}</p>
                <p className='mt-3' style={{fontSize:".9rem"}}>Select dates</p>
                <div className='mt-1 flex justify-center items-center' style={{width:"100%",background:"#F2F2F2", height:"fit-content"}}>
                  <StylesProvider injectFirst>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <StaticDatePicker
                          defaultValue={dayjs('2022-04-17')}
                          displayStaticWrapperAs="desktop"
                          label="Week picker"
                          // value={values}
                          onChange={(newValue) => {
                          //copying the values array
                          const array = [...values];
                          const date = startOfDay(newValue);
                          const index = findIndexDate(array, date);
                          if (index >= 0) {
                              array.splice(index, 1);
                          } else {
                              array.push(date);
                          }
                          setValues(array);
                          }}
                          maxDate={endDate}
                          minDate={startDate}
                          renderDay={renderPickerDay}
                          renderInput={(params) => <TextField {...params} />}
                          inputFormat="'Week of' MMM d"
                      />
                    </LocalizationProvider>
                  </StylesProvider>
                </div>
                <p className='my-3' style={{fontSize:"1rem"}}>Select dates and click "Accept"</p>
                <button
                    className='p-3 py-1 rounded-xl' style={{background:"#fce200", width:"100%"}}
                    onClick={() => {
                      if(selectedBookingDeal[0].booking_id){
                      setIsFirstDealAssign(true)
                      const eachLoads = values.map((item, i) => {
                        // console.log("date length", (item.getMonth()).toString().length)
                        var array = {};
                        console.log("right format", item.getMonth()+1)
                        return {
                            dates : item,
                            loads : item.loads = 0
                        }
                      })

                        const proper = eachLoads.map(element => {
                          var obj = {};
                           obj[(element.dates.getFullYear() + "-" + ((element.dates.getMonth()).toString().length === 1 ? `0${ element.dates.getMonth()+1}` : element.dates.getMonth()+1)  + "-" + (((element.dates).toString()).substring(8,10)))] = {
                              0: {
                                  booking_id: selectedBookingDeal[0].booking_id,
                                  date: (element.dates.getFullYear() + "-" + ((element.dates.getMonth()).toString().length === 1 ? `0${ element.dates.getMonth()+1}` : element.dates.getMonth()+1)  + "-" + (((element.dates).toString()).substring(8,10))),
                                  duration: selectedBookingDeal[0].total_duration,
                                  loads:  element.loads
                              }
                          }
                           return obj
                        });
                        proper.forEach(item =>  {
                            setDatesProperlyFormated(prev => ({
                                ...prev,
                                ...item,
                            }))
                        })

                      setFirstBatch(eachLoads)

                      fetch("https://zipimail.onrender.com/invoiceEmail", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            email: "rambudatshilidzi1@gmail.com",
                            name: name,
                            due_date: ((new Date()).toISOString()).substring(0,10),
                            service_fee: 200,
                            road_haul: price,
                            disbursement_fee: selectedBookingDeal[0].prerequisites.goods_in_transit || 0,
                            sub_total: parseFloat(200 + price).toFixed(2),
                            VAT: (parseFloat(price) * 0.15).toFixed(2),
                            total: (parseFloat(price) * 0.15 + 200 + parseFloat(price)).toFixed(2),
                            booking_ref:selectedBookingDeal[0].booking_ref
                        })
                      })
                      .then(response => response.json())
                      .then(response => console.log(JSON.stringify(response)))
                    }
                    else{
                      console.log("please select");
                    }
                    }}
                >
                    Accept
                </button>
                <p className='my-5' style={{fontSize:"1rem"}}>or</p>
                <div className='flex justify-between items-center flex-row mb-3' style={{width:"100%", zIndex:"1"}}>
                    <input
                      type='number'
                      placeholder='Your rate (R)'
                      className="rounded-tl-xl px-3 rounded-bl-xl"
                      style={{width:"60%", height:"2rem",
                      background:"#F2F2F2"}}
                      onChange={(e) => {
                        setFleetCounter(e.target.value)
                      }}
                    />
                    <button
                        className='p-3 py-1 rounded-tr-xl rounded-br-xl' style={{background:"#fce200", width:"40%"}}
                        onClick={() => {
                          const fleetRef = firebase.database().ref(`/fleets/${userUid}/booking_bids/${selectedBookingDeal[0].booking_id}`);

                          fleetRef.transaction((data) => {
                            if (!data) {
                              data = {}; // Initialize data if it doesn't exist
                            }

                            if (!data.price) {
                              data.price = {}; // Initialize price if it doesn't exist
                            }

                            const prices = data.price;
                            const nextIndex = Object.keys(prices).length;

                            // Append fleetCounter to the prices list
                            prices[nextIndex] = fleetCounter;

                            // Update the existing data with the modified prices list
                            data.price = prices;

                            return data; // Return the updated data
                          });


                          const bookingRef = firebase.database().ref('/booking/' + selectedBookingDeal[0].booking_id);

                          bookingRef.transaction((data) => {
                            if (!data) {
                              data = {}; // Initialize data if it doesn't exist
                            }

                            if (!data.booking_bids_fleet_id) {
                              data.booking_bids_fleet_id = {}; // Initialize booking_bids_fleet_id if it doesn't exist
                            }

                            const bids = data.booking_bids_fleet_id;
                            const nextIndex = Object.keys(bids).length;

                            // Append userUid to the bids list
                            bids[nextIndex] = userUid;

                            // Update the existing data with the modified bids list
                            data.booking_bids_fleet_id = bids;

                            return data; // Return the updated data
                          });

                            setIsCounterSuccess(true)

                            setTimeout(() => {
                              setIsCounterSuccess(false)
                              setSelectedBookingDeal(prevSelectedBookingDeal)

                              const booking_party_uid = selectedBookingDeal[0]?.booking_party_uid
                              firebase.database().ref("/booking_party/" + booking_party_uid).on('value', (snapshot) => {

                                fetch('https://zipimail.onrender.com/newOffer', {
                                  method: 'POST',
                                  headers: {
                                    'Content-Type': 'application/json', //AJdR6hm7LYlCDzN9q8UH
                                  },
                                  body: JSON.stringify({
                                    email: snapshot.val().companyEmailData,
                                    fleet: name,
                                    booking_ref: selectedBookingDeal[0].booking_ref,
                                    starting_location: selectedBookingDeal[0].puDetails.puAddress,
                                    destination: selectedBookingDeal[0].doDetails.doAddress,
                                    distance: selectedBookingDeal[0].total_distance,
                                    total_loads: selectedBookingDeal[0].actual_loads_for_cargo,
                                    asking_rate: selectedBookingDeal[0].rate_required,
                                    offered_rate: fleetCounter,
                                    due_date: selectedBookingDeal[0].dates_time_selection.end_date_string
                                  })
                              })
                                .then(response => response.json())
                                .then(response => console.log(JSON.stringify(response)))
                              })

                            }, 2000)
                        }}
                    >
                        Quote
                    </button>
                </div>
            </div>
        </div>
        {isCounterSuccess &&
          <div
            className='absolute flex justify-center items-center rounded-xl p-4 w-full h-fit right-0 bottom-0'
            style={{
                top:"50%",
            }}>
              <div
                style={{
                  background:"#fff",
                  top:"50%",
                  height:"fit-content"
                }}
                className='flex rounded-xl flex-col justify-center items-center p-4'
              >
                  <p style={{fontSize:"2.5rem", color:"#000"}}><IoCheckmarkCircleOutline/></p>
                  <p className='text-center' style={{fontSize:"1rem"}}>Counter offer successfully sent</p>
              </div>
          </div>
        }
        {isFirstDealAssign &&
          <FirstDealRespondAssignDriver/>
        }
    </div>
  )
}
