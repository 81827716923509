import React from 'react'

export default function TestingRoute() {

  return (
    <div>
        <h1>Hello testing</h1>
    </div>
  )
}
