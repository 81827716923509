import React, { createContext, useContext, useState, useEffect } from "react";
import firebase from '../firebase-config';
import startOfDay from "date-fns/startOfDay";
const Context = createContext();

export const FleetDealsStateContext = ({ children }) => {
    const [selectedBookingDeal, setSelectedBookingDeal] = useState([])
    const [allDeals, setAllDeals] = useState([])
    const [addDriversData, setAddDriversData] = useState([])
    const [addDriverForm, setAddDriverForm] = useState(false)
    const [userUid, setUserUid] = useState([]);
    const [selectDriver, setSelectDriver] = useState([])
    const [addVehiclesData, setAddVehiclesData] = useState([])
    const [selectVehicle, setSelectVehicle] = useState([])
    const [AddTrailerData, setAddTrailerData] = useState([])
    const [selectTrailer, setSelectTrailer] = useState([])
    const [driverPanel, setDriverPanel] = useState(false);
    const [vehiclePanel, setVehiclePanel] = useState(false)
    const [trailerPanel, setTrailerPanel] = useState(false)
    const [trailerPanelSecond, setTrailerPanelSecond] = useState(false)
    const [driverAssigned, setDriverAssigned] = useState([])
    const [vehicleAssigned, setVehicleAssigned] = useState([])
    const [trailersAssigned, setTrailersAssigned] = useState([])
    const [secondTrailersAssigned, setSecondTrailersAssigned] = useState([])
    const [allTheAssignedDrivers, setAllTheAssignedDrivers] = useState([])
    const [selectedStartAddress, setSelectedStartAddress] = useState(null)
    const [selectedEndAddress, setSelectedEndAddress] = useState(null)
    const [counterOfferPrice, setCounterOfferPrice] = useState('')
    const [isFirstDealAssign, setIsFirstDealAssign] = useState(false)
    const [values, setValues] = useState([startOfDay(new Date())]);
    const [firstBatch, setFirstBatch] = useState([])
    const [DriverToAssign, setDriverToAssign] = useState([])
    const [isDealSettleAccept, setIsDealSettleAccept] = useState(false)
    const [datesProperlyFormated, setDatesProperlyFormated] = useState(null)
    const [acceptedBookingOverview, setAcceptedBookingOverview] = useState(false)
    const [selectedBookDriverInfo, setSelectedBookDriverInfo] = useState([])
    const [prevSelectedBookingDeal, setPrevSelectedBookingDeal] = useState([])
    const [pendingDrivers, setPendingDrivers] = useState([]);
    const [userData, setUserData] = useState([])

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                setUserUid(user.uid);
                setUserData(user)
                const fleetUid = user.uid
                firebase.database().ref('/drivers/').orderByChild('fleet').equalTo(fleetUid).on('value', (snapshot) => {
                    if(snapshot.exists()){
                        setAddDriversData(Object?.values(snapshot.val()));
                    }
                });
                firebase.database().ref('/pending_drivers/').orderByChild('fleet').equalTo(fleetUid).on('value', (snapshot) => {
                    if(snapshot?.exists()){
                        setPendingDrivers(Object?.values(snapshot.val()));
                    }else{
                        setPendingDrivers([])
                    }
                });
                firebase.database().ref('/fleets/' + userUid + "/trailers").on('value', (snapshot) => {
                    if(snapshot.exists()){
                        setAddTrailerData(Object?.values(snapshot.val()));
                    }
                });
                firebase.database().ref('/fleets/' + userUid + "/horses").on('value', (snapshot) => {
                    if(snapshot.exists()){
                        setAddVehiclesData(Object?.values(snapshot.val()));
                    }
                });
            }
        });
    }, [])

    useEffect(() => {
        firebase.database().ref('/booking/').on('value', (snapshot) => {
            if(snapshot.exists()){
                setAllDeals(Object?.values(snapshot.val()));
            }
        });
    }, []) 

    console.log("the assigned driver", allTheAssignedDrivers)

    return (
        <Context.Provider
            value={{
                selectedBookingDeal, 
                setSelectedBookingDeal,
                allDeals, 
                setAllDeals,
                addDriversData, 
                setAddDriversData,
                addDriverForm, 
                setAddDriverForm,
                selectDriver, 
                setSelectDriver,
                addVehiclesData, 
                setAddVehiclesData,
                selectVehicle, 
                setSelectVehicle,
                AddTrailerData, 
                setAddTrailerData,
                selectTrailer, 
                setSelectTrailer,
                driverPanel, 
                setDriverPanel,
                vehiclePanel, 
                setVehiclePanel,
                trailerPanel, 
                setTrailerPanel,
                driverAssigned, 
                setDriverAssigned,
                vehicleAssigned, 
                setVehicleAssigned,
                trailersAssigned, 
                setTrailersAssigned,
                trailerPanelSecond, 
                setTrailerPanelSecond,
                secondTrailersAssigned, 
                setSecondTrailersAssigned,
                allTheAssignedDrivers,
                allTheAssignedDrivers, 
                setAllTheAssignedDrivers,
                selectedStartAddress, 
                setSelectedStartAddress,
                selectedEndAddress, 
                setSelectedEndAddress,
                counterOfferPrice, 
                setCounterOfferPrice,
                isFirstDealAssign, 
                setIsFirstDealAssign,
                values, 
                setValues,
                firstBatch, 
                setFirstBatch,
                DriverToAssign, 
                setDriverToAssign,
                isDealSettleAccept, 
                setIsDealSettleAccept,
                datesProperlyFormated, 
                setDatesProperlyFormated,
                acceptedBookingOverview, 
                setAcceptedBookingOverview,
                selectedBookDriverInfo, 
                setSelectedBookDriverInfo,
                prevSelectedBookingDeal, 
                setPrevSelectedBookingDeal,
                userData,
                pendingDrivers, 
                setPendingDrivers
            }}
        >
            {children}
        </Context.Provider>
    )
}   
export const useFleetStateContext = () => useContext(Context);