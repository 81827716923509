import React, {useState, useEffect, useRef} from 'react'
import EnterpriseNav from '../components/EnterpriseNav'
import firebase from '../firebase-config';
import { Link } from 'react-router-dom';
import '../css/bidding.css'
import '../css/tracking.css'
import filterIcon from '../icons/bars.png'
import downIcon from '../icons/download-pdf.png'
import {Avatar} from '@mui/material';
import printIcon from '../icons/awesome-print.png'
import chatIcon from '../icons/chat.png';
import { v4 as uuidv4 } from 'uuid';
import DriverTrackingBar from '../components/DriverTrackingBar'
import BookingTrackingBar from './BookingTrackingBar';
import ReactToPdf from 'react-to-pdf';
import { useStateContext } from '../context/DashboardStateContext'
import { IoSearch } from "react-icons/io5";
import { CiFilter } from "react-icons/ci";
import { IoChevronBack } from "react-icons/io5";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useStateContextBookings } from '../context/AllBookingsContext';
import { FaPrint } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import { FaArrowRight } from "react-icons/fa6";

import delayedIcon from '../icons/delay-icon.svg'
import emptyIcon from '../icons/box.png';

function TrackingPage({

}) {
    const [userEmail, setUserEmail] = useState("");
    const [company, setCompany] = useState("")
    const [telephone, setTelephone] = useState("")
    const [userUid, setUserUid] = useState("")
    const [user, setUser] = useState("")
    const [allBookingarr, setAllBookingarr] = useState([])
    const [bookingDriver, setBookingDriver] = useState([])
    const [driverData, setDriverData] = useState([])
    const [userArray, setUserArray] = useState([])
    const [singleBook, setSingleBook] = useState([])
    const [query, setQuery] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [documentComp, setDocumentComp] = useState(false);
    const [clickOnRight, setClickOnRight] = useState(false);
    const [allBookingParty, setAllBookingParty] = useState([]);
    const [active,setActive] = useState([]);
    const [allDrivers, setAllDrivers] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedLoad,setSelectedLoad] = useState([]);
    const [vehicleData, setVehicleData] = useState([]);
    const [fleetData,setFleetData] = useState([]);
    const [trailerData,setTrailerData] = useState([]);

    const {
        allOfTheBooking
    } = useStateContextBookings();

    const {
        isEnterprise,
        setIsEnterprise,
        isTracking,
        setIsTracking,
        isShowSchedule,
        setIsShowSchedule,
        setIsShowRequest,
        isShowRequest,
        setCargoLink,
        setTrackingLink,
        showTrackFilter,
        setShowTrackFilter
    } = useStateContext();

    console.log("show the filter", showTrackFilter)

    var bookingArray = [];

    const ref = React.createRef();

    // const onSearchChange = (query) =>{
    //     let matches = []
    //     if (query.length>0){
    //         matches = allBookingarr.filter(booking =>{
    //             const regex = new RegExp(`${query}`, "gi");
    //             return booking.booking.puDetails.puName.match(regex);
    //         })
    //     }
    //     console.log('matches', matches)
    //     setSuggestions(matches)
    //     setQuery(query)
    // }

    const filteredBookings = allBookingarr.filter(book =>
        book.puDetails.puCompanyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        book.doDetails.doCompanyName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                setUserEmail(user.email);
                setUser(user)
              var uid = user.uid;
              setUserUid(uid)
              firebase.database().ref('/booking_party/' + uid).once('value', (snapshot) => {
                    const company = snapshot.val().firstName
                    setCompany(company)
                    setTelephone(snapshot.val().phoneNumber)
                });
              // ...
            } else {
            }
        });
    }, [])

    useEffect(() => {
        if(localStorage.getItem("userUid")){
            const Uid = JSON.parse(localStorage.getItem("userUid"))
            const bookingRef = firebase.database().ref('booking').orderByChild('booking_party_uid').equalTo(Uid);
            var childrenArray = []
            bookingRef.once('value', (snapshot) => {
              if(snapshot.exists()) {
                const allBookings = Object?.values(snapshot?.val())
                const allBookWithDrivers = allBookings.filter((item) => item.drivers)

                //Get all the drivers for each booking
                const drivers = allBookWithDrivers.map((item) => {
                return item.drivers
                })

                setAllDrivers(drivers);

                console.log("The filter", allBookWithDrivers)
                setAllBookingarr(allBookWithDrivers);
                localStorage.setItem("AllMyBookings", JSON.stringify(childrenArray));
              }
           });
        }
      }, [])

      useEffect(() => {
        setClickOnRight(true);
      }, [])

    //   useEffect(() => {
    //     const driversUIDs = allDrivers;

    //     const fetchDriverAndFleetData = async () => {
    //         const vehicleAndDriverDataPromises = driversUIDs.map((item) => {
    //             return firebase.database().ref(`/drivers/${item}`).on('value')
    //                 .then(driverSnapshot => {
    //                     const driver = driverSnapshot.val();
    //                     if (driver && driver.fleet) {
    //                         return firebase.database().ref(`/fleets/${driver.fleet}`).on('value')
    //                             .then(fleetSnapshot => {
    //                                 const fleet = fleetSnapshot.val();
    //                                 if (fleet && fleet.owner_id) {
    //                                     // Fetch the owner's details
    //                                     return firebase.database().ref(`/owners/${fleet.owner_id}`).on('value')
    //                                         .then(ownerSnapshot => {
    //                                             const owner = ownerSnapshot.val();
    //                                             const combinedData = {
    //                                                 fleet: { ...fleet, owner }, // Combine fleet data with owner details
    //                                             };
    //                                             console.log("The fleet, owner, and driver data", combinedData);
    //                                             setFleetData((prev) => [...prev, combinedData.fleet]);
    //                                             // Assuming you might want to keep track of vehicles (horses) and drivers together
    //                                             return {
    //                                                 vehicle: fleet.horses ? fleet.horses[driver.horse_id] : null,

    //                                             };
    //                                         });
    //                                 } else {
    //                                     // If there's no owner_id, just return the fleet's horse data without owner details, but include the driver
    //                                     console.log("The fleet data (no owner) and driver", { fleet, driver });
    //                                     setFleetData((prev) => [...prev, fleet]);
    //                                     return {
    //                                         vehicle: fleet.horses ? fleet.horses[driver.horse_id] : null,
    //                                         driver
    //                                     };
    //                                 }
    //                             });
    //                     }
    //                     return null;
    //                 });
    //         });

    //         Promise.all(vehicleAndDriverDataPromises).then(results => {
    //             console.log("The vehicle and driver data", results);
    //             // Filter out any null values if a driver, fleet, or vehicle wasn't found, then update the state
    //             const vehicles = results.map(result => result ? result.vehicle : null).filter(v => v !== null);
    //             const drivers = results.map(result => result ? result.driver : null).filter(d => d !== null);
    //             setVehicleData(vehicles);
    //             // Assuming you have a setter for drivers as well
    //             setDriverData(drivers);
    //         });
    //     };

    //     fetchDriverAndFleetData();
    // }, []);

    // useEffect(() => {
    //     const driversUIDs = allDrivers;
    //     driversUIDs.map((item) => {
    //         const driverDataRef = firebase.database().ref(`/drivers/${item}`)
    //         driverDataRef.on("value", driver => {
    //             var arrayDriver = []
    //             arrayDriver.push(driver.val())
    //             setDriverData(arrayDriver);
    //             //get the vehicles
    //             firebase.database().ref(`/fleets/${driver.fleet}`).on("value", fleet => {
    //                 setFleetData((pre) => [...pre,fleet.val()]);
    //             })
    //         })
    //     })
    // },[])

    console.log("The trailer", trailerData)

    return (
    <div className='flex-grow overflow-auto'>
        <div  className='nav-tracking'>
            <span className='bidding-navigation'>
                <p
                    className='p-0 m-0 cursor-pointer mr-1'
                    onClick={() => {
                        setIsShowSchedule(false);
                        setIsTracking(false);
                        setIsEnterprise(true);
                        setCargoLink(false);
                        setIsShowRequest(false);
                        setTrackingLink(false);
                    }}
                >
                    <IoChevronBack />
                </p>
                <p
                    className='font-[200] text-[#878787] !text-[.9rem] cursor-pointer'
                    onClick={() => {
                        setIsShowSchedule(false);
                        setIsTracking(false);
                        setIsEnterprise(false);
                        setCargoLink(true);
                        setIsShowRequest(false);
                        setTrackingLink(false);
                    }}
                >My Cargo</p>
                <p className='font-bold !text-black !text-[.9rem]'>&nbsp;&nbsp;&nbsp;/&nbsp; Tracking</p>
            </span>
        </div>
        <div className='w-full flex flex-row justify-between px-[3.2rem]'>
            <div className='w-[32%] bg-[#F2F2F2] rounded-xl h-fit'>
                <h2 className='font-bold m-0 p-0'>Bookings</h2>
                <p className='text-[.7rem] mt-1'>Search or filter to see how far your shipment is</p>
                <div className='flex justify-between items-center'>
                    <div className='w-[87%] h-fit rounded-2xl flex justify-between items-center bg-[#E4E4E4] p-[.1rem] px-[.16rem]'>
                        <input
                            className='w-[85%] px-4 bg-[#E4E4E4] text-[.8rem] rounded-l-2xl rounded-r-2xl h-full'
                            type="text"
                            placeholder='Search...'
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <div className='w-[1.7rem] flex bg-[#fce200] h-[1.7rem] cursor-pointer justify-center rounded-full items-center'>
                            <p><IoSearch/></p>
                        </div>
                    </div>
                    <div className='w-[13%] h-full flex justify-center items-center'>
                        <p
                            className='text-black p-1 m-0 rounded-full h-[1.8rem] w-[1.8rem] cursor-pointer bg-[#fce200] text-[.8rem] flex justify-center items-center'
                            onClick={() => setShowTrackFilter(true)}
                        >
                            <CiFilter/>
                        </p>
                    </div>
                </div>

                <div className='w-full mt-2 flex-grow overflow-scroll scrollbar-hide h-[71vh] max-h-[71vh]'>
                    {filteredBookings.length > 0 ? filteredBookings.map((book, i) => (
                        <>
                            <div
                                className={`mt-2 cursor-pointer leading-4 ${active[0] == book ? "w-[100%] bg-[#D3D3D3]" : "w-[96%] bg-[#fff]"} m-auto transition-all duration-300 rounded-2xl p-3 relative mb-2`}
                                onClick={() => {
                                    if (active[0] === book) {
                                        setActive([]);
                                    } else {
                                        setActive([book]);
                                    }
                                }}
                            >
                                <div className='flex justify-between items-center'>
                                    <p className='m-0 p-0 font-bold'>{book.booking_ref}</p>
                                    <p className='m-0 p-0'>{book?.drivers.length}/{book.actual_loads_for_cargo} Loads</p>
                                </div>
                                <div className='flex justify-between items-center'>
                                    <p className='m-0 p-0 text-[.59rem] text-[#878787]'>{book.cargoInformation.vehicle_type}</p>
                                    <p className='m-0 p-0 text-[.59rem] text-[#878787]'>Est Completion: {`
                                            ${((new Date(book.dates_time_selection.end_date_string)).toDateString()).substring(8,10)}
                                            ${((new Date(book.dates_time_selection.end_date_string)).toDateString()).substring(4,8)}
                                            ${((new Date(book.dates_time_selection.end_date_string)).toDateString()).substring(11,15)}
                                    `}</p>
                                </div>

                                <div className='flex justify-between mt-[.2rem] items-center'>
                                    <p className='m-0 p-0 font-bold'>{book.puDetails.puCityName}</p>
                                    <p className='m-0 p-0 font-bold'><FaArrowRight /></p>
                                    <p className='m-0 p-0 font-bold'>{book.doDetails.doCityName}</p>
                                </div>

                                <div className='flex justify-between items-center'>
                                    <p className='m-0 p-0 text-[.59rem] text-[#878787]'>{book.puDetails.puCompanyName}</p>
                                    <p className='m-0 p-0 text-[.59rem] text-[#878787]'>{book.doDetails.doCompanyName}</p>
                                </div>
                            </div>
                            {active[0] == book &&
                                <div className={`w-full flex flex-col items-center m-auto mt-2 ${allDrivers[i].length > 4 ? "h-[300px] max-h-[300px]" : "h-fit max-h-fit"} overflow-scroll scrollbar-hide`}>
                                    {allDrivers[i].length > 0 ? allDrivers[i].map((item, i) => (
                                        <div
                                            key={i}
                                            className='h-fit p-3 w-[92%] mb-[.6rem] bg-[#fff] rounded-2xl cursor-pointer'
                                            onClick={() => {
                                                setSelectedLoad([book])
                                                firebase.database().ref(`/drivers/${item}`).on("value", driver => {
                                                    setDriverData(driver.val())
                                                    const horse = driver.val().horse_id
                                                    const trailer = driver.val().trailer_id
                                                    console.log("The trailer data", driver.val())
                                                    firebase.database().ref(`fleets/${driver.val().fleet}`).on("value", fleet => {
                                                        setTrailerData(fleet.val()?.trailers[trailer])
                                                        setVehicleData(fleet.val()?.horses[horse])
                                                        setFleetData(fleet.val())
                                                    })
                                                })
                                            }}
                                        >
                                            <div className='flex justify-between items-center'>
                                                <p className='m-0 p-0 font-bold'>{`${book.booking_ref}-${i + 1}`}</p>
                                                <p className='m-0 p-0'>3 Feb 15:44</p>
                                            </div>
                                            <div className='flex justify-between items-center'>
                                                <p className='m-0 p-0 text-[#059E00] mb-[.2rem]'>In Transit</p>
                                                <p className='m-0 p-0 text-[#878787]'>+- {book.total_distance}</p>
                                            </div>
                                            <div className='flex justify-between items-center'>
                                                <p className='m-0 p-0 font-bold'>{book.puDetails.puCityName}</p>
                                                <p className='m-0 p-0 font-bold'>{book.doDetails.doCityName}</p>
                                            </div>
                                            <div className='w-full mt-2 relative h-[6.5px] rounded-xl bg-[#E6E6E6]'>
                                                <div className='bg-[#059E00] h-full w-[40%] rounded-xl'></div>
                                                <div className='absolute left-[50%] right-0 bottom-0 top-0 h-[7px] w-[7px] rounded-full bg-[#FFFEFE]'></div>
                                            </div>
                                        </div>
                                    ))
                                    :<div className='dash-date' style={{
                                        width:"68%",
                                        height:"100%",
                                        display:"flex",
                                        justifyContent:"center",
                                        alignItems:"center",
                                        flexDirection:"column",
                                        top:"30%",
                                        textAlign:"center",
                                        fontSize:"1rem"
                                      }}>
                                        <img src={emptyIcon} width={100} height={100} style={{marginBottom:"3rem"}}/>
                                        Please select booking on the left if there is any, to see it's details.
                                      </div>
                                    }
                                    {/* <div className='h-fit p-3 w-[92%] mb-[.6rem] bg-[#fff] rounded-2xl'>
                                        <div className='flex justify-between items-center'>
                                            <p className='m-0 p-0 font-bold'>ZN0404-01</p>
                                            <p className='m-0 p-0'>3 Feb 15:44</p>
                                        </div>
                                        <div className='flex justify-between items-center'>
                                            <div className='flex items-center'>
                                                <p className='m-0 p-0 text-[#C91818] mb-[.2rem]'>Delayed</p>
                                                <img src={delayedIcon} className='ml-2 w-[17px] h-[17px]' alt="" />
                                            </div>
                                            <p className='m-0 p-0 text-[#878787]'>+- 1056KM</p>
                                        </div>
                                        <div className='flex justify-between items-center'>
                                            <p className='m-0 p-0 font-bold'>Parktown</p>
                                            <p className='m-0 p-0 font-bold'>Edenvale</p>
                                        </div>
                                        <div className='w-full mt-2 relative h-[6.5px] rounded-xl bg-[#E6E6E6]'>
                                            <div className='bg-[#C91818] h-full w-[60%] rounded-xl'></div>
                                            <div className='absolute left-[50%] right-0 bottom-0 top-0 h-[7px] w-[7px] rounded-full bg-[#FFFEFE]'></div>
                                        </div>
                                    </div>

                                    <div className='h-fit p-3 w-[92%] mb-[.6rem] bg-[#fff] rounded-2xl'>
                                        <div className='flex justify-between items-center'>
                                            <p className='m-0 p-0 font-bold'>ZN0404-01</p>
                                            <p className='m-0 p-0'>3 Feb 15:44</p>
                                        </div>
                                        <div className='flex justify-between items-center'>
                                            <p className='m-0 p-0 text-[#FF9900] mb-[.2rem]'>Pending</p>
                                            <p className='m-0 p-0 text-[#878787]'>+- 1056KM</p>
                                        </div>
                                        <div className='flex justify-between items-center'>
                                            <p className='m-0 p-0 font-bold'>Parktown</p>
                                            <p className='m-0 p-0 font-bold'>Edenvale</p>
                                        </div>
                                        <div className='w-full mt-2 relative h-[6.5px] rounded-xl bg-[#E6E6E6]'>
                                            <div className='bg-[#FF9900] h-full w-[10%] rounded-xl'></div>
                                            <div className='absolute left-[50%] right-0 bottom-0 top-0 h-[7px] w-[7px] rounded-full bg-[#FFFEFE]'></div>
                                        </div>
                                    </div> */}
                                </div>
                            }
                        </>
                        )):
                        <div className='dash-date' style={{
                            width:"100%",
                            height:"100%",
                            display:"flex",
                            justifyContent:"center",
                            alignItems:"center",
                            flexDirection:"column",
                            top:"30%",
                            textAlign:"center",
                            fontSize:"1rem"
                          }}>
                            <img src={emptyIcon} width={100} height={100} style={{marginBottom:"3rem"}}/>
                            No bookings to track at the moment
                        </div>
                    }
                </div>
            </div>
            <div className='w-[65%] flex justify-center items-center h-fit'>
            {selectedLoad.length > 0 ? selectedLoad.map((item, i) => (
                <div className='w-[100%]'>
                     <div className='bg-[white] rounded-xl !w-full !py-2 p-6 mt-2  h-[80vh] overflow-scroll scrollbar-hide'>
                        <div className='title-summary-head'>
                             <h1 className='!font-bold'>Summary</h1>
                             {/* <p style={{cursor:"pointer", fontWeight:"bold", fontSize:"20px"}} onClick={() => setOpenDetails((prev) => !prev)}>X</p> */}
                        </div>

                        <div className='w-full relative'>
                            <div className='flex justify-between w-full'>
                                <div className='w-[15%]'>
                                    <h1 className='font-bold m-0 p-0 text-[.8rem]'>Start</h1>
                                </div>

                                <div className='w-[85%]'>
                                    <div className='pl-2 flex justify-between'>
                                        <div>
                                            <p className='m-0 p-0 font-bold'>Nhletelo</p>
                                            <p className='m-0 p-0 font-bold text-[.8rem]'>Land and Sea Shipping</p>
                                            <p className='m-0 p-0 text-[.7rem] text-[grey]'>796 Cascades Road, Little Falls, Roodepoort, 1735</p>
                                        </div>
                                        <div className='flex flex-col justify-end'>
                                            <p className='text-end m-0 p-0'>Jan 13 2024</p>
                                            <p className='text-end m-0 p-0 text-[.7rem] text-[grey]'>14:26 PM</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='flex justify-between w-full mt-6'>
                                <div className='w-[15%]'>
                                    <h1 className='font-bold m-0 p-0 text-[.8rem]'>Load Cargo</h1>
                                </div>

                                <div className='w-[85%]'>
                                    <div className='pl-2 flex justify-between'>
                                        <div>
                                            <p className='m-0 p-0 font-bold'>{item?.puDetails?.puName}</p>
                                            <p className='m-0 p-0 font-bold text-[.8rem]'>{item?.puDetails?.puCompanyName} | {item?.gate_in_gate_out_duration} Estimated Gate In</p>
                                            <p className='m-0 p-0 text-[.7rem] text-[grey]'>{item?.puDetails?.puAddress}</p>
                                        </div>
                                        <div className='flex flex-col justify-end'>
                                            <p className='text-end m-0 p-0'>Jan 13 2024</p>
                                            <p className='text-end m-0 p-0 text-[.7rem] text-[grey]'>14:26 PM</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {item?.way_point_address &&
                                <div className='flex justify-between w-full mt-6'>
                                    <div className='w-[15%]'>
                                        <h1 className='font-bold m-0 p-0 text-[.8rem]'>Staging Area</h1>
                                    </div>

                                    <div className='w-[85%]'>
                                        <div className='pl-2 flex justify-between'>
                                            <div>
                                                <p className='m-0 p-0 font-bold'>Nhletelo</p>
                                                <p className='m-0 p-0 font-bold text-[.8rem]'>Land and Sea Shipping </p>
                                                <p className='m-0 p-0 text-[.7rem] text-[grey]'>796 Cascades Road, Little Falls, Roodepoort, 1735</p>
                                            </div>
                                            <div className='flex flex-col justify-end'>
                                                <p className='text-end m-0 p-0'>Jan 13 2024</p>
                                                <p className='text-end m-0 p-0 text-[.7rem] text-[grey]'>14:26 PM</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className='flex justify-between w-full mt-6'>
                                <div className='w-[15%] '>
                                    <h1 className='font-bold m-0 p-0 text-[.8rem]'>Offload Cargo</h1>
                                </div>

                                <div className='w-[85%]'>
                                    <div className='pl-2 flex justify-between'>
                                        <div>
                                            <p className='m-0 p-0 font-bold'>{item?.doDetails?.doName}</p>
                                            <p className='m-0 p-0 font-bold text-[.8rem]'>{item?.doDetails?.doCompanyName} | {item?.gate_in_gate_out_duration && `${item?.gate_in_gate_out_duration} Estimated Gate Out`}</p>
                                            <p className='m-0 p-0 text-[.7rem] text-[grey]'>{item?.puDetails?.puAddress}</p>
                                        </div>
                                        <div className='flex flex-col justify-end'>
                                            <p className='text-end m-0 p-0'>Jan 13 2024</p>
                                            <p className='text-end m-0 p-0 text-[.7rem] text-[grey]'>14:26 PM</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* The tracking indicator */}
                            <div
                                className='absolute left-[5.5rem] right-0 top-0 bottom-0 w-[.2%] h-[88%]'
                                style={{borderLeft:"2px dashed #757575"}}
                            >
                                <div
                                    className='w-[.6rem] h-[.6rem] rounded-full bg-[#059E00] -left-[.35rem] bottom-0 right-0 -top-[.2rem] absolute'
                                >
                                    <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 h-[1rem] w-[1rem] border-[.2rem] border-[#D9D9D9] rounded-full'></div>
                                </div>

                                <div
                                    className='w-[.9rem] h-[.9rem] rounded-full animate-ping bg-[#059E00] -left-[.5rem] bottom-0 right-0 top-[4.55rem] absolute'
                                >
                                    <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 h-[1.20rem] w-[1.20rem] border-[.2rem] border-[#D9D9D9] rounded-full'></div>
                                </div>

                                <div
                                    className='w-[.9rem] h-[.9rem] rounded-full bg-[#059E00] -left-[.5rem] bottom-0 right-0 top-[4.55rem] absolute'
                                >
                                    <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 h-[1.20rem] w-[1.20rem] border-[.2rem] border-[#D9D9D9] rounded-full'></div>
                                </div>

                                {item?.way_point_address &&
                                    <div
                                        className='w-[.6rem] h-[.6rem] rounded-full bg-[#757575] -left-[.35rem] bottom-0 right-0 top-[9rem] absolute'
                                    >
                                        <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 h-[1rem] w-[1rem] border-[.2rem] border-[#D9D9D9] rounded-full'></div>
                                    </div>
                                }

                                <div
                                    className='w-[.9rem] h-[.9rem] rounded-full bg-[#757575] -left-[.5rem] bottom-0 right-0  absolute'
                                >
                                    <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 h-[1.20rem] w-[1.20rem] border-[.2rem] border-[#D9D9D9] rounded-full'></div>
                                </div>
                            </div>
                        </div>

                        <div className='w-full'>
                            <h1 className='font-bold mt-12 mb-2'>Transporter</h1>
                            <div className='flex justify-between border-transparent py-[.30rem] bg-[#f8f8f9] px-4 w-full rounded-tl-[.5rem] rounded-tr-[.5rem]'>
                                <div className='w-[16%] text-[.8rem]'>Name</div>
                                <div className='w-[16%] text-[.8rem]'>Driver</div>
                                <div className='w-[16%] text-[.8rem]'>Make</div>
                                <div className='w-[16%] text-[.8rem]'>Vehicle Type</div>
                                <div className='w-[16%] text-[.8rem]'>Registration</div>
                                <div className='w-[16%] text-[.8rem]'>Trailer</div>
                            </div>

                            <div className='flex justify-between py-[.30rem] border px-4 w-full rounded-bl-xl rounded-br-xl'>
                                <div className='w-[16%] text-[.8rem]'>{fleetData?.companyName}</div>
                                <div className='w-[16%] text-[.8rem]'>{driverData?.name}</div>
                                <div className='w-[16%] text-[.8rem]'>{vehicleData?.make}</div>
                                <div className='w-[16%] text-[.8rem]'>{vehicleData?.vehicle_type}</div>
                                <div className='w-[16%] text-[.8rem]'>{vehicleData?.licence_number}</div>
                                <div className='w-[16%] text-[.8rem]'>{trailerData?.trailer_id}</div>
                            </div>
                        </div>

                        <div className='w-full'>
                            <h1 className='font-bold mt-5 mb-2'>Prerequisites</h1>
                            <div className='flex border-transparent justify-between py-[.30rem] bg-[#f8f8f9] px-4 w-full rounded-tl-[.5rem] rounded-tr-[.5rem]'>
                                <div className='w-[20%] text-[.8rem]'>Documentation</div>
                                <div className='w-[20%] text-[.8rem]'>Ad-hoc Service</div>
                                <div className='w-[20%] text-[.8rem]'>PPE</div>
                                <div className='w-[20%] text-[.8rem]'>Vehicle Equipment</div>
                                <div className='w-[20%] text-[.8rem]'>Insurance</div>
                            </div>
                            <div className='flex justify-between py-[.30rem] border px-4 w-full rounded-bl-xl rounded-br-xl'>
                                <div className='w-[20%] text-[.8rem]'>
                                    {filteredBookings[i]?.prerequisites?.documentation.map(doc => (
                                        <p>{doc}</p>
                                    ))
                                    }
                                </div>
                                <div className='w-[20%] text-[.8rem]'>Solas / Verified Gross Mass</div>
                                <div className='w-[20%] text-[.8rem]'>Safety Hat</div>
                                <div className='w-[20%] text-[.8rem]'>2 Stop Blocks</div>
                                <div className='w-[20%] text-[.8rem]'>R250 000</div>
                            </div>
                        </div>

                        <div className='w-full'>
                            <h1 className='font-bold mt-5 mb-2'>Cargo</h1>
                            <div className='flex border-transparent justify-between py-[.30rem] bg-[#f8f8f9] px-4 w-full rounded-tl-[.5rem] rounded-tr-[.5rem]'>
                                <div className='w-[8%] text-[.8rem]'>#</div>
                                <div className='w-[18%] text-[.8rem]'>Product</div>
                                <div className='w-[13%] text-[.8rem]'>Packaging</div>
                                <div className='w-[13%] text-[.8rem]'>Quantity</div>
                                <div className='w-[13%] text-[.8rem]'>Fragile</div>
                                <div className='w-[13%] text-[.8rem]'>Dimensions</div>
                                <div className='w-[13%] text-[.8rem]'>Weight</div>
                                <div className='w-[13%] text-[.8rem]'>CBM</div>
                            </div>
                            <div className='flex justify-between py-[.30rem] border px-4 w-full rounded-bl-xl rounded-br-xl'>
                                <div className='w-[8%] text-[.8rem]'>{i+1}</div>
                                <div className='w-[18%] text-[.8rem]'>{filteredBookings[i].cargoInformation.productName}</div>
                                <div className='w-[13%] text-[.8rem]'>{filteredBookings[i].cargoInformation.packageType}</div>
                                <div className='w-[13%] text-[.8rem]'>{filteredBookings[i].cargoInformation.quantity}</div>
                                <div className='w-[13%] text-[.8rem]'>{filteredBookings[i].cargoInformation.hazard.hazard_rating ? "Yes" : "No"}</div>
                                <div className='w-[13%] text-[.8rem]'>{filteredBookings[i].cargoInformation.breadth}CM x {filteredBookings[i].cargoInformation.height}CM x {filteredBookings[i].cargoInformation.lengthValue}CM</div>
                                <div className='w-[13%] text-[.8rem]'>10:56 MT</div>
                                <div className='w-[13%] text-[.8rem]'>{filteredBookings[i].cargoInformation.weight} CBM</div>
                            </div>
                        </div>

                        {filteredBookings[i].cargoInformation.hazard.hazard_rating &&
                            <div className='w-full'>
                                <h1 className='font-bold mt-5 mb-2'>Hazardous Cargo</h1>
                                <div className='flex border-transparent justify-start py-[.30rem] bg-[#f8f8f9] px-4 w-full rounded-tl-[.5rem] rounded-tr-[.5rem]'>
                                    <div className='w-[20%] text-[.8rem]'>UN Number</div>
                                    {/* <div className='w-[20%] text-[.8rem]'>Class</div> */}
                                    <div className='w-[20%] text-[.8rem]'>IMO</div>
                                </div>
                                <div className='flex justify-start py-[.30rem] border px-4 w-full rounded-bl-xl rounded-br-xl'>
                                    <div className='w-[20%] text-[.8rem]'>{filteredBookings[i].cargoInformation.unNumber}</div>
                                    {/* <div className='w-[20%] text-[.8rem]'>4.1</div> */}
                                    <div className='w-[20%] text-[.8rem]'>{filteredBookings[i].cargoInformation.imoClass}</div>
                                </div>
                            </div>
                        }

                        {filteredBookings[i].cargoInformation.hazard.hazard_rating &&
                            <div className='w-full'>
                                <h1 className='font-bold mt-5 mb-2'>Temperature</h1>
                                <div className='flex border-transparent justify-strt py-[.30rem] bg-[#f8f8f9] px-4 w-full rounded-tl-[.5rem] rounded-tr-[.5rem]'>
                                    <div className='w-[16%] text-[.8rem]'>Min</div>
                                    <div className='w-[16%] text-[.8rem]'>Max</div>
                                </div>
                                <div className='flex justify-start py-[.30rem] border px-4 w-full rounded-bl-xl rounded-br-xl'>
                                    <div className='w-[16%] text-[.8rem]'>{filteredBookings[i].cargoInformation.hazard.min_temp}</div>
                                    <div className='w-[16%] text-[.8rem]'>{filteredBookings[i].cargoInformation.hazard.max_temp}</div>
                                </div>
                            </div>
                        }

                        {/* <div className='flex justify-between w-full mt-6'>
                            <div className='w-[17%]'>
                                <h1 className='font-bold m-0 p-0 text-[.8rem]'>Product</h1>
                            </div>

                            <div className='w-[83%]'>
                                <div>
                                    <p className='font-bold m-0 p-0 text-[.8rem]'>Product Name</p>
                                </div>
                                <div className='w-full border flex flex-row p-2 mt-2'>
                                    <div className='w-[50%] flex'>
                                        <div className='w-[40%]'>
                                            <p>Quantity</p>
                                            <p>Length</p>
                                            <p>Breadth</p>
                                            <p>Height</p>
                                            <p>Package Type</p>
                                            <p>Fragile</p>
                                            <p>Hazardous</p>
                                        </div>
                                        <div className='w-[20%]'>
                                            <p>: {item?.cargoInformation?.quantity}</p>
                                            <p>: {item?.cargoInformation?.lengthValue}cm</p>
                                            <p>: {item?.cargoInformation?.breadth}cm</p>
                                            <p>: {item?.cargoInformation?.height}cm</p>
                                            <p>: {item?.cargoInformation?.packageType}</p>
                                            <p>: {item?.cargoInformation?.fragile === "true" ? "Yes" : "No"}</p>
                                            <p>: {item?.cargoInformation?.hazard?.hazard_rating === "Hazardous" ? "Yes" : "No"}</p>
                                        </div>
                                    </div>
                                    <div className='w-[50%] flex justify-end'>
                                        <div className='w-[30%] flex flex-col items-end'>
                                            <p>{item?.cargoInformation?.productCode} :</p>
                                            <p>{item?.cargoInformation?.volume}m&#179; :</p>
                                            <p>{((item?.cargoInformation?.volume) * (item?.cargoInformation?.quantity)).toFixed(2)}m&#179; :</p>
                                            <p>{(item?.cargoInformation?.weight) * (item?.cargoInformation?.quantity)}mt :</p>
                                            <p>{item?.cargoInformation?.weight}mt :</p>
                                            <p>{item?.cargoInformation?.imoClass} :</p>
                                            <p>{item?.cargoInformation?.unNumber} :</p>
                                        </div>

                                        <div className='w-[40%] flex flex-col items-end'>
                                            <p>SKU</p>
                                            <p>Net Volume</p>
                                            <p>Total Volume</p>
                                            <p>Net Weight</p>
                                            <p>Total Weight</p>
                                            <p>IMO</p>
                                            <p>UN Number</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='w-full border flex flex-col p-2 mt-2'>
                                    <div>
                                        <p className='font-bold m-0 p-0 text-[.8rem]'>Temperature-controlled</p>
                                    </div>
                                    <div className='w-[50%] flex'>
                                        <div className='w-[40%]'>
                                            <p>Min</p>
                                            <p>Max</p>
                                        </div>
                                        <div className='w-[20%]'>
                                            <p>: {item?.cargoInformation?.hazard?.min_temp}­°C</p>
                                            <p>: {item?.cargoInformation?.hazard?.max_temp}°C</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='w-full border flex flex-col p-2 mt-2'>
                                    <div>
                                        <p className='font-bold m-0 p-0 text-[.8rem]'>Insurance</p>
                                    </div>
                                    <div className='w-[50%] flex flex-col'>
                                        <p>Goods in Transit</p>
                                        <p>{item?.prerequisites?.goods_in_transit}</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* <div className='flex justify-between w-full mt-6'>
                            <div className='w-[17%]'>
                                <h1 className='font-bold m-0 p-0 text-[.8rem]'>Prerequisites</h1>
                            </div>

                            <div className='flex flex-row flex-wrap w-[83%] justify-between'>
                                {item?.prerequisites && (Object.keys(item?.prerequisites)).filter((item) => (
                                    item !== "vehicleDimensions" &&
                                    item !== "goods_in_transit" &&
                                    item !== "vehicle_type"
                                )).map((pre, i) => {
                                        console.log("The pre", item?.prerequisites[pre])
                                        const preItem = item?.prerequisites[pre]
                                        return (
                                            <div key={i} className='w-[49%] border flex flex-col p-2 mt-3'>
                                                <p className='font-bold m-0 p-0 text-[.8rem]'>{pre}</p>
                                                {item?.prerequisites[pre] && (item?.prerequisites[pre])?.map((preItem) => (
                                                    <>
                                                        <p>{preItem}</p>
                                                    </>
                                                ))
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div> */}
                        <div className='my-10 h-12
                        flex justify-end'>
                            <div className='flex items-center'>
                                <p className='m-0 p-0 mr-4 cursor-pointer text-[.9rem] text-[#fce200] bg-[black] h-[23px] w-[23px] flex justify-center items-center rounded-full'><FaPrint /></p>
                                <p className='m-0 p-0 text-[1rem]'>Print</p>
                            </div>
                            <div className='flex items-center ml-3 cursor-pointer'>
                                <p className='m-0 p-0 mr-4 cursor-pointer text-[.9rem] text-[#fce200] bg-[black] h-[23px] w-[23px] flex justify-center items-center rounded-full'><FiDownload /></p>
                                <p className='m-0 p-0 text-[1rem]'>Download PDF</p>
                            </div>
                        </div>
                     </div>
                 </div>
                 )):<div className='dash-date h-[600px] max-h-[600px] ' style={{
                    width:"67%",
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center",
                    flexDirection:"column",
                    top:"30%",
                    textAlign:"center",
                    fontSize:"1rem"
                  }}>
                    <img src={emptyIcon} width={100} height={100} style={{marginBottom:"3rem"}}/>
                    No load to track at the moment
                </div>
                }
            </div>
        </div>
    </div>
  )
}
export default React.memo(TrackingPage)
