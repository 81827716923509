import { useMemo } from 'react'
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api'
import '../css/mapContainer.css'

export default function RouteMap(){
  const { isLoaded } = useLoadScript({ 
      googleMapsApiKey: 'AIzaSyDV1EIKSMBMJGyqwV9ft3y_-z_6Gjs3xEY' 
  })
  if(!isLoaded) return <div>Loading...</div>
  return (
    <Map/>
  )
}

function Map(){
  const options = useMemo(
    () => ({
        mapId:"2cc193ea5e72ad61", 
        disableDefaultUI: true,
        clickableIcons: false,

    }), [])
  return (
    <GoogleMap 
      zoom={10} 
      center={{lat: -26.11813201804211, lng: 27.889527283718596}} 
      mapContainerClassName="map-container"
      options={options}
    >

    </GoogleMap>
  )
}