import React, {useState, useEffect} from 'react'
import { useStateContext } from '../context/DashboardStateContext'
import fnbIcon from '../icons/fnb.svg'
import firebase from '../firebase-config'

const Quote = () => {
    const {
        isEnterprise,
        setIsEnterprise,
        isTracking,
        userUid,
        setIsTracking,
        isShowSchedule,
        setIsShowSchedule,
        setIsShowRequest,
        isShowRequest,
        setCargoLink,
        isQuote,
        setIsQuote
    } = useStateContext();
    const [pickUpDetails , setPickUpDetails] = useState([])
    const [dropDetails , setDropDetails] = useState([])
    const [vehicle , setVehicle] = useState([])
    const [customer , setCustomer] = useState([])
    const [cargoDetails , setCargoDetails] = useState([])

    useEffect(() => {
        if(localStorage.getItem("pickSelectd")){
            const storedList = JSON.parse(localStorage.getItem("pickSelectd"))
            setPickUpDetails(storedList[0].details);
        }
    }, [])

    useEffect(() => {
        if(localStorage.getItem("Prerequisites")){
            const storedList = JSON.parse(localStorage.getItem("Prerequisites"))
            setVehicle(storedList[0].prerequisites.vehicle_type)
        }
    }, [])

    useEffect(() => {
        if(localStorage.getItem("dropSelectd")){
            const storedList = JSON.parse(localStorage.getItem("dropSelectd"))
            setDropDetails(storedList[0].details);
        }
    }, [])

    useEffect(() => {
        firebase.database().ref("booking_party").child(userUid).on("value", snapshot => {
            // console.log("the data", snapshot.val())
            setCustomer(snapshot.val())
        })
    }, [])

    useEffect(() => {
        if(localStorage.getItem("cargoSelectd")){
            const storedList = JSON.parse(localStorage.getItem("cargoSelectd"))
            const vehicleType = JSON.parse(localStorage.getItem("vehicleType"))
            storedList.details.vehicle_type = vehicleType[0]
            setCargoDetails([storedList]);
            console.log("This is the new cargo", storedList);
        }

    }, [])

    console.log("the uid", userUid)

  return (
    <div className='w-[90%] max-w-[90%] my-[28px] h-[100%] m-[auto]'>
       <div  style={{display:"flex", alignItems:"center", marginBottom:"17px"}}>
            <span className='schedule-navigation'>
                <p
                    className='cursor-pointer mr-2 text-[grey]'
                    onClick={() => {
                        setIsShowSchedule(false);
                        setIsTracking(false);
                        setIsEnterprise(true);
                        setCargoLink(false);
                        setIsShowRequest(false);
                        setIsQuote(false);
                    }}
                >Booking</p>
                <p>/</p>
                <p className='ml-2 font-bold'>Quote</p>
            </span>
        </div>
        <div className='w-[100%] py-6 px-16 bg-white rounded-xl'>
            <h1 className='font-bold text-[1.5rem]'>Order Confirmed. awaiting payment</h1>
            <p>Your order has been placed, and is currently set to "Pending" status.</p>
            <div className='my-2 border-b border-t py-3'>
                <div className='flex items-center'><span className='font-bold'>Date:</span> <p className='m-0 p-0 ml-2'>{(new Date().toISOString()).substring(0,10)}</p></div>
            </div>
            <div className='my-2 py-3'>
                <p className='font-bold text-[1rem] mb-2'>Booking information</p>
                <div className='flex justify-between'>
                    <div>
                        <p className='font-bold'>Pick up Details</p>
                        <p>{pickUpDetails.Address}</p>
                        <p>{pickUpDetails.Name}</p>
                        <p>{pickUpDetails.surname}</p>
                        <p>{pickUpDetails.Phone}</p>
                        <p>{pickUpDetails.Telephone}</p>
                    </div>
                    <div className='flex justify-end flex-col'>
                        <p className='font-bold text-end'>Drop of Details</p>
                        <p className='text-end'>{dropDetails.Address}</p>
                        <p className='text-end'>{dropDetails.Name}</p>
                        <p className='text-end'>{dropDetails.surname}</p>
                        <p className='text-end'>{dropDetails.Phone}</p>
                        <p className='text-end'>{dropDetails.Telephone}</p>
                    </div>
                </div>

                <div className='flex justify-between mt-2'>
                    <div>
                        <p className='font-bold'>Vehicle</p>
                        <p>{vehicle}</p>
                    </div>
                </div>

            </div>
            <div className='mt-5 flex justify-between'>
                <div>
                    <p className='font-bold text-[1rem]'>Invoiced To:</p>
                    <div className='flex justify-end flex-col'>
                        <p>{customer.companyName}</p>
                        <p>{customer.firstName}</p>
                        <p>{customer.lastName}</p>
                        <p>{customer.physical_address}</p>
                    </div>
                </div>
                <div>
                    <p className='font-bold text-[1rem] text-end'>Pay To:</p>
                    <div className='flex justify-end flex-col'>
                        <p className='text-end'>Zipi</p>
                        <p className='text-end'>794 Cascades Rd, Little Falls, Roodepoort, 1735 </p>
                    </div>
                </div>
            </div>
            <div className='flex justify-between py-3 bg-[#d6d6d6] p-3 mt-5 font-bold' style={{borderRadius: "15px 15px 0px 0px"}}>
                <div className='w-[20%]'>Service</div>
                <div className='w-[20%]'>Description</div>
                <div className='w-[20%]'>Rate</div>
                <div className='w-[20%]'>QTY</div>
                <div className='w-[20%]'>Amount</div>
            </div>
            <div className='flex justify-between py-3 border p-3'>
                <div className='w-[20%]'>Vehicle</div>
                <div className='w-[20%]'>{cargoDetails[0]?.details?.productName}</div>
                <div className='w-[20%]'>R 200 per load</div>
                <div className='w-[20%]'>{cargoDetails[0]?.details?.quantity}</div>
                <div className='w-[20%]'>R {((cargoDetails[0]?.details?.quantity)*200).toFixed(2)}</div>
            </div>
            <div className='flex justify-end py-3 border p-3'>
                <div className='w-[20%] font-bold'>Sub Total:</div>
                <div className='w-[20%]'>R {((cargoDetails[0]?.details?.quantity)*200).toFixed(2)}</div>
            </div>
            <div className='flex justify-end py-3 border p-3'>
                <div className='w-[20%] font-bold'>VAT:</div>
                <div className='w-[20%]'>R {((15/100)*((cargoDetails[0]?.details?.quantity)*200)).toFixed(2)}</div>
            </div>
            <div className='flex justify-end py-3 border p-3'>
                <div className='w-[20%] font-bold'>Total:</div>
                <div className='w-[20%]'>R {((15/100)*((cargoDetails[0]?.details?.quantity)*200)) + ((cargoDetails[0]?.details?.quantity)*200)}</div>
            </div>
            <p className='mt-5 font-bold text-[1rem] mb-3'>Payment Details</p>
            <div className='w-[30%]'>
                <div className='w-full flex p-3 items-center h-[50px] bg-[#e3e3e3]' style={{borderRadius: "15px 15px 0px 0px"}}>
                    <img src={fnbIcon} alt="fnb logo" className='w-[3rem] h-[3rem]'/>FNB
                </div>
                <div className='p-3 '>
                    <p><b>Account Name:</b> Zipi</p>
                    <p><b>Account No:</b> 62089323704</p>
                    <p><b>Account Type:</b> Cheque</p>
                    <p><b>Branch:</b> Clearwater</p>
                    <p><b>Branch Code:</b> 250130</p>
                    <p><b>Swift Code:</b> FIRNZAJJ</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Quote
