import React, {useState, useEffect} from 'react';
import { useStateContextBookings } from '../context/AllBookingsContext'
import emptyIcon from '../icons/box.png'
import { useStateContext } from '../context/DashboardStateContext'
import BiddingDetails from './BiddingDetails';
import { Link, useNavigate  } from 'react-router-dom'
import NewOffer from './NewOffer';
import firebase from '../firebase-config'

export default function BiddingFinalPage() {
    const {
        allOfTheBooking,
        setAllOfTheBooking,
    } = useStateContextBookings();

    const navigate = useNavigate();

    const handleSelectedBookUpdate = (updatedBook) => {
        // Update your selectedBook state here
        setSelectedBook(updatedBook);
    };

    const [selectedBook, setSelectedBook] = useState(allOfTheBooking[0])
    const [fleetIds, setFleetIds] = useState([]);
    const [settleAmount, setSettleAmount] = useState(null);
    const [ openDetails, setOpenDetails] = useState(false);
    const [acceptedBooking, setAcceptedBooking] = useState(null);
    const [checkAccepted, setCheckAccepted] = useState(false);
    // const [fleetUid, setFleetUid] = useState(false);
    const [biddingData, setBiddingData] = useState({
        loads:"",
        transQuotation:"",
    })

    const {
        isEnterprise,
        setIsEnterprise,
        isTracking,
        setIsTracking,
        isShowSchedule,
        setIsShowSchedule,
        setIsShowRequest,
        isShowRequest,
        setCargoLink,
        userUid
      } = useStateContext();


    console.log("the transporter prices", biddingData)

    useEffect(() => {
        setFleetIds(selectedBook?.booking_bids_fleet_id)
    },[])

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
              var uid = user.uid;
                firebase.database().ref('/booking/').orderByChild('booking_party_uid').equalTo(uid).on('value', (snapshot) => {
                    if(snapshot.exists()){
                        setAllOfTheBooking(Object?.values(snapshot.val()));
                    }else{
                        setAllOfTheBooking([])
                    }
                });
              // ...
            } else {
                navigate('/login');
            }
        });

    }, [])

  return (
    <div style={{position:"relative"}}>
        <div  className='nav-tracking' style={{padding:"1rem 0", width:"90%", margin:"auto"}}>
            <span className='bidding-navigation'>
                <p
                    style={{color:"grey", cursor: "pointer"}}
                    onClick={() => {
                        setIsShowSchedule(false);
                        setIsTracking(false);
                        setIsEnterprise(true);
                        setCargoLink(false);
                        setIsShowRequest(false);
                    }}
                >
                    Booking
                </p>
                <p>&nbsp;&nbsp;&nbsp;/&nbsp; Requests</p>
            </span>
        </div>
        <div className='bidding-page-container'>
            <div>
                <div className='booking-card'>
                    <p>Deals</p>
                    <p>Default filter: <span style={{color:"blue"}}>start date</span> </p>
                </div>
                {allOfTheBooking.length ? allOfTheBooking.sort((a, b) => new Date(b.date_created) - new Date(a.date_created)).map((booking) => (
                    <div className='card-container' onClick={() => {
                            setSelectedBook(booking)
                            // fetchFromFleet()
                        }}>
                        <div>
                            <p>{booking.booking_ref}</p>
                            <p>{booking.puDetails.puCityName} to {booking.doDetails.doCityName}</p>
                        </div>
                        <div>
                            <p>{booking.date_created}</p>
                            <p>{booking.actual_loads_for_cargo}/{booking.actual_loads_for_cargo}</p>
                            <p> offers</p>
                        </div>
                    </div>
                )) :

                    <div className='dash-date' style={{
                        width:"100%",
                        height:"100%",
                        display:"flex",
                        justifyContent:"center",
                        alignItems:"center",
                        flexDirection:"column",
                        top:"30%",
                        height:"fit-content",
                        marginTop:"9%",
                        marginBottom:"9%",
                        textAlign:"center",
                        fontSize:"11.5px"
                    }}>
                        <img src={emptyIcon} width={100} height={100}/>
                        Please make some bookings, it would appear you have none.
                    </div>
                }

            </div>
            <div>
                <div className='tril-deal-container'>
                    <h1 style={{fontSize:"16px"}}>Trip Deals</h1>
                    <div><button onClick={()=> {
                        const bookingRef = firebase.database().ref('/booking/').child(selectedBook.booking_id);
                        bookingRef.remove().then(() =>{
                          firebase.database().ref('/booking_party/').child(selectedBook.booking_id).update({
                            deleted_bookings: {...selectedBook}
                          })
                        })
                  }}>X</button> <span style={{fontSize:"13px", color:"red"}}>Cancel Request</span></div>
                </div>
                {selectedBook?.length ? selectedBook.map((book) => (
                    <p style={{color:"grey"}}>{book.booking_ref}</p>
                    )):
                    <p style={{color:"grey"}}>{selectedBook?.booking_ref}</p>
                }

                <div
                    style={{
                        marginTop:"2.5rem",
                        display:"flex",
                        justifyContent:"space-between",
                        padding:"17px",
                        borderRadius:"15px",
                        boxShadow:"rgb(150 150 150 / 29%) -1px 19px 94px 42px"
                    }}
                >
                    {selectedBook?.length ? selectedBook.map((book) => (
                        <div style={{display:"flex", flexDirection:"column", justifyContent:"start"}}>
                            <p>{book.puDetails.puCityName} to {book.doDetails.doCityName}</p>
                            <p>Your asking rate: R{book.rate_required}</p>
                            <p>Offers (avg. R{book.rate_required})</p>
                        </div>
                        )):

                        <div style={{display:"flex", flexDirection:"column", justifyContent:"start"}}>
                            <p>{selectedBook?.puDetails?.puCityName} to {selectedBook?.doDetails?.doCityName}</p>
                            <p>Your asking rate: R{selectedBook?.rate_required}</p>
                            {/* <p>Offers (avg. R{selectedBook?.rate_required})</p> */}
                        </div>
                    }
                    <div>
                        <p style={{color:"blue", cursor:"pointer"}} onClick={() => setOpenDetails(true)}>View Details</p>
                    </div>
                </div>
                <div className='offer-container-last'>
                    <h1>Offers</h1>
                    <div>
                        <p>All rates submitted exclude Value Added Tax and will be charged where applicable.</p>
                        <p>Default filter: <span style={{color:'blue'}}>Rates &#40;low - High&#41;</span></p>
                    </div>
                    <div>
                        <div className='negotiation-block'>
                            <span><p style={{background:"#fce303"}}></p><p>New Offer</p></span> <span><p style={{background:"#213164"}}></p><p>Negotiation</p></span> <span><p style={{background:"#1d9f00"}}></p><p>Bookings</p></span>
                        </div>
                    </div>
                    <NewOffer
                        fleetIds={fleetIds}
                        selectedBook={selectedBook}
                        onUpdateSelectedBook={handleSelectedBookUpdate}
                    />
                </div>
            </div>
        </div>
        {openDetails &&
            <BiddingDetails
                selectedBook={selectedBook}
                setSelectedBook={setSelectedBook}
                openDetails={openDetails}
                setOpenDetails={setOpenDetails}
            />
        }
    </div>
  )
}
