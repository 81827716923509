import React from 'react'
import {useFleetStateContext} from '../context/FleetDealsContext'
import { useStateContext } from '../context/DashboardStateContext'
import FirstDealRespond from './FirstDealRespond';

export default function DealRequests() {

    const {
        allDeals,
        selectedBookingDeal,
        setSelectedBookingDeal
    } = useFleetStateContext();

    const {
        showFirstDealProcess,
        setShowFirstDealProcess
    } = useStateContext();

    console.log(allDeals)

    console.log("sected book", selectedBookingDeal)

    const adhoc = selectedBookingDeal[0]?.prerequisites?.ad_hoc_services
    const documentation = selectedBookingDeal[0]?.prerequisites?.documentation
    const protective = selectedBookingDeal[0]?.prerequisites?.personal_protective
    const vehicle = selectedBookingDeal[0]?.prerequisites?.vehicle_equipment

  return (
    <div className='h-fit relative rounded-lg flex justify-between' style={{width:"68%", background:"#e0e0e0", height:"644px"}}>
        <div className='p-3 px-4 scrollbar-hide' style={{width:"65%", overflow:"scroll"}}>
            <div style={{background:"#fff"}} className='p-3 rounded-xl'>
                <p style={{color:"#606060"}}>Start</p>
                <p>{selectedBookingDeal.length && selectedBookingDeal[0]?.puDetails?.puCompanyName}</p>
                <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}>{selectedBookingDeal.length && selectedBookingDeal[0]?.puDetails?.puAddress}</p>
                <p style={{color:"#606060"}}>Gate in - gate out time: {selectedBookingDeal.length && selectedBookingDeal[0]?.puDetails?.puGateInGateOut} Min</p>
            </div>
            <div style={{background:"#fff"}} className='p-3 mt-4 rounded-xl relative'>
                <p style={{color:"#606060"}}>End</p>
                <p>{selectedBookingDeal.length && selectedBookingDeal[0]?.doDetails?.doCompanyName}</p>
                <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}>{selectedBookingDeal.length && selectedBookingDeal[0]?.doDetails?.doAddress}</p>
                <p>Gate in - gate out time:  {selectedBookingDeal.length && selectedBookingDeal[0]?.doDetails?.doGateInGateOut} Min</p>
                <div className='absolute font-bold right-0 bottom-0 p-4 px-6 flex justify-between items-center rounded-full w-fit'
                style={{background:"#fff", left:"40%", top:"85%", color:"#606060"}
                }
                >
                 {selectedBookingDeal.length && selectedBookingDeal[0]?.total_distance} km
                </div>
            </div>
            <div className='flex mt-8 justify-between'>
                <div className='p-3 rounded-xl' style={{width:"32.5%", background:"#fff"}}>
                    <p style={{color:"#606060"}}>Vehicle Type</p>
                    <p className='font-bold' style={{fontSize:"1rem",color:"#606060"}}> {selectedBookingDeal.length && selectedBookingDeal[0]?.prerequisites?.vehicle_type[0]}</p>
                </div>
                <div className='p-3 rounded-xl' style={{width:"32.5%", background:"#fff"}}>
                    <p style={{color:"#606060"}}>Loads</p>
                    <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}>{selectedBookingDeal.length && selectedBookingDeal[0]?.actual_loads_for_cargo}</p>
                </div>
                <div className='p-3 rounded-xl' style={{width:"32.5%", background:"#fff"}}>
                    <p style={{color:"#606060"}}>Date created</p>
                    <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}><p>{selectedBookingDeal.length && selectedBookingDeal[0]?.date_created}</p></p>
                </div>
            </div>
            <div
                className='mt-4 p-3 rounded-xl scrollbar-hide'
                style={{
                    background:"#fff",
                    maxHeight:
                    !selectedBookingDeal[0]?.prerequisites?.ad_hoc_services &&
                    !selectedBookingDeal[0]?.prerequisites?.documentation &&
                    !selectedBookingDeal[0]?.prerequisites?.personal_protective &&
                    !selectedBookingDeal[0]?.prerequisites?.vehicle_equipment ?
                    "100px"
                    : "164px",
                    height:
                    !selectedBookingDeal[0]?.prerequisites?.ad_hoc_services &&
                    !selectedBookingDeal[0]?.prerequisites?.documentation &&
                    !selectedBookingDeal[0]?.prerequisites?.personal_protective &&
                    !selectedBookingDeal[0]?.prerequisites?.vehicle_equipment ?
                    "100px"
                    : "164px",
                    overflow:"scroll"
                }}
                >
                {selectedBookingDeal?.length && selectedBookingDeal[0]?.prerequisites?.ad_hoc_services &&
                    <>
                        <p className='font-bold' style={{fontSize:".9rem"}}>Ad hoc services</p>
                        {adhoc.map(item => (
                            <p>{item}</p>
                        ))}
                    </>
                }
                {selectedBookingDeal.length && selectedBookingDeal[0]?.prerequisites?.documentation &&
                    <>
                        <p className='font-bold' style={{fontSize:".9rem"}}>Documentation</p>
                        {documentation.map(item => (
                            <p>{item}</p>
                        ))}
                    </>
                }
                {selectedBookingDeal.length && selectedBookingDeal[0]?.prerequisites?.personal_protective &&
                    <>
                        <p className='font-bold' style={{fontSize:".9rem"}}>Personal Protective Equipment</p>
                        {protective.map(item => (
                            <p>{item}</p>
                        ))}
                    </>
                }
                {selectedBookingDeal?.length && selectedBookingDeal[0]?.prerequisites?.vehicle_equipment &&
                    <>
                        <p className='font-bold' style={{fontSize:".9rem"}}>Vehicle Equipment</p>
                        {vehicle.map(item => (
                            <p>{item}</p>
                        ))}
                    </>
                }

                {
                    !selectedBookingDeal[0]?.prerequisites?.ad_hoc_services &&
                    !selectedBookingDeal[0]?.prerequisites?.documentation &&
                    !selectedBookingDeal[0]?.prerequisites?.personal_protective &&
                    !selectedBookingDeal[0]?.prerequisites?.vehicle_equipment &&
                    <div className='flex justify-center items-center w-full h-full'>
                        <p className='flex justify-center items-center'>
                            No prerequisites selected at the moment
                        </p>
                    </div>

                }
            </div>
            <div className='mt-4'>
                <button className='rounded-full' style={{background:"#fce200", padding:".1rem .9rem", fontSize:".8rem"}}>
                Progress
                </button>
                <p className='mt-2'  style={{color:"#606060"}}>
                    Request has been received and other fleet owners have started bidding for this job. Respond soon to win the deal.
                </p>
            </div>
        </div>

        <div className='p-4 rounded-br-lg rounded-tr-lg relative' style={{width:"35%", background:"#efefef"}}>
            <div className='p-3 px-4 rounded-xl' style={{background:"#fff"}}>
                <p style={{color:"#606060"}}>Rate required</p>
                <p className='font-bold mt-2 mb-2' style={{fontSize:"1.5rem", color:"#606060"}}>R{selectedBookingDeal?.length && selectedBookingDeal[0]?.rate_required}</p>
                <p style={{color:"#606060"}}>Goods in Transit</p>
                <p className='font-bold' style={{fontSize:"1.1rem",color:"#606060"}}>R{selectedBookingDeal?.length && selectedBookingDeal[0]?.prerequisites?.goods_in_transit || "1 000 000"}</p>
            </div>
            <div className='mt-4 pb-3' style={{borderBottom:"1px solid #bebebe", lineHeight:"23px"}}>
                <p style={{color:"#606060"}}>Cargo</p>
                <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}>{selectedBookingDeal?.length && selectedBookingDeal[0]?.cargoInformation?.productName}</p>
                {/* <p style={{color:"#606060"}}>HS Code</p>
                <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}>{selectedBookingDeal.length ? selectedBookingDeal[0].cargoInformation.productName : allDeals[0].cargoInformation.productName}</p> */}
                <div className='flex justify-between'>
                    <div>
                        <p  style={{color:"#606060"}}>Packaging</p>
                        <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}>{selectedBookingDeal?.length && selectedBookingDeal[0]?.cargoInformation?.packageType}</p>
                    </div>
                    <div style={{width:"26%"}}>
                        <p  style={{color:"#606060"}}>Quantity</p>
                        <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}>{selectedBookingDeal?.length && selectedBookingDeal[0]?.cargoInformation?.quantity}</p>
                    </div>
                </div>
                <div className='flex justify-between'>
                    <div>
                    <p  style={{color:"#606060"}}>Total Weight</p>
                    <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}>{selectedBookingDeal?.length && ((selectedBookingDeal[0]?.cargoInformation?.weight)* selectedBookingDeal[0]?.cargoInformation?.quantity)}mt</p>
                    <p style={{color:"#606060"}}>Net Weight per value</p>
                    <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}>{selectedBookingDeal?.length && (parseFloat(selectedBookingDeal[0]?.cargoInformation?.weight)).toFixed(2)}mt</p>
                    </div>
                    <div>
                    <p style={{color:"#606060"}}>Total Volume</p>
                    <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}>{selectedBookingDeal?.length && ((parseFloat((selectedBookingDeal[0]?.cargoInformation?.volume)* selectedBookingDeal[0]?.cargoInformation?.quantity))).toFixed(2)}m&#179;</p>
                    <p style={{color:"#606060"}}>Net Volume</p>
                    <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}>{selectedBookingDeal?.length && (parseFloat(selectedBookingDeal[0]?.cargoInformation?.volume)).toFixed(2)}m&#179;</p>
                    </div>
                </div>
            </div>
            <div>
                {/* <p style={{color:"#606060"}}>Hazardous Rating</p>
                <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}>{}</p> */}
                {/* <div className='flex justify-between'> */}
                    {/* <div> */}
                    {/* <p style={{color:"#606060"}}>IMDG Number</p>
                    <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}>{selectedBookingDeal.length ? selectedBookingDeal[0].cargoInformation.volume : allDeals[0].cargoInformation.volume}m&#179;</p> */}
                    {/* </div> */}
                    {/* <div> */}
                    <p style={{color:"#606060"}}>UN Number</p>
                    <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}>{selectedBookingDeal?.length && selectedBookingDeal[0]?.cargoInformation?.unNumber}</p>
                    {/* </div> */}
                {/* </div> */}
                {/* <p style={{color:"#606060"}}>HS Code</p>
                <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}>2345776567899</p> */}
                <p style={{color:"#606060"}}>Temp Control</p>
                <p className='font-bold' style={{fontSize:"1rem", color:"#606060"}}>{selectedBookingDeal?.length && selectedBookingDeal[0]?.cargoInformation?.hazard?.min_temp}&#176;C to {selectedBookingDeal.length && selectedBookingDeal[0]?.cargoInformation?.hazard?.max_temp}&#176;C</p>
            </div>
            <div className='flex absolute left-0 right-0 mb-4 bottom-0 justify-center item-center'>
                <button
                    className='p-3 py-1 rounded-xl' style={{background:"#fce200", width:"92%"}}
                    onClick={() => {
                        setShowFirstDealProcess(true);
                    }}
                >
                    Respond
                </button>
            </div>
            {showFirstDealProcess &&
                <FirstDealRespond/>
            }
        </div>
    </div>
  )
}
