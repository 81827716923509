import React, {useState, useRef, useEffect} from 'react'
import {AiOutlineInfoCircle} from 'react-icons/ai'
import {BsSquareFill} from 'react-icons/bs'
import {MdOutlineCheck} from 'react-icons/md'
import {BiMinus} from 'react-icons/bi'
import {BiPlus} from 'react-icons/bi'
import {useFleetStateContext} from '../context/FleetDealsContext';
import { TbChevronLeft } from "react-icons/tb"
import firebase from 'firebase'
import { AiOutlineWarning } from "react-icons/ai"
import { useStateContext } from '../context/DashboardStateContext'

export default function SetLoadsToAssignDriver({setIsDriverAssign}) {
    const [isAutoChecked, setIsAutoChecked] = useState(false)
    const [isFixedChecked, setIsFixedChecked] = useState(false)
    const [firstBatchView, setFirstBatchView] = useState(true)
    const [secondBatchView, setSecondBatchView] = useState(false)
    const [thirdBatchView, setThirdBatchView] = useState(false)
    const [counterState, setCounterState] = useState(0)
    const [isDriverAssignedToBook, setIsDriverAssignedToBook] = useState(false)
    const [finalDateFormate, setFinalDateFormate] = useState([])
    const [areLoadsSet, setAreLoadsSet] = useState(false)
    const [loads, setLoads] = useState(false)
    const [allLoads, setAllLoads] = useState([]);
    const [loadsSum, setLoadsSum] = useState(0);
    const [loadIncrease, setLoadIncrease] = useState(0);

    // const [firstBatch, setFirstBatch] = useState([])
    const [firstBatchLoads, setFirstBatchLoads] = useState([])
    const {
        values,
        setIsFirstDealAssign,
        selectedBookingDeal,
        firstBatch,
        setFirstBatch,
        DriverToAssign,
        datesProperlyFormated,
        setDatesProperlyFormated
    } = useFleetStateContext();

    const {
        userUid,
        setShowFirstDealProcess
    } = useStateContext();

        function stepUpFnc(e) {
            const loadsInput = e.currentTarget.previousSibling.firstChild
            loadsInput.stepUp();
            setLoads(true)
        }

        function stepDownFnc(e) {
            const loadsInput = e.currentTarget.nextSibling.firstChild
            loadsInput.stepDown();
            if(loadsInput.value === 0) setLoads(false)
        }

        const updateLoads = (i, e) => {
            const loadsInput = e.currentTarget;
            let newArr = [...firstBatch];
            newArr[i].loads = loadsInput.value;
            setFirstBatch(newArr);
            setLoads(true); // Add this line to indicate that there are loads present
        };

        const updateLoadsUpBtn = (i, e) => {
            const loadsInput = e.currentTarget.previousSibling.firstChild;
            let newArr = [...firstBatch];
            newArr[i].loads = loadsInput.value;

            const dateObj = new Date(newArr[i].dates);
            if (!isNaN(dateObj)) {
              const getYearToPickPosition = dateObj.getFullYear();
              const getMonthToPickPosition = String(dateObj.getMonth() + 1).padStart(2, '0');
              const getDayToPickPosition = String(dateObj.getDate()).padStart(2, '0');
              const wholeYearToPickPosition = `${getYearToPickPosition}-${getMonthToPickPosition}-${getDayToPickPosition}`;

              setDatesProperlyFormated(prevDates => {
                const updatedDates = { ...prevDates };
                if (wholeYearToPickPosition in updatedDates) {
                  // If the date exists, update the loads value
                  updatedDates[wholeYearToPickPosition][0].loads = loadsInput.value;
                } else {
                  // If the date doesn't exist, create a new entry
                  updatedDates[wholeYearToPickPosition] = [
                    {
                      booking_id: selectedBookingDeal[0]?.booking_id,
                      date: wholeYearToPickPosition,
                      duration: selectedBookingDeal[0]?.duration,
                      loads: parseFloat(loadsInput?.value).toFixed(2),
                    },
                  ];
                  setLoadIncrease(loadsInput.value)
                }
                return updatedDates;
              });
            }
            setFirstBatch(newArr);
            setLoads(true);
        };


        //   function formatDateKey(date) {
        //     const year = date.substring(0, 4);
        //     const month = date.substring(5, 7);
        //     const day = date.substring(8, 10);
        //     return `${year}-${month}-${day}`;
        //   }

        const updateLoadsDownBtn = (i, e) => {
            const loadsInput = e.currentTarget.nextSibling.firstChild;
            let newArr = [...firstBatch];
            newArr[i].loads = loadsInput.value;

            const dateObj = new Date(newArr[i].dates);
            if (!isNaN(dateObj)) {
              const getYearToPickPosition = dateObj.getFullYear();
              const getMonthToPickPosition = String(dateObj.getMonth() + 1).padStart(2, '0');
              const getDayToPickPosition = String(dateObj.getDate()).padStart(2, '0');
              const wholeYearToPickPosition = `${getYearToPickPosition}-${getMonthToPickPosition}-${getDayToPickPosition}`;

              setDatesProperlyFormated(prevDates => {
                const updatedDates = { ...prevDates };
                if (wholeYearToPickPosition in updatedDates) {
                  // If the date exists, update the loads value
                  updatedDates[wholeYearToPickPosition][0].loads = loadsInput.value;
                } else {
                  // If the date doesn't exist, create a new entry
                  updatedDates[wholeYearToPickPosition] = [
                    {
                      booking_id: selectedBookingDeal[0].booking_id,
                      date: wholeYearToPickPosition,
                      duration: selectedBookingDeal[0].duration,
                      loads: parseFloat(loadsInput?.value).toFixed(2),
                    },
                  ];
                  setLoadIncrease(loadsInput.value)
                }
                return updatedDates;
              });
            }
            setFirstBatch(newArr);
            setLoads(true);
        };


          const AssignDriverToLoad = (loadsSum) => {
            console.log("The calendar dates", datesProperlyFormated)
            // function transformArray(arr) {
            //     if (!Array.isArray(arr)) {
            //       arr = [arr]; // Convert non-array input into an array
            //     }

            //     const transformedArray = arr.map((obj) => {
            //       const [date, data] = Object.entries(obj)[0];

            //       const updatedData = Array.isArray(data)
            //         ? data.map((item) => ({
            //             ...item,
            //             loads: item.loads + 1,
            //           }))
            //         : data;

            //       return {
            //         [date]: updatedData,
            //       };
            //     });

            //     return transformedArray;
            //   }

            //   // Use console.log to inspect the actual structure of your input data
            //   console.log("Input data:", datesProperlyFormated);

            //   // Assuming datesProperlyFormated is the input array of objects
            //   const newArray = transformArray(datesProperlyFormated);

            if (loads && datesProperlyFormated) {
                const firebaseMethod = firebase.database();
                const dates = Object.keys(datesProperlyFormated);

                // Save the price to the database
                // const bookRefNumber = firebase.database().ref("booking").child(selectedBookingDeal[0].booking_id)

                // const bookingRef =
                // const fleetRef =

                firebase.database().ref("fleets").child(userUid).child("booking_bids").child(selectedBookingDeal[0].booking_id).on("value", snapshot => {
                // let agreedPrice = snapshot.val()?.price !== null ? snapshot.val()?.price : selectedBookingDeal[0].rate_required;

                // Make sure agreedPrice is a number
                if(snapshot.val()?.price){
                    firebase.database().ref("booking").child(selectedBookingDeal[0].booking_id).update({
                        AmountAccepted: snapshot.val()?.price[snapshot.val()?.price.length -1]
                    });
                }else{
                    firebase.database().ref("booking").child(selectedBookingDeal[0].booking_id).update({
                        AmountAccepted: selectedBookingDeal[0].rate_required
                    });
                }
                // agreedPrice = typeof agreedPrice === 'number' ? agreedPrice : parseFloat(agreedPrice); // Assuming agreedPrice should be a number
                });


                // bookRefNumber.update({
                //     AmountAccepted: selectedBookingDeal[0].rate_required
                // })

                if (!DriverToAssign || !DriverToAssign.uid) {
                    console.error("DriverToAssign is not valid");
                    return;
                }

                const bookingId = selectedBookingDeal[0]?.booking_id;
                if (!bookingId) {
                    console.error("Booking ID is missing");
                    return;
                }

                const driversUpdate = {
                    0: DriverToAssign.uid,
                };

                const datesKeys = Object.keys(datesProperlyFormated);

                const updatedDates = {};
                for (const key of datesKeys) {
                    updatedDates[key] = datesProperlyFormated[key];
                }

                const formattedDates = [];
                Object.keys(datesProperlyFormated).forEach((date) => {
                    const dateObj = new Date(date);
                    if (!isNaN(dateObj)) {
                        const getYearToPickPosition = dateObj.getFullYear();
                        const getMonthToPickPosition = String(dateObj.getMonth() + 1).padStart(2, '0');
                        const getDayToPickPosition = String(dateObj.getDate()).padStart(2, '0');
                        const wholeYearToPickPosition = `${getYearToPickPosition}-${getMonthToPickPosition}-${getDayToPickPosition}`;
                        formattedDates.push(wholeYearToPickPosition);
                    }
                });

                const bookingsUpdate = {
                    booking_id: bookingId,
                    dates: { ...formattedDates },
                    drivers: { ...driversUpdate },
                    price: selectedBookingDeal[0]?.rate_required,
                    total_loads: loadsSum,
                };

                firebaseMethod.ref("/booking/" + bookingId).update({
                    drivers: driversUpdate,
                });

                if (bookingsUpdate) {
                    firebaseMethod
                        .ref("/fleets/" + userUid + "/bookings/" + bookingId)
                        .update(bookingsUpdate);
                }

                firebaseMethod.ref("/booking/" + bookingId + "/booking_bids_fleet_id/").remove();

                if (datesProperlyFormated && Object.keys(datesProperlyFormated).length > 0) {
                    Object.keys(datesProperlyFormated).forEach((dateKey) => {
                        if (selectedBookingDeal[0]?.booking_id && selectedBookingDeal[0]?.total_duration) {
                            // if (Array.isArray(datesProperlyFormated[dateKey])) {

                                function transformArray(arr) {
                                    const transformedArray = Object.entries(arr).map(([date, data]) => {
                                      const dataArray = Array.isArray(data) ? data : [data];

                                      const updatedData = dataArray.map((item) => {
                                        const parsedLoads = parseInt(item.loads, 10);
                                        if (isNaN(parsedLoads)) {
                                          console.error(`Invalid 'loads' value: ${item.loads}`);
                                        }
                                        return {
                                          ...item[0],
                                        };
                                      });

                                      return {
                                        [date]: updatedData,
                                      };
                                    });
                                    return transformedArray;
                                  }

                                  // Use console.log to inspect the actual structure of your input data
                                  console.log("Input data:", datesProperlyFormated);

                                  // Assuming datesProperlyFormated is the input array of objects
                                  const newArray = transformArray(datesProperlyFormated);

                                  const driverRef = firebase.database().ref(`/drivers/${DriverToAssign.uid}/calendar/`)

                                  newArray.forEach((obj) => {
                                    const [date, data] = Object.entries(obj)[0];

                                    // Check if the date exists in the database
                                    driverRef.child(date).once("value").then((snapshot) => {
                                      const existingData = snapshot.val();

                                      if (existingData) {
                                        // If the date exists, append the data
                                        const updatedData = [...existingData, ...data];
                                        driverRef.child(date).set(updatedData);
                                      } else {
                                        // If the date doesn't exist, set the data directly
                                        driverRef.child(date).set(data);
                                      }
                                    });
                                  });

                                // datesProperlyFormated[dateKey].forEach((data, index) => {
                                //     const newLoadValue = index + 1; // Increment loads value
                                //     let Increment = 0;

                                //     const updateDriverCalendar = () => {
                                //         const driverCalendarRef = firebaseMethod.ref(`/drivers/${DriverToAssign.uid}/calendar/${dateKey}/${Increment}`);
                                //         driverCalendarRef.once('value').then((snapshot) => {
                                //             const existingData = snapshot.val();
                                //             if (existingData) {
                                //                 Increment++;
                                //                 updateDriverCalendar();
                                //             } else {
                                //                 // Data doesn't exist, add it with the incremented Increment
                                //                 driverCalendarRef.update({
                                //                     booking_id: selectedBookingDeal[0].booking_id,
                                //                     date: data.date,
                                //                     duration: selectedBookingDeal[0].total_duration,
                                //                     loads: newLoadValue,
                                //                 });
                                //             }
                                //         });
                                //     };

                                //     updateDriverCalendar();
                                // });
                            // }
                        }
                    });
                }
                setIsDriverAssignedToBook(true);
            } else {
                setAreLoadsSet(true);
            }
        };

        // console.log("dates properly formatted", datesProperlyFormated[0]);

        console.log("The selected book", selectedBookingDeal[0]);

        useEffect(() => {
            // Calculate the sum of loads whenever 'datesProperlyFormated' changes
            const calculateLoadsSum = () => {
              let sum = 0;
              Object.values(datesProperlyFormated).forEach((item) => {
                const load = parseInt(item[0].loads, 10);
                if (!isNaN(load)) {
                  sum += load;
                }
              });
              setLoadsSum(sum);
            };

            // Run the calculation when the component mounts and whenever 'datesProperlyFormated' changes
            calculateLoadsSum();
          }, [datesProperlyFormated]);



        console.log("All the dates", loadsSum)

  return (
    <div
        className='absolute w-full p-4 py-4 flex flex-col top-0 bottom-0 left-0 right-0 scrollbar-hide'
        style={{background:"#fff", zIndex:"2", height:"650px", maxHeight:"650px", overflow:"scroll"}}
    >
        <div className='flex justify-between items-center'>
            <div className='flex items-center mt-0'>
                <p className='w-16 h-16 m-0 p-0 rounded-full' style={{background:"#dadada"}}><img className='w-full h-full rounded-full' style={{objectFit:"cover"}} src={DriverToAssign.photo_uri} alt="" /></p>
                <div className='ml-2'>
                    <p className='m-0 p-0 font-bold text-sm' style={{fontSize:"1.1rem"}}>{DriverToAssign.name}</p>
                    <p className='m-0 p-0 text-gray-500' style={{fontSize:".7rem"}}>{DriverToAssign.phone}</p>
                </div>
            </div>
            <p className='' style={{fontWeight:".7rem", fontSize:"1.3rem"}}><AiOutlineInfoCircle/></p>
        </div>
        <div className='mt-3'>
            <p className='p-0 m-0' style={{fontSize:".7rem", color:"grey"}}>Expected Location</p>
            <p className='font-bold p-0 m-0' style={{fontSize:".9rem"}}>Johannesburg, Gauteng</p>
        </div>
        <div className='flex justify-between mt-5 items-center'>
            <div className='flex flex-row justify-center items-center'>
                <div className='cursor-pointer' onClick={() => {
                        setIsAutoChecked((prev) => !prev)
                    }}>
                    {!isAutoChecked ?
                        <p className='mr-2' style={{fontSize:".8rem", color:"#CECECE"}}><BsSquareFill/></p>
                        :   <p className='mr-2' style={{fontSize:".9rem", color:"#000", background:"#fce200"}}><MdOutlineCheck/></p>
                    }
                </div>
                <p className='font-bold' style={{fontSize:".9rem"}}>Auto Assign</p>
            </div>
            <div className='flex flex-row justify-center items-center'>
                <div className='cursor-pointer' onClick={() => setIsFixedChecked((prev) => !prev)}>
                    {!isFixedChecked ?
                        <p className='mr-2' style={{fontSize:".8rem", color:"#CECECE"}}><BsSquareFill/></p>
                        :   <p className='mr-2' style={{fontSize:".9rem", color:"#000", background:"#fce200"}}><MdOutlineCheck/></p>
                    }
                </div>
                <p className='font-bold' style={{fontSize:".9rem"}}>Fix to Contract</p>
            </div>

        </div>
        <div className='flex mt-4 px-2 justify-between items-center'>
            <p style={{color:"#818181", fontSize:".7rem"}}>{new Date().getFullYear()}</p>
            <p style={{color:"#818181", fontSize:".7rem"}}>Max Loads</p>
        </div>
        <div className='flex flex-col mt-4 px-2 w-full items-center' style={{height:"365px", maxHeight:"365px"}}>
            {firstBatchView &&
                firstBatch.filter((item, i) => i < 7).map((date, i) => (
                    <div className='flex w-full mb-3 justify-between items-center'>
                        <div>
                            <p className='font-bold' style={{fontSize:".8rem", width:"80px"}}>{date.dates.toString().substring(0,3)}, {date.dates.toString().substring(8,10)}, {date.dates.toString().substring(4,7)}</p>
                        </div>
                        <div className='flex justify-between items-center   px-2 py-2' style={{borderRadius:"10px", backgroundColor:"#F2F2F2", width:"7rem"}}>
                            <div className='cursor-pointer'
                                onClick={(e) => {
                                    stepDownFnc(e)
                                    updateLoadsDownBtn(i,e)
                                }}
                            >
                                <p
                                    className=' flex justify-center cursor-pointer items-center spinner__button spinner__button--left js-spinner-horizontal-subtract'
                                    style={{
                                        fontSize:".9rem",
                                        color:"#000",
                                        width:"1.3rem",
                                        height:"1.3rem",
                                        background:"#FFFFFF",
                                        borderRadius:"5px"
                                    }}
                                    data-type="subtract"
                                    aria-controls="spinner-input"
                                >
                                    <BiMinus/>
                                </p>
                            </div>
                            <div>
                                <input
                                    type='number'
                                    min={0} max={selectedBookingDeal[0].actual_loads_for_cargo}
                                    className='flex text-center justify-center items-center loads-input'
                                    defaultValue={firstBatch[i].autoAssignedNumber ? firstBatch[i].autoAssignedNumber : firstBatch[i].loads}
                                    // value={firstBatch[i].autoAssignedNumber ? firstBatch[i].autoAssignedNumber : firstBatch[i].loads}
                                    onChange={(e)=> {
                                        updateLoads(i,e)
                                    }}
                                    style={{color:"#8A8A8A", width:"40px", background:"transparent"}}
                                />
                            </div>
                            <div
                                    className='cursor-pointer'
                                    onClick={(e) => {
                                        stepUpFnc(e)
                                        updateLoadsUpBtn(i,e)
                                    }}>
                                <p
                                    className=' flex justify-center cursor-pointer items-center spinner__button spinner__button--right js-spinner-horizontal-add'
                                    data-type="add"
                                    aria-controls="spinner-input"
                                    style={{
                                        fontSize:".9rem",
                                        color:"#000",
                                        width:"1.3rem",
                                        height:"1.3rem",
                                        background:"#FFFFFF",
                                        borderRadius:"5px"
                                    }}
                                >
                                    <BiPlus/>
                                </p>
                            </div>
                        </div>
                        <div><p style={{color:"#8A8A8A"}}>{selectedBookingDeal[0].actual_loads_for_cargo}</p></div>
                    </div>
                ))
            }
            {secondBatchView &&
                firstBatch.filter((item, i) => i < 14 && i > 6).map((date, i) => (
                    <div className='flex w-full mb-3 justify-between items-center'>
                        <div>
                            <p className='font-bold' style={{fontSize:".8rem", width:"80px"}}>{date.dates.toString().substring(0,3)}, {date.dates.toString().substring(8,10)}, {date.dates.toString().substring(4,7)}</p>
                        </div>
                        <div className='flex justify-between items-center   px-2 py-2' style={{borderRadius:"10px", backgroundColor:"#F2F2F2", width:"7rem"}}>
                            <div className='cursor-pointer'
                                onClick={(e) => {
                                    stepDownFnc(e)
                                    updateLoadsDownBtn(i,e)
                                }}
                            >
                                <p
                                    className=' flex justify-center cursor-pointer items-center spinner__button spinner__button--left js-spinner-horizontal-subtract'
                                    style={{
                                        fontSize:".9rem",
                                        color:"#000",
                                        width:"1.3rem",
                                        height:"1.3rem",
                                        background:"#FFFFFF",
                                        borderRadius:"5px"
                                    }}
                                    data-type="subtract"
                                    aria-controls="spinner-input"
                                >
                                    <BiMinus/>
                                </p>
                            </div>
                            <div>
                                <input
                                    type='number'
                                    min={0} max={selectedBookingDeal[0].actual_loads_for_cargo}
                                    className='flex text-center justify-center items-center loads-input'
                                    defaultValue={firstBatch[i].loads}
                                    onChange={(e)=> {
                                        updateLoads(i,e)
                                    }}
                                    style={{color:"#8A8A8A", width:"40px", background:"transparent"}}
                                    // id="spinner-input"
                                />
                            </div>
                            <div
                                    className='cursor-pointer'
                                    onClick={(e) => {
                                        stepUpFnc(e)
                                        updateLoadsUpBtn(i,e)
                                    }}>
                                <p
                                    className=' flex justify-center cursor-pointer items-center spinner__button spinner__button--right js-spinner-horizontal-add'
                                    data-type="add"
                                    aria-controls="spinner-input"
                                    style={{
                                        fontSize:".9rem",
                                        color:"#000",
                                        width:"1.3rem",
                                        height:"1.3rem",
                                        background:"#FFFFFF",
                                        borderRadius:"5px"
                                    }}
                                >
                                    <BiPlus/>
                                </p>
                            </div>
                        </div>
                        <div><p style={{color:"#8A8A8A"}}>{selectedBookingDeal[0].actual_loads_for_cargo}</p></div>
                    </div>
                ))
            }
            {thirdBatchView &&
                firstBatch.filter((item, i) => i > 14 && i < 20).map((date, i) => (
                    <div className='flex w-full mb-3 justify-between items-center'>
                        <div>
                            <p className='font-bold' style={{fontSize:".8rem", width:"80px"}}>{date.dates.toString().substring(0,3)}, {date.dates.toString().substring(8,10)}, {date.dates.toString().substring(4,7)}</p>
                        </div>
                        <div className='flex justify-between items-center   px-2 py-2' style={{borderRadius:"10px", backgroundColor:"#F2F2F2", width:"7rem"}}>
                            <div className='cursor-pointer'
                                onClick={(e) => {
                                    stepDownFnc(e)
                                    updateLoadsDownBtn(i,e)
                                }}
                            >
                                <p
                                    className=' flex justify-center cursor-pointer items-center spinner__button spinner__button--left js-spinner-horizontal-subtract'
                                    style={{
                                        fontSize:".9rem",
                                        color:"#000",
                                        width:"1.3rem",
                                        height:"1.3rem",
                                        background:"#FFFFFF",
                                        borderRadius:"5px"
                                    }}
                                    data-type="subtract"
                                    aria-controls="spinner-input"
                                >
                                            <BiMinus/>
                                </p>
                            </div>
                            <div>
                                <input
                                    type='number'
                                    min={0} max={selectedBookingDeal[0].actual_loads_for_cargo}
                                    className='flex text-center justify-center items-center loads-input'
                                    defaultValue={firstBatch[i].loads}
                                    onChange={(e)=> {
                                        updateLoads(i,e)
                                    }}
                                    style={{color:"#8A8A8A", width:"40px", background:"transparent"}}
                                    // id="spinner-input"
                                />
                            </div>
                            <div
                                    className='cursor-pointer'
                                    onClick={(e) => {
                                        stepUpFnc(e)
                                        updateLoadsUpBtn(i,e)
                                    }}>
                                <p
                                    className=' flex justify-center cursor-pointer items-center spinner__button spinner__button--right js-spinner-horizontal-add'
                                    data-type="add"
                                    aria-controls="spinner-input"
                                    style={{
                                        fontSize:".9rem",
                                        color:"#000",
                                        width:"1.3rem",
                                        height:"1.3rem",
                                        background:"#FFFFFF",
                                        borderRadius:"5px"
                                    }}
                                >
                                    <BiPlus/>
                                </p>
                            </div>
                        </div>
                        <div><p style={{color:"#8A8A8A"}}>{selectedBookingDeal[0].actual_loads_for_cargo}</p></div>
                    </div>
                ))
            }
            {!firstBatch.length &&
            <div className='flex w-full mb-3 flex-col absolute left-0 right-0 m-auto'>
                <span className='flex items-center'>
                    <p className='text-2xl font-bold cursor-pointer'
                        onClick={() =>{
                            setIsDriverAssign(false)
                            setIsFirstDealAssign(false)
                        }}><TbChevronLeft/>
                    </p>
                    Back
                </span>
                <p>No dates selected please go back and select dates</p>
            </div>
            }

        </div>
        {firstBatch.length && firstBatch.length > 7 &&
            <div className='flex justify-center items-center mx-auto mb-2' style={{width:"100%"}}>
                {/* {firstBatch.length < 7 ? */}
                    <div
                        className='w-3 mr-3 p-0 m-0 h-3 cursor-pointer rounded-full'
                        style={{background:`${firstBatchView ? "#fce200" : "#818181"}`}}
                        onClick={() => {
                            setFirstBatchView(true)
                            setSecondBatchView(false)
                            setThirdBatchView(false)
                        }}
                    ></div>
                {/* } */}
                {firstBatch.length < 14 && firstBatch.length > 7?
                    <div
                        className='w-3 mr-3 p-0 m-0 h-3 cursor-pointer rounded-full'
                        style={{background:`${secondBatchView ? "#fce200" : "#818181"}`}}
                        onClick={() => {
                            setFirstBatchView(false)
                            setSecondBatchView(true)
                            setThirdBatchView(false)
                        }}
                    ></div>:<></>
                }
                {firstBatch.length > 14 && firstBatch.length < 21?
                    <div
                        className='w-3 mr-3 p-0 m-0 h-3 cursor-pointer rounded-full'
                        style={{background:`${thirdBatchView ? "#fce200" : "#818181"}`}}
                        onClick={() => {
                            setFirstBatchView(false)
                            setSecondBatchView(false)
                            setThirdBatchView(true)
                        }}
                    ></div>:<></>
                }
            </div>
        }
         <div className='mb-5 absolute left-0 right-0 m-auto' style={{width:"95%", bottom:"-1%"}}>
            <button
                className='p-3 py-1 rounded-xl' style={{background:"#fce200", width:"100%"}}
                onClick={() => AssignDriverToLoad(loadsSum)}
            >
                Continue
            </button>
        </div>
        {isDriverAssignedToBook &&
            <div
                className='w-full flex justify-center items-center absolute left-0 right-0 top-0 bottom-0'
                style={{backdropFilter: "blur(5px)", height:"100%"}}
            >
                <div
                    className='flex flex-col justify-center rounded-xl p-3'
                    style={{
                        width:"76%",
                        height:"fit-content",
                        background:"white"
                    }}
                >
                    <p className='font-bold' style={{fontSize:".8rem"}}>Heads up!</p>
                    <p className=''>You have successfully assigned {DriverToAssign.name} {DriverToAssign.surname} to this booking. They will be notified and sent to complete trips.</p>
                    <div className='mt-2 w-full flex justify-end items-centerz'>
                        <p
                            className='font-bold cursor-pointer'
                            onClick={() => {
                                setIsDriverAssignedToBook(false)
                                setIsDriverAssign(false)
                                setIsFirstDealAssign(false)
                                setShowFirstDealProcess(false)
                            }}
                        >OK</p>
                    </div>
                </div>
            </div>
        }
        {areLoadsSet &&
            <div
                className='w-full flex justify-center items-center h-full absolute left-0 right-0 top-0 bottom-0'
                style={{backdropFilter: "blur(5px)"}}
            >
                <div
                    className='flex flex-col justify-center rounded-xl p-3'
                    style={{
                        width:"76%",
                        height:"fit-content",
                        background:"white"
                    }}
                >
                    <p className='font-bold' style={{fontSize:"1.3rem", color:"red"}}><AiOutlineWarning/></p>
                    <p className='mt-3'>Please make sure to set loads for each date.</p>
                    <div className='mt-2 w-full flex justify-end items-center'>
                        <p
                            className='font-bold cursor-pointer'
                            onClick={() => setAreLoadsSet(false)}
                        >OK</p>
                    </div>
                </div>
            </div>
        }
    </div>
  )
}
