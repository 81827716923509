import React, {useState, useEffect} from 'react'
import PlacesAutocomplete from 'react-places-autocomplete';
import '../css/locationAuto.css';
import { useStateContext } from '../context/BookingAddressTwo'
import { useStateBidding } from '../context/BiddingStatesContext'

export default function LocationSearchInputTwo({
  setBookingArrayTwo,
  bookingArrayTwo
}) {

  const {
    isAddressAuto,
    setIsAddressAuto,
  } = useStateContext();

  const {
    doCity,
    setDoCity
  } = useStateBidding();

  const handleChange = (value) => {
    setIsAddressAuto(value);
    console.log("this the value", value);
  }

  const handleSelect = (value) => {
    setIsAddressAuto(value);
    console.log("Selected value:", value);

    const parts = value.split(','); // Split the value by comma
    if (parts.length > 1) {
      const city = parts[parts.length - 3].trim(); // City is the second-to-last part
      setDoCity(city);
      setBookingArrayTwo((prevState) => ({
        ...prevState,
        details:{
            ...prevState.details,
            city:city
        }
      }))
    }
  }

  const handleBlur = (event) => {
    const value = event.target.value;
    if (value) {
      const parts = value.split(',');
      if (parts.length > 1) {
        const city = parts[parts.length - 3].trim();
        setDoCity(city);
        setBookingArrayTwo((prevState) => ({
          ...prevState,
          details:{
            ...prevState.details,
            city:city
          }
        }));
      }
    }
  }

  console.log("This is the city", doCity);

  return (
    <div>
      <PlacesAutocomplete value={isAddressAuto} onChange={handleChange} onSelect={handleSelect}>
        {({getInputProps, suggestions, getSuggestionItemProps, loading})=>(
          <div>
            <input
              {...getInputProps({
                placeholder: 'Physical address',
                className: 'location-search-input',
                onBlur: handleBlur // Added onBlur event handler
              })}
            />
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion) => {
              const style = suggestion.active ?
              {background: "#f9dd07", cursor: "pointer"} :
              {background: "white", cursor: "pointer"}

              return(
                <div {...getSuggestionItemProps(suggestion, {style})}>
                    {suggestion.description}
                </div>
              )
            })}
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  )
}
