import React, { createContext, useContext, useState, useEffect } from "react";
const Context = createContext();

export const AddressStateContext = ({ children }) => {
    const [isAddressAuto, setIsAddressAuto] = useState("");
    const [addressRegistration, setAddressRegistration] = useState();
    const [addressAddDriver, setAddressAddDriver] = useState();


    return (
        <Context.Provider
            value={{
                isAddressAuto,
                setIsAddressAuto,
                addressRegistration,
                setAddressRegistration,
                addressAddDriver,
                setAddressAddDriver
            }}
        >
            {children}
        </Context.Provider>
    )
}
export const useStateContext = () => useContext(Context);