import React from 'react'
import { useStateContextBookings } from '../context/AllBookingsContext'

export default function YearlyFilter() {
    const {
        getYearlyBookings,
        allOfTheBooking,
        getYearlyBookingsPrevious
    } = useStateContextBookings();

    var counter = 0;

    // Determine the year from the bookings
    const year = getYearlyBookings.length > 0
        ? new Date(getYearlyBookings[0].date_created).getFullYear()
        : (new Date()).getFullYear();

    const yearPrev = getYearlyBookingsPrevious?.length > 0
        ? new Date(getYearlyBookingsPrevious[0]?.date_created)?.getFullYear()
        : (new Date()).getFullYear();

  return (
    <div className='dash-date' style={{display:`${allOfTheBooking.length ? "block" : "none"}`}}>
        <div className='dash-date-title'>
            <h2>{year}</h2>
            <div className='w-full h-6 px-[22px] bg-black flex text-white justify-between'>
                <div className='left-book-name'>
                    <p className='m-0 p-0'>Cargo</p>
                </div>
                <div className='right-dash-name'>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Vehicle</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Route</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Reference</p>
                    </div>
                    <div className='w-[100px] flex justify-end'>
                        <p className='m-0 p-0 text-end'>Status</p>
                    </div>
                </div>
            </div>
            <div className='booking-dash' style={{height: `${!getYearlyBookings.length > 0 && getYearlyBookings.length > 3 ? "200px" :"121px"}`, maxHeight: `${!getYearlyBookings.length > 0 && getYearlyBookings.length > 3 ? "200px" :"121px"}`}}>
                {getYearlyBookings.length > 0 && getYearlyBookings.map((booking, i) => (
                    <div className='book-name' key={i}>
                        <div className='left-book-name'>
                            <h2 style={{fontWeight:"bold", marginRight:"15px"}}>{counter += 1}</h2>
                            <p style={{fontSize:"12px", textAlign:"center", fontSize:"11px", marginBottom:"0"}}>{booking.cargoInformation.productName}</p>
                        </div>
                        <div className='right-dash-name'>
                            <div style={{width:"100px"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{booking.cargoInformation.vehicle_type}</p>
                            </div>
                            <div style={{width:"100px", textAlign:"end"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{((booking.puDetails.puCityName).substring(0,3)).toUpperCase()} - {((booking.doDetails.doCityName).substring(0,3)).toUpperCase()}</p>
                            </div>
                            <div style={{width:"100px"}}>
                                <p style={{marginBottom:"0", textAlign:"end"}}>{booking.booking_ref}</p>
                            </div>
                            <div style={{width:"100px"}}>
                                <p style={{ marginBottom:"0", color:"#f90500", fontWeight:"bold", textAlign:"end"}}>{booking?.undelivered_loads === 0 ? "delivered" : "Not delivered"}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        {getYearlyBookingsPrevious &&
            <div className='dash-date-title'>
                <h2>{yearPrev}</h2>
                <div className='w-full h-6 px-[22px] bg-black flex text-white justify-between'>
                    <div className='left-book-name'>
                        <p className='m-0 p-0'>Cargo</p>
                    </div>
                    <div className='right-dash-name'>
                        <div className='w-[100px] flex justify-end'>
                            <p className='m-0 p-0 text-end'>Vehicle</p>
                        </div>
                        <div className='w-[100px] flex justify-end'>
                            <p className='m-0 p-0 text-end'>Route</p>
                        </div>
                        <div className='w-[100px] flex justify-end'>
                            <p className='m-0 p-0 text-end'>Reference</p>
                        </div>
                        <div className='w-[100px] flex justify-end'>
                            <p className='m-0 p-0 text-end'>Status</p>
                        </div>
                    </div>
                </div>
                <div className='booking-dash' style={{height: `${!getYearlyBookingsPrevious.length > 0 && getYearlyBookingsPrevious.length > 3 ? "200px" :"121px"}`, maxHeight: `${!getYearlyBookingsPrevious.length > 0 && getYearlyBookingsPrevious.length > 3 ? "200px" :"121px"}`}}>
                    {getYearlyBookingsPrevious.length > 0 && getYearlyBookingsPrevious.map((booking, i) => (
                        <div className='book-name' key={i}>
                            <div className='left-book-name'>
                                <h2 style={{fontWeight:"bold", marginRight:"15px"}}>{counter += 1}</h2>
                                <p style={{fontSize:"12px", textAlign:"center", fontSize:"11px", marginBottom:"0"}}>{booking.cargoInformation.productName}</p>
                            </div>
                            <div className='right-dash-name'>
                                <div style={{width:"100px"}}>
                                    <p style={{marginBottom:"0", textAlign:"end"}}>{booking.cargoInformation.vehicle_type}</p>
                                </div>
                                <div style={{width:"100px", textAlign:"end"}}>
                                    <p style={{marginBottom:"0", textAlign:"end"}}>{((booking.puDetails.puCityName).substring(0,3)).toUpperCase()} - {((booking.doDetails.doCityName).substring(0,3)).toUpperCase()}</p>
                                </div>
                                <div style={{width:"100px"}}>
                                    <p style={{marginBottom:"0", textAlign:"end"}}>{booking.booking_ref}</p>
                                </div>
                                <div style={{width:"100px"}}>
                                    <p style={{ marginBottom:"0", color:"#f90500", fontWeight:"bold", textAlign:"end"}}>{booking?.undelivered_loads === 0 ? "delivered" : "Not delivered"}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        }
    </div>
  )
}
