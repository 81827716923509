import React, {useState, useEffect} from 'react'
import { useBookingLogic } from '../screens/saveBooking';
import { useStateContext } from '../context/DashboardStateContext'

const QuoteRatePage = ({
    infoShow,
    setInfoShow,
    setPopUpQuote,
}) => {

    const {
        // rateIndication,
        // setRateIndication
        setIsUseOwnTransport
    } = useStateContext();

    const { saveTheBooking } = useBookingLogic();
    const [rateError, setRateError] = useState(false)
    const [rateIndication, setRateIndication] = useState("")

    const noRateInputed = () => {
        if(rateIndication !== ""){
            saveTheBooking(rateIndication)
        }else{
            setRateError(true)
        }
    }

    const {
    } = useStateContext();

  return (
    <div className='w-full beforeEffect flex-col h-full absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center' style={{zIndex:"1"}}>
        <div className='w-[600px] flex justify-end font-bold text-[1.6rem] text-[white] cursor-pointer' onClick={() => setPopUpQuote(false)}>X</div>
        <div className='w-[600px] relative flex-col h-[230px] bg-[#E8E8E8] flex items-center rounded-xl'>
            <div className='flex items-end'>
                <h1 className='mt-6 font-bold text-[1.1rem]'>Do you have a rate indication for this booking?</h1>
                <p className='h-[12px] w-[12px] rounded-full border border-[1] border-black cursor-pointer flex justify-center items-center mb-4 ml-2' onClick={() => setInfoShow(!infoShow)}>i</p>
            </div>
            {infoShow &&
                <div className='absolute right-2 top-3 bottom-0 p-2 bg-white w-[108px] cursor-pointer rounded-xl h-[110px]' onClick={() => setInfoShow(false)}>
                    <p className='text-[.6rem] text-center' style={{lineHeight:"10px"}}>Enter a number you would like a transport to match</p>
                    <p  className='text-[.6rem] text-center' style={{lineHeight:"10px"}}>Please note that your requested rate may not always be matched and transporters may counter- offer or reject your request rate.</p>
                </div>
            }
            <div className='mt-5 w-[250px] flex justify-between rounded-xl h-[30px] bg-white'>
                <input
                    type="number"
                    className='rounded-xl w-[50%] text-[.8rem] px-3'
                    placeholder='e.g. 17000'
                    onChange={(e) => {
                        setRateIndication(e.target.value)
                        // setAskingPrice(true)
                    }}
                />
                <div className='cursor-pointer h-full justify-center items-center rounded-xl flex bg-[#f9dd07] w-[50%]'>
                    <button
                        className='m-0 h-full w-full p-0 font-bold text-[.9rem]'
                        onClick={() => {
                            noRateInputed()
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
            <p className='mt-4 text-[1rem] font-bold'>Or</p>
            <div
                className='mt-4 w-[250px] h-[30px] rounded-xl flex justify-center items-center cursor-pointer bg-[#f9dd07]'
                onClick={() => {
                    saveTheBooking()
                }}
            >
                Submit RFQ
            </div>
            {rateError &&
            <div className='absolute translate-x-[-50%] translate-y-[-50%] flex-col left-[50%] top-[50%] bottom-0 h-fit w-fit bg-[#fff] flex items-center rounded-xl p-4' style={{zIndex:"1"}}>
                <div>Please enter rate</div>
                <p
                    className='p-[.4rem] bg-[#f9dd07] mt-2 rounded-[9px] cursor-pointer'
                    onClick={() => setRateError(false)}
                >
                    Ok
                </p>
            </div>
        }
        </div>
    </div>
  )
}

export default QuoteRatePage
