// useBookingLogic.js
import React, {useState, useEffect} from 'react';
import { useStateContext } from '../context/DashboardStateContext';
import firebase from '../firebase-config';
import { useStateContextBookings } from '../context/AllBookingsContext'

export const useBookingLogic = () => {
    const {
        isEnterprise,
        setIsEnterprise,
        isTracking,
        setIsTracking,
        isShowSchedule,
        isQuote,
        setIsShowSchedule,
        setIsShowRequest,
        isShowRequest,
        cargoLink,
        setCargoLink,
        fleetLink,
        setFleetLink,
        referalLink,
        setReferalLink,
        profileLink,
        setProfileLink,
        analysisLink,
        setAnalysisLink,
        walletLink,
        setWalletLink,
        getHelpLink,
        setGetHelpLink,
        contactLink,
        setContactLink,
        newDeals,
        setNewDeals,
        showFleet,
        setShowFleet,
        driver,
        setDriver,
        showNoFleet,
        setShowNoFleet,
        showAddVehicle,
        setShowAddVehicle,
        name,
        setName,
        isEmailVerified,
        setIsEmailVerified,
        setShowAddTrailer,
        setShowAssign,
        setIsQuote,
        setPopUpQuote,
        popUpQuote,
        endDate,
        setEndDate,
        startDate,
        setStartDate,
        operationDaysNew,
        setOperationDaysNew,
        includeHolidays,
        setIncludeHolidays,
        dropOffBays,
        collectionBays,
        gateInOutDuration,
        setGateInOutDuration,
        setBookingSuccess,
        isLoading,
        setIsLoading,
        // rateIndication,
        setRateIndication,
        userUid,
        isBookClicked,
        setIsBookClicked,
        isUseOwnTransport
    } = useStateContext();

    const [firstRef, setFirstRef] = useState("ZN404");

    const getNextBookingRef = (currentRef) => {
        const prefix = currentRef.substring(0, 2); // "ZN"
        let numberPart = parseInt(currentRef.substring(2)); // Convert the numeric part to an integer
        // Check if the number part is less than 999
        if (numberPart < 999) {
            numberPart++; // Increment the number
            return prefix + numberPart.toString().padStart(3, '0');
        } else {
            // If the number part is 999, reset it to 000 and increment the letter part
            let firstChar = prefix.charCodeAt(0); // Get ASCII code of the first character
            let secondChar = prefix.charCodeAt(1); // Get ASCII code of the second character

            if (secondChar < 90) { // ASCII code of 'Z' is 90
                secondChar++; // Increment the second character
            } else {
                secondChar = 65; // Reset to 'A' (ASCII code 65) if it was 'Z'
                firstChar = firstChar === 90 ? 65 : firstChar + 1; // Increment the first character or reset if it was 'Z'
            }
            // Construct the new reference
            return String.fromCharCode(firstChar) + String.fromCharCode(secondChar) + "000";
        }
    }

    // get all bookings
    const {
        allOfTheBooking,
        setAllOfTheBooking,
    } = useStateContextBookings();

    useEffect(() => {
        if (allOfTheBooking.length > 0) {
            const lastBookingRef = allOfTheBooking[allOfTheBooking.length - 1].booking_ref;
            let newRef = getNextBookingRef(lastBookingRef);
            // Ensure the newRef is not already in use (basic check, consider making it more robust)
            while (allOfTheBooking.some(booking => booking.booking_ref === newRef)) {
                newRef = getNextBookingRef(newRef); // Generate next until unique
            }
            setFirstRef(newRef);
        }
    }, [allOfTheBooking]); // Consider dependencies based on your application's needs


  const getBookingIFnc = async () => {
    return await firebase.database().ref().push();
  };

  const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

    const saveTheBooking = async (rateIndication) => {
        if(
            localStorage.getItem("Prerequisites") &&
            localStorage.getItem("pickSelectd") &&
            localStorage.getItem("dropSelectd") &&
            localStorage.getItem("cargoSelectd")
        ){
            if(isUseOwnTransport === "use own transport"){
                //Send Email
                fetch("https://zipimail.onrender.com/fleetContact", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        fleetName:"Tshilidzi Rambuda",
                        shipper:"hello",
                        bookRef:"hello",
                    })
                    })
                .then(response => response.json())
                .then(response => console.log(JSON.stringify(response)))

                //Send SMS
                fetch("https://sendsms-0vxj.onrender.com/sendSMS", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        phone:"0670295684",
                        message:`Hello Tshilidzi, Musa has added you as their transporter to pick booking rf34567`
                    })
                    })
                .then(response => response.json())
                .then(response => console.log(JSON.stringify(response)))
            }

            const prerequisites = JSON.parse(localStorage.getItem("Prerequisites"))

            const finalPick = JSON.parse(localStorage.getItem("pickSelectd"))[0].details

            const finalDrop = JSON.parse(localStorage.getItem("dropSelectd"))[0].details

            const cargoDetails = JSON.parse(localStorage.getItem("cargoSelectd"))
            const vehicleType = JSON.parse(localStorage.getItem("vehicleType"))
            cargoDetails.details.vehicle_type = vehicleType[0]

            console.log(cargoDetails)

            setIsLoading(true);

            const startDateString = startDate?.toISOString().substring(0,10)
            const endDateString = endDate?.toISOString().substring(0,10)
            const cargo = cargoDetails?.details
            const cargoSds = cargoDetails ? cargoDetails.sds_url : null

            try {
                const data = await getBookingIFnc();
                const booking_id = data?.key;
                await firebase.database().ref('/booking/' + booking_id).update({
                    puDetails:{
                        puCityName: finalPick?.city,
                        puCompanyName: finalPick.CompanyName ? finalPick?.CompanyName : "",
                        puComplexBuilding: finalPick.ComplexBuilding  !== undefined ? finalPick?.ComplexBuilding : "",
                        puEmail: finalPick.Email ? finalPick.Email :"",
                        puGateInGateOut: finalPick.GateInGateOut !== undefined ? finalPick?.GateInGateOut : "",
                        puLoadingBays: finalPick.LoadingBays !== undefined ? finalPick?.LoadingBays: "",
                        puName: finalPick.Name ? finalPick?.Name : "",
                        puOperatingHours: finalPick.OperatingHours !== undefined ? finalPick?.OperatingHours : "",
                        puPhone: finalPick.Phone ? finalPick?.Phone :"",
                        puPublicHoliday: finalPick.PublicHoliday !== undefined ? finalPick?.PublicHoliday : "",
                        puSpecialInstructions: finalPick.SpecialInstructions !== undefined ? finalPick?.SpecialInstructions : "",
                        puSurname: finalPick.Surname ? finalPick?.Surname :"",
                        puTelephone: finalPick.Telephone ? finalPick?.Telephone : "",
                        puAddress: finalPick.Address ? finalPick?.Address:"",
                    },
                    doDetails:{
                        doCityName: finalDrop?.city,
                        doCompanyName: finalDrop.CompanyName ? finalDrop?.CompanyName :"",
                        doComplexBuilding: finalDrop.ComplexBuilding ? finalDrop?.ComplexBuilding : "",
                        doEmail: finalPick.Email ? finalPick.Email : "",
                        doGateInGateOut: finalDrop.GateInGateOut !== undefined ? finalDrop?.GateInGateOut : "",
                        doLoadingBays: finalDrop.LoadingBays !== undefined ? finalDrop?.LoadingBays: "",
                        doName: finalDrop.Name ? finalDrop?.Name :"",
                        doOperatingHours: finalDrop.OperatingHours !== undefined ? finalDrop?.OperatingHours: "",
                        doPhone: finalDrop.Phone ? finalDrop?.Phone :"",
                        doPublicHoliday: finalDrop.PublicHoliday !== undefined ? finalDrop?.PublicHoliday : "",
                        doSpecialInstructions: finalDrop.SpecialInstructions !== undefined ? finalDrop?.SpecialInstructions : "",
                        doSurname: finalDrop?.Surname ? finalDrop?.Surname :"",
                        doTelephone: finalDrop.Telephone ? finalDrop?.Telephone : "",
                        doAddress: finalDrop.Address ? finalDrop.Address :"",
                    },
                    prerequisites:prerequisites[0].prerequisites ? prerequisites[0]?.prerequisites :"",
                    dates_time_selection: {
                        start_date_string: startDateString ? startDateString:"",
                        end_date_string: endDateString ? endDateString:"",
                        operation_days: operationDaysNew ? operationDaysNew :"",
                        include_holidays: includeHolidays ? includeHolidays:"",
                        collection_bays:collectionBays,
                        drop_off_bays: dropOffBays
                    },
                    rate_required: parseFloat(rateIndication) ? parseFloat(rateIndication) :"",
                    booking_party_uid: userUid ? userUid:"",
                    date_created: (new Date()),

                    day_created: `${
                        (new Date()).getDay() === 1 && days[0] ||
                        (new Date()).getDay() === 2 && days[1] ||
                        (new Date()).getDay() === 3 && days[2] ||
                        (new Date()).getDay() === 4 && days[3] ||
                        (new Date()).getDay() === 5 && days[4] ||
                        (new Date()).getDay() === 6 && days[5] ||
                        (new Date()).getDay() === 7 && days[6]
                    }`,
                    gate_in_gate_out_duration: gateInOutDuration,
                    "cargoInformation":
                        {...cargo,
                            volume:
                                (cargo.lengthValue *
                                cargo.breadth *
                                cargo.height)/1000000
                            ,sdsUrl: cargoSds === undefined ? "" : cargoSds,
                        },
                    booking_ref: firstRef,
                    booking_id: booking_id ? booking_id :"",
                    actual_loads_for_cargo: parseInt(((parseFloat((cargo?.weight)*cargo?.quantity).toFixed(2))/34000).toFixed(2)) === 0 ? 1 : parseInt(((parseFloat((cargo?.weight)*cargo?.quantity).toFixed(2))/34000).toFixed(2)),
                    undelivered_loads: parseInt(((parseFloat((cargo?.weight)*cargo?.quantity).toFixed(2))/34000).toFixed(2)) === 0 ? 1 : parseInt(((parseFloat((cargo?.weight)*cargo?.quantity).toFixed(2))/34000).toFixed(2)),

                });

                setTimeout(() => {
                    setIsLoading(false);
                    setBookingSuccess(true);
                    setPopUpQuote(false);
                    setIsBookClicked(false);
                    setIsEnterprise(false);
                },2000)

            } catch (error) {
                console.log("There was an error saving your booking", error);
                setIsLoading(false);
            }
        }
    };

    const saveTheBookingRIQ = async (rateIndication) => {
        if(
            localStorage.getItem("Prerequisites") &&
            localStorage.getItem("pickSelectd") &&
            localStorage.getItem("dropSelectd") &&
            localStorage.getItem("cargoSelectd")
        ){
            const prerequisites = JSON.parse(localStorage.getItem("Prerequisites"))

            const finalPick = JSON.parse(localStorage.getItem("pickSelectd"))[0].details

            const finalDrop = JSON.parse(localStorage.getItem("dropSelectd"))[0].details

            const cargoDetails = JSON.parse(localStorage.getItem("cargoSelectd"))
            const vehicleType = JSON.parse(localStorage.getItem("vehicleType"))
            cargoDetails.details.vehicle_type = vehicleType[0]

            console.log(cargoDetails)

            setIsLoading(true);

            const startDateString = startDate?.toISOString().substring(0,10)
            const endDateString = endDate?.toISOString().substring(0,10)
            const cargo = cargoDetails?.details
            const cargoSds = cargoDetails ? cargoDetails.sds_url : null

            try {
                const data = await getBookingIFnc();
                const booking_id = data?.key;
                await firebase.database().ref('/booking/' + booking_id).update({
                    puDetails:{
                        puCityName: finalPick?.city,
                        puCompanyName: finalPick.CompanyName ? finalPick?.CompanyName : "",
                        puComplexBuilding: finalPick.ComplexBuilding  !== undefined ? finalPick?.ComplexBuilding : "",
                        puEmail: finalPick.Email ? finalPick.Email :"",
                        puGateInGateOut: finalPick.GateInGateOut !== undefined ? finalPick?.GateInGateOut : "",
                        puLoadingBays: finalPick.LoadingBays !== undefined ? finalPick?.LoadingBays: "",
                        puName: finalPick.Name ? finalPick?.Name : "",
                        puOperatingHours: finalPick.OperatingHours !== undefined ? finalPick?.OperatingHours : "",
                        puPhone: finalPick.Phone ? finalPick?.Phone :"",
                        puPublicHoliday: finalPick.PublicHoliday !== undefined ? finalPick?.PublicHoliday : "",
                        puSpecialInstructions: finalPick.SpecialInstructions !== undefined ? finalPick?.SpecialInstructions : "",
                        puSurname: finalPick.Surname ? finalPick?.Surname :"",
                        puTelephone: finalPick.Telephone ? finalPick?.Telephone : "",
                        puAddress: finalPick.Address ? finalPick?.Address:"",
                    },
                    doDetails:{
                        doCityName: finalDrop?.city,
                        doCompanyName: finalDrop.CompanyName ? finalDrop?.CompanyName :"",
                        doComplexBuilding: finalDrop.ComplexBuilding ? finalDrop?.ComplexBuilding : "",
                        doEmail: finalPick.Email ? finalPick.Email : "",
                        doGateInGateOut: finalDrop.GateInGateOut !== undefined ? finalDrop?.GateInGateOut : "",
                        doLoadingBays: finalDrop.LoadingBays !== undefined ? finalDrop?.LoadingBays: "",
                        doName: finalDrop.Name ? finalDrop?.Name :"",
                        doOperatingHours: finalDrop.OperatingHours !== undefined ? finalDrop?.OperatingHours: "",
                        doPhone: finalDrop.Phone ? finalDrop?.Phone :"",
                        doPublicHoliday: finalDrop.PublicHoliday !== undefined ? finalDrop?.PublicHoliday : "",
                        doSpecialInstructions: finalDrop.SpecialInstructions !== undefined ? finalDrop?.SpecialInstructions : "",
                        doSurname: finalDrop?.Surname ? finalDrop?.Surname :"",
                        doTelephone: finalDrop.Telephone ? finalDrop?.Telephone : "",
                        doAddress: finalDrop.Address ? finalDrop.Address :"",
                    },
                    prerequisites:prerequisites[0].prerequisites ? prerequisites[0]?.prerequisites :"",
                    dates_time_selection: {
                        start_date_string: startDateString ? startDateString:"",
                        end_date_string: endDateString ? endDateString:"",
                        operation_days: operationDaysNew ? operationDaysNew :"",
                        include_holidays: includeHolidays ? includeHolidays:"",
                        collection_bays:collectionBays,
                        drop_off_bays: dropOffBays
                    },
                    rate_required: parseFloat(rateIndication) ? parseFloat(rateIndication) :"",
                    booking_party_uid: userUid ? userUid:"",
                    date_created: (new Date()).toISOString().substring(0,10),
                    day_created: `${
                        (new Date()).getDay() === 1 && days[0] ||
                        (new Date()).getDay() === 2 && days[1] ||
                        (new Date()).getDay() === 3 && days[2] ||
                        (new Date()).getDay() === 4 && days[3] ||
                        (new Date()).getDay() === 5 && days[4] ||
                        (new Date()).getDay() === 6 && days[5] ||
                        (new Date()).getDay() === 7 && days[6]
                    }`,
                    gate_in_gate_out_duration: gateInOutDuration,
                    "cargoInformation":
                        {...cargo,
                            volume:
                                (cargo.lengthValue *
                                cargo.breadth *
                                cargo.height)/1000000
                            ,sdsUrl: cargoSds === undefined ? "" : cargoSds,
                        },
                    booking_ref: booking_id.substring(1,7) ? booking_id.substring(1,7):"",
                    booking_id: booking_id ? booking_id :"",
                    loads_per_day: parseInt(((parseFloat((cargo?.weight)*cargo?.quantity).toFixed(2))/34000).toFixed(2)) === 0 ? 1 : parseInt(((parseFloat((cargo?.weight)*cargo?.quantity).toFixed(2))/34000).toFixed(2)),
                    actual_loads_for_cargo: parseInt(((parseFloat((cargo?.weight)*cargo?.quantity).toFixed(2))/34000).toFixed(2)) === 0 ? 1 : parseInt(((parseFloat((cargo?.weight)*cargo?.quantity).toFixed(2))/34000).toFixed(2))
                });

                setTimeout(() => {
                    setIsLoading(false);
                    setIsBookClicked(false);
                    setIsEnterprise(false);
                },2000)

            } catch (error) {
                console.log("There was an error saving your booking", error);
                setIsLoading(false);
            }
        }
    };

  return {
    saveTheBooking,
  };
};
