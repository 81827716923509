import React, {useState, useEffect} from 'react'
import LeftNavigation from './LeftNavigation'
import construction from '../icons/construction.png'
import '../css/fleet.css'
import {FaListUl} from 'react-icons/fa'
import { FaChartPie } from 'react-icons/fa'
import { ImMap } from 'react-icons/im'
import { GrBeacon } from 'react-icons/gr'
import { RiSteering2Fill } from 'react-icons/ri'
import { ImUser } from 'react-icons/im'
import { FaTrailer } from 'react-icons/fa'
import { MdAssignmentInd } from 'react-icons/md'
import { useStateContext } from '../context/DashboardStateContext'
import { MdOutlineManageAccounts } from 'react-icons/md'
import NewDeals from './NewDeals'
import AddDrivers from './AddDrivers'
import AddRoutes from './AddRoutes'
import firebase from '../firebase-config';
import {useFleetStateContext} from '../context/FleetDealsContext'
import AddVehicle from './AddVehicle'
import { useNavigate, Link } from 'react-router-dom'
import AddTrailer from './AddTrailer'
import Assign from './Assign'
import TrackingFleet from "../components/TrackingFleet"

export default function Myfleet() {
  const [userUid, setUserUid] = useState("");
  const [fleetName, setFleetName] = useState(false);
  const navigation = useNavigate();
  const {
    allDeals,
  } = useFleetStateContext();

  const {
    referalLink,
    setReferalLink,
    newDeals,
    setNewDeals,
    driver,
    setDriver,
    showFleet,
    setShowFleet,
    showRoutes,
    setShowRoutes,
    showNoFleet,
    setShowNoFleet,
    showAddVehicle,
    setShowAddVehicle,
    showAddTrailer,
    setShowAddTrailer,
    showAssign,
    setShowAssign,
    fleetTracking,
    setFleetTracking
} = useStateContext();

useEffect(() => {
  firebase.auth().onAuthStateChanged((user) => {
      if (user) {
          setUserUid(user.uid);
          var uid = user.uid;
          firebase.database().ref('/booking_party/' + uid).on('value', (snapshot) => {
              if(snapshot.exists()){
                  if(snapshot.val().serviceOptions.includes("Fleet Owner")){
                      setShowFleet(true)
                      setShowNoFleet(false)
                  }else{
                      setShowFleet(false)
                      setShowNoFleet(true)
                  }
              }
          });
          firebase.database().ref('/fleets/' + uid).on('value', (snapshot) => {
            setFleetName(snapshot.val()?.fleet_name)
          })
      }
  });

}, [])

  return (
    <div className='fleet-container'>
        {!showNoFleet &&
          <div className='top-fleet-head'>
            <h1>My Fleet</h1>
            <p>{fleetName ? fleetName.toUpperCase() : ""}</p>
          </div>
        }

        {showFleet && !showNoFleet &&
          <div className='body-fleet scrollbar-hide sm:w-full sm:h-full md:w-full md:h-full h-[85vh]  overflow-auto'>
            <div className='deals-container'>
              <div className='new-deals'>
                  <div  onClick={() => {
                    setNewDeals(true);
                    setShowFleet(false);
                    setDriver(false);
                    setShowRoutes(false)
                    setShowAddVehicle(false)
                    setShowAddTrailer(false)
                    setShowAssign(false)
                  }}>
                      <div className='pill-top'>
                          <p style={{fontSize:"1.5rem"}}>
                            <FaListUl/>
                          </p>
                          <p style={{color:"#0fce0f"}}>12</p>
                      </div>
                      <p className='below-text'>New Deals</p>
                  </div>
                  <div  style={{pointerEvents: 'none', opacity: .3}}>
                      <div className='pill-top'>
                          <p style={{fontSize:"1.5rem"}}>
                            <FaChartPie/>
                          </p>
                          <p style={{color:"#0fce0f"}}>12 mins ago</p>
                      </div>
                      <p className='below-text'>Statistics</p>
                  </div>
                  <div onClick={() => {
                    setNewDeals(false);
                    setShowFleet(false);
                    setDriver(false);
                    setShowRoutes(true)
                    setShowAddVehicle(false);
                    setShowAddTrailer(false)
                    setShowAssign(false)
                  }}
                    // style={{pointerEvents: 'none', opacity: .3}}
                  >
                      <div className='pill-top'>
                          <p style={{fontSize:"1.5rem"}}>
                            <ImMap/>
                          </p>
                          <p style={{color:"#0fce0f"}}>12</p>
                      </div>
                      <p className='below-text'>Routes</p>
                  </div>
                  <div
                    onClick={() => {
                      setNewDeals(false);
                      setShowFleet(false);
                      setDriver(false);
                      setShowRoutes(false);
                      setShowAddVehicle(false);
                      setShowAddVehicle(false)
                      setShowAddTrailer(false)
                      setShowAssign(false)
                      setFleetTracking(true)
                    }}
                  >
                      <div className='pill-top'>
                          <p style={{fontSize:"1.5rem"}}>
                            <GrBeacon/>
                          </p>
                      </div>
                      <p className='below-text'>Tracking</p>
                  </div>
              </div>
              <div className='new-deals'>
                  <div onClick={() => {
                    // setNewDeals(false);
                    setNewDeals(false);
                    setShowFleet(false);
                    setDriver(true);
                    setShowRoutes(false);
                    setShowAddVehicle(false);
                    setShowAddVehicle(false)
                    setShowAddTrailer(false)
                    setShowAssign(false)
                  }}>
                      <div className='pill-top'>
                          <p style={{fontSize:"1.5rem"}}>
                            <ImUser/>
                          </p>
                          <p style={{color:"#0fce0f"}}>200</p>
                      </div>
                      <p className='below-text'>Drivers</p>
                  </div>
                  <div onClick={() => {
                    setNewDeals(false);
                    setNewDeals(false);
                    setShowFleet(false);
                    setDriver(false);
                    setShowRoutes(false)
                    setShowAddVehicle(true)
                    setShowAddTrailer(false)
                    setShowAssign(false)
                  }}>
                      <div className='pill-top'>
                          <p style={{fontSize:"1.5rem"}}>
                            <RiSteering2Fill/>
                          </p>
                          <p style={{color:"#0fce0f"}}>200</p>
                      </div>
                      <p className='below-text'>Vehicles</p>
                  </div>
                  <div onClick={() => {
                    setNewDeals(false);
                    setNewDeals(false);
                    setShowFleet(false);
                    setDriver(false);
                    setShowRoutes(false)
                    setShowAddVehicle(false)
                    setShowAddTrailer(true)
                    setShowAssign(false)
                  }}>
                      <div className='pill-top'>
                          <p style={{fontSize:"1.5rem"}}>
                            <FaTrailer/>
                          </p>
                          <p style={{color:"#0fce0f"}}>7</p>
                      </div>
                      <p className='below-text'>Trailers</p>
                  </div>
                  <div onClick={() => {
                    setNewDeals(false);
                    setNewDeals(false);
                    setShowFleet(false);
                    setDriver(false);
                    setShowRoutes(false)
                    setShowAddVehicle(false)
                    setShowAddTrailer(false)
                    setShowAssign(true)
                  }}>
                      <div className='pill-top'>
                          <p style={{fontSize:"1.5rem"}}>
                            <MdAssignmentInd/>
                          </p>
                      </div>
                      <p className='below-text'>Assign</p>
                  </div>
              </div>
            </div>
          </div>
        }
        {newDeals &&
          <NewDeals/>
        }
        {driver &&
          <AddDrivers/>
        }
        {showRoutes &&
          <AddRoutes/>
        }
        {showAddVehicle &&
          <AddVehicle/>
        }
        {showAddTrailer &&
          <AddTrailer/>
        }
        {showAssign &&
          <Assign/>
        }
        {fleetTracking &&
          <TrackingFleet/>
        }
        {showNoFleet &&
          <div className='p-20 flex flex-col justify-center items-center w-full text-center' style={{height:"70vh"}}>
            <p className='mb-4' style={{fontSize:"5rem"}}><MdOutlineManageAccounts/></p>
            <p className='text-center font-bold text-xl'>You do not have an account as a fleet owner yet would you like to create one?</p>
            <Link to="/login" className="mt-3" style={{color:"#393301"}}>Create a Fleet Owner Account</Link>
          </div>
        }
    </div>
  )
}
