import React, { useEffect } from 'react'

const BookingSuccess = ({
    setCargoLink,
    setIsEnterprise,
    setIsTracking,
    setIsShowSchedule,
    setIsShowRequest,
    setFleetLink,
    setReferalLink,
    setProfileLink,
    setAnalysisLink,
    setWalletLink,
    setGetHelpLink,
    setContactLink,
    setIsQuote,
    setPopUpQuote,
    setBookingSuccess
}) => {

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setCargoLink(false)
            setIsEnterprise(false)
            setIsTracking(false)
            setIsShowSchedule(false)
            setIsShowRequest(true)
            setFleetLink(false)
            setReferalLink(false)
            setProfileLink(false)
            setAnalysisLink(false)
            setWalletLink(false)
            setGetHelpLink(false)
            setContactLink(false)
            setIsQuote(false)
            setPopUpQuote(false)
            setBookingSuccess(false)
        }, 2000);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [])

  return (
    <div className='w-full beforeEffect flex-col h-full absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center' style={{zIndex:"1"}}>
        {/* <div className='w-[600px] flex justify-end font-bold text-[1.6rem] text-[white] cursor-pointer' onClick={() => setBookingSuccess(false)}>X</div> */}
        <div className='w-[600px] relative flex-col h-[230px] bg-[#E8E8E8] flex items-center justify-center rounded-xl'>
            <h1 className='font-bold text-[1.1rem]'>Success! Your rate indication has been submitted.</h1>
            <p className='mt-2 text-[.8rem]'>Transporters will now try to match your rate or send you a counter-offer. </p>
            {/* <div
                className='mt-4 w-[250px] h-[30px] rounded-xl flex justify-center items-center cursor-pointer bg-[#f9dd07]'
                onClick={() => {
                    setCargoLink(true)
                    setIsEnterprise(false)
                    setIsTracking(false)
                    setIsShowSchedule(false)
                    setIsShowRequest(false)
                    setFleetLink(false)
                    setReferalLink(false)
                    setProfileLink(false)
                    setAnalysisLink(false)
                    setWalletLink(false)
                    setGetHelpLink(false)
                    setContactLink(false)
                    setIsQuote(false)
                    setPopUpQuote(false)
                    setBookingSuccess(false)
                }}
            >
                Return to Dashboard
            </div> */}
        </div>
    </div>
  )
}

export default BookingSuccess
