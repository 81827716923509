import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useStateContext } from '../context/DashboardStateContext'
import { FaChevronLeft } from "react-icons/fa6";
import { IoSearch } from "react-icons/io5";
import { CiFilter } from "react-icons/ci";
import firebase from '../firebase-config';
import { FaArrowRight } from "react-icons/fa6";
import emptyIcon from '../icons/box.png';
import { FaPrint } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Directly use the external URL for the icon
// const icon = new L.Icon({
//     iconUrl: 'https://static.vecteezy.com/system/resources/thumbnails/000/573/134/small/vector60-6783-01.jpg',
//     iconSize: [50, 50], // Adjust the size as needed
// });



const Map = ({
    allDriverData,
    markerRefs,
    selectedDriverUid,
    selectedLoad,
    vehicles,
    trailer,
    markerInformation
}) => {

    const [mapCenter, setMapCenter] = useState([-26.042237144295434, 28.17599103658138]);
    const [mapZoom, setMapZoom] = useState(10); // Initial zoom level
    const mapRef = useRef();

    // Function to create a custom DivIcon
const createCustomIcon = (iconUrl, onDelivery) => {
    return L.divIcon({
      className: '', // Custom class for extra styling
      html: `
        <div
            style="
                width: 30px;
                height: 30px;
                border-radius: 50% 50% 50% 0;
                background: ${onDelivery ? "green" : "#FFE200"};
                position: absolute;
                transform: rotate(-45deg);
                left: 50%;
                top: 50%;
                margin: -20px 0 0 -20px;
                display: flex;
                justify-content: center;
                align-items: center;
            "
            class="transition-all duration-300"
        >
            <div
                style="
                    width: 18px;
                    height: 18px;
                    border-radius: 100%;
                    background: #fff
                "
                class="transition-all duration-300"
            >
                <img
                    style="
                        width: 100%;
                        height: 100%;
                        border-radius: 100%;
                        object-fit: cover;
                    "
                    src=${iconUrl}
                />
            </div>
        </div>
      `,
      iconSize: [60, 75], // Size of the icon
      iconAnchor: [30, 75], // Ensures the icon is anchored correctly
      popupAnchor: [0, -75], // Adjusts the popup position
    });
};

    // Function to update map center and zoom
    const updateMap = (center, zoom) => {
        const map = mapRef.current;
            if (map != null) {
                map.flyTo(center, zoom, {
                    animate: true,
                    duration: 1.0 // Fly to the new center in 1 second
            });
        }
    };

    // Effect hook to handle when selectedDriverUid changes
    useEffect(() => {
        const selectedDriver = allDriverData.find(driver => driver.uid === selectedDriverUid);
        if (selectedDriver) {
            const selectedDriverPosition = [selectedDriver.driver_location.latitude, selectedDriver.driver_location.longitude];
            setMapCenter(selectedDriverPosition);
            setMapZoom(12); // Set a closer zoom level when a driver is selected
            updateMap(selectedDriverPosition, 12); // Adjust map view
        }
    }, [selectedDriverUid, allDriverData]);

    // Marker data
    const markers = allDriverData.map((driver, index) => ({
        id: index + 1,
        uid: driver.uid,
        position: [driver.driver_location.latitude, driver.driver_location.longitude],
        label: `${driver.name} ${driver.surname}`,
        photo_uri: driver.photo_uri,
        onDelivery: driver.on_delivery
    }));

    useEffect(() => {
        if (selectedDriverUid && markerRefs.current[selectedDriverUid]) {
            markerRefs.current[selectedDriverUid].openPopup();
        }
    }, [selectedDriverUid, markerRefs]);

    return (
        <MapContainer
            ref={mapRef}
            zoomControl={false}
            center={mapCenter}
            zoom={mapZoom}
            style={{ height: '100%', width: '100%' }}
        >
            <TileLayer
                url="https://api.mapbox.com/styles/v1/rambuda/clt49pn0c00p101me75k1dsw2/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicmFtYnVkYSIsImEiOiJjbHQ0ZnllZ2UwMnFrMmptbjdicmNyYnByIn0.6R_FcaNoS2hs1PT54olDzA"
                attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
            />
            {markers.map((marker, i) => {
                console.log("the marker", marker)
                return(
                <Marker
                    key={marker.id}
                    position={marker.position}
                    icon={createCustomIcon(marker.photo_uri, marker.onDelivery)}
                    ref={(ref) => {
                        if (ref) {
                            markerRefs.current[marker.uid] = ref;
                        }
                    }}
                >
                    <Popup>
                        <div className='w-fit'>
                            <p className='!m-0 !p-0 font-bold text-[.8rem]'>{marker.label}</p>
                            <p className='!m-0 !p-0 text-[.7rem]'>{trailer?.make}</p>
                            <p className='!m-0 !p-0 text-[.7rem]'>{markerInformation[i]?.horseMake}: {markerInformation[i]?.trailerMake}</p>
                            <p className='!m-0 !p-0 text-[.7rem]'>43min</p>
                            <p className={`!m-0 !p-0 text-[.7rem] font-bold w-fit ${marker.onDelivery ? "text-[green]" : "bg-[yellow]"}`}>{marker.onDelivery ? "In transit" : "Pending"}</p>
                            {/* <p className='!m-0 !p-0 text-[.7rem]'>{vehicleData.make }</p> */}
                        </div>
                    </Popup>
                </Marker>)
})}
        </MapContainer>
    );
};

export default function Assign() {
    const [searchQuery, setSearchQuery] = useState("");
    const [allBookingarr, setAllBookingarr] = useState([])
    const [allDrivers, setAllDrivers] = useState([]);
    const [active,setActive] = useState([]);
    const [selectedLoad,setSelectedLoad] = useState([]);
    const [driverData, setDriverData] = useState([])
    const [trailerData,setTrailerData] = useState([]);
    const [vehicleData, setVehicleData] = useState([]);
    const [fleetData,setFleetData] = useState([]);
    const [actualBook, setActualBook] = useState([]);
    const [allDriverData,setAllDriverData] = useState([])
    const [uniqueDriverData, setUniqueDriverData] = useState([]);
    const [selectedDriverUid, setSelectedDriverUid] = useState(null);
    const [markerInformation, setMarkerInformation] = useState([]);
    const markerRefs = useRef({});

    const {
        isEnterprise,
        setIsEnterprise,
        isTracking,
        setIsTracking,
        isShowSchedule,
        setIsShowSchedule,
        setIsShowRequest,
        isShowRequest,
        setCargoLink,
        isQuote,
        setPopUpQuote,
        popUpQuote,
        cargoLink,
        fleetLink,
        setFleetLink,
        referalLink,
        setReferalLink,
        profileLink,
        setProfileLink,
        analysisLink,
        setAnalysisLink,
        walletLink,
        setWalletLink,
        getHelpLink,
        setGetHelpLink,
        contactLink,
        setContactLink,
        setIsQuote,
        endDate,
        setEndDate,
        startDate,
        setStartDate,
        operationDaysNew,
        setOperationDaysNew,
        includeHolidays,
        setIncludeHolidays,
        setCollectionBays,
        setDropOffBays,
        gateInOutDuration,
        setGateInOutDuration,
        setIsUseOwnTransport,
        transporterContact,
        setTransporterContact,
        setShowTrackFilter,
        setFleetTracking,
        setShowFleet
    } = useStateContext();

    useEffect(() => {
        // Reset markerInformation to an empty array or initial state before fetching new data
        setMarkerInformation([]);

        const vehicleDataPromises = allDriverData.map(item => {
            return new Promise((resolve, reject) => {
                const horse = item.horse_id;
                const trailerId = item.trailer_id || null;
                firebase.database().ref(`fleets/${item.fleet}`).on("value", snapshot => {
                    const data = {
                        trailerMake: snapshot.val()?.trailers[trailerId]?.make,
                        horseMake: snapshot.val()?.horses[horse]?.make,
                    };
                    resolve(data);
                }, reject);
            });
            });
            Promise.all(vehicleDataPromises)
                .then(vehicleDataArray => {
                    // Now, this will set markerInformation with only the current fetched data
                    setMarkerInformation(vehicleDataArray);
                })
                .catch(error => console.error("Failed to fetch vehicle data:", error));
        }, [allDriverData]);

    useEffect(() => {
        const userUid = localStorage.getItem("userUid");

        if (userUid) {
            const Uid = JSON.parse(userUid);
            const bookingsRef = firebase.database().ref(`/fleets/${Uid}/bookings`);

            bookingsRef.on("value", snapshot => {
                const bookings = snapshot.val() ? Object.values(snapshot.val()) : [];
                let bookingsProcessed = 0;
                let tempBookingArray = [];
                let processedDriverUids = new Set(); // Track processed driver UIDs to avoid duplicates

                bookings.forEach((item) => {
                    const uid = item.booking_id;

                    firebase.database().ref(`/booking/${uid}`).on("value", bookingSnapshot => {
                        const bookingData = bookingSnapshot.val();

                        if (bookingData) {
                            tempBookingArray.push(bookingData);

                            bookingData.drivers?.forEach(driverId => {
                                // Check if driverId has already been processed
                                if (!processedDriverUids.has(driverId)) {
                                    processedDriverUids.add(driverId); // Mark driverId as processed

                                    firebase.database().ref(`drivers/${driverId}`).on("value", driverSnapshot => {
                                        const driverData = driverSnapshot.val();
                                        if (driverData) {
                                            setAllDriverData((prev) => {
                                                // Prevent potential race condition duplicates
                                                if (prev.find(d => d.uid === driverData.uid)) {
                                                    return prev;
                                                }
                                                return [...prev, driverData];
                                            });
                                        }
                                    });
                                }
                            });
                        }
                        bookingsProcessed++;
                        if (bookingsProcessed === bookings.length) {
                            setAllBookingarr(tempBookingArray);
                        }
                    });
                });
            });
        }
    }, []);

    const formatDuration = (totalDuration) => {
        let hours = Math.floor(totalDuration); // Gets the whole hour part
        let minutes = Math.round((totalDuration - hours) * 60); // Converts the decimal part into minutes

        // Adjust for rounding up to 60 minutes
        if (minutes === 60) {
            hours += 1;
            minutes = 0;
        }

        // Formats the string; e.g., "2hr 46min"
        return `${hours}hr ${minutes}min`;
    };

    return (
        <div className={`px-12`}>
            <div
                style={{display:"flex", alignItems:"center"}}
                className='flex items-center py-4'
            >
                    <p className='m-0 p-0 text-[.8rem]'><FaChevronLeft /></p>&nbsp;
                    <p
                        style={{cursor: "pointer"}}
                        onClick={() => {
                            setIsShowSchedule(false);
                            setIsTracking(false);
                            setIsEnterprise(false);
                            setFleetLink(true);
                            setShowFleet(true);
                            setCargoLink(false);
                            setIsShowRequest(false);
                            setFleetTracking(false);
                        }}
                        className='m-0 p-0 text-[.9rem] text-[#878787]'
                    >My Fleet &nbsp;</p>
                    <p className='m-0 p-0 text-[#878787]'>/ &nbsp;</p>
                    <p className='m-0 p-0 text-[.9rem] font-bold'>Tracking</p>
            </div>
            <div
                style={{display:"flex", alignItems:"center"}}
                className='flex items-center py-2'
            >
                <p className='font-bold text-[.9rem]'>Map View - Drivers</p>
            </div>
            <div
                style={{display:"flex", alignItems:"center"}}
                className='flex items-center justify-center w-full h-[300px] rounded-xl overflow-hidden border border-black'
            >
                <Map
                    allDriverData={allDriverData}
                    markerRefs={markerRefs}
                    selectedDriverUid={selectedDriverUid}
                    vehicleData={vehicleData}
                    selectedLoad={selectedLoad}
                    trailerData={trailerData}
                    markerInformation={markerInformation}
                />
            </div>

            <div className='w-full flex flex-row justify-between mt-5'>
                <div className='w-[32%] bg-[#F2F2F2] rounded-xl h-fit'>
                    <h2 className='font-bold m-0 p-0 text-[1rem]'>Deals</h2>
                    <p className='text-[.7rem] mt-1'>Search or filter to see how far your shipment is</p>
                    <div className='flex justify-between items-center'>
                        <div className='w-[87%] rounded-2xl flex justify-between items-center bg-[#E4E4E4] p-[.1rem] px-[.16rem]'>
                            <input
                                className='w-[85%] px-4 bg-[#E4E4E4] text-[.8rem] rounded-l-2xl rounded-r-2xl'
                                type="text"
                                placeholder='Search...'
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <div className='w-[1.7rem] flex bg-[#fce200] h-[1.7rem] cursor-pointer justify-center rounded-full items-center'>
                                <p><IoSearch/></p>
                            </div>
                        </div>
                        <div className='w-[13%] h-full flex justify-center items-center'>
                            <p
                                className='text-black p-1 m-0 rounded-full h-[1.8rem] w-[1.8rem] cursor-pointer bg-[#fce200] text-[.8rem] flex justify-center items-center'
                                onClick={() => setShowTrackFilter(true)}
                            >
                                <CiFilter/>
                            </p>
                        </div>
                    </div>

                    <div className='w-full mt-2 flex-grow overflow-scroll scrollbar-hide h-[250px]'>
                        {allBookingarr.length > 0 ? allBookingarr.map((book, i) => (
                            <>
                                <div
                                    className={`mt-2 cursor-pointer leading-4 ${active[0] == book ? "w-[100%] bg-[#D3D3D3]" : "w-[96%] bg-[#fff]"} m-auto transition-all duration-300 rounded-2xl p-3 relative mb-2`}
                                    onClick={() => {
                                        if (active[0] === book) {
                                            setActive([]);
                                            setAllDrivers([])
                                        } else {
                                            setActive([book]);
                                            setAllDrivers(book.drivers)
                                        }
                                    }}
                                >
                                    <div className='flex justify-between items-center'>
                                        <p className='m-0 p-0 font-bold'>{book?.booking_ref}</p>
                                        <p className='m-0 p-0'>{book?.drivers.length}/{book?.actual_loads_for_cargo} Loads</p>
                                    </div>
                                    <div className='flex justify-between items-center'>
                                        <p className='m-0 p-0 text-[.59rem] text-[#878787]'>{book?.cargoInformation?.vehicle_type}</p>
                                        <p className='m-0 p-0 text-[.59rem] text-[#878787]'>Est Completion: {`
                                                ${((new Date(book?.dates_time_selection?.end_date_string)).toDateString()).substring(8,10)}
                                                ${((new Date(book?.dates_time_selection?.end_date_string)).toDateString()).substring(4,8)}
                                                ${((new Date(book?.dates_time_selection?.end_date_string)).toDateString()).substring(11,15)}
                                        `}</p>
                                    </div>

                                    <div className='flex justify-between mt-[.2rem] items-center'>
                                        <p className='m-0 p-0 font-bold'>{book?.puDetails?.puCityName}</p>
                                        <p className='m-0 p-0 font-bold'><FaArrowRight /></p>
                                        <p className='m-0 p-0 font-bold'>{book?.doDetails?.doCityName}</p>
                                    </div>

                                    <div className='flex justify-between items-center'>
                                        <p className='m-0 p-0 text-[.59rem] text-[#878787]'>{book?.puDetails?.puCompanyName}</p>
                                        <p className='m-0 p-0 text-[.59rem] text-[#878787]'>{book?.doDetails?.doCompanyName}</p>
                                    </div>
                                </div>
                                {active[0] === book &&
                                    <div className={`w-full flex flex-col items-center m-auto mt-2 ${allDrivers?.length > 4 ? "h-[300px] max-h-[300px]" : "h-fit max-h-fit"} overflow-scroll scrollbar-hide`}>
                                        {allDrivers?.length > 0 ? allDrivers?.map((item, i) => {
                                            const currentDriverData = allDriverData.find(driver => driver.uid === item)
                                            console.log("currentDriver", currentDriverData)
                                            console.log("driver status", currentDriverData)
                                            return(
                                            <div
                                                className='flex flex-row h-fit p-3 w-[92%] mb-[.6rem] bg-[#fff] rounded-2xl cursor-pointer'
                                                onClick={() => {
                                                    setSelectedDriverUid(item);
                                                    setSelectedLoad([book])
                                                    firebase.database().ref(`/drivers/${item}`).on("value", driver => {
                                                        setDriverData(driver.val())
                                                        const horse = driver.val().horse_id
                                                        const trailer = driver.val().trailer_id
                                                        console.log("The trailer data", driver.val())
                                                        firebase.database().ref(`fleets/${driver.val().fleet}`).on("value", fleet => {
                                                            setTrailerData(fleet.val()?.trailers[trailer])
                                                            setVehicleData(fleet.val()?.horses[horse])
                                                            setFleetData(fleet.val())
                                                        })
                                                    })
                                                }}
                                            >
                                                <div className='w-[20%] flex justify-start items-center'>
                                                    <div className='w-[50px] h-[50px] bg-[grey] rounded-full'>
                                                        <img
                                                            src={currentDriverData?.photo_uri}
                                                            alt=""
                                                            className='w-full object-cover h-full rounded-full'
                                                        />
                                                    </div>
                                                </div>
                                                <div className='w-[80%]'>
                                                    <div className='flex justify-between items-center'>
                                                        <div className='flex justify-between items-center'>
                                                            <div><p className='font-bold text-[.67rem]'>{book.booking_ref}-{i+1}</p></div>
                                                            {/* <div>image</div> */}
                                                        </div>
                                                        <div>
                                                            <p className='font-bold text-[.67rem]'>{formatDuration(book.total_duration)}</p>
                                                        </div>
                                                    </div>
                                                    <div className='flex w-full justify-between items-center'>
                                                        <div className='flex justify-between items-center'>
                                                            <div><p className={`text-[.6rem] ${currentDriverData.on_delivery ? "text-[green]" : "yellow"}`}>{currentDriverData.on_delivery ? "In transit" : "Pending"}</p></div>
                                                        </div>
                                                        <div>
                                                            <p className='text-[#878787] text-[.6rem]'>Superlink Tautliner</p>
                                                        </div>
                                                    </div>
                                                    <div className='flex justify-between items-center'>
                                                        <div className='flex justify-between items-center'>
                                                            <div><p className='font-bold text-[.67rem]'>{book.puDetails.puCityName}</p></div>
                                                            {/* <div>image</div> */}
                                                        </div>
                                                        <div>
                                                            <p className='font-bold text-[.67rem]'>{book.doDetails.doCityName}</p>
                                                        </div>
                                                    </div>
                                                    <div className='w-full relative h-[6.5px] rounded-xl bg-[#E6E6E6]'>
                                                        <div className={`${currentDriverData.on_delivery ? "bg-[#059E00]" : "bg-[yellow]"} h-full w-[40%] rounded-xl`}></div>
                                                        <div className='absolute left-[50%] right-0 bottom-0 top-0 h-[7px] w-[7px] rounded-full bg-[#FFFEFE]'></div>
                                                    </div>
                                                </div>
                                            </div>)
                                        })
                                        :<div className='dash-date' style={{
                                            width:"68%",
                                            height:"100%",
                                            display:"flex",
                                            justifyContent:"center",
                                            alignItems:"center",
                                            flexDirection:"column",
                                            top:"30%",
                                            textAlign:"center",
                                            fontSize:"1rem"
                                        }}>
                                            <img src={emptyIcon} width={100} height={100} style={{marginBottom:"3rem"}}/>
                                            Please select booking on the left if there is any, to see it's details.
                                        </div>
                                        }
                                        {/* <div className='h-fit p-3 w-[92%] mb-[.6rem] bg-[#fff] rounded-2xl'>
                                            <div className='flex justify-between items-center'>
                                                <p className='m-0 p-0 font-bold'>ZN0404-01</p>
                                                <p className='m-0 p-0'>3 Feb 15:44</p>
                                            </div>
                                            <div className='flex justify-between items-center'>
                                                <div className='flex items-center'>
                                                    <p className='m-0 p-0 text-[#C91818] mb-[.2rem]'>Delayed</p>
                                                    <img src={delayedIcon} className='ml-2 w-[17px] h-[17px]' alt="" />
                                                </div>
                                                <p className='m-0 p-0 text-[#878787]'>+- 1056KM</p>
                                            </div>
                                            <div className='flex justify-between items-center'>
                                                <p className='m-0 p-0 font-bold'>Parktown</p>
                                                <p className='m-0 p-0 font-bold'>Edenvale</p>
                                            </div>
                                            <div className='w-full mt-2 relative h-[6.5px] rounded-xl bg-[#E6E6E6]'>
                                                <div className='bg-[#C91818] h-full w-[60%] rounded-xl'></div>
                                                <div className='absolute left-[50%] right-0 bottom-0 top-0 h-[7px] w-[7px] rounded-full bg-[#FFFEFE]'></div>
                                            </div>
                                        </div>

                                        <div className='h-fit p-3 w-[92%] mb-[.6rem] bg-[#fff] rounded-2xl'>
                                            <div className='flex justify-between items-center'>
                                                <p className='m-0 p-0 font-bold'>ZN0404-01</p>
                                                <p className='m-0 p-0'>3 Feb 15:44</p>
                                            </div>
                                            <div className='flex justify-between items-center'>
                                                <p className='m-0 p-0 text-[#FF9900] mb-[.2rem]'>Pending</p>
                                                <p className='m-0 p-0 text-[#878787]'>+- 1056KM</p>
                                            </div>
                                            <div className='flex justify-between items-center'>
                                                <p className='m-0 p-0 font-bold'>Parktown</p>
                                                <p className='m-0 p-0 font-bold'>Edenvale</p>
                                            </div>
                                            <div className='w-full mt-2 relative h-[6.5px] rounded-xl bg-[#E6E6E6]'>
                                                <div className='bg-[#FF9900] h-full w-[10%] rounded-xl'></div>
                                                <div className='absolute left-[50%] right-0 bottom-0 top-0 h-[7px] w-[7px] rounded-full bg-[#FFFEFE]'></div>
                                            </div>
                                        </div> */}
                                    </div>
                                }
                            </>
                            )):
                            <div className='' style={{
                                width:"100%",
                                height:"100%",
                                display:"flex",
                                justifyContent:"center",
                                alignItems:"center",
                                flexDirection:"column",
                                top:"30%",
                                textAlign:"center",
                                fontSize:"1rem"
                            }}>
                                <img src={emptyIcon} width={100} height={100} style={{marginBottom:"3rem"}}/>
                                No bookings to track at the moment
                            </div>
                        }
                    </div>
                </div>
                <div className='w-[65%] flex justify-center items-center h-fit'>
                {selectedLoad.length > 0 ? selectedLoad.map((item, i) => (
                    <div className='w-[100%]'>
                    <div className='bg-[white] rounded-xl !w-full !py-2 p-6 mt-2  h-[328px] overflow-scroll scrollbar-hide'>
                       <div className='title-summary-head'>
                            <h1 className='!font-bold'>Summary</h1>
                            {/* <p style={{cursor:"pointer", fontWeight:"bold", fontSize:"20px"}} onClick={() => setOpenDetails((prev) => !prev)}>X</p> */}
                       </div>

                       <div className='w-full relative'>
                           <div className='flex justify-between w-full'>
                               <div className='w-[15%]'>
                                   <h1 className='font-bold m-0 p-0 text-[.8rem]'>Start</h1>
                               </div>

                               <div className='w-[85%]'>
                                   <div className='pl-2 flex justify-between'>
                                       <div>
                                           <p className='m-0 p-0 font-bold'>Nhletelo</p>
                                           <p className='m-0 p-0 font-bold text-[.8rem]'>Land and Sea Shipping</p>
                                           <p className='m-0 p-0 text-[.7rem] text-[grey]'>796 Cascades Road, Little Falls, Roodepoort, 1735</p>
                                       </div>
                                       <div className='flex flex-col justify-end'>
                                           <p className='text-end m-0 p-0'>Jan 13 2024</p>
                                           <p className='text-end m-0 p-0 text-[.7rem] text-[grey]'>14:26 PM</p>
                                       </div>
                                   </div>
                               </div>
                           </div>

                           <div className='flex justify-between w-full mt-6'>
                               <div className='w-[15%]'>
                                   <h1 className='font-bold m-0 p-0 text-[.8rem]'>Load Cargo</h1>
                               </div>

                               <div className='w-[85%]'>
                                   <div className='pl-2 flex justify-between'>
                                       <div>
                                           <p className='m-0 p-0 font-bold'>{item?.puDetails?.puName}</p>
                                           <p className='m-0 p-0 font-bold text-[.8rem]'>{item?.puDetails?.puCompanyName} | {item?.gate_in_gate_out_duration} Estimated Gate In</p>
                                           <p className='m-0 p-0 text-[.7rem] text-[grey]'>{item?.puDetails?.puAddress}</p>
                                       </div>
                                       <div className='flex flex-col justify-end'>
                                           <p className='text-end m-0 p-0'>Jan 13 2024</p>
                                           <p className='text-end m-0 p-0 text-[.7rem] text-[grey]'>14:26 PM</p>
                                       </div>
                                   </div>
                               </div>
                           </div>

                           {item?.way_point_address &&
                               <div className='flex justify-between w-full mt-6'>
                                   <div className='w-[15%]'>
                                       <h1 className='font-bold m-0 p-0 text-[.8rem]'>Staging Area</h1>
                                   </div>

                                   <div className='w-[85%]'>
                                       <div className='pl-2 flex justify-between'>
                                           <div>
                                               <p className='m-0 p-0 font-bold'>Nhletelo</p>
                                               <p className='m-0 p-0 font-bold text-[.8rem]'>Land and Sea Shipping </p>
                                               <p className='m-0 p-0 text-[.7rem] text-[grey]'>796 Cascades Road, Little Falls, Roodepoort, 1735</p>
                                           </div>
                                           <div className='flex flex-col justify-end'>
                                               <p className='text-end m-0 p-0'>Jan 13 2024</p>
                                               <p className='text-end m-0 p-0 text-[.7rem] text-[grey]'>14:26 PM</p>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           }

                           <div className='flex justify-between w-full mt-6'>
                               <div className='w-[15%] '>
                                   <h1 className='font-bold m-0 p-0 text-[.8rem]'>Offload Cargo</h1>
                               </div>

                               <div className='w-[85%]'>
                                   <div className='pl-2 flex justify-between'>
                                       <div>
                                           <p className='m-0 p-0 font-bold'>{item?.doDetails?.doName}</p>
                                           <p className='m-0 p-0 font-bold text-[.8rem]'>{item?.doDetails?.doCompanyName} | {item?.gate_in_gate_out_duration && `${item?.gate_in_gate_out_duration} Estimated Gate Out`}</p>
                                           <p className='m-0 p-0 text-[.7rem] text-[grey]'>{item?.puDetails?.puAddress}</p>
                                       </div>
                                       <div className='flex flex-col justify-end'>
                                           <p className='text-end m-0 p-0'>Jan 13 2024</p>
                                           <p className='text-end m-0 p-0 text-[.7rem] text-[grey]'>14:26 PM</p>
                                       </div>
                                   </div>
                               </div>
                           </div>

                           {/* The tracking indicator */}
                           <div
                               className='absolute left-[5.5rem] right-0 top-0 bottom-0 w-[.2%] h-[88%]'
                               style={{borderLeft:"2px dashed #757575"}}
                           >
                               <div
                                   className='w-[.6rem] h-[.6rem] rounded-full bg-[#059E00] -left-[.35rem] bottom-0 right-0 -top-[.2rem] absolute'
                               >
                                   <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 h-[1rem] w-[1rem] border-[.2rem] border-[#D9D9D9] rounded-full'></div>
                               </div>

                               <div
                                   className='w-[.9rem] h-[.9rem] rounded-full animate-ping bg-[#059E00] -left-[.5rem] bottom-0 right-0 top-[4.55rem] absolute'
                               >
                                   <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 h-[1.20rem] w-[1.20rem] border-[.2rem] border-[#D9D9D9] rounded-full'></div>
                               </div>

                               <div
                                   className='w-[.9rem] h-[.9rem] rounded-full bg-[#059E00] -left-[.5rem] bottom-0 right-0 top-[4.55rem] absolute'
                               >
                                   <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 h-[1.20rem] w-[1.20rem] border-[.2rem] border-[#D9D9D9] rounded-full'></div>
                               </div>

                               {item?.way_point_address &&
                                   <div
                                       className='w-[.6rem] h-[.6rem] rounded-full bg-[#757575] -left-[.35rem] bottom-0 right-0 top-[9rem] absolute'
                                   >
                                       <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 h-[1rem] w-[1rem] border-[.2rem] border-[#D9D9D9] rounded-full'></div>
                                   </div>
                               }

                               <div
                                   className='w-[.9rem] h-[.9rem] rounded-full bg-[#757575] -left-[.5rem] bottom-0 right-0  absolute'
                               >
                                   <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 h-[1.20rem] w-[1.20rem] border-[.2rem] border-[#D9D9D9] rounded-full'></div>
                               </div>
                           </div>
                       </div>

                       <div className='w-full'>
                           <h1 className='font-bold mt-12 mb-2'>Transporter</h1>
                           <div className='flex justify-between border-transparent py-[.30rem] bg-[#f8f8f9] px-4 w-full rounded-tl-[.5rem] rounded-tr-[.5rem]'>
                               <div className='w-[16%] text-[.8rem]'>Name</div>
                               <div className='w-[16%] text-[.8rem]'>Driver</div>
                               <div className='w-[16%] text-[.8rem]'>Make</div>
                               <div className='w-[16%] text-[.8rem]'>Vehicle Type</div>
                               <div className='w-[16%] text-[.8rem]'>Registration</div>
                               <div className='w-[16%] text-[.8rem]'>Trailer</div>
                           </div>

                           <div className='flex justify-between py-[.30rem] border px-4 w-full rounded-bl-xl rounded-br-xl'>
                               <div className='w-[16%] text-[.8rem]'>{fleetData?.companyName}</div>
                               <div className='w-[16%] text-[.8rem]'>{driverData?.name}</div>
                               <div className='w-[16%] text-[.8rem]'>{vehicleData?.make}</div>
                               <div className='w-[16%] text-[.8rem]'>{vehicleData?.vehicle_type}</div>
                               <div className='w-[16%] text-[.8rem]'>{vehicleData?.licence_number}</div>
                               <div className='w-[16%] text-[.8rem]'>{trailerData?.trailer_id}</div>
                           </div>
                       </div>

                       <div className='w-full'>
                           <h1 className='font-bold mt-5 mb-2'>Prerequisites</h1>
                           <div className='flex border-transparent justify-between py-[.30rem] bg-[#f8f8f9] px-4 w-full rounded-tl-[.5rem] rounded-tr-[.5rem]'>
                               <div className='w-[20%] text-[.8rem]'>Documentation</div>
                               <div className='w-[20%] text-[.8rem]'>Ad-hoc Service</div>
                               <div className='w-[20%] text-[.8rem]'>PPE</div>
                               <div className='w-[20%] text-[.8rem]'>Vehicle Equipment</div>
                               <div className='w-[20%] text-[.8rem]'>Insurance</div>
                           </div>
                           <div className='flex justify-between py-[.30rem] border px-4 w-full rounded-bl-xl rounded-br-xl'>
                               <div className='w-[20%] text-[.8rem]'>
                                   {allBookingarr[i]?.prerequisites?.documentation.map(doc => (
                                       <p>{doc}</p>
                                   ))
                                   }
                               </div>
                               <div className='w-[20%] text-[.8rem]'>Solas / Verified Gross Mass</div>
                               <div className='w-[20%] text-[.8rem]'>Safety Hat</div>
                               <div className='w-[20%] text-[.8rem]'>2 Stop Blocks</div>
                               <div className='w-[20%] text-[.8rem]'>R250 000</div>
                           </div>
                       </div>

                       <div className='w-full'>
                           <h1 className='font-bold mt-5 mb-2'>Cargo</h1>
                           <div className='flex border-transparent justify-between py-[.30rem] bg-[#f8f8f9] px-4 w-full rounded-tl-[.5rem] rounded-tr-[.5rem]'>
                               <div className='w-[8%] text-[.8rem]'>#</div>
                               <div className='w-[18%] text-[.8rem]'>Product</div>
                               <div className='w-[13%] text-[.8rem]'>Packaging</div>
                               <div className='w-[13%] text-[.8rem]'>Quantity</div>
                               <div className='w-[13%] text-[.8rem]'>Fragile</div>
                               <div className='w-[13%] text-[.8rem]'>Dimensions</div>
                               <div className='w-[13%] text-[.8rem]'>Weight</div>
                               <div className='w-[13%] text-[.8rem]'>CBM</div>
                           </div>
                           <div className='flex justify-between py-[.30rem] border px-4 w-full rounded-bl-xl rounded-br-xl'>
                               <div className='w-[8%] text-[.8rem]'>{i+1}</div>
                               <div className='w-[18%] text-[.8rem]'>{allBookingarr[i].cargoInformation.productName}</div>
                               <div className='w-[13%] text-[.8rem]'>{allBookingarr[i].cargoInformation.packageType}</div>
                               <div className='w-[13%] text-[.8rem]'>{allBookingarr[i].cargoInformation.quantity}</div>
                               <div className='w-[13%] text-[.8rem]'>{allBookingarr[i].cargoInformation.hazard.hazard_rating ? "Yes" : "No"}</div>
                               <div className='w-[13%] text-[.8rem]'>{allBookingarr[i].cargoInformation.breadth}CM x {allBookingarr[i].cargoInformation.height}CM x {allBookingarr[i].cargoInformation.lengthValue}CM</div>
                               <div className='w-[13%] text-[.8rem]'>10:56 MT</div>
                               <div className='w-[13%] text-[.8rem]'>{allBookingarr[i].cargoInformation.weight} CBM</div>
                           </div>
                       </div>

                       {allBookingarr[i].cargoInformation.hazard.hazard_rating &&
                           <div className='w-full'>
                               <h1 className='font-bold mt-5 mb-2'>Hazardous Cargo</h1>
                               <div className='flex border-transparent justify-start py-[.30rem] bg-[#f8f8f9] px-4 w-full rounded-tl-[.5rem] rounded-tr-[.5rem]'>
                                   <div className='w-[20%] text-[.8rem]'>UN Number</div>
                                   {/* <div className='w-[20%] text-[.8rem]'>Class</div> */}
                                   <div className='w-[20%] text-[.8rem]'>IMO</div>
                               </div>
                               <div className='flex justify-start py-[.30rem] border px-4 w-full rounded-bl-xl rounded-br-xl'>
                                   <div className='w-[20%] text-[.8rem]'>{allBookingarr[i].cargoInformation.unNumber}</div>
                                   {/* <div className='w-[20%] text-[.8rem]'>4.1</div> */}
                                   <div className='w-[20%] text-[.8rem]'>{allBookingarr[i].cargoInformation.imoClass}</div>
                               </div>
                           </div>
                       }

                       {allBookingarr[i].cargoInformation.hazard.hazard_rating &&
                           <div className='w-full'>
                               <h1 className='font-bold mt-5 mb-2'>Temperature</h1>
                               <div className='flex border-transparent justify-strt py-[.30rem] bg-[#f8f8f9] px-4 w-full rounded-tl-[.5rem] rounded-tr-[.5rem]'>
                                   <div className='w-[16%] text-[.8rem]'>Min</div>
                                   <div className='w-[16%] text-[.8rem]'>Max</div>
                               </div>
                               <div className='flex justify-start py-[.30rem] border px-4 w-full rounded-bl-xl rounded-br-xl'>
                                   <div className='w-[16%] text-[.8rem]'>{allBookingarr[i].cargoInformation.hazard.min_temp}</div>
                                   <div className='w-[16%] text-[.8rem]'>{allBookingarr[i].cargoInformation.hazard.max_temp}</div>
                               </div>
                           </div>
                       }

                       {/* <div className='flex justify-between w-full mt-6'>
                           <div className='w-[17%]'>
                               <h1 className='font-bold m-0 p-0 text-[.8rem]'>Product</h1>
                           </div>

                           <div className='w-[83%]'>
                               <div>
                                   <p className='font-bold m-0 p-0 text-[.8rem]'>Product Name</p>
                               </div>
                               <div className='w-full border flex flex-row p-2 mt-2'>
                                   <div className='w-[50%] flex'>
                                       <div className='w-[40%]'>
                                           <p>Quantity</p>
                                           <p>Length</p>
                                           <p>Breadth</p>
                                           <p>Height</p>
                                           <p>Package Type</p>
                                           <p>Fragile</p>
                                           <p>Hazardous</p>
                                       </div>
                                       <div className='w-[20%]'>
                                           <p>: {item?.cargoInformation?.quantity}</p>
                                           <p>: {item?.cargoInformation?.lengthValue}cm</p>
                                           <p>: {item?.cargoInformation?.breadth}cm</p>
                                           <p>: {item?.cargoInformation?.height}cm</p>
                                           <p>: {item?.cargoInformation?.packageType}</p>
                                           <p>: {item?.cargoInformation?.fragile === "true" ? "Yes" : "No"}</p>
                                           <p>: {item?.cargoInformation?.hazard?.hazard_rating === "Hazardous" ? "Yes" : "No"}</p>
                                       </div>
                                   </div>
                                   <div className='w-[50%] flex justify-end'>
                                       <div className='w-[30%] flex flex-col items-end'>
                                           <p>{item?.cargoInformation?.productCode} :</p>
                                           <p>{item?.cargoInformation?.volume}m&#179; :</p>
                                           <p>{((item?.cargoInformation?.volume) * (item?.cargoInformation?.quantity)).toFixed(2)}m&#179; :</p>
                                           <p>{(item?.cargoInformation?.weight) * (item?.cargoInformation?.quantity)}mt :</p>
                                           <p>{item?.cargoInformation?.weight}mt :</p>
                                           <p>{item?.cargoInformation?.imoClass} :</p>
                                           <p>{item?.cargoInformation?.unNumber} :</p>
                                       </div>

                                       <div className='w-[40%] flex flex-col items-end'>
                                           <p>SKU</p>
                                           <p>Net Volume</p>
                                           <p>Total Volume</p>
                                           <p>Net Weight</p>
                                           <p>Total Weight</p>
                                           <p>IMO</p>
                                           <p>UN Number</p>
                                       </div>
                                   </div>
                               </div>

                               <div className='w-full border flex flex-col p-2 mt-2'>
                                   <div>
                                       <p className='font-bold m-0 p-0 text-[.8rem]'>Temperature-controlled</p>
                                   </div>
                                   <div className='w-[50%] flex'>
                                       <div className='w-[40%]'>
                                           <p>Min</p>
                                           <p>Max</p>
                                       </div>
                                       <div className='w-[20%]'>
                                           <p>: {item?.cargoInformation?.hazard?.min_temp}­°C</p>
                                           <p>: {item?.cargoInformation?.hazard?.max_temp}°C</p>
                                       </div>
                                   </div>
                               </div>

                               <div className='w-full border flex flex-col p-2 mt-2'>
                                   <div>
                                       <p className='font-bold m-0 p-0 text-[.8rem]'>Insurance</p>
                                   </div>
                                   <div className='w-[50%] flex flex-col'>
                                       <p>Goods in Transit</p>
                                       <p>{item?.prerequisites?.goods_in_transit}</p>
                                   </div>
                               </div>
                           </div>
                       </div> */}

                       {/* <div className='flex justify-between w-full mt-6'>
                           <div className='w-[17%]'>
                               <h1 className='font-bold m-0 p-0 text-[.8rem]'>Prerequisites</h1>
                           </div>

                           <div className='flex flex-row flex-wrap w-[83%] justify-between'>
                               {item?.prerequisites && (Object.keys(item?.prerequisites)).filter((item) => (
                                   item !== "vehicleDimensions" &&
                                   item !== "goods_in_transit" &&
                                   item !== "vehicle_type"
                               )).map((pre, i) => {
                                       console.log("The pre", item?.prerequisites[pre])
                                       const preItem = item?.prerequisites[pre]
                                       return (
                                           <div key={i} className='w-[49%] border flex flex-col p-2 mt-3'>
                                               <p className='font-bold m-0 p-0 text-[.8rem]'>{pre}</p>
                                               {item?.prerequisites[pre] && (item?.prerequisites[pre])?.map((preItem) => (
                                                   <>
                                                       <p>{preItem}</p>
                                                   </>
                                               ))
                                               }
                                           </div>
                                       )
                                   })
                               }
                           </div>
                       </div> */}
                       <div className='my-10 h-12
                       flex justify-end'>
                           <div className='flex items-center'>
                               <p className='m-0 p-0 mr-4 cursor-pointer text-[.9rem] text-[#fce200] bg-[black] h-[23px] w-[23px] flex justify-center items-center rounded-full'><FaPrint /></p>
                               <p className='m-0 p-0 text-[1rem]'>Print</p>
                           </div>
                           <div className='flex items-center ml-3 cursor-pointer'>
                               <p className='m-0 p-0 mr-4 cursor-pointer text-[.9rem] text-[#fce200] bg-[black] h-[23px] w-[23px] flex justify-center items-center rounded-full'><FiDownload /></p>
                               <p className='m-0 p-0 text-[1rem]'>Download PDF</p>
                           </div>
                       </div>
                    </div>
                </div>
                    )):<div className='h-fit mt-16' style={{
                        width:"67%",
                        display:"flex",
                        justifyContent:"center",
                        alignItems:"center",
                        flexDirection:"column",
                        top:"30%",
                        textAlign:"center",
                        fontSize:"1rem"
                    }}>
                        <img src={emptyIcon} width={100} height={100} style={{marginBottom:"3rem"}}/>
                        No load to track at the moment
                    </div>
                    }
                </div>
            </div>
        </div>
    );
}
