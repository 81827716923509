import React from 'react'
import { TbChevronLeft } from 'react-icons/tb'
import {AiOutlineArrowRight} from 'react-icons/ai'
import { lineHeight } from '@mui/system'
import DealRequests from './DealRequests'
import DealSettlements from './DealSettlements'
import DealBooking from './DealBooking'
import { BiFilter } from 'react-icons/bi'
import {useFleetStateContext} from '../context/FleetDealsContext'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { FiAlertOctagon } from 'react-icons/fi'
import { TfiDropbox } from 'react-icons/tfi'
import { AiOutlinePlus } from 'react-icons/ai'
import AddDriverFormMod from './AddDriverFormMod'
import { useStateContext } from '../context/DashboardStateContext'
import DriverDetailsPage from './DriverDetailsPage'
import {Avatar} from '@mui/material';
import RoutesSection from './RoutesSection'
import AddRouteModalForm from './AddRouteModalForm'
import ManualRouteAdd from './ManualRouteAdd'
import AssignDriver from './AssignDriver'


export default function AddRoutes() {
    const {
        addDriversData, 
        addDriverForm, 
        setAddDriverForm
      } = useFleetStateContext();
      const {
        setNewDeals,
        setShowFleet,
        setDriver, 
        setShowRoutes,
        showAddRouteMod, 
        setShowAddRouteMod,
        manualRoute, 
        setManualRoute,
        assignDriverRoute, 
        setAssignDriverRoute,
        setShowAddTrailer,
        setShowAddVehicle,
        showAssign,
        setShowAssign
    } = useStateContext();
  return (
    <div className='new-deals-page h-full flex justify-between relative scrollbar-hide' style={{height:"70vh", maxHeight:"70vh", overflow:"scroll"}}>
        <div 
          className='h-fit rounded-lg p-4 flex flex-col scrollbar-hide relative'  
          style={{
              width:"30%", 
              background:"#dadada", 
              maxheight:"100%",
              height:"100%",
              overflow:"scroll"
          }}
        >
          <div className='flex items-center'>
            <p className='text-2xl font-bold cursor-pointer' onClick={() => {
                setNewDeals(true); 
                setShowFleet(false);
                setDriver(false);
                setShowRoutes(false);
                setShowAddTrailer(false);
                setShowAddVehicle(false);
                setShowAssign(false)
            }}><TbChevronLeft/></p>
            <p className='font-bold' style={{fontSize:"1rem"}}>Routes</p>
          </div>

          {/* {addDriversData.length ? addDriversData.map((driver) => ( */}
            {/* <> */}
            <div className='mt-3 mb-2 cursor-pointer mx-auto h-fit bg-white rounded-2xl p-3' style={{width:"90%"}}>
                <div className='flex justify-between'>
                    <div className='flex items-center'>
                        <p className='w-9 h-9 m-0 p-0 rounded-full' style={{background:"#dadada"}}><Avatar className='Enterprise-icon'>JO</Avatar></p>
                        <div className='ml-3'>
                            <p className='m-0 p-0' style={{fontSize:"1.3rem"}}>Jozi Mlazi</p>
                            <p className='m-0 p-0 text-gray-500' style={{fontSize:".9rem"}}>Durban to Johannesburg</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* </> */}
          {/* )): 
            <div className='mt-4 flex justify-center items-center flex-col'>
                <p style={{fontSize:"4rem", color:"#a7a7a7"}}><TfiDropbox/></p>
                <p className='text-lg' style={{color:"grey"}}>Add drivers</p>
            </div> */}

          {/* } */}
        <p 
            className='rounded-full cursor-pointer absolute flex justify-center items-center' 
            onClick={() => {
              setShowAddRouteMod(true)
            }}
            style={{
                fontSize:"2rem", 
                background:"#fce200",
                width:"48px",
                height:"48px",
                bottom:".6rem",
                right:".6rem"
            }}><AiOutlinePlus/></p>
          {showAddRouteMod &&
            <AddRouteModalForm/>
          }
        </div>
        <RoutesSection/>
        {manualRoute &&
          <ManualRouteAdd/>
        }
        {assignDriverRoute &&
          <AssignDriver/>
        }
    </div>
  )
}
