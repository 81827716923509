import React, {useState} from 'react'
import { SlOptionsVertical } from 'react-icons/sl'
import { BsCheckLg } from 'react-icons/bs'
import MyMap from './AddRouteMap'

export default function RoutesSection() {
    const [showopts, setShowopts] = useState(false)
  return (
    <div className='rounded-lg flex justify-between' style={{width:"68%", background:"rgb(218, 218, 218)", height:"100%"}}>
        <div className='flex justify-center items-center' style={{width:"60%"}}>
            <MyMap/>
        </div>

        <div className='p-4 rounded-br-lg scrollbar-hide rounded-tr-lg' style={{width:"40%", background:"#fff", maxHeight:"100%", height:"100%", overflow:"scroll"}}>
            <div className='flex justify-between relative items-center'>
                <p style={{fontSize:".9rem"}}>Vehicle Types Allowed</p>
                <p className='cursor-pointer hover:bg-gray-300 p-2 rounded-full' onClick={() => setShowopts((prev) => !prev) }><SlOptionsVertical/></p>
                {showopts &&
                    <div className='flex flex-col p-4 justify-center items-end bg-white absolute top-6 right-0' style={{height:"5rem"}}>
                        <p className='cursor-pointer' style={{fontSize:"1rem"}}>Edit</p>
                        <p className='cursor-pointer' style={{color:"red", fontSize:"1rem"}}>Delete</p>
                    </div>
                }
            </div>
            <div className='mt-3 flex items-center'>
                <p className='w-4 h-4 mr-3' style={{background:"#707070"}}></p>
                {/* <BsCheckLg/> */}
                <p>Light &#40;3.5 tons or less&#41;</p>
            </div>
            <div className='mt-3 flex items-center'>
                <p className=' w-4 h-4 mr-3 flex justify-center items-center' style={{fontSize:"1rem", background:"#fce200"}}><BsCheckLg/></p>
                {/* <<BsCheckLg/>/> */}
                <p>Heavy &#40;3.5 tons to 34 tons&#41;</p>
            </div>
            <div className='mt-3 flex items-center'>
            <p className='w-4 h-4 mr-3' style={{background:"#707070"}}></p>
                {/* <<BsCheckLg/>/> */}
                <p>Abnormal &#40;3.5 tons or higher&#41;</p>
            </div>
            <div className='w-full mt-4' style={{borderLeft:"1px dashed black"}}>
                <p className="pl-3 start-before" style={{fontSize:'.9rem'}}>Start</p>
                <p className='pl-4 mt-4' style={{fontSize:'.8rem'}}>Kazakastan Depot</p>
                <p 
                    style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden", 
                        whiteSpace: "nowrap",
                        backgroundColor: "#d6d6d6",
                        padding:".5rem 1rem",
                        width: "85%",
                    }}
                    className="mt-6 ml-3 rounded-lg"
                >4877 Ugago Street, Pretoria Glenmeridale gardens</p>
                <p className='pl-4 mt-4 filling-before' style={{fontSize:'.8rem'}}>Filling Station</p>
                <p 
                    style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden", 
                        whiteSpace: "nowrap",
                        backgroundColor: "#d6d6d6",
                        padding:".5rem 1rem",
                        width: "85%",
                    }}
                    className="mt-6 ml-3 rounded-lg"
                >4877 Ugago Street, Pretoria Glenmeridale gardens</p>
                <p className='pl-4 mt-4 filling-before' style={{fontSize:'.8rem'}}>Refreshment Break</p>
                <p 
                    style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden", 
                        whiteSpace: "nowrap",
                        backgroundColor: "#d6d6d6",
                        padding:".5rem 1rem",
                        width: "85%",
                    }}
                    className="mt-6 ml-3 rounded-lg"
                >4877 Ugago Street, Pretoria Glenmeridale gardens</p>
                <p className='pl-4 mt-4 destination-before' style={{fontSize:'.8rem'}}>Destination &#40;120km&#41;</p>
                <p 
                    style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden", 
                        whiteSpace: "nowrap",
                        backgroundColor: "#d6d6d6",
                        padding:".5rem 1rem",
                        width: "fit-content",
                    }}
                    className="mt-6 ml-3 rounded-lg"
                >South Africa Branch</p>
                 <p 
                    style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden", 
                        whiteSpace: "nowrap",
                        backgroundColor: "#d6d6d6",
                        padding:".5rem 1rem",
                        width: "85%",
                    }}
                    className="mt-6 ml-3 rounded-lg"
                >4877 Ugago Street, Pretoria Glenmeridale gardens</p>
            </div>
        </div>
    </div>
  )
}
