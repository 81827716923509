import React, {useState, useEffect} from 'react'
import {RxCross2} from 'react-icons/rx'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { styled } from "@mui/material/styles";
import { PickersDay } from '@mui/x-date-pickers/PickersDay'
import {useFleetStateContext} from '../context/FleetDealsContext';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import dayjs from 'dayjs';
import startOfDay from "date-fns/startOfDay";
import TextField from "@mui/material/TextField";
import { useStateContext } from '../context/DashboardStateContext'
import firebase from '../firebase-config';

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) => prop !== "selected"
  })(({ theme, selected }) => ({
    ...(selected && {
      backgroundColor: "#fce200",
      color: "black",
      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary.dark
      },
      borderTopLeftRadius: "50%",
      borderBottomLeftRadius: "50%",
      borderTopRightRadius: "50%",
      borderBottomRightRadius: "50%"
    })
  }));
export default function AcceptedBookOverview() {
    const [theDates, setTheDates] = useState([])
    const [driverVehicles, setDriverVehicles] = useState([])
    const [bookingPricesOverView, setBookingPricesOverView] = useState();
    const {
        values,
        setValues,
        setAcceptedBookingOverview,
        selectedBookingDeal,
        selectedBookDriverInfo,
        setSelectedBookDriverInfo
    } = useFleetStateContext();

    const {
        userUid,
        setShowFirstDealProcess,
    } = useStateContext();

    const findDate = (dates, date) => {
        const dateTime = date.getTime();
        return dates.find((item) => item.getTime() === dateTime);
      };

      const findIndexDate = (dates, date) => {
        const dateTime = date.getTime();
        return dates.findIndex((item) => item.getTime() === dateTime);
      };

      const renderPickerDay = (date, selectedDates, pickersDayProps) => {
        if (!values) {
          return <PickersDay {...pickersDayProps} />;
      }

      const selected = findDate(values, date);

      return (
        <CustomPickersDay
          {...pickersDayProps}
          disableMargin
          selected={selected}
        />
      );
    };

    useEffect(() => {
        if(selectedBookDriverInfo[0]?.calendar !== undefined)setTheDates(Object.keys(selectedBookDriverInfo[0]?.calendar));
    }, [])

    console.log("the driver dates", selectedBookDriverInfo)

    useEffect(() => {
        const driverId = selectedBookingDeal[0].drivers[0]
        let fleet;
        firebase.database().ref("/drivers/" + driverId).on("value", (snapshot) => {
            fleet = snapshot.val().fleet;
        })
        // console.log("The fleet guys",fleet)
        firebase.database().ref("/fleets/" + fleet).child("bookings").child(selectedBookingDeal[0].booking_id).on("value", (snapshot)=> {
            const data = snapshot.val()?.price
            if(data?.length){
                setBookingPricesOverView(data[data?.length -1])
            }else{
                setBookingPricesOverView(selectedBookingDeal[0].rate_required)
            }
            console.log("your data please",data)
        })
    },[])

  return (
    <div
        style={{
            background:"#00000066",
            maxheight:"80vh",
            height:"80vh",
            zIndex:"99",
            left:"0",
            right:"0",
            top:"0",
            bottom:"0",
            height:"100%"
        }}
        className='w-full overview-modal-mobile absolute left-0 right-0 bottom-0'
    >
        <div className='flex flex-row justify-between w-full h-full'>
            <div
                className='cursor-pointer'
                onClick={()=>{
                    setAcceptedBookingOverview(false)
                }}
                style={{
                    width:"4%",
                }}>
            </div>
            <div style={{
                    width:"92%",
                }}>
                    <div
                        className='w-full flex items-center cursor-pointer'
                        style={{height:"10%"}}
                        onClick={()=>{
                            setAcceptedBookingOverview(false)
                        }}
                    >
                        <div
                            className='flex mx-2'>
                                <p
                                    onClick={()=>{
                                        setAcceptedBookingOverview(false)
                                    }}
                                    className='cursor-pointer'
                                    style={{
                                        color:"#fff",
                                        fontSize:"1.3rem"
                                    }}>
                                        <RxCross2/>
                                </p>
                                <p
                                    className='ml-1'
                                    style={{
                                        color:"#fff",
                                        fontSize:"1.3rem"
                                    }}>
                                        Trip Overview
                                </p>
                        </div>
                    </div>
                    <div
                        style={{height:"80%"}}
                        className='flex justify-between over-container-mobile'
                    >
                        <div
                            className="rounded-xl p-4 flex justify-between"
                            style={{width:"76%", height:"100%", background:"#F2F2F2"}}
                        >
                            <div
                                className='scrollbar-hide'
                                style={{
                                    width:"70%",
                                    height:"100%",
                                    maxHeight:"100%",
                                    overflow:"scroll",
                                }}
                            >
                                <div className='w-full p-3 rounded-xl' style={{background:"#fff"}}>
                                    <p style={{fontSize:".7rem", color:"#646464"}} className=''>Booking reference</p>
                                    <p style={{fontSize:".9rem", color:"#333333", fontWeight:"bold"}}>{selectedBookingDeal[0].booking_ref}</p>
                                </div>
                                <div className='w-full flex justify-between mt-3'>
                                    <div className='p-3 rounded-xl' style={{width:"49%", background:"#fff"}}>
                                        <p className='' style={{color:"#646464"}}>Loads committed</p>
                                        <p style={{fontSize:"1rem", color:"#333333"}}>{selectedBookingDeal[0].actual_loads_for_cargo}</p>
                                    </div>
                                    <div className='p-3 rounded-xl' style={{width:"49%", background:"#fff"}}>
                                        <p style={{color:"#646464"}}>Vehicles committed</p>
                                        <p style={{fontSize:"1rem", color:"#333333"}}>{selectedBookingDeal[0].drivers.length}</p>
                                    </div>
                                </div>
                                <div className='w-full mt-3 p-3 rounded-xl' style={{background:"#fff"}}>
                                    <p style={{color:"#646464"}}>Goods in Transit</p>
                                    <p style={{fontSize:".95rem",fontWeight:"bold", color:"#333333"}} className='mb-2'>R{selectedBookingDeal[0].prerequisites.goods_in_transit}</p>

                                    <p style={{color:"#646464"}}>Rate per load</p>
                                    <p style={{fontSize:".95rem",fontWeight:"bold", color:"#333333"}} className='mb-2'>R{200}</p>

                                    <p  style={{color:"#646464"}}>Total due to you</p>
                                    <p style={{fontSize:".95rem",fontWeight:"bold", color:"#333333"}}>R{bookingPricesOverView}</p>
                                </div>
                                <div className='w-full flex justify-between mt-3'>
                                    <div className='p-3 rounded-xl' style={{width:"49%", background:"#fff"}}>
                                        <p className='' style={{color:"#646464"}}>Start</p>
                                        <p style={{fontSize:".7rem", color:"#333333"}}>{selectedBookingDeal[0].puDetails.puAddress}</p>
                                    </div>
                                    <div className='p-3 rounded-xl' style={{width:"49%", background:"#fff"}}>
                                        <p style={{color:"#646464"}}>End</p>
                                        <p style={{fontSize:".7rem", color:"#333333"}}>{selectedBookingDeal[0].doDetails.doAddress}</p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className='px-3 h-full scrollbar-hide'
                                style={{
                                    width:"30%",
                                    overflow:"scroll"
                                }}
                            >
                                <p className='my-3'>Schedule</p>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <StaticDatePicker
                                        className='h-72'
                                        defaultValue={dayjs('2022-04-17')}
                                        displayStaticWrapperAs="desktop"
                                        label="Week picker"
                                        value={theDates}
                                        onChange={(theDates) => {
                                        //copying the values array
                                        const array = [...values];
                                        const date = startOfDay(theDates);
                                        const index = findIndexDate(array, date);
                                        if (index >= 0) {
                                            array.splice(index, 1);
                                        } else {
                                            array.push(date);
                                        }
                                        setValues(array);
                                        }}
                                        maxDate={theDates[theDates.length - 1]}
                                        minDate={theDates[0]}
                                        renderDay={renderPickerDay}
                                        renderInput={(params) => <TextField {...params} />}
                                        inputFormat="'Week of' MMM d"
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div className="rounded-xl p-4 scrollbar-hide" style={{width:"22%", height:"100%", background:"#fff", maxHeight:"100%", overflow:"scroll"}}>
                            <p className='mb-6' style={{fontSize:".7rem", color:"#646464"}}>Assigned drivers</p>
                            {selectedBookDriverInfo && selectedBookDriverInfo.map((driver, i) => (
                                <div key={i} className='flex mb-3 items-center '>
                                    <p className='w-9 h-9 m-0 p-0 rounded-full' style={{background:"#dadada"}}><img className='w-full h-full rounded-full' style={{objectFit:"cover"}} src={driver.photo_uri} alt="" /></p>
                                    <div className='ml-2'>
                                        <p className='m-0 p-0 font-bold text-sm'>{driver.name} {driver.surname}</p>
                                        <p className='m-0 p-0 text-gray-500'>{selectedBookingDeal[0].puDetails.puCityName}, {selectedBookingDeal[0].doDetails.doCityName}</p>
                                    </div>
                                </div>
                            ))

                            }

                        </div>
                    </div>
                    <div
                        className='w-full flex items-center cursor-pointer'
                        style={{height:"10%"}}
                        onClick={()=>{
                            setAcceptedBookingOverview(false)
                        }}
                    >

                    </div>
            </div>
            <div
                onClick={()=>{
                    setAcceptedBookingOverview(false)
                }}
                style={{
                    width:"4%",
                }}
                className='cursor-pointer'
            ></div>
        </div>
    </div>
  )
}
