import React, {useEffect} from 'react'
import { TbChevronLeft } from 'react-icons/tb'
import {AiOutlineArrowRight} from 'react-icons/ai'
import { lineHeight } from '@mui/system'
import DealRequests from './DealRequests'
import DealSettlements from './DealSettlements'
import DealBooking from './DealBooking'
import { BiFilter } from 'react-icons/bi'
import {useFleetStateContext} from '../context/FleetDealsContext'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { FiAlertOctagon } from 'react-icons/fi'
import { TfiDropbox } from 'react-icons/tfi'
import { AiOutlinePlus } from 'react-icons/ai'
import AddDriverFormMod from './AddDriverFormMod'
import { useStateContext } from '../context/DashboardStateContext'
import VehicleDetails from './VehicleDetails'
import AddVehicleFormMod from './AddVehicleFormMod'
import firebase from '../firebase-config'
import TrailerDetails from './TrailerDetails'
import AddTrailerFormMod from './AddTrailerFormMod'
import emptyIcon from '../icons/box.png'

export default function AddTrailer() {
    const {
        selectVehicle,
        AddTrailerData, 
        setAddTrailerData,
        selectTrailer, 
        setSelectTrailer
      } = useFleetStateContext();
      
      const {
        setDriver, 
        setNewDeals,
        setShowFleet,
        setShowRoutes,
        setShowAddVehicle,
        userUid, 
        setShowAddTrailerMod,
        showAddTrailerMod,
        setShowAssign,
        setShowAddTrailer
      } = useStateContext();

    useEffect(() => {
      firebase.database().ref('/fleets/' + userUid + "/trailers").on('value', (snapshot) => {
          if(snapshot.exists()){
            setAddTrailerData(Object?.values(snapshot.val()));
          }
      });
  }, [])

  return (
    <div className='new-deals-page flex justify-between relative' style={{height:"80vh", maxHeight:"80vh", overflow:"hidden"}}>
        <div 
          className='h-fit rounded-lg p-4 scrollbar-hide relative trailer-mobile'  
          style={{
              width:"30%", 
              background:"#dadada", 
              maxheight:"574px",
              height:"100%",
              overflow:"scroll"
          }}
        >
          <div className='flex items-center'>
            <p className='text-2xl font-bold cursor-pointer' onClick={() => {
              setDriver(false)
              setNewDeals(false)
              setShowFleet(true)
              setShowRoutes(false)
              setShowAddVehicle(false)
              setShowAssign(false)
              setShowAddTrailer(false)
            }}><TbChevronLeft/></p>
            <p className='font-bold' style={{fontSize:"1rem"}}>Trailers</p>
          </div>
          <div className='flex mt-3 w-full justify-between'>
            <input type="text" className='h-8 px-3 rounded-lg border-transparent' 
                placeholder='Search' 
                style={{width:"85%", background:"rgb(207 207 207)", color:"#000"}} 
            />
            <p className='ml-2 text-2xl flex justify-center items-center' style={{width:"15%"}}><BiFilter/></p>
          </div>

          {AddTrailerData.length > 0 ? AddTrailerData.map((trailer) => (
            <>
            <div 
              className='mt-3 mb-2 h-fit cursor-pointer w-full bg-white rounded-lg px-3 py-1'
              onClick={() => setSelectTrailer([trailer])}
            >
                <div className='flex justify-between'>
                    <div className='flex items-center'>
                        <p className='w-9 h-9 m-0 p-0 rounded-full' style={{background:"#dadada"}}><img className='w-full h-full rounded-full' style={{objectFit:"cover"}} src={trailer.photo_uri} alt="vehicle image" /></p>
                        <div className='ml-2'>
                            <p className='m-0 p-0 font-bold text-sm'>{trailer?.year} {trailer?.make} {trailer?.model}</p>
                            <p className='m-0 p-0 text-gray-500'>{trailer?.licence_number}</p>
                        </div>
                    </div>
                    <div className='flex items-center'>
                        <p><AiOutlineClockCircle/></p>
                        <p className='ml-2 text-red-600'><FiAlertOctagon/></p>
                    </div>
                </div>
            </div>
            </>
           )): 
            <div className='mt-4 flex justify-center items-center flex-col'>
                <p style={{fontSize:"4rem", color:"#a7a7a7"}}><TfiDropbox/></p>
                <p className='text-lg' style={{color:"grey"}}>Add vehicle</p>
            </div>

          }  
        <p 
            className='rounded-full cursor-pointer sticky flex justify-center items-center' 
            onClick={() => {
                setShowAddTrailerMod(true)
            }}
            style={{
                fontSize:"2rem", 
                background:"#fce200",
                width:"48px",
                height:"48px",
                top:"100%",
                left:"100%"
            }}><AiOutlinePlus/></p>
        </div>
        {selectTrailer.length ? 
          <TrailerDetails/>
        :
          <div className='dash-date' style={{
            width:"68%", 
            height:"100%",
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            flexDirection:"column",
            top:"30%",
            textAlign:"center",
            fontSize:"1rem"
          }}>
            <img src={emptyIcon} width={100} height={100} style={{marginBottom:"3rem"}}/>
            Please select a trailer on the left to see it's details.
          </div>
        }
        {showAddTrailerMod &&
            <AddTrailerFormMod/>
        }
    </div>
  )
}
