import {
	Combobox,
	ComboboxInput,
	ComboboxPopover,
	ComboboxList,
	ComboboxOption,
	ComboboxOptionText,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import usePlacesAutoComplete, {getGeocode, getLatLng} from 'use-places-autocomplete'
import {useFleetStateContext} from '../context/FleetDealsContext'


export default function PlacesAutoCompleteStart() {
    const {
      selectedStartAddress
    } = useFleetStateContext();

    const {
      ready,
      value,
      setValue,
      suggestions: {status, data},
      clearSuggestions
    } = usePlacesAutoComplete();

    const handleSelect = async(address) => {
      setValue(address, false);
      clearSuggestions();

      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      selectedStartAddress({lat, lng});
    }

    return (
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={!ready}
          className='combobox-input'
          placeholder='Start'
        />
        <ComboboxPopover>
          <ComboboxList>
            {status === "OK" &&
              data.map(({place_id, description}) => (
                <ComboboxOption key={place_id} value={description}/>
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    )
  }