import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { TbChevronLeft } from 'react-icons/tb';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useFleetStateContext } from '../context/FleetDealsContext';
import { useStateContext } from '../context/DashboardStateContext';
import '../css/newdeals.css';
import firebase from '../firebase-config';
import AcceptedBookOverview from './AcceptedBookOverview';
import emptyIcon from '../icons/box.png';
import DealRequests from './DealRequests'; // Check if the path and filename are correct
import DealSettlements from './DealSettlements'; // Check if the path and filename are correct
import DealBooking from './DealBooking';

export default function NewDeals() {
  const [active, setActive] = useState(false);
  const [allMyDrivers, setAllMyDrivers] = useState([]);
  const [filteredDeals, setFilteredDeals] = useState([]);
  const [filterValue, setFilterValue] = useState('all');
  const [loadsLeft, setLoadsLeft] = useState(false);

  const {
    allDeals,
    selectedBookingDeal,
    setSelectedBookingDeal,
    setCounterOfferPrice,
    acceptedBookingOverview,
    setAcceptedBookingOverview,
    prevSelectedBookingDeal,
    setPrevSelectedBookingDeal,
  } = useFleetStateContext();

  const {
    setNewDeals,
    setShowFleet,
    setDriver,
    setShowRoutes,
    bookingSelectedDates,
    setBookingSelectedDates,
    setShowAddTrailer,
    setShowAddVehicle,
    showAssign,
    setShowAssign,
    userUid,
  } = useStateContext();

  useEffect(() => {
    allDeals && setSelectedBookingDeal([allDeals[0]]);
  }, []);

  // useEffect(() => {
  //   selectedBookingDeal ? setPrevSelectedBookingDeal(selectedBookingDeal) : setPrevSelectedBookingDeal(allDeals[0]);
  // }, []);

  useEffect(() => {
    firebase.database().ref('/fleets/' + userUid).on('value', (snapshot) => {
      setAllMyDrivers(snapshot.val()?.drivers || []); // Set an empty array as default if drivers is not present in the snapshot
    });
  }, []);

  const showDealRequest = !selectedBookingDeal[0]?.drivers && !selectedBookingDeal[0]?.booking_bids_fleet_id || false;
  const showDealSettlement = selectedBookingDeal[0]?.booking_bids_fleet_id && !selectedBookingDeal[0]?.drivers || false;
  const showDealBooking = selectedBookingDeal[0]?.drivers && !selectedBookingDeal[0]?.booking_bids_fleet_id || false;

  const filteredBookingsFleet = allDeals.filter((item) => Array.isArray(item.booking_bids_fleet_id) && item.booking_bids_fleet_id.includes(userUid));

  useEffect(() => {
    console.log("the booking fleet", filteredBookingsFleet)
  },[])

  const dealsWithMatchingDrivers = allDeals
    .filter((item) => item?.drivers && item.drivers.length > 0) // Filter out items with non-empty drivers array
    .filter((deal) => deal?.drivers.some((driver) => allMyDrivers.includes(driver)));

  const driversInMatchingDeals = dealsWithMatchingDrivers.map((item) => item?.drivers); // Use flatMap to flatten the array of drivers

  // console.log("The match array", driversInMatchingDeals)


  useEffect(() =>{
    const testUID = "ESGwMJ8T39QJYKybupwSbfOGG8O2";
    console.log("this is the test array", testUID)
    console.log("this is the driversInMatchingDeals", driversInMatchingDeals)
    driversInMatchingDeals.some((driver) =>Array.isArray(testUID) ? testUID.includes(driver) : driver === testUID.trim()) ? console.log("yes i am found"): console.log("not found")
  },[])

  useEffect(() => {
    // Filter the deals when the component mounts and whenever allDeals changes
    filterDeals();
  }, [allDeals, filterValue]);

  const filterDeals = () => {
    let filteredDeals = allDeals;

    if (filterValue === 'requests') {
      filteredDeals = allDeals.filter((item) => {
        const hasBookingBidsFleetId = Array.isArray(item.booking_bids_fleet_id) && !item.booking_bids_fleet_id.includes(userUid);
        const hasNoMatchingDrivers = !item?.drivers || (Array.isArray(item?.drivers) && item?.drivers.every((driver) => !driversInMatchingDeals.some((driversArray) => Array.isArray(driver) ? driver.some((d) => driversArray.includes(d)) : driversArray.includes(driver.trim()))));
        const hasNoBookingBidsFleetIdOrDrivers = (!item.booking_bids_fleet_id || (Array.isArray(item.booking_bids_fleet_id) && !item.booking_bids_fleet_id.includes(userUid))) && hasNoMatchingDrivers;
        return hasNoBookingBidsFleetIdOrDrivers;
      });
    }
     else if (filterValue === 'settlements') {
      filteredDeals = allDeals.filter((item) => Array.isArray(item.booking_bids_fleet_id) && item.booking_bids_fleet_id.includes(userUid));
    }else if (filterValue === 'bookings') {
      // Flatten driversInMatchingDeals before checking
      const flattenedDriversInMatchingDeals = driversInMatchingDeals.flat();

      filteredDeals = allDeals.filter((item) => {
        const hasNoBookingBidsFleetId = !item.booking_bids_fleet_id;
        const hasBookingBidsWithUserUid = Array.isArray(item.booking_bids_fleet_id) && item.booking_bids_fleet_id.includes(userUid);

        const hasMatchingDrivers = Array.isArray(item?.drivers)
          ? item?.drivers.some((driver) => flattenedDriversInMatchingDeals.includes(driver))
          : flattenedDriversInMatchingDeals.includes(item?.drivers);

        return (hasNoBookingBidsFleetId || (hasBookingBidsWithUserUid && item?.drivers)) && hasMatchingDrivers;
      });
    }

    else if (filterValue === 'all'){
      filteredDeals = allDeals
    }
    setFilteredDeals(filteredDeals);
  };

  useEffect(() => {
    if(selectedBookingDeal.length > 0 ){
      if(selectedBookingDeal[0]?.undelivered_loads){
          setLoadsLeft(true);
      }
    }
  },[])

  return (
    <div className='new-deals-page flex justify-between relative scrollbar-hide'> <div className='deals-mobile rounded-lg p-2 scrollbar-hide' style={{ width: '30%', background: '#e0e0e0', overflow: 'hidden' }}>
        <div>
          <div className='flex items-center'>
            <p className='text-2xl font-bold cursor-pointer' onClick={() => {
              setNewDeals(false);
              setShowFleet(true);
              setDriver(false);
              setShowRoutes(false);
              setShowAddTrailer(false);
              setShowAddVehicle(false);
              setShowAssign(false);
            }}><TbChevronLeft /></p>
            <p className='font-bold' style={{ fontSize: '1rem' }}>Deals</p>
          </div>
          <div className='flex mt-2 ml-2'>
          <p className='cursor-pointer mr-3' style={{fontWeight:filterValue === "all" ? "bold" : "unset"}} onClick={() => setFilterValue('all')}>All</p>
            <div className='flex mr-3'>
            <div
              className='rounded-full w-4 h-4 cursor-pointer'
              style={{ background: '#fce200'}}
              onClick={() => {
                setFilterValue('requests');
              }}
            ></div><p className='pl-2' style={{fontWeight:filterValue === "requests" ? "bold" : "unset"}}>Requests</p>
            </div>
            <div className='flex mr-3'>
              <div className='rounded-full w-4 h-4 cursor-pointer' style={{ background: '#213164'}} onClick={() => setFilterValue('settlements')}></div><p className='pl-2' style={{fontWeight:filterValue === "settlements" ? "bold" : "unset"}}>Settlements</p>
            </div>
            <div className='flex mr-3'>
              <div className='rounded-full w-4 h-4 cursor-pointer' style={{ background: '#059E00' }} onClick={() => setFilterValue('bookings')}></div><p className='pl-2' style={{fontWeight:filterValue === "bookings" ? "bold" : "unset"}}>Bookings</p>
            </div>
          </div>
        </div>
        <div className='scrollbar-hide' style={{ minHeight: '574px', maxHeight: '574px', overflow: 'scroll' }}>
        {filteredDeals.length > 0 ?
          filteredDeals
            .sort((a, b) => new Date(b.date_created) - new Date(a.date_created))
            .map((book, i) => (
            <div
              key={i}
              className={`mt-3 mx-auto cursor-pointer w-11/12 hover:w-full p-2 py-0 relative rounded-xl pl-6 ${active == book && 'activedeals'} `}
              style={{ background: '#fff' }}
              onClick={() => {
                setSelectedBookingDeal([book]);
                setBookingSelectedDates({ 'Start': book?.dates_time_selection?.start_date_string, 'end': book.dates_time_selection?.end_date_string });
                setActive(book);
                if (book.booking_bids_fleet_id) {
                  firebase.database().ref('/fleets/' + book?.booking_bids_fleet_id[0] + '/booking_bids/' + '/' + book.booking_id + '/').on('value', snapshot => {
                    if (snapshot.exists()) setCounterOfferPrice(() => snapshot.val().price[0]);
                  });
                }
              }}
            >

            {
            // ((filteredBookingsFleet.filter((item) => item.booking_ref === book.booking_ref).length === 0) && !book?.drivers)
            //   ||
              ((!book?.booking_bids_fleet_id) // Added condition: If booking_bids_fleet_id is not present
              ||
              (Array.isArray(book?.booking_bids_fleet_id) && !book.booking_bids_fleet_id.includes(userUid))
              ||
              (book?.drivers &&
                !driversInMatchingDeals?.some((driversArray) =>
                  Array.isArray(book?.drivers)
                    ? book?.drivers.some((driver) => driversArray?.includes(driver))
                    : driversArray?.includes(book?.drivers.trim())
                )
              ))
              && (
                <div className='absolute rounded-tl-xl rounded-bl-xl top-0 left-0 right-0 bottom-0 h-full w-3' style={{ background: '#fce200' }}></div>
              )}

              {filteredBookingsFleet.filter((item) => item.booking_ref === book.booking_ref).length > 0 && (
                <div className='absolute rounded-tl-xl rounded-bl-xl top-0 left-0 right-0 bottom-0 h-full w-3' style={{ background: '#213164' }}></div>
              )}

              {
                book?.drivers &&
                driversInMatchingDeals.some((driversArray) =>
                  Array.isArray(book?.drivers)
                    ? book?.drivers.some((driver) => driversArray.includes(driver))
                    : driversArray.includes(book?.drivers.trim())
                ) && (
                  <div className='absolute rounded-tl-xl rounded-bl-xl top-0 left-0 right-0 bottom-0 h-full w-3' style={{ background: '#059E00' }}></div>
                )
              }

              <div className='flex items-center'>
                <p className='mr-3 font-bold text-sm mt-0'>{book.puDetails?.puCityName}</p>
                <p className='text-lg mr-3 font-bold'><AiOutlineArrowRight /></p>
                <p className='mr-3 font-bold mt-0 text-sm'>{book.doDetails?.doCityName}</p>
              </div>
              <div className='flex justify-between'>
                <p>{book.prerequisites?.vehicle_type[0]}</p>
                <p>+- {book.total_distance}KM</p>
              </div>
              <div className='flex justify-between'>
                <p>Now</p>
                <p className='font-bold'>{book.booking_ref}</p>
              </div>
            </div>
          )) : (
            <div className='dash-date' style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              top: '30%',
              height: 'fit-content',
              marginTop: '9%',
              marginBottom: '9%',
              textAlign: 'center',
              fontSize: '11.5px'
            }}>
              <img src={emptyIcon} width={100} height={100} alt='No deals' />
              No Deals at the moment
            </div>
          )}
        </div>
        {acceptedBookingOverview && <AcceptedBookOverview />}
      </div>

      {
        (
          !filteredBookingsFleet.some(item => item?.booking_ref === (selectedBookingDeal[0]?.booking_ref ))
          && (
            selectedBookingDeal[0] &&
            (!selectedBookingDeal[0]?.drivers ||
            !driversInMatchingDeals?.some(driversArray =>
              Array.isArray(selectedBookingDeal[0]?.drivers)
                ? selectedBookingDeal[0]?.drivers?.some(driver => driversArray?.includes(driver))
                : driversArray?.includes(selectedBookingDeal[0]?.drivers?.trim())
            ))
          )
        )
        && <DealRequests />
      }

      {
        selectedBookingDeal[0] &&
        filteredBookingsFleet.some((item) => item?.booking_ref === selectedBookingDeal[0]?.booking_ref) &&
        <DealSettlements />
      }


      {
        selectedBookingDeal[0]?.drivers &&
        driversInMatchingDeals?.some((driversArray) =>
          Array.isArray(selectedBookingDeal[0]?.drivers)
            ? selectedBookingDeal[0]?.drivers.some((driver) => driversArray?.includes(driver))
            : driversArray?.includes(selectedBookingDeal[0]?.drivers.trim())
        ) && <DealBooking />
      }
      {loadsLeft &&
        <div className='absolute h-full w-full flex items-center justify-center'>
          <div className='p-5 rounded-xl flex items-center justify-center flex-col' style={{width:"244px", height:"fit-content", background:"#fff"}}>
              <p style={{fontSize:"1rem", textAlign:"center", marginBottom:"1rem"}}>Please be advised that there are {selectedBookingDeal[0]?.undelivered_loads} loads left, still yet to be delivered</p>
              <button className='rounded-xl px-2 py-1' style={{background:"#fce200", fontSize:".8rem"}} onClick={() => setLoadsLeft(false)}>Ok</button>
          </div>
        </div>
      }

      {!selectedBookingDeal[0]?.booking_ref &&
          <div className='dash-date' style={{
            width:"68%",
            height:"100%",
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            flexDirection:"column",
            top:"30%",
            textAlign:"center",
            fontSize:"1rem"
          }}>
            <img src={emptyIcon} width={100} height={100} style={{marginBottom:"3rem"}}/>
            Please select booking on the left if there is any, to see it's details.
          </div>
      }
    </div>
  );
}
