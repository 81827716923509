import React from 'react'
import { useStateContext } from '../context/DashboardStateContext'
import { RxCross2 } from "react-icons/rx";

const BookingSummary = () => {

    const {
        setIsBookingSummary,
        universalSelectedBook
    } = useStateContext();


    return (
        <div className='w-full beforeEffect flex-row h-full absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center' style={{zIndex:"1"}}>
            <div className='w-[22.5%] h-full cursor-pointer' onClick={() => setIsBookingSummary(false)}></div>
            <div className='h-full flex justify-center items-center flex-col w-[55%]'>

                <div className='h-[25%] flex justify-end items-end w-full cursor-pointer' onClick={() => setIsBookingSummary(false)}>
                    <p
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsBookingSummary(false)
                        }}
                        className='w-[2rem] text-[1.5rem] rounded-full m-0 mb-3 p-0 h-[2rem] bg-[white] flex justify-center items-center'
                    >
                        <RxCross2/>
                    </p>
                </div>
                <div className='w-full relative h-[95%] scrollbar-hide bg-[#F2F2F2] overflow-scroll p-8 flex flex-col  justify-between rounded-xl'>
                    <div className='mb-9'>
                        <p className='text-[.99rem]'><b>Booking Summary:</b> {universalSelectedBook.booking_ref}</p>
                    </div>
                    <hr className='mb-5 border-black'/>
                    <div className='summary-wrap w-full'>
                        <div>
                            <div style={{height:"64px"}}>
                                <p style={{color:"grey"}}>Load Cargo</p>
                            </div>
                            <div style={{height:"64px"}}>
                                <p style={{color:"grey"}}>Offload Cargo</p>
                            </div>

                            <div style={{height:"332px", marginTop:"24px"}}>
                                <p style={{color:"grey"}}>Cargo</p>
                            </div>

                            <div style={{height:"236px", marginTop:"24px"}}>
                                <p style={{color:"grey"}}>prerequisites</p>
                            </div>

                            {/* <div style={{height:"64px", marginTop:"24px"}}>
                                <p style={{color:"grey"}}>Schedule</p>
                            </div> */}
                        </div>
                        <div>
                            <div style={{height:"64px"}} className="left-border-sum the-second">
                                <>
                                    <p>{universalSelectedBook?.puDetails.puName}</p>
                                    <p>{universalSelectedBook?.puDetails.puCompanyName}</p>
                                    <p style={{color:"grey"}}>{universalSelectedBook?.puDetails.puAddress}</p>
                                    <p></p>
                                </>
                            </div>

                            <div style={{height:"64px"}} className="left-border-sum the-second the-last">
                                    <>
                                        <p>{universalSelectedBook?.doDetails.doName}</p>
                                        <p>{universalSelectedBook?.doDetails.doCompanyName}</p>
                                        <p style={{color:"grey"}}>{universalSelectedBook?.doDetails.doAddress}</p>
                                        <p></p>
                                    </>
                            </div>

                            <div style={{
                                    marginTop:"24px",
                                    borderLeft:"1px solid grey",
                                    padding:"0 10px",
                            }}>
                                    <div style={{
                                            display:"flex",
                                            justifyContent:"space-between",
                                    }}>
                                        <h1 style={{fontWeight:"normal"}}>{universalSelectedBook?.cargoInformation.productName}</h1>
                                        <p>SKU: {universalSelectedBook?.cargoInformation.productCode}</p>
                                    </div>

                                    <div style={{
                                            display:"flex",
                                            justifyContent:"space-between",
                                            border:"1px solid grey",
                                            padding:"10px",
                                            marginTop:"10px"

                                    }}>
                                    <div style={{
                                        width:"31%",
                                        display:"flex",
                                        justifyContent:"space-between",
                                        }}>
                                            <div className='cargo-bid'>
                                                <p>Quantity</p>
                                                <p>Length</p>
                                                <p>Breadth</p>
                                                <p>height</p>
                                            </div>
                                            <div className='cargo-bid'>
                                                <p>: {universalSelectedBook?.cargoInformation.quantity}</p>
                                                <p>: {universalSelectedBook?.cargoInformation.lengthValue}cm</p>
                                                <p>: {universalSelectedBook?.cargoInformation.breadth}cm</p>
                                                <p>: {universalSelectedBook?.cargoInformation.height}cm</p>
                                            </div>
                                    </div>
                                    <div  style={{
                                        width:"31%",
                                        display:"flex",
                                        justifyContent:"space-between"
                                        }}>


                                            <div className='cargo-bid' style={{textAlign:"end"}}>
                                                <p>{(universalSelectedBook?.cargoInformation?.volume)}m&sup3; :</p>
                                                <p>{((universalSelectedBook?.cargoInformation?.volume)*universalSelectedBook?.cargoInformation?.quantity).toFixed(2)}m&sup3; :</p>
                                                <p>{universalSelectedBook?.cargoInformation?.weight}mt :</p>
                                                <p>{(universalSelectedBook?.cargoInformation?.weight)* universalSelectedBook?.cargoInformation?.quantity}mt :</p>
                                            </div>
                                            <div className='cargo-bid' style={{textAlign:"end"}}>
                                                <p>Net Volume</p>
                                                <p>Total Volume</p>
                                                <p>Net Weight</p>
                                                <p>Total Weight</p>
                                            </div>
                                    </div>
                                    </div>

                                    <div style={{
                                            display:"flex",
                                            justifyContent:"space-between",
                                            flexDirection:"column",
                                            border:"1px solid grey",
                                            padding:"10px",
                                            marginTop:"10px"

                                    }}>
                                        <div style={{display:"flex", justifyContent:"end"}}>
                                            <p>Hazardous Cargo</p>
                                        </div>
                                        <div style={{
                                                display:"flex",
                                                justifyContent:"space-between",
                                            }}>
                                            <div style={{
                                                width:"31%",
                                                display:"flex",
                                                justifyContent:"space-between",
                                            }}>
                                                    <div className='cargo-bid'>
                                                        <p>Package Type</p>
                                                        <p>Fragile Cargo</p>
                                                    </div>
                                                    <div className='cargo-bid' >
                                                        <p>: {universalSelectedBook?.cargoInformation?.packageType}</p>
                                                        <p>: No</p>
                                                    </div>
                                            </div>
                                            <div  style={{
                                                width:"31%",
                                                display:"flex",
                                                justifyContent:"space-between"
                                                }}>


                                                    <div className='cargo-bid' style={{textAlign:"end"}}>
                                                        <p>{universalSelectedBook?.cargoInformation?.imoClass} :</p>
                                                        <p>{universalSelectedBook?.cargoInformation?.unNumber} :</p>
                                                    </div>
                                                    <div className='cargo-bid' style={{textAlign:"end"}}>
                                                        <p>IMO</p>
                                                        <p>UN Number</p>
                                                    </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div style={{
                                            display:"flex",
                                            justifyContent:"space-between",
                                            flexDirection:"column",
                                            border:"1px solid grey",
                                            padding:"10px",
                                            marginTop:"10px",
                                            marginBotton:"10px",
                                            marginBottom: "11px"
                                    }}>
                                        <div>
                                            <p>Temperature Controlled Cargo</p>
                                        </div>
                                        <div style={{
                                                display:"flex",
                                                justifyContent:"space-between",
                                            }}>
                                            <div style={{
                                                width:"31%",
                                                display:"flex",
                                                justifyContent:"space-between",
                                            }}>
                                                    <div className='cargo-bid'>
                                                        <p>Min</p>
                                                        <p>Max</p>
                                                    </div>
                                                    <div className='cargo-bid' >
                                                        <p>: {universalSelectedBook?.cargoInformation?.hazard?.min_temp}&#8451;</p>
                                                        <p>: {universalSelectedBook?.cargoInformation?.hazard?.max_temp}&#8451;</p>
                                                    </div>
                                            </div>

                                        </div>

                                    </div>

                                    <div style={{marginTop:"15px"}}>
                                        <a href="" className='sds-view-doc'>View SDS <i class="fa-solid fa-eye"></i></a>
                                    </div>
                            </div>
                            <div className='prerequi'>
                                <h1 style={{fontWeight:"normal"}}>{universalSelectedBook?.cargoInformation?.vehicle_type}</h1>
                                <div style={{
                                    display:"flex",
                                    justifyContent:"space-between",
                                    //  padding:"10px",
                                    marginTop:"10px",
                                    marginBotton:"10px"
                                }}>
                                    <div style={{
                                        width:"45%",
                                        border:"1px solid grey",
                                        padding:"10px"
                                    }}>
                                        <p>Ad-hoc Services</p>
                                        <p>{universalSelectedBook?.prerequisites?.ad_hoc_services}</p>
                                    </div>
                                    <div  style={{
                                        width:"45%",
                                        border:"1px solid grey",
                                        padding:"10px"
                                    }}>
                                        <p>Documentation</p>
                                        {/* {data.length > 0 ? */}
                                            <p>{universalSelectedBook?.prerequisites?.documentation}</p>
                                            {/* :
                                            <p>Not selected</p>
                                        } */}
                                    </div>
                                </div>

                                <div style={{
                                    display:"flex",
                                    justifyContent:"space-between",
                                    //  padding:"10px",
                                    marginTop:"10px",
                                    marginBotton:"10px"
                                }}>
                                    <div style={{
                                        width:"45%",
                                        border:"1px solid grey",
                                        padding:"10px"
                                    }}>
                                        <p>Personal Protective Equipment</p>
                                        {universalSelectedBook?.length > 0 ?
                                            <p>{universalSelectedBook?.prerequisites?.personal_protective}</p>
                                            :
                                            <p>Not selected</p>
                                        }
                                    </div>
                                    <div  style={{
                                        width:"45%",
                                        border:"1px solid grey",
                                        padding:"10px"
                                    }}>
                                        <p>Vehicle Equipment</p>
                                        {/* {data.length > 0 ? */}
                                            <p>{universalSelectedBook?.prerequisites?.vehicle_equipment?.toString()} </p>
                                            {/* :
                                            <p>Not selected</p>
                                        } */}
                                    </div>
                                </div>

                                <div style={{
                                    display:"flex",
                                    justifyContent:"space-between",
                                    //  padding:"10px",
                                    marginTop:"10px",
                                    marginBotton:"10px"
                                }}>

                                    <div  style={{
                                        width:"100%",
                                        border:"1px solid grey",
                                        padding:"10px"
                                    }}>
                                        <p>Insurance (Goods in Transit)</p>
                                        {universalSelectedBook?.prerequisites?.goods_in_transit ?
                                            <p>R {universalSelectedBook?.prerequisites?.goods_in_transit}</p>
                                            :
                                            <p>Not selected</p>
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* <div className='prerequi'>
                                <h1 style={{fontWeight:"normal"}}>Dates</h1>
                                {data.length > 0 ?
                                    <>
                                        <p style={{color:"grey"}}>Start date :{universalSelectedBook?.dates_time_selection.start_date_string}</p>
                                        <p style={{color:"grey"}}>End date :{universalSelectedBook?.dates_time_selection.end_date_string}</p>
                                    </>
                                    :
                                    <>
                                        <p>Not selected</p>
                                        <p>Not selected</p>
                                    </>
                                }
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className='h-[25%] w-full cursor-pointer' onClick={() => setIsBookingSummary(false)}></div>

            </div>
            <div className='w-[22.5%] h-full cursor-pointer' onClick={() => setIsBookingSummary(false)}></div>
        </div>
    )
}

export default BookingSummary
