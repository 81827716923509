import React, {useState, useEffect} from 'react'
import PlacesAutocomplete from 'react-places-autocomplete';
import '../css/locationAuto.css';
import { useStateContext } from '../context/BookingAddress'
import { useStateBidding } from '../context/BiddingStatesContext'

export default function LocationSearchInput({
  setBookingArray,
  bookingArray
}) {
  const {
    isAddressAuto,
    setIsAddressAuto,
  } = useStateContext();

  const {
    puCity,
    setPuCity
  } = useStateBidding();

  const handleChange = (value) => {
    setIsAddressAuto(value);
    console.log("this the value", value);
  }

  const handleSelect = (value) => {
    setIsAddressAuto(value);
    console.log("Selected value:", value);

    const parts = value.split(',');
    if (parts.length > 1) {
      const city = parts[parts.length - 3].trim();
      setPuCity(city);
      setBookingArray((prevState) => ({
        ...prevState,
        details:{
          ...prevState.details,
          city:city
        }
      }));
    }
  }

  const handleBlur = (event) => {
    const value = event.target.value;
    if (value) {
      const parts = value.split(',');
      if (parts.length > 1) {
        const city = parts[parts.length - 3].trim();
        setPuCity(city);
        setBookingArray((prevState) => ({
          ...prevState,
          details:{
            ...prevState.details,
            city:city
          }
        }));
      }
    }
  }

  console.log("check right here the city results", puCity);

  return (
    <div>
      <PlacesAutocomplete value={isAddressAuto} onChange={handleChange} onSelect={handleSelect}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Physical address',
                className: 'location-search-input',
                onBlur: handleBlur // Added onBlur event handler
              })}
            />
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion) => {
              const style = suggestion.active ?
              {background: "#f9dd07", cursor: "pointer"} :
              {background: "white", cursor: "pointer"}

              return(
                <div style={{fontSize:"13.5px"}} {...getSuggestionItemProps(suggestion, {style})}>
                  {suggestion.description}
                </div>
              )
            })}
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  )
}

