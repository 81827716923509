import React, { useState, useEffect } from 'react';
import { useStateContext } from '../context/DashboardStateContext';
import { TbChevronLeft } from 'react-icons/tb';
import { ImUser } from 'react-icons/im';
import { RiSteering2Fill } from 'react-icons/ri';
import { FaSnapchat, FaTrailer } from 'react-icons/fa';
import AssignSelectPanel from './AssignSelectPanel';
import { useFleetStateContext } from '../context/FleetDealsContext';
import profileImage from '../icons/profilePic.jpg';
import truckPic from '../icons/truckPic.jpg';
import firebase from '../firebase-config';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { RiErrorWarningLine } from 'react-icons/ri';

export default function Assign() {
  const [assignedSuccess, setAssignedSuccess] = useState(false);
  const [reAsignDriver, setReAsignDriver] = useState(false);
  const [driverAlreadyAssigned, setDriverAlreadyAssigned] = useState(false);
  const [allAssignedDrivers, setAllAssignedDrivers] = useState([]);
  const [assignedDriversWithImg, setAssignedDriversWithImg] = useState([]);
  const [vehicleTaken, setVehicleTaken] = useState(false);
  const {
    setDriver,
    setNewDeals,
    setShowFleet,
    setShowRoutes,
    setShowAddVehicle,
    setShowAssign,
    setAssignPanel,
    assignPanel,
    showAssignPanel,
    setShowAssignPanel,
    userUid,
  } = useStateContext();
  const {
    driverPanel,
    setDriverPanel,
    vehiclePanel,
    setVehiclePanel,
    trailerPanel,
    setTrailerPanel,
    driverAssigned,
    setDriverAssigned,
    vehicleAssigned,
    setVehicleAssigned,
    trailersAssigned,
    setTrailersAssigned,
    trailerPanelSecond,
    setTrailerPanelSecond,
    secondTrailersAssigned,
    setSecondTrailersAssigned,
    allTheAssignedDrivers,
    setAllTheAssignedDrivers,
  } = useFleetStateContext();

  const handleAssignCheck = () => {
    if (driverAssigned.horse_id || driverAssigned.trailer_id) {
      setDriverAlreadyAssigned(true);
    } else {
      handleAssign();
    }
  };

  const handleAssign = async () => {
    await firebase.database().ref('/drivers/' + driverAssigned.uid).update({
      horse_id: vehicleAssigned.licence_number,
      trailer_id: {
        0: trailersAssigned.licence_number,
        1: secondTrailersAssigned?.licence_number || '',
      },
    });
    await firebase
      .database()
      .ref('/fleets/' + userUid)
      .child('/' + 'trailers' + '/' + trailersAssigned.licence_number)
      .update({
        horse_id: vehicleAssigned.licence_number,
      });
    setAssignedSuccess(true);
    setTimeout(() => {
      setAssignedSuccess(false);
    }, 4000);
  };

  useEffect(() => {
    if (vehicleAssigned.driver_id) {
      setVehicleTaken(true);
      setTimeout(() => {
        setVehicleTaken(false);
      }, 4000);
    }
  }, []);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .database()
          .ref('/drivers/')
          .on('value', (snapshot) => {
            // const assignedFisrtFilter = (Object.values(snapshot.val())).filter((item) => item.fleet === userUid)
            const assigned = Object.values(snapshot.val()).filter(
              (driver) => driver.horse_id && driver.fleet === user.uid
            );
            console.log('filter data', assigned);
            assigned.map((driver) => {
              firebase
                .database()
                .ref('/fleets/' + userUid)
                .child('/' + 'horses' + '/' + driver.horse_id)
                .on('value', (snapshot) => {
                  driver.vehicle_photo = snapshot.val()?.photo_uri;
                });
              const alltrailersPhotos = driver.trailer_id;
              const photos = [];
              alltrailersPhotos?.map((item) => {
                firebase
                  .database()
                  .ref('/fleets/' + userUid)
                  .child('/' + 'trailers' + '/' + item)
                  .on('value', (snapshot) => {
                    photos.push(snapshot.val()?.photo_uri);
                  });
              });
              driver.trailer_photo = photos;
            });
            setAllTheAssignedDrivers(assigned);
          });
      }
    });
  }, []);

  useEffect(() => {
    //  reset everything
    setSecondTrailersAssigned([]);
    setTrailersAssigned([]);
    setVehicleAssigned([]);
    setDriverAssigned([]);
    setTrailerPanel(false);
    setTrailerPanelSecond(false);
    setDriverPanel(false);
    setVehiclePanel(false);
    setDriverAlreadyAssigned(false);
  }, []);

  return (
    <div className="flex justify-between assign-mobile items-center scrollbar-hide relative" style={{ height: '80vh', maxHeight: '80vh', overflow: 'scroll' }}>
      <div className="scrollbar-hide assign-mobile-first-div p-8" style={{ width: '70%', height: '100%', overflow: 'scroll' }}>
        <div className="flex items-center w-full">
          <p
            className="text-2xl font-bold cursor-pointer"
            onClick={() => {
              setDriver(false);
              setNewDeals(false);
              setShowFleet(true);
              setShowRoutes(false);
              setShowAddVehicle(false);
              setShowAssign(false);
            }}
          >
            <TbChevronLeft />
          </p>
          <p className="font-bold" style={{ fontSize: '1rem' }}>
            Assign
          </p>
        </div>
        <div className="flex assign-panel-mobile h-full justify-center items-center flex-col" style={{ padding: '7rem' }}>
          <div className="flex w-full justify-between items-center">
            <div
              className="driver-mobile-assign rounded-xl p-3 flex items-center cursor-pointer"
              style={{ width: '48%', background: '#fff' }}
              onClick={() => {
                setShowAssignPanel(true);
                setDriverPanel(true);
                setVehiclePanel(false);
                setTrailerPanel(false);
              }}
            >
              <div className="rounded-full flex justify-center items-center" style={{ width: '60px', height: '60px', background: '#e3e3e3' }}>
                {driverAssigned.length !== 0 ? (
                  <img className="w-full h-full rounded-full" style={{ objectFit: 'cover' }} src={driverAssigned?.photo_uri} alt="" />
                ) : (
                  <p className="" style={{ color: '#fff', fontSize: '2.6rem' }}>
                    <ImUser />
                  </p>
                )}
              </div>
              {driverAssigned.length !== 0 ? (
                <div className="pl-4">
                  <p className="font-bold" style={{ fontSize: '1rem' }}>
                    {driverAssigned.name} {driverAssigned.surname}
                  </p>
                  <p className="" style={{ color: '#727272', fontSize: '.9rem' }}>
                    {driverAssigned.phone}
                  </p>
                </div>
              ) : (
                <div className="pl-4 font-bold" style={{ fontSize: '1.3rem' }}>
                  Driver
                </div>
              )}
            </div>
            <div
              className="rounded-xl p-3 flex items-center cursor-pointer"
              style={{ width: '48%', background: '#fff' }}
              onClick={() => {
                setShowAssignPanel(true);
                setDriverPanel(false);
                setVehiclePanel(true);
                setTrailerPanel(false);
              }}
            >
              <div className="rounded-full flex justify-center items-center" style={{ width: '60px', height: '60px', background: '#e3e3e3' }}>
                {vehicleAssigned.length !== 0 ? (
                  <img className="w-full h-full rounded-full" style={{ objectFit: 'cover' }} src={vehicleAssigned.photo_uri} alt="" />
                ) : (
                  <p className="" style={{ color: '#fff', fontSize: '2.6rem' }}>
                    <RiSteering2Fill />
                  </p>
                )}
              </div>
              {vehicleAssigned.length !== 0 ? (
                <div className="pl-4">
                  <p className="font-bold" style={{ fontSize: '1rem' }}>
                    {vehicleAssigned.year} {vehicleAssigned.model} {vehicleAssigned.make}
                  </p>
                  <p className="" style={{ color: '#727272', fontSize: '.9rem' }}>
                    {vehicleAssigned.licence_number}
                  </p>
                </div>
              ) : (
                <div className="pl-4 font-bold" style={{ fontSize: '1.3rem' }}>
                  Vehicle
                </div>
              )}
            </div>
          </div>
          <div
            className="flex w-full pt-5 justify-between cursor-pointer items-center"
            onClick={() => {
              setShowAssignPanel(true);
              setDriverPanel(false);
              setVehiclePanel(false);
              setTrailerPanel(true);
            }}
          >
            <div className="driver-mobile-assign rounded-xl p-3 flex items-center" style={{ width: '48%', background: '#fff' }}>
              <div className="rounded-full flex justify-center items-center" style={{ width: '60px', height: '60px', background: '#e3e3e3' }}>
                {trailersAssigned.length !== 0 ? (
                  <img className="w-full h-full rounded-full" style={{ objectFit: 'cover' }} src={trailersAssigned.photo_uri} alt="" />
                ) : (
                  <p className="" style={{ color: '#fff', fontSize: '2.6rem' }}>
                    <FaTrailer />
                  </p>
                )}
              </div>
              {trailersAssigned.length !== 0 ? (
                <div className="pl-4">
                  <p className="font-bold" style={{ fontSize: '1rem' }}>
                    {trailersAssigned.year} {trailersAssigned.model} {trailersAssigned.make}
                  </p>
                  <p className="" style={{ color: '#727272', fontSize: '.9rem' }}>
                    {trailersAssigned.licence_number}
                  </p>
                </div>
              ) : (
                <div className="pl-4 font-bold" style={{ fontSize: '1.3rem' }}>
                  Trailer
                </div>
              )}
            </div>
            <div
              className="rounded-xl p-3 flex items-center cursor-pointer"
              style={{ width: '48%', background: '#fff' }}
              onClick={() => {
                setShowAssignPanel(true);
                setDriverPanel(false);
                setVehiclePanel(false);
                setTrailerPanel(false);
                setTrailerPanelSecond(true);
              }}
            >
              <div className="rounded-full flex justify-center items-center" style={{ width: '60px', height: '60px', background: '#e3e3e3' }}>
                {secondTrailersAssigned.length > 0 ? (
                  <img className="w-full h-full rounded-full" style={{ objectFit: 'cover' }} src={secondTrailersAssigned.photo_uri} alt="" />
                ) : (
                  <p className="" style={{ color: '#fff', fontSize: '2.6rem' }}>
                    <FaTrailer />
                  </p>
                )}
              </div>
              {secondTrailersAssigned.length > 0 ? (
                <div className="pl-4">
                  <p className="font-bold" style={{ fontSize: '1rem' }}>
                    {secondTrailersAssigned.year} {secondTrailersAssigned.model} {secondTrailersAssigned.make}
                  </p>
                  <p className="" style={{ color: '#727272', fontSize: '.9rem' }}>
                    {secondTrailersAssigned.licence_number}
                  </p>
                </div>
              ) : (
                <div className="pl-4 font-bold" style={{ fontSize: '1.3rem' }}>
                  Trailer 2
                </div>
              )}
            </div>
          </div>
          <div className="pt-7 w-full flex justify-center items-center" disabled={driverAlreadyAssigned ? true : false}>
            <button
              className="px-3 py-1 rounded-xl"
              style={{ background: '#fce200', width: '40%' }}
              onClick={handleAssignCheck}
            >
              Assign
            </button>
          </div>
        </div>
      </div>
      <div className="scrollbar-hide assigned-drivers-mobile pt-5 pb-5 pr-5" style={{ width: '30%', height: '100%', overflow: 'scroll' }}>
        <div className="w-full h-full rounded-xl" style={{ background: '#fff' }}>
          <div className="p-4 w-full" style={{ borderBottom: '1px solid grey' }}>
            <p>Assigned Drivers</p>
          </div>

          {allTheAssignedDrivers?.length > 0 &&
            allTheAssignedDrivers?.map((driver, i) => (
              <div key={i} className="p-4 py-1 w-full flex" style={{ borderBottom: '1px solid grey' }}>
                <div className="rounded-full relative" style={{ background: '#e3e3e3', height: '3rem', width: '3rem' }}>
                  {driver.photo_uri ? (
                    <img className="rounded-full w-full h-full" style={{ objectFit: 'cover' }} src={driver.photo_uri} alt="" />
                  ) : (
                    <p className="h-full w-full flex items-center justify-center m-0 p-0" style={{ fontSize: '1.9rem', color: '#fff' }}>
                      <ImUser />
                    </p>
                  )}

                  <div className="absolute w-5 h-5 bottom-0 right-0 rounded-full bg-white" style={{ zIndex: '2' }}>
                    <img
                      className="rounded-full w-full h-full"
                      style={{ objectFit: 'cover' }}
                      src={driver?.vehicle_photo}
                      alt=""
                    />
                  </div>
                  <div className="absolute w-5 h-5 top-7 left-10 rounded-full bg-gray-300" style={{ zIndex: '1' }}>
                    <img
                      className="rounded-full w-full h-full"
                      style={{ objectFit: 'cover' }}
                      src={driver.trailer_photo !== undefined ? driver.trailer_photo[0] : ''}
                      alt=""
                    />
                  </div>
                  <div className="absolute w-4 h-4 top-7 rounded-full bg-yellow-300" style={{ left: '3.3rem' }}>
                    <img
                      className="rounded-full w-full h-full"
                      style={{ objectFit: 'cover' }}
                      src={driver.trailer_photo !== undefined ? driver.trailer_photo[1] : ''}
                      alt=""
                    />
                  </div>
                </div>
                <div className="pl-2 pt-2">
                  <p className="font-bold" style={{ fontSize: '1rem' }}>
                    {driver.name} {driver.surname}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
      {driverAlreadyAssigned && (
        <div
          className="p-4 flex justify-center flex-col items-center rounded-xl absolute h-full right-0 bottom-0"
          style={{ width: '263px', height: 'fit-content', background: '#fff', top: '50%', left: '50%' }}
        >
          <p style={{ fontSize: '3rem', color: '#000' }}>
            <RiErrorWarningLine />
          </p>
          <p className="font-bold" style={{ fontSize: '1rem' }}>
            Driver Already Assigned
          </p>
          <p style={{ textAlign: 'center' }}>Would you like to choose a different vehicle and trailer for the driver?</p>
          <div className="flex mt-2 justify-between" style={{ width: '8rem' }}>
            <button
              className="p-4 py-1 rounded-xl"
              style={{ background: 'rgb(252, 226, 0)' }}
              onClick={() => {
                handleAssign();
                setDriverAlreadyAssigned(false);
              }}
            >
              Yes
            </button>
            <button
              className="p-4 py-1 rounded-xl"
              style={{ background: 'rgb(252, 226, 0)' }}
              onClick={() => {
                setSecondTrailersAssigned([]);
                setTrailersAssigned([]);
                setVehicleAssigned([]);
                setDriverAssigned([]);
                setTrailerPanel(false);
                setTrailerPanelSecond(false);
                setDriverPanel(false);
                setVehiclePanel(false);
                setDriverAlreadyAssigned(false);
              }}
            >
              No
            </button>
          </div>
        </div>
      )}

      {showAssignPanel && <AssignSelectPanel />}

      {assignedSuccess && (
        <div
          className="p-4 flex justify-center items-center rounded-xl absolute h-full w-full right-0 bottom-0"
          style={{ width: 'fit-content', height: 'fit-content', background: '#fff', top: '50%', left: '50%' }}
        >
          <p style={{ fontSize: '3rem', color: '#000' }}>
            <IoCheckmarkCircleOutline />
          </p>
          <p className="font-bold" style={{ fontSize: '1rem' }}>
            Driver connected to vehicle
          </p>
        </div>
      )}

      {vehicleTaken && (
        <div
          className="p-4 flex justify-center flex-col items-center rounded-xl absolute h-full w-full right-0 bottom-0"
          style={{ width: 'fit-content', height: 'fit-content', background: '#fff', top: '50%', left: '50%' }}
        >
          <p style={{ fontSize: '3rem', color: '#000' }}>
            <RiErrorWarningLine />
          </p>
          <p className="font-bold" style={{ fontSize: '1rem' }}>
            Vehicle Already taken please choose another one
          </p>
        </div>
      )}
    </div>
  );
}
