import React, {useEffect} from 'react'
import { TbChevronLeft } from 'react-icons/tb'
import {AiOutlineArrowRight} from 'react-icons/ai'
import { lineHeight } from '@mui/system'
import DealRequests from './DealRequests'
import DealSettlements from './DealSettlements'
import DealBooking from './DealBooking'
import { BiFilter } from 'react-icons/bi'
import {useFleetStateContext} from '../context/FleetDealsContext'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { FiAlertOctagon } from 'react-icons/fi'
import { TfiDropbox } from 'react-icons/tfi'
import { AiOutlinePlus } from 'react-icons/ai'
import AddDriverFormMod from './AddDriverFormMod'
import { useStateContext } from '../context/DashboardStateContext'
import VehicleDetails from './VehicleDetails'
import AddVehicleFormMod from './AddVehicleFormMod'
import firebase from '../firebase-config'
import emptyIcon from '../icons/box.png'
import { ImUser } from 'react-icons/im'

export default function AddVehicle() {
    const {
        addVehiclesData, 
        setAddVehiclesData,
        setSelectVehicle,
        selectVehicle
      } = useFleetStateContext();
      
      const {
        driver,
        setDriver, 
        setNewDeals,
        setShowFleet,
        setShowRoutes,
        ShowVehicleDetails, 
        setShowVehicleDetails,
        setShowAddVehicle,
        showAddVMod, 
        setShowAddVMod,
        userUid,
        showAssign, 
        setShowAssign
    } = useStateContext();

    console.log("this is the data", addVehiclesData)

    useEffect(() => {
      firebase.database().ref('/fleets/' + userUid + "/horses").on('value', (snapshot) => {
          if(snapshot.exists()){
              setAddVehiclesData(Object?.values(snapshot.val()));
          }
      });
  }, [])

  console.log("all the vehicle", addVehiclesData)

  return (
    <div className='new-deals-page flex justify-between relative' style={{height:"80vh", maxHeight:"80vh"}}>
        <div 
          className='h-fit vehicle-mobile rounded-lg p-4 scrollbar-hide relative'  
          style={{
              width:"30%", 
              background:"#dadada", 
              maxheight:"574px",
              height:"100%",
              overflow:"hiden"
          }}
        >
          <div className='flex items-center'>
            <p className='text-2xl font-bold cursor-pointer' onClick={() => {
              setDriver(false)
              setNewDeals(false)
              setShowFleet(true)
              setShowRoutes(false)
              setShowAddVehicle(false)
              setShowAssign(false)
            }}><TbChevronLeft/></p>
            <p className='font-bold' style={{fontSize:"1rem"}}>Vehicles</p>
          </div>
          <div className='flex mt-3 w-full justify-between' style={{paddingBottom: "10px"}}>
            <input type="text" className='h-8 px-3 rounded-lg border-transparent' 
                placeholder='Search' 
                style={{width:"85%", background:"rgb(207 207 207)", color:"#000"}} 
            />
            <p className='ml-2 text-2xl flex justify-center items-center' style={{width:"15%"}}><BiFilter/></p>
          </div>

          <div className="scrollbar-hide" style={{height:"515px", maxHeight:"515px", overflow:"scroll"}}>
          {addVehiclesData.length > 0 ? addVehiclesData.map((vehicle) => (
            <>
            <div 
              className='mt-3 mb-2 h-fit cursor-pointer w-full bg-white rounded-lg px-3 py-1'
              onClick={() => setSelectVehicle([vehicle])}
            >
                <div className='flex justify-between'>
                    <div className='flex items-center'>
                        <p className='w-9 h-9 m-0 p-0 rounded-full' style={{background:"#dadada"}}>
                          {vehicle.photo_uri ?
                            <img className='w-full h-full rounded-full' style={{objectFit:"cover"}} src={vehicle.photo_uri} alt="vehicle image" />
                            :<p className='h-full w-full flex items-center justify-center m-0 p-0' style={{fontSize:"1.5rem", color:"#fff"}}><ImUser/></p>
                          }
                        </p>
                        
                        <div className='ml-2'>
                            <p className='m-0 p-0 font-bold text-sm'>{vehicle?.year} {vehicle?.make} {vehicle?.model}</p>
                            <p className='m-0 p-0 text-gray-500'>{vehicle?.licence_number}</p>
                        </div>
                    </div>
                    <div className='flex items-center'>
                        <p><AiOutlineClockCircle/></p>
                    </div>
                </div>
            </div>
            </>
           )): 
            <div className='mt-4 flex justify-center items-center flex-col'>
                <p style={{fontSize:"4rem", color:"#a7a7a7"}}><TfiDropbox/></p>
                <p className='text-lg' style={{color:"grey"}}>Add vehicle</p>
            </div>

          } 
          </div>
        <p 
            className='rounded-full cursor-pointer absolute flex justify-center items-center' 
            onClick={() => {
              setShowAddVMod(true)
            }}
            style={{
                fontSize:"2rem", 
                background:"#fce200",
                width:"48px",
                height:"48px",
                bottom:".6rem",
                right:".6rem"
            }}><AiOutlinePlus/></p>
        </div>
        {selectVehicle.length ? 
          <VehicleDetails/>
        :
          <div className='dash-date' style={{
            width:"68%", 
            height:"100%",
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            flexDirection:"column",
            top:"30%",
            textAlign:"center",
            fontSize:"1rem"
          }}>
            <img src={emptyIcon} width={100} height={100} style={{marginBottom:"3rem"}}/>
            Please select a vehicle on the left to see it's details.
          </div>
        }
        {showAddVMod &&
            <AddVehicleFormMod/>
        }
    </div>
  )
}
