import React, {useState, useEffect, useRef} from 'react'
import firebase from '../firebase-config'
import { FiUpload } from "react-icons/fi";
import { FaPen } from "react-icons/fa";
import { useStateContext } from '../context/DashboardStateContext'

export default function FleetSection({
    setTabText,
    tabText,
    aboutYouValuesFleet,
    isAboutYouUpdateClickedFleet,
    setAboutYouValuesFleet,
    aboutYouValues,
    userUid,
    setCompanyUpdatedFleet,
    companyValuesFleet,
    isCompanyUpdateClickedFleet,
    setIsCompanyUpdateClickedFleet,
    heavyVehicles,
    setHeavyVehicles,
    southProvince,
    setSouthernCountries,
    setIsAboutYouUpdateClickedFleet,
    setAboutYouUpdatedFleet,
    aboutYouUpdatedFleet,
    setCompanyValuesFleet,
    companyUpdatedFleet,
    isContactUpdateClicked,
    contactValuesFleet,
    setIsContactUpdateClickedFleet,
    setcontactValuesFleet,
    contactUpdatedFleet,
    BsCheck,
    setContactUpdatedFleet,
    fleet,
    setIsVehicleUpdateClicked,
    vehicleUpdateClicked,
    setSouthProvince,
    southernCountries,
    updateAreas,
    // isSouthProvinces,
    setContactUpdated
}) {

  const [archiveOut, setArchiveOut] = useState("Outstanding")
  const [cipcUrl, setCipcUrl] = useState("")
  const [cipcFile,setCipcFile] = useState(null)

  const [sameCompanyEmail,setSameCompanyEmail] = useState(false)

  const [vatUploadUrl, setVatUploadUrl] = useState("")
  const [letterUrl, setLetterUrl] = useState("")

  const [directorIdUrl,setDirectorIdUrl] = useState("")
  const [directorImageUrl,setDirectorImageUrl] = useState("")

  const [inputDirector,setInputDirector] =useState(false)
  const [directorAdded, setDirectorAdded] = useState(false);
  const [uploadedinvoice, setUploadedinvoice] = useState(false);

  const [bookingAccepted,setBookingAccepted] = useState([])
  const [inputBanking,setInputBanking] = useState(false)
  const [bankAdded,setBankAdded] = useState(false)
  const [bookingPartyData,setBookingPartyData] = useState([])
  const [invoiceUrl,setInvoiceUrl] = useState("")
  const [bookingUid, setBookingUid] = useState("");

  const [fleetBookingData, setFleetBookingData] = useState([])

  const fileInputRef = useRef(null);
  const fileInputRefTwo = useRef(null);
  const fileInputRefThree = useRef(null);
  const fileInputRefFour = useRef(null);
  const fileInputRefFive = useRef(null);
  const fileInputRefSix = useRef(null);
  const fileInputRefSeven = useRef(null);

  const {
    setIsShipperUpload,
    setSelectedBookInvoice,
    setIsFleetOwnerUpload,
    setUniversalSelectedBook,
    setIsBookingSummary
  } = useStateContext();

  const [director, setDirector] = useState({
    first_name:"",
    last_name:"",
    email:"",
    identity_number:"",
    Id_copy_url:"",
    picture_url:"",
  })

  const [banking, setBanking] = useState({
    bank_name:"",
    branch_name:"",
    branch_code:"",
    account_number:"",
    bank_letter_url:"",
  })

  const addDirector = () => {
    if (director.first_name && director.last_name && director.email && director.identity_number) {
      const newDirector = {
        first_name: director.first_name,
        last_name: director.last_name,
        email: director.email,
        identity_number: director.identity_number,
        Id_copy_url: directorIdUrl,
        picture_url: directorImageUrl,
      };

      const directorsRef = firebase.database().ref(`/fleets/${userUid}/directors`);

      directorsRef.push(newDirector)
        .then(() => {
          setDirectorAdded(true);
          setTimeout(() => {
            setDirectorAdded(false);
          }, 2000);

          // Reset the director state after successfully adding the director
          setDirector({
            first_name: "",
            last_name: "",
            email: "",
            identity_number: "",
            Id_copy_url: "",
            picture_url: "",
          });
        })
        .catch((error) => {
          console.error('Error adding director:', error);
        });
    } else {
      setInputDirector(true); // Trigger the input field error message if fields are incomplete
    }
  };

  const addBanking = () => {
    if (banking.bank_name && banking.branch_name && banking.branch_code && banking.account_number) {
      const bankingRef = firebase.database().ref(`/fleets/${userUid}/banking`);
      bankingRef.set(banking)
        .then(() => {
          setBankAdded(true);
          setTimeout(() => {
            setBankAdded(false);
          }, 2000);
          // Reset the director state after successfully adding the director
          setBanking({
            bank_name:"",
            branch_name:"",
            branch_code:"",
            account_number:"",
            bank_letter_url:"",
          });
        })
        .catch((error) => {
          console.error('Error adding banking:', error);
        });
    } else {
      setInputBanking(true); // Trigger the input field error message if fields are incomplete
    }
  };

  //for company details
  const handleCipcUpload = (file) => {
    const storage = firebase.storage()
    if(file === null) return
    const fileRefId = storage.ref(`files/${userUid}/cipc`)
    // setIdUpload(file.name)
    fileRefId.put(file).then((snapshot) => {
        const fileUrl = fileRefId.getDownloadURL()
        .then((url) => {
          setCipcUrl(url)
          fileInputRef.current.value = null;
        })
        .catch((error) => {
        });
    });
  }

  const handleVatUpload =  (file) => {
    const storage = firebase.storage()
    if(file === null) return
    const fileRefId = storage.ref(`files/${userUid}/VatUpload`)
    // setIdUpload(file.name)
    fileRefId.put(file).then((snapshot) => {
        const fileUrl = fileRefId.getDownloadURL()
        .then((url) => {
          setVatUploadUrl(url)
          fileInputRefTwo.current.value = null;
        })
        .catch((error) => {
        });
    });
  };

  const handleLetterUpload =  (file) => {
    const storage = firebase.storage()
    if(file === null) return
    const fileRefId = storage.ref(`files/${userUid}/VatUpload`)
    // setIdUpload(file.name)
    fileRefId.put(file).then((snapshot) => {
        const fileUrl = fileRefId.getDownloadURL()
        .then((url) => {
          setLetterUrl(url)
          fileInputRefThree.current.value = null;
        })
        .catch((error) => {
        });
    });
  };

  //for directors
  const handleDirectorIdUpload = (file) => {
    console.log("the file", file)
    const storage = firebase.storage()
    if(file === null) return
    const fileRefId = storage.ref(`files/${userUid}/directorId`)
    // setIdUpload(file.name)
    fileRefId.put(file).then((snapshot) => {
        const fileUrl = fileRefId.getDownloadURL()
        .then((url) => {
          setDirectorIdUrl(url)
          setDirector((prev) => ({
            ...prev,
            Id_copy_url: url
          }))
          fileInputRefFour.current.value = null;
        })
        .catch((error) => {
        });
    });
  }

  const handleDirectorImageUpload = (file) => {
    const storage = firebase.storage()
    if(file === null) return
    const fileRefId = storage.ref(`files/${userUid}/directorImg`)
    // setIdUpload(file.name)
    fileRefId.put(file).then((snapshot) => {
        const fileUrl = fileRefId.getDownloadURL()
        .then((url) => {
          setDirectorImageUrl(url)
          setDirector((prev) => ({
            ...prev,
            picture_url: fileUrl
          }))
          fileInputRefFive.current.value = null;
        })
        .catch((error) => {
        });
    });
  }

  //for banking
  const handleBankLetterUpload = (file) => {
    const storage = firebase.storage()
    if(file === null) return
    const fileRefId = storage.ref(`files/${userUid}/bankLetter`)
    // setIdUpload(file.name)
    fileRefId.put(file).then((snapshot) => {
        const fileUrl = fileRefId.getDownloadURL()
        .then((url) => {
          setBanking((prev) => ({
            ...prev,
            bank_letter_url: fileUrl
          }))
          fileInputRefSix.current.value = null;
        })
        .catch((error) => {
        });
    });
  }

   //for uploading invoice
  //  const handleReceiptUpload = async (file) => {
  //   setUploadedinvoice(true);
  //   if (!file) return;
  //   try {
  //     const storage = firebase.storage();
  //     const fileRefId = storage.ref(`files/${userUid}/invoice/${bookingUid}`);
  //     const snapshot = await fileRefId.put(file);
  //     const url = await snapshot.ref.getDownloadURL();

  //     if (url) {
  //       await firebase
  //         .database()
  //         .ref("booking")
  //         .child(bookingUid)
  //         .update({ receipt_url: url });

  //       setInvoiceUrl(url);
  //       fileInputRefSeven.current.value = null;
  //       setTimeout(() => {
  //         setUploadedinvoice(false)
  //       }, 2000)
  //     }
  //   } catch (error) {
  //     console.error("Error uploading invoice:", error);
  //   }
  // };

  const [myRenamedSetDirectors, setMyRenamedDirectors] = useState([]);

  useEffect(() => {
      const directorsSnapshot = firebase.database().ref(`/fleets/${userUid}/directors`).on('value', snapshot =>{
        if (snapshot.exists()) {
          const directorsArray = Object.values(snapshot.val());
          setMyRenamedDirectors(directorsArray);
        } else {
          setMyRenamedDirectors([]);
        }
      });
  }, []);

  useEffect(() => {
    firebase.database().ref(`/fleets/${userUid}/bookings`).on('value', snapshot => {
      if (snapshot.exists()) {
        const fleetBooking = Object.values(snapshot.val());
        const bookingPartyData = [];
        const bookingOfBooking = [];

        const promises = fleetBooking.map(booking => {
          return new Promise((resolve, reject) => {
            firebase.database().ref("booking").child(booking?.booking_id).on("value", bookingSnapshot => {
              const bookingData = bookingSnapshot.val();
              // Fetch details of the booking party
              firebase.database().ref("booking_party").child(bookingData?.booking_party_uid).on("value", partySnapshot => {
                const partyData = partySnapshot.val();
                bookingPartyData.push(partyData);
                bookingOfBooking.push(bookingData);
                resolve({
                  booking: bookingData,
                  bookingParty: partyData
                });
              });
            });
          });
        });

        Promise.all(promises)
          .then(data => {
            setFleetBookingData(bookingOfBooking);
            setBookingAccepted(fleetBooking);
            setBookingPartyData(bookingPartyData); // Store booking party data in state
          })
          .catch(error => {
            // Handle error if any of the promises fails
            console.error("Error fetching booking details:", error);
          });
      }
    });
  }, []);

  return (
      <div
          style={{
            height:"60vh",
            width:"100%",
            padding: "3rem 3.6rem",
          }}
        >
        <div
          className='flex'
          style={{
            width:"100%",
          }}
        >
          <div
            className='relative cursor-pointer'
            onClick={() => {
              setTabText("about you")
            }}
          >
            {tabText === "about you" &&
              <p className='absolute' style={{bottom:"100%"}}>You are here</p>
            }
            <p className='mr-12 text-[black]' style={{fontSize:"1.2rem", fontWeight:tabText === "about you" ? "bold" : "200"}}>About you</p>
          </div>

          <div
            className='relative cursor-pointer'
            onClick={() => {
              setTabText("company")
            }}
          >
            {tabText === "company" &&
              <p className='absolute' style={{bottom:"100%"}}>You are here</p>
            }
            <p className='mr-12 text-[black]' style={{fontSize:"1.2rem", fontWeight:tabText === "company" ? "bold" : "200"}}>Company details</p>
          </div>
          <div
            className='relative cursor-pointer'
            onClick={() => {
              setTabText("contact details")
            }}
          >
            {tabText === "contact details" &&
              <p className='absolute' style={{bottom:"100%"}}>You are here</p>
            }
            <p className='mr-12 text-[black]' style={{fontSize:"1.2rem", fontWeight:tabText === "contact details" ? "bold" : "200"}}>Contact details</p>
          </div>
          <div
            className='relative cursor-pointer'
            onClick={() => {
              setTabText("directors")
            }}
          >
            {tabText === "directors" &&
              <p className='absolute' style={{bottom:"100%"}}>You are here</p>
            }
            <p className='mr-12 text-[black]' style={{fontSize:"1.2rem", fontWeight:tabText === "directors" ? "bold" : "200"}}>Directors</p>
          </div>

          <div
            className='relative cursor-pointer'
            onClick={() => {
              setTabText("banking")
            }}
          >
            {tabText === "banking" &&
              <p className='absolute' style={{bottom:"100%"}}>You are here</p>
            }
            <p className='mr-12 text-[black]' style={{fontSize:"1.2rem", fontWeight:tabText === "banking" ? "bold" : "200"}}>Banking</p>
          </div>

          <div
            className='relative cursor-pointer'
            onClick={() => {
              setTabText("payment")
            }}
          >
            {tabText === "payment" &&
              <p className='absolute' style={{bottom:"100%"}}>You are here</p>
            }
            <p className='font-bold mr-12' style={{fontSize:"1.2rem", fontWeight:tabText === "payment" ? "bold" : "200"}}>Payment</p>
          </div>
        </div>

        {tabText === "about you" && (
          <div className='w-full mt-5'>
            <div style={{ width: "30%", height: "fit-content" }}>
              <div className='w-full mt-3'>
                <input
                  type="text"
                  className=''
                  value={aboutYouValuesFleet.name}
                  placeholder='First Name'
                  onChange={(e) => {
                    if (!isAboutYouUpdateClickedFleet) {
                      setAboutYouValuesFleet({
                        ...aboutYouValuesFleet,
                        name: e.target.value
                      });
                    }
                  }}
                  style={{
                    width: "100%",
                    borderRadius: "15px",
                    padding: ".3rem 1rem",
                    background:"rgb(232 232 232)",
                    color:"#848484"
                  }}
                />
              </div>
              <div className='w-full mt-2'>
                {/* <p>Last Name</p> */}
                <input
                  type="text"
                  className=''
                  placeholder='Last Name'
                  value={aboutYouValuesFleet.lastName}
                  onChange={(e) => {
                    if (!isAboutYouUpdateClickedFleet) {
                      setAboutYouValuesFleet({
                        ...aboutYouValuesFleet,
                        lastName: e.target.value
                      });
                    }
                  }}
                  style={{
                    width: "100%",
                    borderRadius: "15px",
                    padding: ".3rem 1rem",
                    background:"rgb(232 232 232)",
                    color:"#848484"
                  }}
                />
              </div>
              <div className='w-full mt-2'>
                <input
                  type="text"
                  className=''
                  placeholder='Email'
                  value={aboutYouValuesFleet.email}
                  onChange={(e) => {
                    if (!isAboutYouUpdateClickedFleet) {
                      setAboutYouValuesFleet({
                        ...aboutYouValuesFleet,
                        email: e.target.value
                      });
                    }
                  }}
                  style={{
                    width: "100%",
                    borderRadius: "15px",
                    padding: ".3rem 1rem",
                    background:"rgb(232 232 232)",
                    color:"#848484"
                  }}
                />
              </div>
              <div className='w-full mt-2'>
                <input
                  type="text"
                  className=''
                  placeholder='Telephone'
                  value={aboutYouValuesFleet.Telephone}
                  onChange={(e) => {
                    if (!isAboutYouUpdateClickedFleet) {
                      setAboutYouValuesFleet({
                        ...aboutYouValuesFleet,
                        Telephone: e.target.value
                      });
                    }
                  }}
                  style={{
                    width: "100%",
                    borderRadius: "15px",
                    padding: ".3rem 1rem",
                    background:"rgb(232 232 232)",
                    color:"#848484"
                  }}
                />
              </div>
            </div>
            <div className='w-full'>
              <div className='flex mt-3 w-full justify-end '>
                <button
                  className='px-8 py-2 font-bold  text-[1.1rem]'
                  style={{ borderRadius: "20px", background: "#fce200" }}
                  onClick={() => {
                    setIsAboutYouUpdateClickedFleet(true);
                    const updatedData = {
                      name: aboutYouValuesFleet.name,
                      lastName: aboutYouValuesFleet.lastName,
                      email: aboutYouValuesFleet.email,
                      Telephone: aboutYouValuesFleet.Telephone,
                      // ... (other fields)
                    };
                    firebase.database().ref("/fleets/" + userUid).update(updatedData).then(() => {
                      setAboutYouUpdatedFleet(true);
                      setTimeout(() => {
                        setAboutYouUpdatedFleet(false);
                        setIsAboutYouUpdateClickedFleet(false);
                      }, 2000);
                    });
                  }}
                >
                  Next
                </button>
              </div>
              <div className='w-full my-3'>
                <p>*We would appreciate it if you would take the necessary steps to keep all provided information accurate and up-to-date by notifying us of any changes or updating your profile accordingly.</p>
              </div>
            </div>
            {aboutYouUpdatedFleet && (
              <div className='p-5 rounded-xl absolute flex items-center justify-center flex-col'
                style={{
                  width: "244px",
                  height: "fit-content",
                  background: "#fff",
                  top: "50%",
                  right: "50%",
                  transform: "translate(-50%, -50%)"
                }}>
                <p style={{ fontSize: "1rem", textAlign: "center", marginBottom: "1rem" }}>About you updated successfully</p>
              </div>
            )}
          </div>
        )}

        {tabText === "company" && (
          <div className='w-full mt-5'>
            <div style={{ width: "100%", height: "fit-content" }}>
              <div className='mb-4 w-[25%]'>
                <p className='text-[.7rem]'>
                    Your company details are vital to the system as they help us
                    identify who you are. Let's get to know you and your company a
                    bit more.
                </p>
              </div>
              <div className='flex justify-between w-[100%]'>
                <div className=' w-[30%]'>

                  <div className='w-full'>
                    <input
                      type="text"
                      className=''
                      placeholder='Company Name'
                      value={companyValuesFleet.fleet_name}
                      onChange={(e) => {
                        if (!isCompanyUpdateClickedFleet) {
                          setCompanyValuesFleet({
                            ...companyValuesFleet,
                            fleet_name: e.target.value
                          });
                        }
                      }}
                      style={{
                        width: "100%",
                        borderRadius: "15px",
                        padding: ".3rem 1rem",
                        background:"rgb(232 232 232)",
                        color:"#848484"
                      }}
                    />
                  </div>

                  <div className='w-full my-3'>
                    <input
                      type="text"
                      className=''
                      placeholder='Registration Number'
                      value={companyValuesFleet.registration_number}
                      onChange={(e) => {
                        if (!isCompanyUpdateClickedFleet) {
                          setCompanyValuesFleet({
                            ...companyValuesFleet,
                            registration_number: e.target.value
                          });
                        }
                      }}
                      style={{
                        width: "100%",
                        borderRadius: "15px",
                        padding: ".3rem 1rem",
                        background:"rgb(232 232 232)",
                        color:"#848484"
                      }}
                    />
                  </div>

                  <div className='w-full my-3'>
                    <input
                      type="text"
                      className=''
                      placeholder='VAT Number'
                      value={companyValuesFleet.vat_number}
                      onChange={(e) => {
                        if (!isCompanyUpdateClickedFleet) {
                          setCompanyValuesFleet({
                            ...companyValuesFleet,
                            vat_number: e.target.value
                          });
                        }
                      }}
                      style={{
                        width: "100%",
                        borderRadius: "15px",
                        padding: ".3rem 1rem",
                        background:"rgb(232 232 232)",
                        color:"#848484"
                      }}
                    />
                  </div>

                  <div className='w-full my-3'>
                    <input
                      type="text"
                      className=''
                      placeholder='Company Email'
                      value={companyValuesFleet.companyEmail}
                      onChange={(e) => {
                        if (!isCompanyUpdateClickedFleet) {
                          setCompanyValuesFleet({
                            ...companyValuesFleet,
                            companyEmail: e.target.value
                          });
                        }
                      }}
                      style={{
                        width: "100%",
                        borderRadius: "15px",
                        padding: ".3rem 1rem",
                        background:"rgb(232 232 232)",
                        color:"#848484"
                      }}
                    />
                  </div>
                </div>

                <div className='w-[30%]'>
                  <div className='w-full'>
                    <input
                      type="text"
                      className=''
                      placeholder='Complex/ Office Block Number (Optional)'
                      value={companyValuesFleet.complex}
                      onChange={(e) => {
                        if (!isCompanyUpdateClickedFleet) {
                          setCompanyValuesFleet({
                            ...companyValuesFleet,
                            complex: e.target.value
                          });
                        }
                      }}
                      style={{
                        width: "100%",
                        borderRadius: "15px",
                        padding: ".3rem 1rem",
                        background:"rgb(232 232 232)",
                        color:"#848484"
                      }}
                    />
                  </div>

                  <div className='w-full my-3'>
                    <input
                      type="text"
                      className=''
                      placeholder='Physical Address'
                      value={companyValuesFleet.physical_address}
                      onChange={(e) => {
                        if (!isCompanyUpdateClickedFleet) {
                          setCompanyValuesFleet({
                            ...companyValuesFleet,
                            physical_address: e.target.value
                          });
                        }
                      }}
                      style={{
                        width: "100%",
                        borderRadius: "15px",
                        padding: ".3rem 1rem",
                        background:"rgb(232 232 232)",
                        color:"#848484"
                      }}
                    />
                  </div>

                  <div className='flex items-center w-full my-3'>
                    <input
                      type="checkbox"
                      className='mx-4 w-[1rem] h-[1rem]'
                      placeholder='Company Email'
                      onClick={()=>setSameCompanyEmail(!sameCompanyEmail)}
                    />
                    <p className='p-0 m-0'>Postal address same as physical address</p>
                  </div>

                  <div className='w-full my-3'>
                    <input
                      type="text"
                      className=''
                      placeholder='P.O Box'
                      disabled={sameCompanyEmail && true}
                      value={companyValuesFleet.PO_box}
                      onChange={(e) => {
                        if (!isCompanyUpdateClickedFleet) {
                          setCompanyValuesFleet({
                            ...companyValuesFleet,
                            PO_box: e.target.value
                          });
                        }
                      }}
                      style={{
                        width: "100%",
                        borderRadius: "15px",
                        padding: ".3rem 1rem",
                        background:"rgb(232 232 232)",
                        color:"#848484"
                      }}
                    />
                  </div>

                  <div className='w-full my-3'>
                    <input
                      type="text"
                      className=''
                      placeholder='City'
                      value={companyValuesFleet.city}
                      onChange={(e) => {
                        if (!isCompanyUpdateClickedFleet) {
                          setCompanyValuesFleet({
                            ...companyValuesFleet,
                            city: e.target.value
                          });
                        }
                      }}
                      style={{
                        width: "100%",
                        borderRadius: "15px",
                        padding: ".3rem 1rem",
                        background:"rgb(232 232 232)",
                        color:"#848484"
                      }}
                    />
                  </div>
                </div>

                <div className='w-[30%]'>
                  <input
                    type="file"
                    style={{ position: 'absolute', top: 0, left: 0, opacity: 0, zIndex: -1 }}
                    onChange={(e) => handleCipcUpload(e, e.target.files[0])}

                    ref={fileInputRef} // Assigning the ref to the fileInputRef variable
                  />
                    <div
                      className='flex justify-center flex-col items-center rounded-[15px] w-[100%] cursor-pointer h-[70px] bg-[#e8e8e8]'
                      onClick={() => fileInputRef.current.click()} // Click the hidden file input when this div is clicked
                    >
                        <p className='text-[1.3rem] mb-2 text-[#878787]'><FiUpload /></p>
                      <p>Upload CIPC Registration</p>
                    </div>

                  <input
                    type="file"
                    style={{ position: 'absolute', top: 0, left: 0, opacity: 0, zIndex: -1 }}
                    onChange={(e) => handleVatUpload(e.target.files[0])}
                    ref={fileInputRefTwo} // Assigning the ref to the fileInputRef variable
                  />

                  <div
                    className='flex justify-center mt-3 flex-col items-center rounded-[15px] w-[100%] cursor-pointer h-[70px] bg-[#e8e8e8]'
                    onClick={() => fileInputRefTwo.current.click()} // Click the hidden file input when this div is clicked
                  >
                      <p className='text-[1.3rem] mb-2 text-[#878787]'><FiUpload /></p>
                      <p>Upload VAT Certificate</p>
                  </div>

                  <input
                    type="file"
                    style={{ position: 'absolute', top: 0, left: 0, opacity: 0, zIndex: -1 }}
                    onChange={(e) => handleLetterUpload(e.target.files[0])}
                    ref={fileInputRefThree} // Assigning the ref to the fileInputRef variable
                  />

                  <div
                    className='flex justify-center mt-3 flex-col items-center rounded-[15px] w-[100%] cursor-pointer h-[70px] bg-[#e8e8e8]'
                    onClick={() => fileInputRefThree.current.click()} // Click the hidden file input when this div is clicked
                  >
                      <p className='text-[1.3rem] mb-2 text-[#878787]'><FiUpload /></p>
                      <p className='text-center'>Upload Letter of Good Standing (Bank) <br /> Not older than 3 months</p>
                  </div>
                </div>
              </div>

              <div className='flex mt-3 justify-end w-full'>
                <button
                  className='px-8 py-2 font-bold  text-[1.1rem]'
                  style={{ borderRadius: "20px", background: "#fce200" }}
                  onClick={() => {
                    setIsCompanyUpdateClickedFleet(true);
                    const updatedData = {
                      fleet_name: companyValuesFleet?.fleet_name,
                      registration_number: companyValuesFleet?.registration_number,
                      companyEmail: companyValuesFleet?.companyEmail,
                      Telephone: companyValuesFleet?.Telephone,
                      phone: companyValuesFleet?.phone,
                      complex: companyValuesFleet?.complex,
                      physical_address: companyValuesFleet?.physical_address,
                      city: companyValuesFleet?.city,
                      PO_box: companyValuesFleet?.PO_box || "",
                      vat_number: companyValuesFleet?.vat_number || "",
                      cipcUrl: cipcUrl || companyValuesFleet?.cipcUrl || "",
                      vatUploadUrl: vatUploadUrl || contactUpdatedFleet?.vatUploadUrl || "",
                      letterOfGoodStandUrl: letterUrl || contactUpdatedFleet?.letterUrl || ""
                      // Add other fields here...
                    };
                    firebase.database().ref("/fleets/" + userUid).update(updatedData).then(() => {
                      setCompanyUpdatedFleet(true);
                      setTimeout(() => {
                        setCompanyUpdatedFleet(false);
                        setIsCompanyUpdateClickedFleet(false);
                      }, 2000);
                    });
                    console.log("The data", companyValuesFleet.fleet_name)
                  }}
                >
                  Next
                </button>
              </div>
              <div className='w-full py-5'>
                  <p>*We would appreciate it if you would take the necessary steps to keep all provided information accurate and up-to-date by notifying us of any changes or updating your profile accordingly.</p>
              </div>
            </div>
            {companyUpdatedFleet && (
              <div className='p-5 rounded-xl translate-x-[-50%] translate-y-[-50%] left-[50%] right-[50%] absolute flex items-center justify-center flex-col'
                style={{
                  width: "244px",
                  height: "fit-content",
                  background: "#fff",
                  top: "50%",
                  right: "50%",
                  transform: "translate(-50%, -50%"
                }}>
                <p style={{ fontSize: "1rem", textAlign: "center", marginBottom: "1rem" }}>Fleet details updated successfully</p>
              </div>
            )}
          </div>
        )}

        {tabText === "contact details" && (
          <div className='w-full mt-5 relative'>
            <div className='mb-5'>
              <p>How can we contact you?</p>
            </div>
            <div style={{
              width:"30%",
              height:"fit-content"
            }}>
              <div className='w-full mt-2'>
                <input
                  type="text"
                  className=''
                  placeholder='Telephone'
                  value={contactValuesFleet.Telephone}
                  onChange={(e) => {
                    if (!isContactUpdateClicked) {
                      setcontactValuesFleet({
                        ...contactValuesFleet,
                        Telephone: e.target.value
                      });
                    }
                  }}
                  style={{
                    width: "100%",
                    borderRadius: "15px",
                    padding: ".3rem 1rem",
                    background:"rgb(232 232 232)",
                    color:"#848484"
                  }}
                />
              </div>

              <div className='w-full mt-2'>
                <input
                  type="text"
                  className=''
                  placeholder='phone'
                  value={contactValuesFleet.phone}
                  onChange={(e) => {
                    if (!isContactUpdateClicked) {
                      setcontactValuesFleet({
                        ...contactValuesFleet,
                        phone: e.target.value
                      });
                    }
                  }}
                  style={{
                    width: "100%",
                    borderRadius: "15px",
                    padding: ".3rem 1rem",
                    background:"rgb(232 232 232)",
                    color:"#848484"
                  }}
                />
              </div>

              <div className='flex items-center w-full my-3'>
                <input
                  type="checkbox"
                  className='mx-4 w-[1rem] h-[1rem]'
                  placeholder='Company Email'
                />
                <p className='p-0 m-0'>Postal address same as physical address</p>
              </div>

              <div className='w-full mt-2'>
                <input
                  type="text"
                  className='Company Email'
                  value={contactValuesFleet.companyEmail}
                  onChange={(e) => {
                    if (!isContactUpdateClicked) {
                      setcontactValuesFleet({
                        ...contactValuesFleet,
                        companyEmail: e.target.value
                      });
                    }
                  }}
                  style={{
                    width: "100%",
                    borderRadius: "15px",
                    padding: ".3rem 1rem",
                    background:"rgb(232 232 232)",
                    color:"#848484"
                  }}
                />
              </div>

            </div>

            <div className='flex mt-3 justify-end w-full'>
              <button
                className='px-8 py-2 font-bold  text-[1.1rem]'
                style={{ borderRadius: "20px", background: "#fce200" }}
                onClick={() => {
                  setIsContactUpdateClickedFleet(true);
                  const updatedData = {
                    Telephone: contactValuesFleet.telephone,
                    physical_address: contactValuesFleet.physical_address,
                  };
                  firebase.database().ref("/fleets/" + userUid).update(updatedData).then(() => {
                    setContactUpdatedFleet(true);
                    setTimeout(() => {
                      setContactUpdatedFleet(false);
                      setIsContactUpdateClickedFleet(false)
                    }, 2000);
                  });
                }}
              >
                Next
              </button>
            </div>

            <div className='w-full py-5'>
                <p>*We would appreciate it if you would take the necessary steps to keep all provided information accurate and up-to-date by notifying us of any changes or updating your profile accordingly.</p>
            </div>

            {contactUpdatedFleet && (
              <div className='p-5 rounded-xl absolute flex items-center justify-center flex-col'
                style={{
                  width:"244px",
                  height:"fit-content",
                  background:"#fff",
                  top:"50%",
                  right:"50%",
                  transform:"translate(-50%, -50%)"
                }}
              >
                <p style={{fontSize:"1rem", textAlign:"center", marginBottom:"1rem"}}>Contact details updated successfully</p>
              </div>
            )}
          </div>
        )}

        {tabText === "directors" && (
          <div className='w-full mt-5'>
            <div className='mb-5'>
              <p>We are getting there, add your directors below  </p>
            </div>
            <div className='w-[100%] flex justify-between h-fit'>
            <div className='w-[30%]'>
              <div className='w-full mt-2'>
                <input
                  type="text"
                  className=''
                  placeholder='First Name'
                  value={director.first_name}
                  onChange={(e) => {
                    const { value } = e.target;
                    setDirector((prev) => ({
                      ...prev,
                      first_name: value
                    }));
                  }}
                  style={{
                    width: "100%",
                    borderRadius: "15px",
                    padding: ".3rem 1rem",
                    background:"rgb(232 232 232)",
                    color:"#848484"
                  }}
                />
              </div>

              <div className='w-full mt-2'>
                <input
                  type="text"
                  className=''
                  placeholder='Last Name'
                  value={director.last_name}
                  onChange={(e) => {
                    const { value } = e.target;
                    setDirector((prev) => ({
                      ...prev,
                      last_name: value
                    }));
                  }}
                  style={{
                    width: "100%",
                    borderRadius: "15px",
                    padding: ".3rem 1rem",
                    background:"rgb(232 232 232)",
                    color:"#848484"
                  }}
                />
              </div>

              <div className='w-full mt-2'>
                <input
                  type="text"
                  className=''
                  placeholder='Email'
                  value={director.email}
                  onChange={(e) => {
                    const { value } = e.target;
                    setDirector((prev) => ({
                      ...prev,
                      email: value
                    }));
                  }}
                  style={{
                    width: "100%",
                    borderRadius: "15px",
                    padding: ".3rem 1rem",
                    background:"rgb(232 232 232)",
                    color:"#848484"
                  }}
                />
              </div>

              <div className='w-full mt-2'>
                <input
                  type="text"
                  className=''
                  placeholder='ID/ Passport Number'
                  value={director.identity_number}
                  onChange={(e) => {
                    const { value } = e.target;
                    setDirector((prev) => ({
                      ...prev,
                      identity_number: value
                    }));
                  }}
                  style={{
                    width: "100%",
                    borderRadius: "15px",
                    padding: ".3rem 1rem",
                    background:"rgb(232 232 232)",
                    color:"#848484"
                  }}
                />
              </div>
            </div>
            <div className='w-[30%]'>
                  <input
                    type="file"
                    style={{ position: 'absolute', top: 0, left: 0, opacity: 0, zIndex: -1 }}
                    onChange={(e) => handleDirectorIdUpload(e.target.files[0])}

                    ref={fileInputRefFour} // Assigning the ref to the fileInputRef variable
                  />
                  <div
                      className='flex justify-center flex-col items-center rounded-[15px] w-[100%] cursor-pointer h-[70px] bg-[#e8e8e8]'
                      onClick={() => fileInputRefFour.current.click()} // Click the hidden file input when this div is clicked
                  >
                        <p className={`text-[1.3rem] mb-2 ${director.Id_copy_url ? "text-[green]" : "text-[#878787]"}`}><FiUpload /></p>
                        <p className='text-center'>Upload Certified ID Copy <br /> <p className='text-[.7rem] text-center'>Not older than 3 months</p></p>
                  </div>

                <input
                    type="file"
                    style={{ position: 'absolute', top: 0, left: 0, opacity: 0, zIndex: -1 }}
                    onChange={(e) => handleDirectorImageUpload(e.target.files[0])}

                    ref={fileInputRefFive} // Assigning the ref to the fileInputRef variable
                  />
                  <div
                      className='flex justify-center mt-3 flex-col items-center rounded-[15px] w-[100%] cursor-pointer h-[70px] bg-[#e8e8e8]'
                      onClick={() => fileInputRefFive.current.click()} // Click the hidden file input when this div is clicked
                  >
                        <p className={`text-[1.3rem] mb-2 ${director.picture_url ? "text-[green]" : "text-[#878787]"}`}><FiUpload /></p>
                        <p>Upload Picture</p>
                  </div>
            </div>

            <div className='w-[30%]'>
                <div className='flex items-center'>
                  <p>Add another director</p>
                  <p
                      className='w-[25px] h-[25px] bg-[#fce200] flex justify-center text-[1.5rem] font-bold ml-2 items-center rounded-full cursor-pointer'
                      onClick={addDirector}
                  >
                      +
                  </p>
                </div>
            </div>

            </div>
            <div className='mt-5 mb-3'>
              <p className='mb-2'>Added directors</p>
              <div className='h-[140px] max-h-[140px] overflow-scroll scrollbar-hide'>
                {myRenamedSetDirectors.length > 0 && myRenamedSetDirectors.map((director, i) => (
                  <div key={i} className='mb-2 flex items-center'>
                    <div className='w-[2rem] h-[2rem] rounded-full bg-[grey]'>
                      {director.picture_url &&
                        <img src={director.picture_url} alt="" className='h-full w-full object-cover rounded-full' />
                      }
                    </div>
                    <div className='ml-2'>
                      <p className='p-0 m-0 font-bold'>{director.first_name}</p>
                      <p className='p-0 m-0'>{director.email}</p>
                    </div>
                    {/* <div className='ml-4 flex justify-center items-center'>
                      <p className='w-[1.2rem] h-[1.2rem] cursor-pointer bg-[#fce200] text-[.6rem] flex justify-center items-center m-0 p-0 rounded-full'><FaPen /></p>
                    </div> */}
                  </div>
                ))
                }
              </div>
            </div>

            {directorAdded && (
              <div className='p-5 rounded-xl translate-x-[-50%] translate-y-[-50%] left-[50%] right-[50%] absolute flex items-center justify-center flex-col'
                style={{
                  width: "244px",
                  height: "fit-content",
                  background: "#fff",
                  top: "50%",
                  right: "50%",
                  transform: "translate(-50%, -50%"
                }}>
                <p style={{ fontSize: "1rem", textAlign: "center", marginBottom: "1rem" }}>director added successfully</p>
              </div>
            )}
            {inputDirector&&(
              <div className='p-5 translate-x-[-50%] translate-y-[-50%] left-[50%] right-[50%] rounded-xl absolute flex items-center justify-center flex-col'
                style={{
                  width: "244px",
                  height: "fit-content",
                  background: "#fff",
                  top: "50%",
                  right: "50%",
                  transform: "translate(-50%, -50%"
                }}>
              <p style={{ fontSize: "1rem", textAlign: "center", marginBottom: "1rem" }}>Please input all the fields</p>
              <p className='cursor-pointer bg-[#fce200] p-2 rounded-xl' onClick={()=> {setInputDirector(false)}}>Ok</p>
            </div>
            )}
          </div>
        )}

        {tabText === "banking" && (
          <div className='w-full mt-5'>
            <div className='w-[100%] h-fit'>
              <div className='mb-3'>
                <p>For the purpose of payments and refunds, we will need this <br /> information.</p>
              </div>
              <div className='w-[100%] flex justify-start'>
                <div className='w-[30%] mr-14'>
                  <div className='w-full mt-2'>
                    <input
                      type="text"
                      className=''
                      placeholder='Bank Name'
                      value={banking.bank_name}
                      onChange={(e) => {
                        const { value } = e.target;
                        setBanking((prev) => ({
                          ...prev,
                          bank_name: value
                        }));
                      }}
                      style={{
                        width: "100%",
                        borderRadius: "15px",
                        padding: ".3rem 1rem",
                        background:"rgb(232 232 232)",
                        color:"#848484"
                      }}
                    />
                  </div>

                  <div className='w-full mt-2'>
                    <input
                      type="text"
                      className=''
                      placeholder='Branch Name'
                      value={banking.branch_name}
                      onChange={(e) => {
                        const { value } = e.target;
                        setBanking((prev) => ({
                          ...prev,
                          branch_name: value
                        }));
                      }}
                      style={{
                        width: "100%",
                        borderRadius: "15px",
                        padding: ".3rem 1rem",
                        background:"rgb(232 232 232)",
                        color:"#848484"
                      }}
                    />
                  </div>

                  <div className='w-full mt-2'>
                    <input
                      type="number"
                      className=''
                      placeholder='Branch Code'
                      value={banking.branch_code}
                      onChange={(e) => {
                        const { value } = e.target;
                        setBanking((prev) => ({
                          ...prev,
                          branch_code: value
                        }));
                      }}
                      style={{
                        width: "100%",
                        borderRadius: "15px",
                        padding: ".3rem 1rem",
                        background:"rgb(232 232 232)",
                        color:"#848484"
                      }}
                    />
                  </div>

                  <div className='w-full mt-2'>
                    <input
                      type="number"
                      className=''
                      placeholder='Account Number'
                      value={banking.account_number}
                      onChange={(e) => {
                        const { value } = e.target;
                        setBanking((prev) => ({
                          ...prev,
                          account_number: value
                        }));
                      }}
                      style={{
                        width: "100%",
                        borderRadius: "15px",
                        padding: ".3rem 1rem",
                        background:"rgb(232 232 232)",
                        color:"#848484"
                      }}
                    />
                  </div>
                </div>

                <div className='w-[30%]'>
                  <input
                    type="file"
                    style={{ position: 'absolute', top: 0, left: 0, opacity: 0, zIndex: -1 }}
                    onChange={(e) => handleBankLetterUpload(e.target.files[0])}

                    ref={fileInputRefSix} // Assigning the ref to the fileInputRef variable
                  />
                  <div
                      className='flex justify-center flex-col items-center rounded-[15px] w-[100%] cursor-pointer h-[70px] bg-[#e8e8e8]'
                      onClick={() => fileInputRefSix.current.click()} // Click the hidden file input when this div is clicked
                  >
                        <p className={`text-[1.3rem] mb-2 ${banking.bank_letter_url ? "text-[green]" : "text-[#878787]"}`}><FiUpload /></p>
                        <p className='text-center'>Upload Certified ID Copy <br /> <p className='text-[.7rem] text-center'>Not older than 3 months</p></p>
                  </div>
                </div>
              </div>

              <div className='flex mt-3 justify-end w-full'>
                <button
                  className='px-8 py-2 font-bold  text-[1.1rem]'
                  style={{ borderRadius: "20px", background: "#fce200" }}
                  onClick={() => {
                    addBanking();
                  }}
                >
                  Next
                </button>
              </div>

              <div className='w-full py-5'>
                <p>*Please take the necessary steps to keep all provided information accurate and up-to-date by notifying us of any changes or updating your profile accordingly.</p>
              </div>

            </div>
            {bankAdded && (
              <div className='p-5 translate-x-[-50%] translate-y-[-50%] left-[50%] right-[50%] rounded-xl absolute flex items-center justify-center flex-col'
                style={{
                  width: "244px",
                  height: "fit-content",
                  background: "#fff",
                  top: "50%",
                  right: "50%",
                  transform: "translate(-50%, -50%"
                }}>
                <p style={{ fontSize: "1rem", textAlign: "center", marginBottom: "1rem" }}>Banking details updated successfully</p>
              </div>
            )}

            {inputBanking && (
              <div className='p-5 translate-x-[-50%] translate-y-[-50%] left-[50%] right-[50%] rounded-xl absolute flex items-center justify-center flex-col'
                  style={{
                    width: "244px",
                    height: "fit-content",
                    background: "#fff",
                    top: "50%",
                    right: "50%",
                    transform: "translate(-50%, -50%"
                  }}>
                <p style={{ fontSize: "1rem", textAlign: "center", marginBottom: "1rem" }}>Please input all the fields</p>
                <p className='cursor-pointer bg-[#fce200] p-2 rounded-xl' onClick={()=> {setInputBanking(false)}}>Ok</p>
              </div>
            )}
          </div>
        )}

        {tabText === "payment" && (
          <div className='w-full mt-5 relative'>
            <div style={{ width: "100%", height: "fit-content" }}>
              <div className='w-full'>
                <p className='!w-[100%]'>Upload invoices for completed shipments to receive payment</p>
              </div>
              <div className='flex mt-10'>
                  <div
                    className='flex items-center'
                    onClick={() => {
                      setArchiveOut("Outstanding")
                    }}
                  >
                    <div className={`w-[1rem] cursor-pointer h-[1rem] rounded-full ${archiveOut === "Outstanding" ? "bg-[#fce200] border-[1px] border-black" : "bg-black"} mr-2`}></div>
                    <p className='p-0 m-0'>Outstanding</p>
                  </div>

                  <div
                    className='flex items-center ml-6'
                    onClick={() => {
                      setArchiveOut("Archive")
                    }}
                  >
                    <div className={`w-[1rem] cursor-pointer h-[1rem] rounded-full ${archiveOut === "Archive" ? "bg-[#fce200] border-[1px] border-black" : "bg-black"} mr-2`}></div>
                    <p className='p-0 m-0'>Archive</p>
                  </div>
              </div>
              {archiveOut === "Outstanding" &&
                <div className='w-full bg-[#fff]'>
                  <div className='h-[26px] mt-3 w-full bg-[#E8E8E8] flex justify-between items-center'>
                      <div className='w-[12.5%] pl-4 flex h-full items-center'>
                        <p className='font-bold m-0 p-0'>Latest POD</p>
                      </div>
                      <div className='w-[12.5%] flex h-full items-center'>
                        <p className='font-bold m-0 p-0'>Reference</p>
                      </div>
                      <div className='w-[12.5%] flex h-full items-center'>
                        <p className='font-bold m-0 p-0'>Cargo</p>
                      </div>
                      <div className='w-[12.5%] flex h-full items-center'>
                        <p className='font-bold m-0 p-0'>Company</p>
                      </div>
                      <div className='w-[12.5%] flex h-full items-center'>
                        <p className='font-bold m-0 p-0'>Vehicle</p>
                      </div>
                      <div className='w-[12.5%] flex h-full items-center'>
                        <p className='font-bold m-0 p-0'>Route</p>
                      </div>
                      <div className='w-[12.5%] flex h-full items-center'>
                        <p className='font-bold m-0 p-0'>Invoice</p>
                      </div>
                      <div className='w-[12.5%] flex h-full items-center'>
                        <p className='font-bold m-0 p-0'>Loads</p>
                      </div>
                      <div className='w-[12.5%] flex h-full items-center'>
                        <p className='font-bold m-0 p-0'></p>
                      </div>
                  </div>

                  <div className={`${bookingAccepted.length > 6 ? "max-h-[226px]" : ""} overflow-scroll scrollbar-hide w-full`}>
                    {bookingAccepted.length > 0 && bookingAccepted.map((book, i) => (
                      <div
                        key={i}
                        className='h-[40px] cursor-pointer w-full flex justify-between items-center'
                        onClick={() => {
                          setIsBookingSummary(true)
                          setUniversalSelectedBook(fleetBookingData[i])
                        }}
                      >
                        <div className='w-[12.5%] pl-4 flex h-full items-center'>
                          <p className='m-0 p-0'>None</p>
                        </div>
                        <div className='w-[12.5%] flex h-full items-center'>
                          <p className='m-0 p-0'>{book.booking_id.substring(1,7)}</p>
                        </div>
                        <div className='w-[12.5%] flex h-full items-center'>
                          <p className='m-0 p-0'>{fleetBookingData[i].cargoInformation.productName}</p>
                        </div>
                        <div className='w-[12.5%] flex h-full items-center'>
                          <p className='m-0 p-0'>{bookingPartyData[i].companyName}</p>
                        </div>
                        <div className='w-[12.5%] flex h-full items-center'>
                          <p className='m-0 p-0'>{fleetBookingData[i].cargoInformation.vehicle_type}</p>
                        </div>
                        <div className='w-[12.5%] flex h-full items-center'>
                          <p className='m-0 p-0'>{(fleetBookingData[i].puDetails.puCityName.substring(0,3)).toUpperCase()} - {(fleetBookingData[i].doDetails.doCityName.substring(0,3)).toUpperCase()}</p>
                        </div>
                        <div className='w-[12.5%] flex h-full items-center'>
                          <p className='m-0 p-0'>R7,006.00</p>
                        </div>
                        <div className='w-[12.5%] flex h-full items-center'>
                          <p className='m-0 p-0'>{book.total_loads} out of {fleetBookingData[i].actual_loads_for_cargo}</p>
                        </div>
                        <div
                          className='w-[12.5%] flex h-full items-center'
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedBookInvoice(fleetBookingData[i])
                            setIsFleetOwnerUpload(true)
                          }}
                        >
                          <div
                              className={`m-0 px-3 py-[.2rem] bg-[#fce200] rounded-xl cursor-pointer flex justify-center items-center`}
                          >
                              <p className={`text-[.8rem] m-0 p-0 text-[#000] mr-2`}><FiUpload /></p>
                              <p className='m-0 p-0'>Invoice</p>
                          </div>
                        </div>
                      </div>
                    ))
                    }
                  </div>
                </div>
              }

              {archiveOut === "Archive" &&
                <div className='w-full bg-[#fff]'>
                  <div className='h-[26px] mt-3 w-full bg-[#E8E8E8] flex justify-between items-center'>
                      <div className='w-[12.5%] flex h-full justify-center items-center'>
                        <p className='font-bold m-0 p-0'>Latest POD</p>
                      </div>
                      <div className='w-[12.5%] flex h-full justify-center items-center'>
                        <p className='font-bold m-0 p-0'>Reference</p>
                      </div>
                      <div className='w-[12.5%] flex h-full justify-center items-center'>
                        <p className='font-bold m-0 p-0'>Cargo</p>
                      </div>
                      <div className='w-[12.5%] flex h-full justify-center items-center'>
                        <p className='font-bold m-0 p-0'>Company</p>
                      </div>
                      <div className='w-[12.5%] flex h-full justify-center items-center'>
                        <p className='font-bold m-0 p-0'>Vehicle</p>
                      </div>
                      <div className='w-[12.5%] flex h-full justify-center items-center'>
                        <p className='font-bold m-0 p-0'>Route</p>
                      </div>
                      <div className='w-[12.5%] flex h-full justify-center items-center'>
                        <p className='font-bold m-0 p-0'>Invoice</p>
                      </div>
                      <div className='w-[12.5%] flex h-full justify-center items-center'>
                        <p className='font-bold m-0 p-0'>Loads</p>
                      </div>
                      <div className='w-[12.5%] flex h-full justify-center items-center'>
                        <p className='font-bold m-0 p-0'></p>
                      </div>
                  </div>
                  <div className='h-[40px] w-full flex justify-between items-center'>
                      <div className='w-[12.5%] flex h-full justify-center items-center'>
                        <p className='m-0 p-0'>13 Dec 2022 16:41</p>
                      </div>
                      <div className='w-[12.5%] flex h-full justify-center items-center'>
                        <p className='m-0 p-0'>QWE123</p>
                      </div>
                      <div className='w-[12.5%] flex h-full justify-center items-center'>
                        <p className='m-0 p-0'>Bananas</p>
                      </div>
                      <div className='w-[12.5%] flex h-full justify-center items-center'>
                        <p className='m-0 p-0'>Y2K Bananas</p>
                      </div>
                      <div className='w-[12.5%] flex h-full justify-center items-center'>
                        <p className='m-0 p-0'>Refrigerated</p>
                      </div>
                      <div className='w-[12.5%] flex h-full justify-center items-center'>
                        <p className='m-0 p-0'>JOH - DUR</p>
                      </div>
                      <div className='w-[12.5%] flex h-full justify-center items-center'>
                        <p className='m-0 p-0'>R7,006.00</p>
                      </div>
                      <div className='w-[12.5%] flex h-full justify-center items-center'>
                        <p className='m-0 p-0'>3 out of 3</p>
                      </div>
                      <div className='w-[12.5%] flex h-full justify-center items-center'>
                        <p className='m-0 px-3 py-[.2rem] bg-[#fce200] rounded-xl cursor-pointer flex justify-center items-center'>
                          <p className='text-[.8rem] p-0 m-0 text-[#000]'><FiUpload /></p>
                          <p className='p-0 m-0 ml-2'>Invoice</p>
                        </p>
                      </div>
                  </div>
                </div>
              }
            </div>

            {uploadedinvoice && (
              <div className='left-[50%] right-0 top-[50%] translate-x-[-50%] translate-y-[-50%] bottom-0   p-5 rounded-xl absolute flex items-center justify-center flex-col'
                style={{
                  width: "244px",
                  height: "fit-content",
                  background: "#fff",
                  top: "50%",
                  right: "50%",
                  transform: "translate(-50%, -50%)"
                }}>
                <p style={{ fontSize: "1rem", textAlign: "center", marginBottom: "1rem" }}>Receipt uploaded successfully</p>
              </div>
            )}
          </div>
        )}

      </div>
  )
}
