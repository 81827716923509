import { useState, useMemo, useRef, useCallback } from 'react'
import { GoogleMap, useLoadScript, Marker, DirectionsService, DirectionsRenderer, Polyline } from '@react-google-maps/api'
import usePlacesAutoComplete, {getGeocode, getLatLng} from 'use-places-autocomplete'
import '../css/mapContainer.css'
import {useFleetStateContext} from '../context/FleetDealsContext'

export default function RouteMap(){
  const { isLoaded } = useLoadScript({ 
      googleMapsApiKey: 'AIzaSyCDv9ViM5f03ZnI9i8vKz9xI_conVCyKx8',
      libraries: ["places"],
  })
  if(!isLoaded) return <div>Loading...</div>
  return (
    <Map/>
  )
}

const Map = () => {
  const {
    selectedStartAddress,
    selectedEndAddress
  } = useFleetStateContext();

  const mapRef = useRef()
  const center = useMemo(() => ({lat: -26.11813201804211, lng: 27.889527283718596}), [])

  const [response, setResponse] = useState(null)

  const polyOptions = {
    strokeColor: '#fce200',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#fce200',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    paths: [
      {lat: 37.772, lng: -122.214},
      {lat: 21.291, lng: -157.821},
      {lat: -18.142, lng: 178.431},
      {lat: -27.467, lng: 153.027}
    ],
    zIndex: 1
  };

  const options = useMemo(
    () => ({
        mapId:"2cc193ea5e72ad61", 
        disableDefaultUI: true,
        clickableIcons: false,

    }), [])
    const onLoad = useCallback((map) => (mapRef.current = map), []);

    const polyOnLoad = polyline => {
      console.log('polyline: ', polyline)
    };

    const path = [
      {lat: 37.772, lng: -122.214},
      {lat: 21.291, lng: -157.821},
      {lat: -18.142, lng: 178.431},
      {lat: -27.467, lng: 153.027}
    ];

    // const directionsCallback = useMemo((response) =>  {
    //   console.log(response)
  
    //   if (response !== null) {
    //     if (response?.status === 'OK') {
    //       // this.setState(
    //       //   () => ({
    //       //     response
    //       //   })
    //       // )
    //       setResponse({response})
    //     } else {
    //       console.log('response: ', response)
    //     }
    //   }
    // }, [response]) 

  return (
    <>
      <GoogleMap 
        zoom={10}
        center={{lat: 37.772, lng: -122.214}} 
        mapContainerClassName="map-container"
        options={options}
        onLoad={onLoad}
      >
        <> 
          <Polyline
            onLoad={polyOnLoad}
            path={path}
            options={options}
          />
        </>
      </GoogleMap>
    </>
    
  )
}
